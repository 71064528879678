import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";

import Page from "../components/Page";

const PrivateRoute = ({ component: Component, isAuthenticated, authChecked, ...rest }) => {
    return (
        authChecked && (
            <Route
                {...rest}
                render={(props) =>
                    isAuthenticated ? (
                        <Page>
                            <Component {...props} />
                        </Page>
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                            }}
                        />
                    )
                }
            />
        )
    );
};
PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
    location: PropTypes.object,
    cookies: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
};

export default PrivateRoute;
