import { Podcast, PodcastProps } from "./podcast";
import { Header, HorizontalList } from './commonStyles';
import styled from "styled-components";

const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 10px;
`;

interface PodcastCarouselProps {
    data: {
        title: string;
        destination: string;
        podcasts: [PodcastProps["data"]];
    }
}

export default function PodcastCarousel({ data }: PodcastCarouselProps) {
    return (
        <div>
            <TopContainer>
                <Header>
                    {data.title}
                </Header>
                {data.destination && <button onClick={() => alert(data.destination)}>{'View all >'}</button>}
            </TopContainer>
            <HorizontalList>
                {data.podcasts.map((e, i) => <Podcast key={i} data={e} />)}
            </HorizontalList>
        </div>
    )
}