import { Box, Button, ModalFooter } from "@chakra-ui/react";

interface Props {
    handleSubmit: any;
    isSubmitting: boolean;
}

export default function PodmeModalFooter({ handleSubmit, isSubmitting }: Props) {
    return (
        <ModalFooter display="flex" justifyContent="center">
            <Box padding={4}>
                <Button colorScheme="green" size="lg" onClick={handleSubmit} isLoading={isSubmitting}>
                    Save
                </Button>
            </Box>
        </ModalFooter>
    );
}
