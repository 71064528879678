import { put, takeLatest, call } from "redux-saga/effects";
import { ApiError } from "../../redux/types";

import { createAxiosRequest, createPostAxiosRequest, createPutAxiosRequest } from "../../utils";

import {
    CreateVoucherCampaign,
    CreateVoucherCampaignSuccess,
    CreateVoucherCampaignFailed,
    UpdateVoucherCampaign,
    ArchiveVoucherCampaign,
    GetActiveVoucherCampaigns,
    GetActiveVoucherCampaignsSuccess,
    GetActiveVoucherCampaignsFailed,
    GetVoucherCampaignHistory,
    VoucherCampaignType,
    ListVoucherCampaignVouchers,
    GetOverviewContent,
    GetOverviewContentSuccess,
    GetOverviewContentFailed,
    UpdateVoucherCampaignSuccess,
    UpdateVoucherCampaignFailed,
    GetVoucherCampaignHistorySuccess,
    GetVoucherCampaignHistoryFailed,
    ArchiveVoucherCampaignSuccess,
    ArchiveVoucherCampaignFailed,
    GetVoucherCampaignDiscountTypesSuccess,
    GetVoucherCampaignDiscountTypesFailed,
    ListVoucherCampaignVouchersSuccess,
    ListVoucherCampaignVouchersFailed,
    GetVoucherByCodeFailed,
    GetVoucherByCode,
    GetVoucherByCodeSuccess,
} from "./types";

function* getOverviewContent({ region }: GetOverviewContent): any {
    try {
        const result = yield call(createAxiosRequest, `api/voucherCampaign/statistics${region ? `?region=${region}` : ""}`);

        const { data } = result;

        return yield put<GetOverviewContentSuccess>({
            type: "GET_OVERVIEW_CONTENT_SUCCESS",
            activeVoucherCampaigns: data.activeVoucherCampaigns,
            topVoucherCampaigns: data.topVoucherCampaigns,
        });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<GetOverviewContentFailed>({
                type: "GET_OVERVIEW_CONTENT_FAILED",
                message: error.message,
                showToastErrorMessage: true,
            });
        }
    }
}

function* getActiveVoucherCampaigns({ page, pageSize, region }: GetActiveVoucherCampaigns): any {
    try {
        const activeVoucherCampaignResult = yield call(
            createAxiosRequest,
            `api/voucherCampaign/active?page=${page}&pageSize=${pageSize}${region ? `&region=${region}` : ""}`
        );

        const upcomingVoucherCampaignResult = yield call(
            createAxiosRequest,
            `api/voucherCampaign/upcoming?page=${page}&pageSize=${pageSize}${region ? `&region=${region}` : ""}`
        );

        return yield put<GetActiveVoucherCampaignsSuccess>({
            type: "GET_ACTIVE_VOUCHERCAMPAIGNS_SUCCESS",
            activeVoucherCampaignsResult: activeVoucherCampaignResult.data,
            upcomingVoucherCampaignsResult: upcomingVoucherCampaignResult.data,
        });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<GetActiveVoucherCampaignsFailed>({
                type: "GET_ACTIVE_VOUCHERCAMPAIGNS_FAILED",
                message: error.message,
                showToastErrorMessage: true,
            });
        }
    }
}

function* createVoucherCampaign({ values }: CreateVoucherCampaign): any {
    var body;

    switch (values.voucherCampaignType) {
        case VoucherCampaignType.Generic:
            body = {
                discriminator: "CreateGenericVoucherCampaignRequest",
                region: values.region,
                voucherCampaignName: values.voucherCampaignName,
                discountTypeId: values.discountTypeId,
                voucherCode: values.voucherCode,
                validFrom: values.validFrom,
                validUntil: values.validUntil,
                redemptionLimit: values.redemptionLimit,
                churnEligibilityInDays: values.churnEligibilityInDays,
            };
            break;

        case VoucherCampaignType.Unique:
            body = {
                discriminator: "CreateUniqueVoucherCampaignRequest",
                region: values.region,
                voucherCampaignName: values.voucherCampaignName,
                discountTypeId: values.discountTypeId,
                validFrom: values.validFrom,
                validUntil: values.validUntil,
                redemptionLimit: values.redemptionLimit,
                churnEligibilityInDays: values.churnEligibilityInDays,
            };
            break;

        case VoucherCampaignType.Reward:
            body = {
                discriminator: "CreateRewardVoucherCampaignRequest",
                region: values.region,
                voucherCampaignName: values.voucherCampaignName,
                discountTypeId: values.discountTypeId,
                validFrom: values.validFrom,
                validUntil: values.validUntil,
                redemptionLimit: values.redemptionLimit,
                monthCredit: values.monthCredit,
            };
            break;
    }

    try {
        const response = yield call(createPostAxiosRequest, `api/voucherCampaign`, body);
        return yield put<CreateVoucherCampaignSuccess>({
            type: "CREATE_VOUCHERCAMPAIGN_SUCCESS",
            data: response,
            message: "Campaign created successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<CreateVoucherCampaignFailed>({
                type: "CREATE_VOUCHERCAMPAIGN_FAILED",
                message: error.message,
                showToastErrorMessage: true,
            });
        }
    }
}

function* updateVoucherCampaign({ values }: UpdateVoucherCampaign): any {
    var body;
    if (values.voucherCampaignType === VoucherCampaignType.Generic) {
        body = {
            discriminator: "UpdateGenericVoucherCampaignRequest",
            validUntil: values.validUntil,
            redemptionLimit: values.redemptionLimit,
        };
    }

    if (values.voucherCampaignType === VoucherCampaignType.Unique) {
        body = {
            discriminator: "UpdateUniqueVoucherCampaignRequest",
            validUntil: values.validUntil,
            redemptionLimit: values.redemptionLimit,
        };
    }

    if (values.voucherCampaignType === VoucherCampaignType.Reward) {
        body = {
            discriminator: "UpdateRewardVoucherCampaignRequest",
            validUntil: values.validUntil,
            redemptionLimit: values.redemptionLimit,
            monthCredit: values.monthCredit,
        };
    }

    try {
        yield call(createPutAxiosRequest, `api/voucherCampaign/${values.id}`, body);

        return yield put<UpdateVoucherCampaignSuccess>({
            type: "UPDATE_VOUCHERCAMPAIGN_SUCCESS",
            message: "Campaign updated successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<UpdateVoucherCampaignFailed>({
                type: "UPDATE_VOUCHERCAMPAIGN_FAILED",
                message: error.message,
                showToastErrorMessage: true,
            });
        }
    }
}

function* getVoucherCampaignHistory({ page, pageSize, region }: GetVoucherCampaignHistory): any {
    try {
        const result = yield call(
            createAxiosRequest,
            `api/voucherCampaign/inactive?page=${page}&pageSize=${pageSize}${region ? `&region=${region}` : ""}`
        );

        return yield put<GetVoucherCampaignHistorySuccess>({ type: "GET_VOUCHERCAMPAIGN_HISTORY_SUCCESS", data: result.data });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<GetVoucherCampaignHistoryFailed>({
                type: "GET_VOUCHERCAMPAIGN_HISTORY_FAILED",
                message: error.message,
                showToastErrorMessage: true,
            });
        }
    }
}

function* archiveVoucherCampaign({ values }: ArchiveVoucherCampaign): any {
    try {
        yield call(createPutAxiosRequest, `api/voucherCampaign/archive/${values.id}`, {});

        return yield put<ArchiveVoucherCampaignSuccess>({
            type: "ARCHIVE_VOUCHERCAMPAIGN_SUCCESS",
            message: "Campaign archived successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<ArchiveVoucherCampaignFailed>({
                type: "ARCHIVE_VOUCHERCAMPAIGN_FAILED",
                message: error.message,
                showToastErrorMessage: true,
            });
        }
    }
}

function* getAvailableDiscountTypes(): any {
    try {
        const result = yield call(createAxiosRequest, `api/voucherCampaign/available-discount-types`);

        return yield put<GetVoucherCampaignDiscountTypesSuccess>({
            type: "GET_VOUCHERCAMPAIGN_DISCOUNT_TYPES_SUCCESS",
            discountTypes: result.data.items,
        });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<GetVoucherCampaignDiscountTypesFailed>({
                type: "GET_VOUCHERCAMPAIGN_DISCOUNT_TYPES_FAILED",
                message: error.message,
                showToastErrorMessage: true,
            });
        }
    }
}

function* listVoucherCampaignVouchers({ values }: ListVoucherCampaignVouchers): any {
    try {
        const result = yield call(createAxiosRequest, `api/voucherCampaign/vouchers/${values.id}`);

        if (result.data.items) {
            var csvContent = "Code,Created at,Redeemed at\r\n";
            result.data.items.forEach((element: any) => {
                var row = element.code + "," + element.createdAt + "," + element.redeemedAt + "\r\n";
                csvContent += row;
            });

            const blob = new Blob([csvContent], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            const sanitizedFileName = values.voucherCampaignName.replace(/[^a-z0-9]/gi, "_").toLowerCase();
            link.download = "voucher-codes-" + sanitizedFileName + ".csv";
            link.href = url;
            link.click();
        }
        return yield put<ListVoucherCampaignVouchersSuccess>({ type: "LIST_VOUCHERCAMPAIGNVOUCHERS_SUCCESS" });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<ListVoucherCampaignVouchersFailed>({
                type: "LIST_VOUCHERCAMPAIGNVOUCHERS_FAILED",
                message: error.message,
                showToastErrorMessage: true,
            });
        }
    }
}

function* getVoucherByCode({ code, region }: GetVoucherByCode): any {
    try {
        const result = yield call(createAxiosRequest, `api/voucherCampaign/get-voucher-by-code?voucherCode=${code}&region=${region}`);
        return yield put<GetVoucherByCodeSuccess>({ type: "GET_VOUCHER_BY_CODE_SUCCESS", data: result.data });
    } catch (e) {
        if (e && e.stack && e.message) {
            return yield put<GetVoucherByCodeFailed>({
                type: "GET_VOUCHER_BY_CODE_FAILED",
                message: e.message,
                showToastErrorMessage: true,
            });
        }
    }
}

export default [
    takeLatest("GET_OVERVIEW_CONTENT", getOverviewContent),
    takeLatest("GET_ACTIVE_VOUCHERCAMPAIGNS", getActiveVoucherCampaigns),
    takeLatest("CREATE_VOUCHERCAMPAIGN", createVoucherCampaign),
    takeLatest("UPDATE_VOUCHERCAMPAIGN", updateVoucherCampaign),
    takeLatest("GET_VOUCHERCAMPAIGN_HISTORY", getVoucherCampaignHistory),
    takeLatest("ARCHIVE_VOUCHERCAMPAIGN", archiveVoucherCampaign),
    takeLatest("GET_VOUCHERCAMPAIGN_DISCOUNT_TYPES", getAvailableDiscountTypes),
    takeLatest("LIST_VOUCHERCAMPAIGNVOUCHERS", listVoucherCampaignVouchers),
    takeLatest("GET_VOUCHER_BY_CODE", getVoucherByCode),
];
