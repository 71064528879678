import config from "../config";
import { ExportUsersResponseModel, ExportedUserModel } from "./ExportedUserModel";

export class BrazeApi {

    private _restEndpoint = config.BRAZE_REST_ENDPOINT;
    private _restApiKey = config.BRAZE_REST_API_KEY;

    async exportUsers(userId: string): Promise<ExportedUserModel> {
        const headers = new Headers();
        headers.set('Content-Type', 'application/json')
        headers.set('Authorization', `Bearer ${this._restApiKey}`)

        const body = {
            external_ids: [userId]
        };

        const request: RequestInfo = new Request(`${this._restEndpoint}/users/export/ids`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });

        return fetch(request)
            .then(res => res.json())
            .then(res => {
                const users = res as ExportUsersResponseModel;
                return users.users[0];
            })
            .catch(err => {
                console.log(err);
                return Promise.reject(err);
            });
    }
}