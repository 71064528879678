import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Checkbox,
    CheckboxGroup,
    Input,
    Box,
    Textarea,
    Heading,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";

import UploadImageBox from "./UploadImageBox";
import { filterOutPremiumCategory, getCategoryName } from "../../utils";
import { LanguageCodes } from "../constants";

const createPremiumPodcastState = (state) => state.managePodcasts.createPremiumPodcast;

function AddPremiumPodcast({ onSubmit, resetForm }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { categories, createdPodcast, isLoading } = useSelector(createPremiumPodcastState);
    const [regions] = useState([
        { id: 1, name: "Sweden" },
        { id: 2, name: "Norway" },
        { id: 3, name: "Finland" },
    ]);
    const [formSubmitted, setFormsubmitted] = useState(false);
    const [languages] = useState(LanguageCodes);

    useEffect(() => {
        if (!isOpen) setFormsubmitted(false);
        resetForm();
    }, [isOpen]);

    return (
        <>
            <Button colorScheme="green" size="md" height="100%" borderRadius="1rem" onClick={onOpen}>
                Create podcast
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
                <ModalOverlay backgroundColor="rgba(0,0,0,0.7)" />
                <Formik
                    initialValues={{
                        title: "",
                        author: "",
                        description: "",
                        categories: [],
                        image: "",
                        regions: [],
                        primaryCategory: 0,
                        language: 0,
                        isPodmeOriginal: false,
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.title) {
                            errors.title = "Field cannot be empty";
                        }
                        if (!values.author) {
                            errors.author = "Field cannot be empty";
                        }
                        if (!values.description) {
                            errors.description = "Field cannot be empty";
                        }
                        if (!values.image) {
                            errors.image = "You must select an image";
                        }

                        if (filterOutPremiumCategory(values.categories).length === 0) {
                            errors.categories = "You have to select at least one category";
                        }

                        if (values.primaryCategory === 0) {
                            errors.primaryCategory = "You have to select primary category";
                        }

                        if (values.language === 0) {
                            errors.language = "You have to select language";
                        }

                        if (values.regions.length === 0) {
                            errors.regions = "Select at least one region";
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            onSubmit(values);
                            setSubmitting(false);
                            setFormsubmitted(false);
                        }, 800);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <ModalContent>
                            {createdPodcast ? (
                                <>
                                    <ModalHeader>
                                        <Box display="flex" justifyContent="center">
                                            <Heading size="md" display="inline-block">
                                                {createdPodcast.podcastTitle} is now created!{" "}
                                                <span role="img" aria-label="emoji">
                                                    🥳
                                                </span>
                                            </Heading>
                                        </Box>
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Box pb={4} display="flex" justifyContent="center">
                                            <Link to={`/podcasts/${createdPodcast.podcastId}`}>
                                                <Button colorScheme="teal" size="lg" onClick={onClose}>
                                                    Go to podcast
                                                </Button>
                                            </Link>
                                        </Box>
                                    </ModalBody>
                                </>
                            ) : (
                                <>
                                    <ModalHeader>
                                        <Heading size="lg">Create podcast</Heading>
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Form>
                                            <Box paddingBottom={6}>
                                                <FormControl isInvalid={errors.title && touched.title} isRequired>
                                                    <FormLabel htmlFor="title">Podcast title</FormLabel>
                                                    <Input
                                                        id="title"
                                                        placeholder="Podcast title"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.title}
                                                    />
                                                    <FormErrorMessage>{errors.title}</FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                            <Box paddingBottom={6}>
                                                <FormControl isInvalid={errors.author && touched.author} isRequired>
                                                    <FormLabel htmlFor="author">Author</FormLabel>
                                                    <Input
                                                        id="author"
                                                        placeholder="Who created this podcast?"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.author}
                                                    />
                                                    <FormErrorMessage>{errors.author}</FormErrorMessage>
                                                </FormControl>
                                            </Box>

                                            <Box paddingBottom={6}>
                                                <FormControl isRequired isInvalid={errors.image && formSubmitted}>
                                                    <FormLabel htmlFor="image">podcast image</FormLabel>
                                                    <UploadImageBox
                                                        id="image"
                                                        setValue={(value) => setFieldValue("image", value.file)}
                                                    />
                                                    <FormHelperText>The resolution should be 1024x1024</FormHelperText>
                                                    <FormErrorMessage>{errors.image}</FormErrorMessage>
                                                </FormControl>
                                            </Box>

                                            <Box paddingBottom={6}>
                                                <FormControl isRequired isInvalid={errors.description && touched.description}>
                                                    <FormLabel htmlFor="description">Description</FormLabel>
                                                    <Textarea
                                                        id="description"
                                                        placeholder="Describe the podcast"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.description}
                                                    />
                                                    <FormErrorMessage>{errors.description}</FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                            <Box paddingBottom={6}>
                                                <FormControl as="fieldset" isRequired isInvalid={errors.categories && formSubmitted}>
                                                    <FormLabel as="legend" marginBottom={2}>
                                                        Select categories that match the podcast
                                                    </FormLabel>
                                                    <CheckboxGroup
                                                        id="categories"
                                                        colorScheme="teal"
                                                        defaultValue={[]}
                                                        isInline
                                                        onChange={(cat) => {
                                                            const catArray = cat.map((item) => parseInt(item));
                                                            setFieldValue("categories", catArray);

                                                            const filteredOut = filterOutPremiumCategory(catArray);
                                                            let primaryIsNull = false;
                                                            if (
                                                                filteredOut.filter((cat) => cat === values.primaryCategory).length ===
                                                                0
                                                            ) {
                                                                setFieldValue("primaryCategory", 0);
                                                                primaryIsNull = true; // had to to it like this beacuse of formik
                                                            }

                                                            if (
                                                                (values.primaryCategory === 0 || primaryIsNull) &&
                                                                filteredOut.length > 0
                                                            )
                                                                setFieldValue("primaryCategory", filteredOut[0]);
                                                        }}
                                                    >
                                                        {categories.map((category) => (
                                                            <Checkbox key={category.id} value={`${category.id}`} marginRight={4}>
                                                                {category.name}
                                                            </Checkbox>
                                                        ))}
                                                    </CheckboxGroup>
                                                    <FormErrorMessage>{errors.categories}</FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                            <Box paddingBottom={6}>
                                                <FormControl as="fieldset" isInvalid={errors.primaryCategory && formSubmitted}>
                                                    <FormLabel as="legend" marginBottom={2}>
                                                        Select primary category for podcast
                                                    </FormLabel>
                                                    <Field
                                                        as="select"
                                                        name="primaryCategory"
                                                        onChange={(cat) => {
                                                            setFieldValue("primaryCategory", parseInt(cat.target.value));
                                                        }}
                                                        placeholder="Select category"
                                                        value={values.primaryCategory}
                                                    >
                                                        <option defaultValue>Select category</option>
                                                        {values.categories
                                                            .filter((x) => x !== "222" && x !== 222)
                                                            .map((category) => (
                                                                <option key={category} value={`${category}`}>
                                                                    {getCategoryName(category, categories)}
                                                                </option>
                                                            ))}
                                                    </Field>
                                                </FormControl>
                                            </Box>
                                            <Box paddingBottom={6}>
                                                <FormControl display="flex" alignItems="center">
                                                    <FormLabel as="legend" htmlFor="isPodmeOriginal">
                                                        Is PodmeOriginal
                                                    </FormLabel>
                                                    <Checkbox
                                                        id="isPodmeOriginal"
                                                        name="isPodmeOriginal"
                                                        value={values.isPodmeOriginal}
                                                        onChange={handleChange}
                                                    ></Checkbox>
                                                </FormControl>
                                            </Box>
                                            <Box paddingBottom={6}>
                                                <FormControl as="fieldset" isRequired isInvalid={errors.language && formSubmitted}>
                                                    <FormLabel as="legend" marginBottom={2}>
                                                        Select language for podcast
                                                    </FormLabel>
                                                    <Field as="select" name="language" onChange={handleChange} value={values.language}>
                                                        <option defaultValue hidden value="0">
                                                            Select language
                                                        </option>
                                                        {languages.map((language) => (
                                                            <option key={language.LanguageCode} value={`${language.LanguageCode}`}>
                                                                {language.LanguageCode} ({language.Name})
                                                            </option>
                                                        ))}
                                                    </Field>
                                                    <FormErrorMessage>{errors.language}</FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                            <Box paddingBottom={6}>
                                                <FormControl as="fieldset" isRequired isInvalid={errors.regions && formSubmitted}>
                                                    <FormLabel as="legend" marginBottom={2}>
                                                        Select to which region(s) the podcast should belong
                                                    </FormLabel>
                                                    <CheckboxGroup
                                                        id="regions"
                                                        colorScheme="teal"
                                                        defaultValue={[]}
                                                        isInline
                                                        onChange={(cat) => {
                                                            const catArray = cat.map((item) => parseInt(item));
                                                            setFieldValue("regions", catArray);
                                                        }}
                                                    >
                                                        {regions.map((region) => (
                                                            <Checkbox key={region.id} value={`${region.id}`} marginRight={4}>
                                                                {region.name}
                                                            </Checkbox>
                                                        ))}
                                                    </CheckboxGroup>
                                                    <FormErrorMessage>{errors.regions}</FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                            <Box paddingBottom={6}>
                                                <FormControl>
                                                    <FormLabel htmlFor="rss">RSS feed</FormLabel>
                                                    <Input
                                                        id="rss"
                                                        placeholder="RSS feed"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.rss}
                                                    />
                                                </FormControl>
                                            </Box>
                                        </Form>
                                    </ModalBody>
                                    <ModalFooter display="flex" justifyContent="center">
                                        <Box padding={4}>
                                            <Button
                                                colorScheme="green"
                                                size="lg"
                                                onClick={() => {
                                                    handleSubmit();
                                                    setFormsubmitted(true);
                                                }}
                                                isLoading={isSubmitting || isLoading}
                                            >
                                                Create podcast
                                            </Button>
                                        </Box>
                                    </ModalFooter>
                                </>
                            )}
                        </ModalContent>
                    )}
                </Formik>
            </Modal>
        </>
    );
}

export default AddPremiumPodcast;
