import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import {
    Box,
    Heading,
    Image,
    Text,
    Button,
    Spinner,
    Alert,
    AlertIcon,
    SimpleGrid,
    Collapse,
    Divider,
    Stack,
    Badge,
    Skeleton,
} from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";
import { colors } from "../../constants";

import { actions } from "../redux";
import { useMedia } from "../../hooks";
import DeleteDialog from "../../components/DeleteDialog";
import PublishPodcastDialog from "../components/PublishPodcastDialog";
import EditRssPodcast from "../components/EditRssPodcast";
import EditPremiumPodcast from "../components/EditPremiumPodcast";
import EpisodesList from "./EpisodeList";
import EpisodeItem from "./EpisodeItem";
import premiumBadge from "../../assets/images/premium-badge.png";
import { RootState } from "../../redux/reducers/rootReducer";
import { Podcast } from "../../redux/types";

interface PodcastViewProps {
    match: any;
}

const podcastViewState = (state: RootState) => state.managePodcasts.podcastView;

function PodcastView({ match }: PodcastViewProps) {
    const dispatch = useDispatch();
    const [podcastId] = useState(match.params.id);
    const {
        podcast,
        episodes,
        isLoading,
        isLoadingEpisodes,
        podcastRemoved,
        episodeRemoved,
        episodeUploaded,
        podcastPublished,
        podcastUpdated,
        episodeUpdated,
        hasMoreEpisodes,
    } = useSelector(podcastViewState);
    const { isSmallScreen } = useMedia();
    const [anyEncodedEpisodes, setEncodedEpisodes] = useState(false);
    const [page, setCurrentPage] = useState(0);
    const [pageSize] = useState(30);
    const [mainImageLoaded, setMainImageLoaded] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        dispatch(actions.getAllCategories());
    }, []);

    useEffect(() => {
        if (podcastId) {
            dispatch(actions.getPodcast(podcastId));
        }
    }, [podcastId, podcastUpdated]);

    useEffect(() => {
        if (podcastId) {
            dispatch(actions.getEpisodes(podcastId, page, pageSize));
        }
    }, [podcastId, page, episodeUploaded, episodeRemoved, podcastUpdated, episodeUpdated]);

    useEffect(() => {
        if (episodes && episodes.length > 0) {
            const isEncodedEpisodes = episodes.some((ep) => ep.isEncoded);
            setEncodedEpisodes(isEncodedEpisodes);
        }
    }, [episodes]);

    const fetchMoreEpisodes = () => {
        setCurrentPage(page + 1);
    };

    const isPodmePremium = (podcast: Podcast | null) => {
        return podcast && podcast.isPremium && !podcast.isManagedBySchibsted;
    };

    return isLoading && podcast == null ? (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" minHeight="500px">
            <Spinner thickness="4px" speed="0.65s" color="green.300" size="xl" />
        </Box>
    ) : podcastRemoved ? (
        <Redirect to="/podcasts" />
    ) : (
        <Box>
            <Box paddingBottom={8}>
                <NavLink to="/podcasts">
                    <Box display="flex">
                        <MdArrowBack fontSize="1.5em" color="white" />
                        <Text as="span" fontWeight="semibold" paddingLeft={2} color="white">
                            Back
                        </Text>
                    </Box>
                </NavLink>
            </Box>

            <Box bg="white" rounded={10}>
                <Box padding={[4]}>
                    <Box>
                        {podcast && !podcast.isPublished && isPodmePremium(podcast) && !podcastPublished && (
                            <Box marginBottom={4}>
                                <Alert status="warning">
                                    <AlertIcon />
                                    <Text fontSize="sm" mr={1}>
                                        This podcast is not published.
                                    </Text>
                                    {episodes && episodes.length > 0 && anyEncodedEpisodes ? (
                                        <PublishPodcastDialog
                                            podcast={podcast}
                                            title={`Publish ${podcast.title}`}
                                            text="Do you want to publish? This action cannot be reverted."
                                            buttonLabel="publish podcast"
                                            colorScheme="orange"
                                            variant="link"
                                            publishAction={() => dispatch(actions.publishPodcast(podcast.id))}
                                        />
                                    ) : (
                                        <Text fontSize="sm" ml={5}>
                                            You can publish it manually by clicking on the Publish button.
                                            <br />
                                            Otherwise it will be automatically published with the first scheduled episode.
                                        </Text>
                                    )}
                                </Alert>
                            </Box>
                        )}

                        <Box>
                            <Box display="flex" justifyContent="space-between" flexDir={isSmallScreen ? "column" : "row"}>
                                <Box
                                    display="flex"
                                    flexDir={isSmallScreen ? "column" : "row"}
                                    alignItems={isSmallScreen ? "center" : "flex-start"}
                                >
                                    <Skeleton size="200px" isLoaded={mainImageLoaded}>
                                        <Box border={`1px solid ${colors.lightGray}`} position="relative">
                                            {isPodmePremium(podcast) && (
                                                <Image
                                                    src={premiumBadge}
                                                    position="absolute"
                                                    top="-3px"
                                                    right="10px"
                                                    boxSize="30px"
                                                    objectFit="contain"
                                                />
                                            )}
                                            <Image
                                                onLoad={() => setMainImageLoaded(true)}
                                                src={podcast?.smallImageUrl}
                                                alt={podcast?.title}
                                                width="200px"
                                                minWidth="200px"
                                            />
                                        </Box>
                                    </Skeleton>

                                    <Box paddingLeft={[0, 0, 4]} width={["100%", null, "auto"]}>
                                        <Box paddingBottom={4} paddingTop={isSmallScreen ? 1 : 0}>
                                            <Box>
                                                <Stack isInline display="block" textAlign={["center", null, "left"]}>
                                                    {podcast &&
                                                        podcast.categories &&
                                                        podcast.categories.map((cat) => (
                                                            <Badge colorScheme="teal" key={cat.key}>
                                                                {cat.name}
                                                            </Badge>
                                                        ))}
                                                </Stack>
                                                <Heading as="h1" size="lg" mt={[2, 2, 0]} textAlign={["center", "center", "left"]}>
                                                    {podcast?.title}
                                                </Heading>
                                            </Box>
                                            <Text fontSize="sm" color={colors.aquablue} textAlign={["center", "center", "left"]}>
                                                {`${podcast?.author.firstName} ${podcast?.author.lastName}`}
                                            </Text>
                                        </Box>

                                        <Box display="flex" justifyContent={["center", null, "flex-start"]} pb={[4, 4, 0]}>
                                            <CollapseView text={podcast?.description} />
                                        </Box>
                                    </Box>
                                </Box>

                                <Box display="flex" justifyContent={["space-around", null, "flex-end"]}>
                                    <Box paddingRight={4}>
                                        {podcast && isPodmePremium(podcast) ? (
                                            <EditPremiumPodcast
                                                onSubmit={(values: any) => dispatch(actions.editPremiumPodcast(podcast.id, values))}
                                                resetForm={() => dispatch(actions.resetForm())}
                                            />
                                        ) : (
                                            podcast &&
                                            !podcast.isManagedBySchibsted && (
                                                <EditRssPodcast
                                                    onSubmit={(values: any) => dispatch(actions.editRssPodcast(podcast!.id, values))}
                                                    resetForm={() => dispatch(actions.resetForm())}
                                                />
                                            )
                                        )}
                                    </Box>
                                    {podcast && !podcast.isPremium && !podcast.isManagedBySchibsted && (
                                        <Box paddingRight={4}>
                                            <NavLink to={`/podcasts/${podcastId}/migrate`} exact>
                                                <Button colorScheme="blue">Migrate to Premium</Button>
                                            </NavLink>
                                        </Box>
                                    )}
                                    {podcast && !podcast.isManagedBySchibsted && (
                                        <DeleteDialog
                                            buttonLabel="Remove podcast"
                                            title={`Remove ${podcast !== null ? podcast.title : ""}`}
                                            text="Are you sure? This action cannot be reverted."
                                            deleteAction={() => dispatch(actions.removePodcast(podcastId))}
                                            buttonVariant="solid"
                                            buttonVariantColor="red"
                                            buttonSize="md"
                                        />
                                    )}
                                </Box>
                            </Box>
                            <SimpleGrid columns={2} spacing={2} paddingTop={4}>
                                <Box>
                                    <Text>
                                        Typ: {podcast?.isPremium ? (podcast.isManagedBySchibsted ? "Schibsted" : "Premium") : "RSS"}
                                    </Text>
                                </Box>
                                <Box>
                                    <Text>Id: {podcast?.id}</Text>
                                </Box>
                            </SimpleGrid>
                            <Box pt={4}></Box>
                        </Box>
                    </Box>
                </Box>
                <Divider />

                <Box padding={[0, null, 4]} rounded="10px" bg="white" maxW="100%">
                    <EpisodesList
                        episodes={episodes}
                        podcast={podcast}
                        deleteAction={(id) => dispatch(actions.removeEpisode(id))}
                        uploadEpisode={(values) => dispatch(actions.uploadEpisode(values))}
                        resetForm={() => dispatch(actions.resetForm())}
                        isLoading={isLoading}
                    />
                    {(isLoading || isLoadingEpisodes) &&
                        Array.from({ length: 5 }, (x, i) => <EpisodeItem key={i} episode={null} podcast={null} />)}
                    {hasMoreEpisodes && (
                        <Button
                            colorScheme="green"
                            size="lg"
                            width="100%"
                            onClick={fetchMoreEpisodes}
                            isLoading={isLoading || isLoadingEpisodes}
                        >
                            Load more
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

interface CollapseViewProps {
    text: string | undefined;
}

function CollapseView({ text }: CollapseViewProps) {
    const [show, setShow] = React.useState(true);

    const handleToggle = () => setShow(!show);

    return (
        <Box>
            <Collapse in={show}>{text}</Collapse>
            <Box>
                <Button size="sm" onClick={handleToggle} mt={[0, 0, 4]}>
                    {show ? "Hide" : "Show"} description
                </Button>
            </Box>
        </Box>
    );
}

export default PodcastView;
