import { Box, FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import DatePicker from "../../components/DatePicker/DatePicker";
import { Field, FormikErrors } from "formik";

interface Props {
    errors: FormikErrors<{
        email: string;
        userGroup: string;
        validUntil: Date;
    }>;
    values: {
        email: string;
        userGroup: string;
        validUntil: Date;
    };
}

export default function ValidUntilSelect({ errors, values }: Props) {
    return (
        <Box>
            <FormControl isRequired isInvalid={Boolean(errors.validUntil)} id="validUntil">
                <FormLabel htmlFor="validUntil">Valid until</FormLabel>
                <Field name="validUntil" value={values.validUntil} minDate={new Date()} component={DatePicker} />
                <FormErrorMessage>{errors.validUntil}</FormErrorMessage>
            </FormControl>
        </Box>
    );
}
