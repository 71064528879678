import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    Box,
    Spinner,
} from "@chakra-ui/react";

const publishPodcastState = (state) => state.managePodcasts.publishPodcast;

export default function PublishPodcastDialog({ buttonLabel, title, text, publishAction, ...rest }) {
    const { isLoading, isPublished } = useSelector(publishPodcastState);
    const [isOpen, setIsOpen] = useState();
    const onClose = () => setIsOpen(false);
    const cancelRef = useRef();

    return (
        <>
            <Button variant='link' onClick={() => setIsOpen(true)} {...rest}>
                {buttonLabel}
            </Button>

            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay />
                <AlertDialogContent>
                    {isLoading ? (
                        <Box display='flex' alignItems='center' justifyContent='center' height='150px'>
                            <Spinner thickness='4px' speed='0.65s' color='green.300' size='xl' />
                        </Box>
                    ) : isPublished ? (
                        <>
                            <AlertDialogHeader fontSize='xl' fontWeight='bold' textAlign='center'>
                                The podcast is published!
                            </AlertDialogHeader>

                            <AlertDialogBody display='flex' justifyContent='center'>
                                <span role='img' aria-label='dance' style={{ fontSize: "3em" }}>
                                    💃
                                </span>
                            </AlertDialogBody>

                            <AlertDialogFooter display='flex' justifyContent='center'>
                                <Button ref={cancelRef} onClick={onClose}>
                                    Close
                                </Button>
                            </AlertDialogFooter>
                        </>
                    ) : (
                        <>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                {title}
                            </AlertDialogHeader>

                            <AlertDialogBody>{text}</AlertDialogBody>

                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button
                                    colorScheme='green'
                                    onClick={() => {
                                        publishAction();
                                    }}
                                    ml={3}
                                >
                                    Publish
                                </Button>
                            </AlertDialogFooter>
                        </>
                    )}
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
}
