import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 144px;
    margin-right: 16px;
`;

const Title = styled.h4`
    margin: 4px 0 0 0;
    padding: 0;
    font-size: 12px;
    line-height:14px;
    font-weight: normal;
    letter-spacing: -0.01em;
`;

const Image = styled.img`
    height: 144px;
    width: 144px;
    max-width: 144px;
    border-radius: 4px;
`;

export interface EpisodeImageProps {
    data: {
        title: string;
        imageUrl: string;
    }
}

export function EpisodeImage({ data }: EpisodeImageProps) {

    const getImageUrl = (url: string) => {
        return url ? url : 'http://placekitten.com/g/128/128';
    }
    return (
        <Container>
            <Image alt="episode" src={getImageUrl(data.imageUrl)} />
            <Title>{data.title}</Title>
        </Container>
    );
}