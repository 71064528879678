import {
    ADD_PODCAST_TO_USER,
    ADD_PODCASTER_PERMISSION,
    AddPodcasterPermission,
    AddPodcastToUser,
    CANCEL_SUBSCRIPTION,
    CancelSubscription,
    DELETE_PODCAST_FROM_USER,
    DELETE_PODCASTER_PERMISSION,
    DELETE_USER,
    DeletePodcasterPermission,
    DeletePodcastFromUser,
    DeleteUser,
    GET_USER_DETAILS,
    GET_USER_ROLE,
    GET_USERNAME,
    GET_USERS,
    GetUser,
    GetUsername,
    GetUsers,
    REFRESH_SUBSCRIPTIONS,
    RefreshSubscriptions,
    SEARCH_USER,
    SearchUser,
    Unmigrate,
    UNMIGRATE,
    UPDATE_PODCAST_STATISTICS_PERMISSION,
    UpdatePodcastStatisticsPermission,
    GET_USER_QUERY_TYPES,
    GetUserQueryTypes,
    FETCH_SUBSCRIPTION_EVENTS,
    SearchContext,
    MIGRATE_USER,
    TOGGLE_SOFT_MIGRATION,
    GET_SOFT_MIGRATION_STATUS,
    START_FETCH_SUBSCRIPTION_EVENTS_SEQUENCE,
    StartFetchSubscriptionEventsSequence,
    Subscription,
    FETCH_PAYMENT_TRANSACTIONS,
    FetchPaymentTransactions,
    StartFetchPaymentTransactionsSequence,
    START_FETCH_PAYMENT_TRANSACTIONS_SEQUENCE,
    ADD_FREE_SUBSCRIPTION,
    AddFreeSubscription,
    REFUND_TRANSACTION,
    RefundTransaction,
} from "./types";

function getUsers(pageSize: number, currentPage: number): GetUsers {
    return {
        type: GET_USERS,
        pageSize,
        currentPage,
    };
}

function getUsername(): GetUsername {
    return { type: GET_USERNAME };
}

function getUser(userId: string): GetUser {
    return { type: GET_USER_DETAILS, userId };
}

function searchUser(searchQuery: string, searchType: string = "", context: SearchContext): SearchUser {
    return { type: SEARCH_USER, searchQuery, searchType, context };
}

function getUserQueryTypes(): GetUserQueryTypes {
    return { type: GET_USER_QUERY_TYPES };
}

function getUserRole() {
    return { type: GET_USER_ROLE };
}

function addPodcasterPermission(userAccountId: string): AddPodcasterPermission {
    return { type: ADD_PODCASTER_PERMISSION, userAccountId };
}

function deletePodcasterPermission(userAccountId: string): DeletePodcasterPermission {
    return { type: DELETE_PODCASTER_PERMISSION, userAccountId };
}

function deleteUser(userAccountId: string): DeleteUser {
    return { type: DELETE_USER, userAccountId };
}

function cancelSubscription(userAccountId: string, podcastId?: string): CancelSubscription {
    return { type: CANCEL_SUBSCRIPTION, userAccountId, podcastId };
}

function addPodcastToUser(userAccountId: string, podcastId: number): AddPodcastToUser {
    return { type: ADD_PODCAST_TO_USER, userAccountId, podcastId };
}

function deletePodcastFromUser(userAccountId: string, podcastId: number): DeletePodcastFromUser {
    return { type: DELETE_PODCAST_FROM_USER, userAccountId, podcastId };
}

function updatePodcastStatisticsPermission(
    userAccountId: string,
    podcastId: number,
    value: boolean
): UpdatePodcastStatisticsPermission {
    return { type: UPDATE_PODCAST_STATISTICS_PERMISSION, userAccountId, podcastId, value };
}

function refreshSubscriptions(userAccountId: string): RefreshSubscriptions {
    return { type: REFRESH_SUBSCRIPTIONS, userAccountId };
}

function unmigrate(userAccountId: string): Unmigrate {
    return { type: UNMIGRATE, userAccountId };
}

function fetchSubscriptionEvents(userAccountId: string, subscriptionId: number) {
    return { type: FETCH_SUBSCRIPTION_EVENTS, userAccountId, subscriptionId };
}

function fetchPaymentTransactions(subscriptionId: number): FetchPaymentTransactions {
    return { type: FETCH_PAYMENT_TRANSACTIONS, subscriptionId };
}

function fetchPaymentTransactionsSequence(subscriptionIds: number[]): StartFetchPaymentTransactionsSequence {
    return {
        type: START_FETCH_PAYMENT_TRANSACTIONS_SEQUENCE,
        subscriptionIds,
    };
}

function refundTransaction(transactionId: number, userAccountId: string, subscriptionId: number): RefundTransaction {
    return { type: REFUND_TRANSACTION, transactionId, userAccountId, subscriptionId };
}

function startFetchSubscriptionEventsSequence(
    subscriptions: Subscription[],
    userAccountId: string
): StartFetchSubscriptionEventsSequence {
    return {
        type: START_FETCH_SUBSCRIPTION_EVENTS_SEQUENCE,
        subscriptions,
        userAccountId,
    };
}

function migrateUser(masterAccountId: string, schibstedUserAccountId: string) {
    return { type: MIGRATE_USER, masterAccountId, schibstedUserAccountId };
}

function toggleSoftMigration(userId: string, softMigrationEnabled: boolean) {
    return { type: TOGGLE_SOFT_MIGRATION, userId, softMigrationEnabled };
}

function getSoftMigrationStatus(userId: string) {
    return { type: GET_SOFT_MIGRATION_STATUS, userId };
}

function addFreeVoucherSubscription(userId: string, voucherCode: string): AddFreeSubscription {
    return { type: ADD_FREE_SUBSCRIPTION, userId, voucherCode };
}

export default {
    getUsers,
    getUsername,
    getUser,
    searchUser,
    getUserRole,
    deleteUser,
    cancelSubscription,
    addPodcasterPermission,
    deletePodcasterPermission,
    addPodcastToUser,
    deletePodcastFromUser,
    updatePodcastStatisticsPermission,
    refreshSubscriptions,
    unmigrate,
    getUserQueryTypes,
    fetchSubscriptionEvents,
    fetchPaymentTransactions,
    startFetchSubscriptionEventsSequence,
    fetchPaymentTransactionsSequence,
    migrateUser,
    toggleSoftMigration,
    getSoftMigrationStatus,
    addFreeVoucherSubscription,
    refundTransaction,
};
