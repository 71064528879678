import { AnyAction } from "redux";
import { ShowSuccessToastAction, ShowErrorToastAction } from "../types";

export interface toastMessageState {
    showErrorToast: boolean;
    showSuccessToast: boolean;
    message: string;
    createAccountFailed: boolean;
    errorStatus?: number;
}

const initialState: toastMessageState = {
    showErrorToast: false,
    showSuccessToast: false,
    message: "",
    createAccountFailed: false,
};

function toastMessageReducer(
    state = initialState,
    action: ShowSuccessToastAction | ShowErrorToastAction | AnyAction
): toastMessageState {
    if ("showToastSuccessMessage" in action) {
        return {
            ...state,
            showSuccessToast: true,
            showErrorToast: false,
            message: action.message,
        };
    }

    if ("showToastErrorMessage" in action) {
        return {
            ...state,
            showErrorToast: true,
            showSuccessToast: false,
            message: action.message,
            errorStatus: action.status,
        };
    }

    return initialState;
}

export default toastMessageReducer;
