import { useState } from "react";
import { Box, Text, Flex, Button } from "@chakra-ui/react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useEffect } from "react";
import { Region } from "../../redux/types";

interface PaginationProps {
    currentPage: number;
    pages: number;
    currentPageSize: number;
    setCurrentPage: (page: any) => any;
}

export default function Pagination({ currentPage = 1, setCurrentPage, pages, currentPageSize }: PaginationProps) {
    const [startIndex, setStartIndex] = useState(3);
    const pagesArray = Array.from(Array(pages), (e, i) => i + 1);
    const activePage = currentPage + 1;

    const [firstGroup, setFirstGroup] = useState(pagesArray.slice(0, 3));
    const [thirdGroup, setThirdGroup] = useState(pagesArray.slice(-1));
    const [showSecondGroup, setShowSecondGroup] = useState(false);
    const showThirdGroup = pagesArray.length > 3;

    const secondGroup = pagesArray.slice(startIndex, startIndex + 3);
    const lastThree = pagesArray.slice(-3);

    useEffect(() => {
        setFirstGroup(pagesArray.slice(0, 3));
        setThirdGroup(pagesArray.slice(-1));
    }, [pages]);

    useEffect(() => {
        switch (true) {
            case currentPage < 3:
                setShowSecondGroup(false);
                setFirstGroup(pagesArray.slice(0, 3));
                setThirdGroup(pagesArray.slice(-1));
                break;

            case currentPage >= 3 && currentPage < pagesArray.indexOf(lastThree[0]):
                setShowSecondGroup(true);
                setFirstGroup(pagesArray.slice(0, 1));
                setThirdGroup(pagesArray.slice(-1));
                break;

            case currentPage >= pagesArray.indexOf(lastThree[0]):
                setShowSecondGroup(false);
                setFirstGroup(pagesArray.slice(0, 1));
                setThirdGroup(pagesArray.slice(-3));
                break;
            default:
                break;
        }
    }, [currentPage]);

    return (
        <Flex maxWidth='100%' alignItems='center' padding='2rem 0 0 1.5rem' marginTop={2}>
            <Box display='flex' justifyContent='flex-start' alignItems='center' marginRight={8}>
                <BackButton
                    handleClick={() => {
                        setCurrentPage(activePage === 1 ? 0 : --currentPage);
                        setStartIndex(currentPage);
                    }}
                    disabled={activePage === 1}
                />

                {firstGroup.map((page, i) => (
                    <Box
                        key={i}
                        fontWeight={activePage === page ? "bold" : "normal"}
                        color={activePage === page ? "#424242" : "#a2a2a2"}
                        onClick={() => {
                            setCurrentPage(page - 1);
                            setStartIndex(3);
                        }}
                        padding='0.2rem'
                        marginRight={4}
                        cursor='pointer'
                    >
                        {page}
                    </Box>
                ))}

                {firstGroup.length === 1 && (
                    <Text marginRight={4} color='#a2a2a2'>
                        ...
                    </Text>
                )}

                {showSecondGroup &&
                    secondGroup.map((page, i) => (
                        <Box
                            key={i}
                            fontWeight={activePage === page ? "bold" : "normal"}
                            color={activePage === page ? "#424242" : "#a2a2a2"}
                            onClick={() => {
                                setCurrentPage(page - 1);
                            }}
                            padding='0.2rem'
                            marginRight={4}
                            cursor='pointer'
                        >
                            {page}
                        </Box>
                    ))}

                {showThirdGroup && thirdGroup.length === 1 && (
                    <Text marginRight={4} color='#a2a2a2'>
                        ...
                    </Text>
                )}

                {showThirdGroup &&
                    thirdGroup.map((page, i) => (
                        <Box
                            key={i}
                            fontWeight={activePage === page ? "bold" : "normal"}
                            color={activePage === page ? "#424242" : "#a2a2a2"}
                            onClick={() => {
                                setCurrentPage(page - 1);
                            }}
                            padding='0.2rem'
                            marginRight={4}
                            cursor='pointer'
                        >
                            {page}
                        </Box>
                    ))}

                <ForwardButton
                    handleClick={() => {
                        setCurrentPage(activePage === pages ? pages : ++currentPage);
                        if (secondGroup?.indexOf(activePage) === 2) setStartIndex(currentPage);
                    }}
                    disabled={activePage >= pages}
                />
            </Box>
            <Text color='#a2a2a2' fontSize='0.9em'>
                Showing {currentPageSize} results
            </Text>
        </Flex>
    );
}

function BackButton({ handleClick, disabled }: { handleClick: () => any; disabled: boolean }) {
    return (
        <Button variant='ghost' color='#424242' onClick={handleClick} padding={0} marginRight={4} cursor='pointer' disabled={disabled}>
            <IoIosArrowBack />
        </Button>
    );
}

function ForwardButton({ handleClick, disabled }: { handleClick: () => any; disabled: boolean }) {
    return (
        <Button variant='ghost' color='#424242' onClick={handleClick} padding={0} marginRight={4} cursor='pointer' disabled={disabled}>
            <IoIosArrowForward />
        </Button>
    );
}
