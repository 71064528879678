import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    Heading,
    Spinner,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { PaymentTransaction, PaymentTransactionState } from "../redux/types";
import { format } from "date-fns";
import { ReactElement } from "react";
import { PodmeColor } from "../../redux/types";
import RefundConfirmationModal from "./RefundConfirmationModal";

interface Props {
    groupedPaymentTransactions: { year: string; transactions: PaymentTransaction[] }[];
    subscriptionId: number;
    userAccountId: string;
}

function PaymentTransactions({ groupedPaymentTransactions, userAccountId, subscriptionId }: Props) {
    if (!groupedPaymentTransactions || groupedPaymentTransactions?.length === 0)
        return (
            <Flex justifyContent="center" alignItems="center">
                <Spinner />
            </Flex>
        );

    return (
        <Flex direction="column" gridGap="1rem">
            <Heading as="h3" fontSize="1.25rem" fontWeight="700">
                Vipps/Mobile pay transactions
            </Heading>
            <Flex direction="column" gridGap="0.5rem">
                {groupedPaymentTransactions.map(({ year, transactions }, idx) => (
                    <Flex flexDirection="column" key={idx} backgroundColor="#e9e9e9" rounded="0.75rem" border="1px solid #ccc">
                        <Accordion allowToggle key={year}>
                            <AccordionItem border="none">
                                <AccordionButton
                                    padding="0.75rem 1rem"
                                    rounded="0.75rem"
                                    transition="border-radius 0.5s ease-in-out"
                                    sx={{
                                        "&[aria-expanded=true]": {
                                            rounded: "0.75rem 0.75rem 0 0",
                                        },
                                    }}
                                    _focus={{ outline: "none" }}
                                >
                                    <Box as="span" flex="1" fontWeight="700" textAlign="left">
                                        {year}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel p="0">
                                    <Table borderColor="#cfcfcf">
                                        <Thead fontSize="sm">
                                            <Tr>
                                                <Th textAlign="left" background="#cfcfcf">
                                                    <Text>Id</Text>
                                                </Th>
                                                <Th width="13%" textAlign="center" background="#cfcfcf">
                                                    <Text>Amount</Text>
                                                </Th>
                                                <Th width="13%" textAlign="center" background="#cfcfcf">
                                                    <Text>State</Text>
                                                </Th>
                                                <Th width="13%" textAlign="center" background="#cfcfcf">
                                                    <Text>Psp reference</Text>
                                                </Th>
                                                <Th width="13%" textAlign="center" background="#cfcfcf">
                                                    <Text>Created at</Text>
                                                </Th>
                                                <Th width="13%" textAlign="center" background="#cfcfcf">
                                                    <Text>Captured at</Text>
                                                </Th>
                                                <Th width="13%" textAlign="center" background="#cfcfcf">
                                                    <Text>Refunded at</Text>
                                                </Th>
                                                <Th width="13%" textAlign="right" background="#cfcfcf"></Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody fontSize="sm">
                                            {transactions?.map((trans) => {
                                                return (
                                                    <Tr>
                                                        <Td textAlign="left" borderColor="#cfcfcf" borderTop="1px" borderBottom="1px">
                                                            {trans.paymentTransactionId}
                                                        </Td>
                                                        <Td
                                                            width="13%"
                                                            textAlign="center"
                                                            borderColor="#cfcfcf"
                                                            borderTop="1px"
                                                            borderBottom="1px"
                                                        >
                                                            {(Math.round(trans.amount * 100) / 100).toFixed(2)} {trans.currency}
                                                        </Td>
                                                        <Td
                                                            width="13%"
                                                            textAlign="center"
                                                            borderColor="#cfcfcf"
                                                            borderTop="1px"
                                                            borderBottom="1px"
                                                        >
                                                            <Flex direction="row" justify="center" align="center">
                                                                <PaymentStatusIndicator transactionState={trans.state} />
                                                                <Text>{trans.state}</Text>
                                                            </Flex>
                                                        </Td>
                                                        <Td
                                                            width="13%"
                                                            textAlign="center"
                                                            borderColor="#cfcfcf"
                                                            borderTop="1px"
                                                            borderBottom="1px"
                                                        >
                                                            <Flex direction="row" justify="center" align="center">
                                                                <Text>{trans.pspReference}</Text>
                                                            </Flex>
                                                        </Td>
                                                        <Td
                                                            width="13%"
                                                            textAlign="center"
                                                            borderColor="#cfcfcf"
                                                            borderTop="1px"
                                                            borderBottom="1px"
                                                        >
                                                            {format(new Date(trans.createdAt), "yyyy-MM-dd HH:mm")}
                                                        </Td>
                                                        <Td
                                                            width="13%"
                                                            textAlign="center"
                                                            borderColor="#cfcfcf"
                                                            borderTop="1px"
                                                            borderBottom="1px"
                                                        >
                                                            {!trans.capturedAt
                                                                ? "-"
                                                                : format(new Date(trans.capturedAt), "yyyy-MM-dd HH:mm")}
                                                        </Td>
                                                        <Td
                                                            width="13%"
                                                            textAlign="center"
                                                            borderColor="#cfcfcf"
                                                            borderTop="1px"
                                                            borderBottom="1px"
                                                        >
                                                            {!trans.refundedAt
                                                                ? "-"
                                                                : format(new Date(trans.refundedAt), "yyyy-MM-dd HH:mm")}
                                                        </Td>
                                                        <Td
                                                            width="13%"
                                                            textAlign="right"
                                                            borderColor="#cfcfcf"
                                                            borderTop="1px"
                                                            borderBottom="1px"
                                                        >
                                                            <RefundConfirmationModal
                                                                paymentTransaction={trans}
                                                                subscriptionId={subscriptionId}
                                                                userAccountId={userAccountId}
                                                            />
                                                        </Td>
                                                    </Tr>
                                                );
                                            })}
                                        </Tbody>
                                    </Table>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );
}

function PaymentStatusIndicator({ transactionState }: { transactionState: string }): ReactElement {
    if (transactionState === PaymentTransactionState.Succeeded) {
        return <PaymentStatusCircle backgroundColor={PodmeColor.Success} />;
    } else if (
        transactionState === PaymentTransactionState.Pending ||
        transactionState === PaymentTransactionState.Cancelled ||
        transactionState === PaymentTransactionState.Created
    ) {
        return <PaymentStatusCircle backgroundColor={PodmeColor.Warning} />;
    }
    return <PaymentStatusCircle backgroundColor={PodmeColor.Red} />;
}

function PaymentStatusCircle({ backgroundColor }: { backgroundColor: PodmeColor }): ReactElement {
    return <Box width="10px" height="10px" borderRadius="full" backgroundColor={backgroundColor} marginRight="10px"></Box>;
}

export default PaymentTransactions;
