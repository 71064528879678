import React from "react";
import { HorizontalBar } from "react-chartjs-2";

function HorizontalBarChart({ data, labels, title }) {
    const graphData = {
        labels: labels,
        datasets: [
            {
                label: title,
                backgroundColor: "#2ddedf",
                borderColor: "#666666",
                borderWidth: 1,
                hoverBackgroundColor: "#174666",
                hoverBorderColor: "#666666",
                data: data,
            },
        ],
    };

    return (
        <HorizontalBar
            data={graphData}
            options={{
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                precision: 0,
                            },
                        },
                    ],
                },
            }}
        />
    );
}

export default HorizontalBarChart;
