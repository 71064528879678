import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Flex,
    ModalFooter,
    Button,
    Text,
    Box,
    ChakraProps,
    Spinner,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchField from "../../../components/SearchField/SearchField";
import { RootState } from "../../../redux/reducers/rootReducer";
import { LOGIN_TYPE, PodmeColor, UserRole } from "../../../redux/types";
import { fadeIn } from "../../../styles/animations";
import { debouncedSearch } from "../../../utils";
import { actions } from "../../redux";
import { Account, SEARCH_CONTEXT } from "../../redux/types";
import ManualMigrationConfirmView from "./ManualMigrationConfirmView";
import SearchAccountList from "./SearchAccountList";
import SelectedAccountSection from "./SelectedAccountSection";
import UnauthorizedView from "./UnauthorizedView";

import checkmarkIcon from "../../../assets/images/icons/check-black.svg";
import useAuthorizationStatus from "../../../hooks/useAuthorizationStatus";

const manageUsersState = (state: RootState) => state.manageUsers;

export const absoluteModalStyles = {
    position: "absolute",
    top: "16rem",
    left: "0",
    bgColor: PodmeColor.SoftWhite,
    width: "100%",
    rounded: "1rem",
    zIndex: "10",
    border: "1px solid #e0e0e0",
} satisfies ChakraProps;

function ManualMigrationModal({ closeModal }: { closeModal: () => void }) {
    const dispatch = useDispatch();
    const { manualMigration, user, manualMigrationStatus } = useSelector(manageUsersState);
    const { searchedUsers, isLoading } = manualMigration || {};
    const { id: masterAccountId } = user || {};
    const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
    const [showAccountList, setShowAccountList] = useState(false);
    const [showConfirmView, setShowConfirmView] = useState(false);

    const isAuthorized = useAuthorizationStatus({ requiredRoles: [UserRole.Admin] });

    const filteredUsers = useMemo(
        () =>
            searchedUsers?.userAccounts?.filter(
                (searchedUser: Account) =>
                    searchedUser.loginType === LOGIN_TYPE.SchibstedSE ||
                    searchedUser.loginType === LOGIN_TYPE.SchibstedNO ||
                    searchedUser.loginType === LOGIN_TYPE.SchibstedFI
            ),
        [searchedUsers]
    );

    const handleAccountSearch = useCallback((searchValue: string) => {
        if (searchValue.trim() === "") {
            setShowAccountList(false);
        } else {
            setShowAccountList(true);
            debouncedSearch(() => {
                const clearedSearchValue = searchValue.trim();
                dispatch(actions.searchUser(clearedSearchValue, "", SEARCH_CONTEXT.ManualMigration));
            });
        }
    }, []);

    const handleAccountSelection = useCallback((selAcc: Account) => {
        setSelectedAccount(selAcc);
        setShowAccountList(false);
    }, []);

    const handleManualMigration = useCallback(() => {
        if (selectedAccount && masterAccountId) {
            dispatch(actions.migrateUser(masterAccountId, selectedAccount.userAccountId));
        }
    }, [selectedAccount, masterAccountId]);

    const renderAccountSearchResults = useMemo(() => {
        if (!showAccountList) return null;

        if (isLoading) {
            return (
                <Flex {...absoluteModalStyles} padding="1rem 1.5rem" gap="1rem" alignItems="center">
                    <Spinner thickness="2px" speed="0.65s" color={PodmeColor.Silver} size="sm" />
                    <Text>
                        Searching for{" "}
                        <Box as="span" fontWeight="500">
                            Schibsted
                        </Box>{" "}
                        user accounts...
                    </Text>
                </Flex>
            );
        }

        if (filteredUsers && filteredUsers.length > 0) {
            return <SearchAccountList filteredUsers={filteredUsers} handleAccountSelection={handleAccountSelection} />;
        } else if (filteredUsers && filteredUsers.length === 0) {
            return (
                <Box {...absoluteModalStyles} padding="1rem 1.5rem">
                    <Text>
                        Couldn't find any{" "}
                        <Box as="span" fontWeight="500">
                            Schibsted
                        </Box>{" "}
                        user accounts
                    </Text>
                </Box>
            );
        }

        return null;
    }, [showAccountList, filteredUsers, isLoading]);

    const renderModalContent = useMemo(() => {
        if (!isAuthorized) {
            return <UnauthorizedView />;
        }

        if (manualMigrationStatus === "success") {
            return <SuccessView />;
        }

        if (showConfirmView) {
            return (
                <ManualMigrationConfirmView
                    selectedSchibstedAccount={selectedAccount}
                    setShowConfirmView={setShowConfirmView}
                    handleManualMigration={handleManualMigration}
                />
            );
        }

        return (
            <MainModalView
                handleAccountSearch={handleAccountSearch}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                renderAccountSearchResults={renderAccountSearchResults}
                setShowConfirmView={setShowConfirmView}
                closeModal={closeModal}
            />
        );
    }, [isAuthorized, showConfirmView, selectedAccount, renderAccountSearchResults, manualMigrationStatus]);

    return (
        <Modal
            isOpen
            onClose={closeModal}
            isCentered
            size={showConfirmView ? "4xl" : "xl"}
            closeOnOverlayClick={false}
            autoFocus={false}
        >
            <ModalOverlay />
            <ModalContent gap="0.5rem" rounded="1.5rem" padding="1.5rem" bgColor={PodmeColor.SoftWhite} position="relative">
                {renderModalContent}
            </ModalContent>
        </Modal>
    );
}

function MainModalView({
    handleAccountSearch,
    selectedAccount,
    setSelectedAccount,
    renderAccountSearchResults,
    setShowConfirmView,
    closeModal,
}: {
    handleAccountSearch: (searchValue: string) => void;
    selectedAccount: Account | null;
    setSelectedAccount: React.Dispatch<React.SetStateAction<Account | null>>;
    renderAccountSearchResults: JSX.Element | null;
    setShowConfirmView: React.Dispatch<React.SetStateAction<boolean>>;
    closeModal: () => void;
}) {
    return (
        <>
            <ModalHeader p="0">Manual migration</ModalHeader>
            <ModalCloseButton top="1rem" right="1rem" rounded="50%" />
            <ModalBody p="0">
                <Flex direction="column" gridGap={selectedAccount ? "1.5rem" : "1rem"}>
                    {selectedAccount ? (
                        <SelectedAccountSection
                            text="You have selected the following Schibsted account:"
                            selectedAccount={selectedAccount}
                            setSelectedAccount={setSelectedAccount}
                            showCloseButton
                        />
                    ) : (
                        <>
                            <Text>
                                Choose a{" "}
                                <Box as="span" fontWeight="500">
                                    Schibsted
                                </Box>{" "}
                                account that you want to migrate this User to:
                            </Text>
                            <SearchField
                                label="Search user account Mails, IDs..."
                                handleChange={handleAccountSearch}
                                sx={{
                                    ".chakra-input__group:has(input:not(:focus))": {
                                        borderColor: "#e0e0e0",
                                    },
                                }}
                            />
                        </>
                    )}
                    {renderAccountSearchResults}
                </Flex>
            </ModalBody>
            <ModalFooter pt="1rem !important" p="0">
                <Flex gridGap="1rem">
                    <Button padding="1.5rem 2rem" rounded="0.75rem" onClick={closeModal} bgColor="transparent">
                        Cancel
                    </Button>
                    <Button
                        disabled={!selectedAccount}
                        onClick={() => setShowConfirmView(true)}
                        bgColor={PodmeColor.Cyan}
                        colorScheme="cyan"
                        padding="1.5rem 2rem"
                        rounded="0.75rem"
                    >
                        Continue
                    </Button>
                </Flex>
            </ModalFooter>
        </>
    );
}

function SuccessView() {
    return (
        <>
            <ModalBody
                p="0"
                display="flex"
                alignItems="center"
                justifyContent="center"
                animation={`${fadeIn} 100ms ease-in forwards`}
                minHeight="calc(12.875rem - 3rem)"
            >
                <Flex gap="1rem" width="100%" justifyContent="center">
                    <Box width="100%" maxWidth="1.5rem">
                        <Box rounded="50%" border={`2px solid ${PodmeColor.Success}`} height="100%" maxH="1.5rem">
                            <Box
                                height="100%"
                                maxH="1.5rem"
                                bgColor={PodmeColor.Success}
                                sx={{
                                    WebkitMask: `url(${checkmarkIcon}) no-repeat center`,
                                }}
                            />
                        </Box>
                    </Box>
                    <Text>Migration process completed successfully</Text>
                </Flex>
            </ModalBody>
        </>
    );
}

export default ManualMigrationModal;
