import styled from "styled-components";
import chevronImg from "../../assets/images/cmspreview/chevron.svg";
const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    border-bottom: 1px solid #353D47;
    height: 40px;
`;
const Title = styled.h5`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.02em;
`;

const Icon = styled.img`
    margin-left: auto;
`;

interface CategoryProps {
    data: {
        name: string;
    }
}

export default function Category({ data }: CategoryProps) {
    return (
        <Container>
            <Title>{data.name}</Title>
            <Icon alt="chevron" src={chevronImg} />
        </Container>
    )
}