import styled from "styled-components";
import premiumImg from "../../assets/images/cmspreview/premium.svg";
import playiconImg from "../../assets/images/cmspreview/playicon.svg";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 16px 15px;
    position: static;
    width: 375px;
    height: 110px;
    left: 0px;
    top: 0px;
    background: #1E232B;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 0 8px;
`;

const SmallContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: static;
    width: 297px;
    height: 64px;
    left: 0px;
    top: 0px;
    background: #1E232B;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 20px 8px 0;
`;

const Image = styled.img`
    width: 72px;
    height: 72px;
    left: 15px;
    top: 19px;
    border-radius: 4px;
    margin: 0px 12px;
`;

const SmallImage = styled.img`
    width: 64px;
    height: 64px;
    border-radius: 4px 0px 0px 4px;
    align-self: flex-start;
    margin-right: 8px;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const TextTop = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;
`;

const SubHeader = styled.h6`
    padding: 0;
    margin: 0;    
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #BABABA;  
`;

const Header = styled.h5`
    padding: 0;
    padding-right: 10px;
    margin: 0;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -0.01em;
    color: #F8F8F8;    
`;

const InfoText = styled.p`
    color: #BABABA;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: 10px 0 0 0;
    padding: 0;
`;

const Icon = styled.img`
    margin-left: auto;
    align-self: center;
    height: 40px;
    width: 40px;
`;

const SmallIcon = styled.img`
    margin-left: auto;
    margin-right: 16px;
    align-self: center;
    height: 32px;
    width: 32px;
`;

const PremiumImg = styled.img`
    margin-right: 7px;
`;

export interface EpisodeProps {
    data: {
        id: number;
        title: string;
        podcastTitle: string;
        destination: string;
        length: string;
        dateAdded: string;
        imageUrl: string;
        isPremium: boolean;
    },
    isSmall?: boolean
}

export function Episode({ data, isSmall }: EpisodeProps) {
    const getImageUrl = (url: string) => {
        const extra = Math.floor(Math.random() * 10);
        const size = 70 + extra;
        return url ? url : `http://placekitten.com/g/${size}/${size}`;
    }

    const regularEpisode = (
        <Container>
            <Image alt={data.title} src={getImageUrl(data.imageUrl)} />
            <TextContainer>
                <TextTop>
                    {data.isPremium && <PremiumImg alt="premium" src={premiumImg} />}
                    <SubHeader>{data.podcastTitle}</SubHeader>
                </TextTop>
                <Header>{data.title}</Header>
                <InfoText>{data.dateAdded.substring(0, 10)} {data.length} min</InfoText>
            </TextContainer>
            <Icon alt="play" src={playiconImg} />
        </Container>
    );

    const smallEpisode = (
        <SmallContainer>
            <SmallImage alt={data.title} src={getImageUrl(data.imageUrl)} />
            <TextContainer>
                <Header>{data.title}</Header>
            </TextContainer>
            <SmallIcon alt="play" src={playiconImg} />
        </SmallContainer>
    );

    return isSmall ? smallEpisode : regularEpisode;
}