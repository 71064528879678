import { Box, Tooltip, Text, Stack } from "@chakra-ui/react";
import { IoMdInformationCircleOutline } from "react-icons/io";

export default function EmailAddressTooltip() {
    return (
        <Tooltip
            hasArrow
            placement="bottom"
            bg="#f7f7f7"
            color="black"
            label={
                <Stack>
                    <Text>Warning!</Text>
                    <Text fontWeight="300">Make sure the user has already signed-up for Podme</Text>
                </Stack>
            }
        >
            <Box padding={"0.3em"} color={"grey.300"}>
                <IoMdInformationCircleOutline />
            </Box>
        </Tooltip>
    );
}
