import { useCallback, useState } from "react";
import { Box, Switch, Table, Thead, Tr, Th, Text, Flex, Select } from "@chakra-ui/react";
import TableContainer from "../ManageVoucherCampaigns/components/TableContainer";
import { useGetAudioProcessingJobsQuery } from "../services/adminApi/adminApi";
import EncodingQueueTableRows from "./EncodingQueueTableRows";

export default function EncodingQueuePage() {
    const [includeFinished, setIncludeFinished] = useState(false);
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState(0);
    const pageSize = 10;

    const { data, isLoading, isFetching, isError } = useGetAudioProcessingJobsQuery({ includeFinished, page, pageSize, orderBy });

    const handleIncludeFinishedChange = useCallback(() => {
        setPage(0);
        setIncludeFinished((prev) => !prev);
    }, []);

    const handleOrderByChange = useCallback((val) => {
        setPage(0);
        setOrderBy(val);
    }, []);

    const IncludeFinishedSwitch = () => (
        <Flex gap={4} alignItems="center">
            <Text fontSize="lg" pl={6}>
                Include finished
            </Text>
            <Switch isChecked={includeFinished} onChange={handleIncludeFinishedChange} />
        </Flex>
    );

    const isLoadingOrFetching = isLoading || isFetching;
    const numberOfPages = data?.totalCount ? Math.ceil(data?.totalCount / pageSize) : 0;

    const COLUMNS = [
        "Id",
        "Name",
        "Asset Type",
        "Asset Id",
        "Queued At",
        "Priority Weight",
        "is Finished",
        "is Processing",
        "Failures Count",
    ] as const;

    if (isError) {
        return (
            <Box>
                <Text fontSize="lg" mb={4} pl={6}>
                    Ups! something went wrong
                </Text>
            </Box>
        );
    }

    return (
        <TableContainer
            isLoading={false}
            title="Audio Encoding Queue"
            currentPage={page}
            currentPageSize={pageSize}
            setCurrentPage={(p: number) => setPage(p)}
            numberOfPages={numberOfPages}
            showPagination={true}
        >
            <Flex direction="column">
                <Flex>
                    <IncludeFinishedSwitch />
                    <Flex gap={4} alignItems="center">
                        <Text fontSize="lg" pl={6} minWidth={"100px"}>
                            Order by
                        </Text>
                        <Select
                            defaultValue={"0"}
                            value={orderBy}
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                handleOrderByChange(event.target.value);
                            }}
                        >
                            <option hidden disabled value=""></option>
                            <option value="0">Descending</option>
                            <option value="1">Ascending</option>
                        </Select>
                    </Flex>
                </Flex>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            {COLUMNS.map((column) => (
                                <Th key={column}>{column}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <EncodingQueueTableRows isLoading={isLoadingOrFetching} data={data?.result} />
                </Table>
            </Flex>
        </TableContainer>
    );
}
