import React, { useState, useEffect } from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import { FiDownload } from "react-icons/fi";
import { TiDelete } from "react-icons/ti";

export default function UploadImageBox({ imageUrl, setValue, setError }) {
    const [imageSrc, setImageSrc] = useState("");
    const [imageFile, setImageFile] = useState("");
    const [previewImage, setPreviewImage] = useState(imageUrl);

    useEffect(() => {
        setValue({ file: imageFile, base64string: imageSrc });
    }, [imageSrc]);

    function onDrag(evt) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    function onDrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        const dt = evt.dataTransfer;
        const file = dt.files[0];

        previewFile(file);
        if (!["image/jpeg", "image/png"].includes(file.type)) {
            setError("Non supported image format. Must be PNG or JPEG.");
        } else {
            previewFile(file);
        }
    }

    function onSelect(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        const file = evt.target.files[0];

        if (!["image/jpeg", "image/png"].includes(file.type)) {
            setError("Non supported image format. Must be PNG or JPEG.");
        } else {
            previewFile(file);
        }
    }

    function previewFile(file) {
        setImageFile(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (response) {
            setPreviewImage(response.target.result);
            const base64string = response.target.result.split(",")[1];
            setImageSrc(base64string);
        };
    }

    return !imageSrc && !previewImage ? (
        <Box
            id='upload-box'
            backgroundColor='#e7eff1'
            height='150px'
            padding={2}
            display='flex'
            alignItems='center'
            justifyContent='center'
            style={{ outline: "1px dashed #93b0b3", outlineOffset: "-10px" }}
            onDragEnter={onDrag}
            onDragOver={onDrag}
            onDrop={onDrop}
        >
            <Box display='flex' flexDirection='column' alignItems='center'>
                <FiDownload fontSize='3em' />
                <Text marginTop={2}>
                    <label>
                        <strong style={{ cursor: "pointer" }}>Select a file</strong>
                        <input
                            type='file'
                            style={{ position: "fixed", marginTop: "-100em" }}
                            onChange={onSelect}
                            accept='.jpeg, .jpg, .png'
                        />
                    </label>{" "}
                    or drag it here.
                </Text>
            </Box>
        </Box>
    ) : (
        <Box>
            <Box as='span' position='relative' display='inline-block'>
                <TiDelete
                    style={{ position: "absolute", top: "5px", right: "5px", color: "#f74545", fontSize: "1.3em", cursor: "pointer" }}
                    onClick={() => {
                        setImageSrc("");
                        setImageFile("");
                        setPreviewImage("");
                    }}
                />
                <Image src={previewImage} alt='upload img' size='150px' />
            </Box>
        </Box>
    );
}
