import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { ChakraProvider } from "@chakra-ui/react";

import configureStore from "./redux/store";
import Root from "./Root";
import theme from "./config/theme";

import "./index.css";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
moment.locale("sv");

const history = createBrowserHistory({ basename: "/" });
const store = configureStore(history);


function App() {
    return (
        <Provider store={store}>

            <ChakraProvider theme={theme}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale='sv'>
                    <ConnectedRouter history={history}>
                        <Root store={store} />

                    </ConnectedRouter>
                </MuiPickersUtilsProvider>
            </ChakraProvider>
            <ToastContainer
                position="top-center"
                autoClose={3000}
            />
        </Provider>

    );
}

ReactDOM.render(<App />, document.getElementById("root"));

export default App;
