import React, { useState, useEffect } from 'react';

interface CountdownProps {
    duration: number;
    onFinish: () => void;
};

export default function Countdown({ onFinish, duration }: CountdownProps) {
    const [number, setNumber] = useState(duration);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setNumber((t) => t - 1);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleCount = (num: number) => {
        if (num === 0) {
            onFinish();
            setNumber(duration);
        }
        return num;
    }

    return (
        <strong>{handleCount(number)}</strong>
    );
}