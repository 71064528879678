import { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";

import { CheckAuthStatus } from "../redux/authActions";

function useSession() {
    const dispatch = useDispatch();
    useEffect(() => {
        const interval = setInterval(() => dispatch(CheckAuthStatus()), 5000);

        return () => clearInterval(interval);
    }, [dispatch]);
}

export default useSession;
