import { ManageVoucherCampaignsState, VoucherCampaignType, ManageVoucherCampaignsActionTypes } from "./types";

const initialState: ManageVoucherCampaignsState = {
    voucherCampaignTypes: [VoucherCampaignType.Generic, VoucherCampaignType.Unique, VoucherCampaignType.Reward],
    activeVoucherCampaignsData: null,
    upcomingVoucherCampaignsData: null,
    statistics: {
        activeVoucherCampaigns: null,
        topVoucherCampaigns: null,
    },
    historyData: null,
    discountTypes: null,
    voucherCampaignCreated: false,
    voucherCampaignUpdated: false,
    voucherCampaignArchived: false,
    voucherListIsLoading: false,
    validateVoucherData: null,
    validateVoucherIsLoading: false,
};

export default function manageVoucherCampaignReducer(state = initialState, action: ManageVoucherCampaignsActionTypes) {
    switch (action.type) {
        case "GET_ACTIVE_VOUCHERCAMPAIGNS_SUCCESS": {
            return {
                ...state,
                activeVoucherCampaignsData: action.activeVoucherCampaignsResult,
                upcomingVoucherCampaignsData: action.upcomingVoucherCampaignsResult,
                voucherCampaignCreated: false,
                voucherCampaignUpdated: false,
                voucherCampaignArchived: false,
            };
        }

        case "GET_OVERVIEW_CONTENT_SUCCESS": {
            const activeVoucherCampaigns = action?.activeVoucherCampaigns
                ?.map((x: any) => ({ voucherCampaignName: x.voucherCampaignName, redemptionCount: x.redemptionCount }))
                ?.sort((a: any, b: any) => (a.count > b.count ? -1 : 1));

            const topVoucherCampaigns = action?.topVoucherCampaigns
                ?.map((x: any) => ({ voucherCampaignName: x.voucherCampaignName, redemptionCount: x.redemptionCount }))
                ?.sort((a: any, b: any) => (a.count > b.count ? -1 : 1));

            return {
                ...state,
                statistics: {
                    activeVoucherCampaigns: activeVoucherCampaigns ?? null,
                    topVoucherCampaigns: topVoucherCampaigns ?? null,
                },
            };
        }

        case "GET_VOUCHERCAMPAIGN_HISTORY_SUCCESS": {
            return {
                ...state,
                historyData: action.data,
            };
        }

        case "CREATE_VOUCHERCAMPAIGN":
            return {
                ...state,
                isSubmitting: true,
            };

        case "CREATE_VOUCHERCAMPAIGN_SUCCESS":
            return {
                ...state,
                isSubmitting: false,
                voucherCampaignCreated: true,
            };

        case "UPDATE_VOUCHERCAMPAIGN":
            return {
                ...state,
                isSubmitting: true,
            };

        case "UPDATE_VOUCHERCAMPAIGN_SUCCESS":
            return {
                ...state,
                isSubmitting: false,
                voucherCampaignUpdated: true,
            };

        case "GET_VOUCHERCAMPAIGN_DISCOUNT_TYPES_SUCCESS":
            return {
                ...state,
                discountTypes: action.discountTypes,
                voucherCampaignUpdated: false,
                voucherCampaignCreated: false,
                voucherCampaignArchived: false,
            };

        case "ARCHIVE_VOUCHERCAMPAIGN_SUCCESS":
            return {
                ...state,
                voucherCampaignArchived: true,
            };

        case "LIST_VOUCHERCAMPAIGNVOUCHERS":
            return {
                ...state,
                voucherListIsLoading: true,
            };

        case "LIST_VOUCHERCAMPAIGNVOUCHERS_SUCCESS":
        case "LIST_VOUCHERCAMPAIGNVOUCHERS_FAILED":
            return {
                ...state,
                voucherListIsLoading: false,
            };

        case "GET_VOUCHER_BY_CODE":
            return {
                ...state,
                validateVoucherIsLoading: true,
            };

        case "GET_VOUCHER_BY_CODE_SUCCESS":
            return {
                ...state,
                validateVoucherData: action.data,
                validateVoucherIsLoading: false,
            };

        case "GET_VOUCHER_BY_CODE_FAILED":
            return {
                ...state,
                validateVoucherIsLoading: false,
            };

        default: {
            return state;
        }
    }
}
