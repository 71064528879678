import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { createAxiosRequest } from '../utils';
import PromoCard from './components/promoCard';
import BannerLink from './components/bannerLink';
import BannerWithEpisodeList from './components/bannerWithEpisodeList';
import HeroCards from './components/heroCards';
import { EpisodeList } from './components/episodeList';
import PodcastCarousel from './components/podcastCarousel';
import EpisodeCarousel from './components/episodeCarousel';
import PodcastPromoCarousel from './components/podcastPromoCarousel';
import EpisodePromoCarousel from './components/episodePromoCarousel';
import PodcastNuggets from './components/podcastNuggets';
import PremiumPromo from './components/premiumPromo';
import CategoryList from './components/categoryList';
import { Box, Heading, Select, Checkbox, Text, Button } from "@chakra-ui/react";
import CircularProgress from "../components/CircularProgress";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Controls = styled.div`
    display: flex;
    flex-direction: row;
    background: #fff;
    filter: drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.24));
    padding:20px;
    border-radius: 16px;
    margin-bottom: 50px;
    & > label {
        margin-top: 10px;
    }
    & > div {
        margin-right: 50px;
    }
`;

const Preview = styled.div`
    display: flex;
    flex-direction: column;
    color: #F8F8F8;
    background: #121821;
    width: 375px;
    margin-bottom: 200px;
    & > img {
        max-width: 375px;
    }
`;

const Section = styled.div`
    margin-bottom: 50px;
`;

interface DiscoverPage {
    sections: [any];
}

export default function CmsPreview() {
    const regionMapper = new Map([
        ['SE', 1],
        ['NO', 2],
        ['FI', 3],
    ]);
    const emptyDiscoverPage: DiscoverPage = {
        sections: [null]
    };
    const languages = ['sv-SE', 'fi-FI', 'nb-NO'];
    const [lang, setLang] = useState('sv-SE');
    const [region, setRegion] = useState(1);
    const [isPremium, setIsPremium] = useState(true);
    const [isPreview, setIsPreview] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [content, setContent] = useState(emptyDiscoverPage);

    const sectionMapper = new Map([
        ['promoCard', (data: any, key: number) => <PromoCard data={data} key={key} />],
        ['bannerLink', (data: any, key: number) => <BannerLink data={data} key={key} />],
        ['bannerWithEpisodeList', (data: any, key: number) => <BannerWithEpisodeList data={data} key={key} />],
        ['listOfHeroCards', (data: any, key: number) => <HeroCards data={data} key={key} />],
        ['listOfEpisodes', (data: any, key: number) => <EpisodeList data={data} key={key} />],
        ['episodeCarousel', (data: any, key: number) => <EpisodeCarousel data={data} key={key} />],
        ['episodePromoCarousel', (data: any, key: number) => <EpisodePromoCarousel data={data} key={key} />],
        ['podcastCarousel', (data: any, key: number) => <PodcastCarousel data={data} key={key} />],
        ['podcastPromoCarousel', (data: any, key: number) => <PodcastPromoCarousel data={data} key={key} />],
        ['podcastNuggets', (data: any, key: number) => <PodcastNuggets data={data} key={key} />],
        ['listOfCategories', (data: any, key: number) => <CategoryList data={data} key={key} />],
        ['premiumPromo', (data: any, key: number) => <PremiumPromo data={data} key={key} />],
    ]);

    const fetchContent = async (isPremiumUser: boolean, isPreviewMode: boolean, reg: number, language: string) => {
        try {
            setIsLoading(true);
            setHasError(false);
            const res = await createAxiosRequest(`api/cms/preview?isPremiumUser=${isPremiumUser}&isPreview=${isPreviewMode}&region=${reg}&locale=${language}`);
            setContent(res.data);
            setIsLoading(false);
        }
        catch (err) {
            setHasError(true);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchContent(isPremium, isPreview, region, lang);
    }, []);

    const handleIsPremium = () => {
        const newValue = !isPremium;
        setIsPremium(newValue);
        fetchContent(newValue, isPreview, region, lang);
    };

    const handleIsPreview = () => {
        const newValue = !isPreview;
        setIsPreview(newValue);
        fetchContent(isPremium, newValue, region, lang);
    };

    const handleRegionChange = (ev: any) => {
        const newRegion = ev.target.value;
        setRegion(newRegion);
        fetchContent(isPremium, isPreview, newRegion, lang);
    };

    const handleLanguageChange = (ev: any) => {
        const newLang = ev.target.value;
        setLang(newLang);
        fetchContent(isPremium, isPreview, region, newLang);
    };

    const handleRefresh = () => {
        fetchContent(isPremium, isPreview, region, lang);
    };

    return (
        <Box width='100%' margin='0 auto'>
            <Heading as='h1' size={"xl"} mb={8} color='white'>
                CMS Preview
            </Heading>
            <Box width='100%' margin='0 auto'>
                <Controls>
                    <div>
                        <label>Select region</label>
                        <Select value={region} onChange={handleRegionChange}>
                            {Array.from(regionMapper.keys()).map((key) => <option key={key} value={regionMapper.get(key)}>{key}</option>)}
                        </Select>
                    </div>
                    <div>
                        <label>Select language</label>
                        <Select value={lang} onChange={handleLanguageChange}>
                            {languages.map(key => <option key={key} value={key}>{key}</option>)}
                        </Select>
                    </div>
                    <div>
                        <Checkbox isChecked={isPremium} onChange={handleIsPremium}>
                            Is premium user?
                        </Checkbox>
                    </div>
                    <div>
                        <Checkbox isChecked={isPreview} onChange={handleIsPreview}>
                            Preview?
                        </Checkbox>
                        <Text fontSize="xs">View drafts and circumvent caching of components.</Text>
                    </div>
                    <div>
                        <Button onClick={handleRefresh}>Refresh</Button>
                    </div>
                </Controls>
            </Box>
            {isLoading && <CircularProgress />}
            {!isLoading && hasError && (<div>Something went wrong when fetching data :( Please try again</div>)}
            {!isLoading && !hasError && content && (
                <Container>
                    <Preview>
                        {content.sections.map((s, i) => {
                            const type = s.content.type;
                            if (sectionMapper.has(type)) {
                                return <Section key={i}>{sectionMapper.get(type)?.(s.content, i)}</Section>;
                            }
                            return "";
                        })}
                    </Preview>
                </Container>)}
        </Box>
    )
}
