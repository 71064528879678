import { Skeleton, SkeletonCircle as Circle } from "@chakra-ui/react";
import { ReactElement } from "react";
import { PodmeColor } from "../../redux/types";
import { leftRight } from "../../styles/animations";

interface SkeletonProps {
    children?: ReactElement;
    isLoaded?: boolean;
    startColor?: PodmeColor;
    [x: string]: any;
}

export default function ({ children, isLoaded = false, ...rest }: SkeletonProps): ReactElement {
    return (
        <Skeleton
            fadeDuration={0}
            sx={
                !isLoaded
                    ? {
                          animation: `${leftRight} 2s infinite`,
                          background: `linear-gradient(90deg, ${PodmeColor.Silver + "50"}, ${PodmeColor.SoftWhite}, ${
                              PodmeColor.Silver + "50"
                          }, ${PodmeColor.Silver + "50"})`,
                          backgroundSize: "200% 100%",
                      }
                    : undefined
            }
            isLoaded={isLoaded}
            {...rest}
        >
            {children}
        </Skeleton>
    );
}

export function SkeletonCircle({
    children,
    isLoaded = false,
    startColor = PodmeColor.Cinder,
    endColor = PodmeColor.LicoriceBlue,
    ...rest
}: SkeletonProps): ReactElement {
    return (
        <Circle
            startColor={PodmeColor.Cyan + "30"}
            endColor={PodmeColor.LicoriceBlue}
            fadeDuration={0}
            sx={
                !isLoaded
                    ? {
                          animation: `${leftRight} 2s infinite`,
                          background: `linear-gradient(90deg, ${startColor}, ${endColor}, ${startColor}, ${startColor})`,
                          backgroundSize: "200% 100%",
                      }
                    : undefined
            }
            isLoaded={isLoaded}
            {...rest}
        >
            {children}
        </Circle>
    );
}
