import React from "react";

import PodMeLogo from "../../../assets/images/logo_white.svg";

export default function LogoReveal() {
    return (
        <div>
            <img src={PodMeLogo} alt="PodMe logo" style={{ width: "400px", margin: 20 }} />
        </div>
    );
}
