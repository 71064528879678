import styled from "styled-components";
import one from "../../assets/images/cmspreview/1.png";
import two from "../../assets/images/cmspreview/2.png";
import three from "../../assets/images/cmspreview/3.png";
import four from "../../assets/images/cmspreview/4.png";
import five from "../../assets/images/cmspreview/5.png";
import six from "../../assets/images/cmspreview/6.png";
import seven from "../../assets/images/cmspreview/7.png";
import eight from "../../assets/images/cmspreview/8.png";
import nine from "../../assets/images/cmspreview/9.png";
import ten from "../../assets/images/cmspreview/10.png";


const Container = styled.div`
    background-image: url("${(props: { image: string }) => props.image}");
    background-repeat: no-repeat;
    background-size: 144px 203px;
    display: flex;
    flex-direction: column;
    height: 203px;
    width: 144px;
    max-width: 144px;
    max-height: 203px;
    border-radius: 4px;
    margin-right: 16px;
`;

interface NumberProps {
    height: number;
    width: number;
    bottom?: number;
}

const Number = styled.img`
    height: ${(props: NumberProps) => props.height}px;
    width: ${(props: NumberProps) => props.width}px;
    margin-left: auto;
    margin-top: auto;
    margin-right: 10px;
    margin-bottom: ${(props: NumberProps) => props.bottom ? props.bottom : 10}px;
`;

const numbers = [
    <Number width={35} height={85} src={one} />,
    <Number width={56} height={86} src={two} />,
    <Number width={57} height={88} src={three} />,
    <Number width={80} height={97} bottom={4} src={four} />,
    <Number width={72} height={99} bottom={2} src={five} />,
    <Number width={73} height={99} bottom={2} src={six} />,
    <Number width={56} height={85} src={seven} />,
    <Number width={59} height={88} src={eight} />,
    <Number width={61} height={87} src={nine} />,
    <Number width={111} height={88} src={ten} />
];

export interface PromoItemProps {
    data: {
        imageUrl: string;
        index: number;
        isToplist: boolean;
    }
}

export function PromoItem({ data }: PromoItemProps) {
    const getNumber = (index: number) => {
        if (index < numbers.length) {
            return numbers[index];
        }
        return '';
    }

    const getImageUrl = (url: string) => {
        return url ? url : 'http://placekitten.com/g/203/144';
    }
    return (
        <Container image={getImageUrl(data.imageUrl)}>
            {data.isToplist && getNumber(data.index)}
        </Container>
    );
}