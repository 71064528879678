import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Heading } from "@chakra-ui/react";

import UserTable from "./components/table/UserTable";

import SearchField from "../components/SearchField/SearchField";
import { actions } from "./redux";
import { RootState } from "../redux/reducers/rootReducer";
import { actions as statisticsActions } from "../Statistics/redux";
import { GET_USER_QUERY_TYPES, SEARCH_CONTEXT } from "./redux/types";
import { useHistory, useLocation } from "react-router";
import { debouncedSearch } from "../utils";

const manageUsersState = (state: RootState) => state.manageUsers;
const globalState = (state: RootState) => state.global;

const URL_SEARCH = "search";
const URL_TYPE = "type";

export default function ManageUsers() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const { users, noUsersFound, hasMoreUsers, userQueryTypes, usersTable } = useSelector(manageUsersState);
    const { searchedUsers, isLoading } = usersTable || {};
    const { region } = useSelector(globalState);

    const [page, setPage] = useState(1);
    const [pageSize] = useState(40);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchType, setSearchType] = useState("");
    const [shownUsers, setShownUsers] = useState(users);

    const searchParams = new URLSearchParams(location.search);
    const urlUserSearch = searchParams.get(URL_SEARCH);
    const urlUserTypeSearch = searchParams.get(URL_TYPE);

    useEffect(() => {
        if (urlUserSearch) setSearchQuery(urlUserSearch);
        if (urlUserTypeSearch) setSearchType(urlUserTypeSearch);
        setSearchQuery(`${urlUserSearch ? urlUserSearch : ""}`);
    }, [urlUserSearch, urlUserTypeSearch]);

    useEffect(() => {
        if (region) {
            dispatch(statisticsActions.getPremiumPodcasts(region));
            dispatch({ type: GET_USER_QUERY_TYPES });
        }
    }, [region]);

    useEffect(() => {
        if (searchQuery.trim() === "") {
            dispatch(actions.getUsers(pageSize, page));
        } else if (searchQuery) {
            debouncedSearch(() => {
                const cleanedSearchValue = searchQuery.trim();
                dispatch(actions.searchUser(cleanedSearchValue, searchType, SEARCH_CONTEXT.UsersTable));
            });
        }
    }, [searchQuery]);

    useEffect(() => {
        if (searchQuery && searchedUsers && searchQuery.trim() !== "") {
            setShownUsers(searchedUsers);
        } else {
            setShownUsers(users);
        }
    }, [users, usersTable, searchedUsers, searchQuery]);

    const fetchMoreUsers = useCallback(() => {
        dispatch(actions.getUsers(pageSize, page + 1));
        setPage((prev) => prev + 1);
    }, [page, pageSize]);

    const handleKeyDown = (e: React.KeyboardEvent<any>) => {
        if (e.key === "Enter") {
            setPage(1);
            dispatch(actions.searchUser(searchQuery, searchType, "usersTable"));
        }
    };

    const updateUrlQuery = useCallback((search?: string, type?: string) => {
        if ((!search || search.trim() === "") && type && type.trim() !== "") {
            history.replace({
                search: `?type=${type}`,
            });
        } else {
            history.replace({
                search: `${search && search !== "" ? `?search=${search}` : ""}${type && type.trim() !== "" ? `&type=${type}` : ""}`,
            });
        }
    }, []);

    const handleChange = useCallback(
        (query: string) => {
            setSearchQuery(query);

            query.trim() !== ""
                ? updateUrlQuery(query, searchType)
                : searchType !== ""
                ? updateUrlQuery(query, searchType)
                : history.replace({ search: "" });
        },
        [searchType]
    );

    const changeSearchType = useCallback(
        (type: string) => {
            setSearchType(() => type);

            updateUrlQuery(searchQuery, type);

            searchQuery && searchQuery.trim() !== "" && dispatch(actions.searchUser(searchQuery.trim(), type, "usersTable"));
        },
        [searchQuery]
    );

    return (
        <Flex direction="column" gridGap="1rem" width="100%" margin="0 auto">
            <Heading as="h1" size={"xl"} color="white">
                Users
            </Heading>

            <SearchField
                label="Search users"
                searchValue={searchQuery}
                searchType={searchType}
                queryTypes={userQueryTypes}
                changeSearchType={changeSearchType}
                handleKeyDown={handleKeyDown}
                handleChange={handleChange}
                isExpandable
            />

            <UserTable
                shownUsers={shownUsers}
                hasMoreUsers={hasMoreUsers}
                isLoading={!!isLoading}
                fetchMoreUsers={fetchMoreUsers}
                noUsersFound={noUsersFound}
            />
        </Flex>
    );
}
