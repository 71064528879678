import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text, Badge } from "@chakra-ui/react";
import TableContainer from "../components/TableContainer";
import { formatInTimeZone } from "date-fns-tz";

import { actions } from "../redux";
import { VoucherCampaignDiscountType, VoucherCampaignType } from "../redux/types";
import { RootState } from "../../redux/reducers/rootReducer";
import DownloadVoucherCodesButton from "../components/DownloadVoucherCodesButton";

const manageVoucherCampaignsState = (state: RootState) => state.manageVoucherCampaigns;
const globalState = (state: RootState) => state.global;

export default function InactivePage() {
    const dispatch = useDispatch();
    const { historyData, discountTypes, voucherListIsLoading } = useSelector(manageVoucherCampaignsState);
    const { region, timezone } = useSelector(globalState);
    const [downloadVoucherCodesCampaignId, setDownloadVoucherCodesCampaignId] = useState<number>();
    const [page, setPage] = useState(0);
    const [pageSize] = useState(10);

    useEffect(() => {
        if (!discountTypes) dispatch(actions.getVoucherCampaignDiscountTypes());
        dispatch(actions.getVoucherCampaignHistory(page, pageSize, region));
    }, [page, region]);

    useEffect(() => {
        setPage(0);
    }, [region]);

    return (
        <Box>
            <TableContainer
                isLoading={false}
                title="Inactive voucher campaigns"
                currentPage={page}
                currentPageSize={historyData?.currentPageSize}
                setCurrentPage={(p) => setPage(p)}
                numberOfPages={historyData?.numberOfPages}
                showPagination={(historyData && historyData?.numberOfPages > 1) || undefined}
            >
                {historyData && historyData.items.length > 0 ? (
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Id</Th>
                                <Th>Name</Th>
                                <Th>Type</Th>
                                <Th>Activations</Th>
                                <Th>Limit</Th>
                                <Th>Started</Th>
                                <Th>Ended</Th>
                                <Th>Churn eligibility</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {historyData.items?.map((c, i) => (
                                <Tr key={c.name}>
                                    <Td>{c.id}</Td>
                                    <Td>
                                        <Box display="flex" flexDirection="column">
                                            <Box>
                                                <Badge mb={4} mt="-5px" variant="subtle" colorScheme="green">
                                                    {c.type === (VoucherCampaignType.Reward as number)
                                                        ? `Month credit: ${c.monthCredit}`
                                                        : getVoucherCampaignDiscountType(discountTypes, c.discountTypeId)}
                                                </Badge>
                                            </Box>
                                            <Text fontWeight="600">
                                                {c.name}
                                                {c.type == VoucherCampaignType.Generic ? `, code: ${c.voucherCode}` : null}
                                            </Text>
                                        </Box>
                                    </Td>
                                    <Td>{VoucherCampaignType[c.type]}</Td>
                                    <Td>{c.redemptionCount ?? 0}</Td>
                                    <Td>{!c.redemptionLimit || c.redemptionLimit < 1 ? "none" : c.redemptionLimit}</Td>
                                    <Td>{formatInTimeZone(c.validFrom, timezone, "yyyy-MM-dd")}</Td>
                                    <Td>
                                        {c.isArchived ? (
                                            <Badge colorScheme="orange">Archived</Badge>
                                        ) : (
                                            formatInTimeZone(c.validUntil, timezone, "yyyy-MM-dd")
                                        )}
                                    </Td>
                                    <Td>
                                        <Box>
                                            <Text>{c.churnEligibilityInDays ? `${c.churnEligibilityInDays} days` : "N/A"}</Text>
                                        </Box>
                                    </Td>
                                    <Td isNumeric>
                                        <DownloadVoucherCodesButton
                                            onSubmit={() => {
                                                setDownloadVoucherCodesCampaignId(c.id);
                                                dispatch(actions.listVoucherCampaignVouchers(c.id, c.name));
                                            }}
                                            voucherCampaign={c}
                                            marginRight={2}
                                            isLoading={voucherListIsLoading && downloadVoucherCodesCampaignId === c.id}
                                        />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                ) : (
                    <Text fontSize="lg" mb={4} pl={6}>
                        No voucher campaign history
                    </Text>
                )}
            </TableContainer>
        </Box>
    );
}

function getVoucherCampaignDiscountType(plans: VoucherCampaignDiscountType[] | null, id: number) {
    const plan = plans?.filter((p) => p.id === id)[0]?.name;

    return plan;
}
