import config from "../config";
import { initHello } from "../config/hello";
import types, {RegionLogin} from "./types";
import {REFRESH_SCHIBSTED_TOKEN} from "../ManageUsers/redux/types";
import Cookies from "universal-cookie/cjs";

export function CheckAuthStatus() {

    return async (dispatch) => {
        if (localStorage.getItem("hello")) {

            const hello = JSON.parse(localStorage.getItem("hello"));
            const session = hello.adminAdB2CSignIn;


            if (session && session.expires) {
                const dateObject = new Date();
                let currentTime = new Date(1970, 0, 1);
                let cookieTime = new Date(1970, 0, 1);
                currentTime = currentTime.setSeconds(dateObject.getTime() / 1000);
                cookieTime = cookieTime.setSeconds(session.expires);

                if (cookieTime > currentTime) {
                    return await dispatch({ type: types.USER_IS_LOGGED_IN, hello, session });
                } else {
                    return await dispatch({ type: types.USER_IS_NOT_LOGGED_IN });
                }
            }
        }
        const expTokenDate = localStorage.getItem("expToken");
        const cookie = (new Cookies()).get("podme_admincookie");

        if(expTokenDate && cookie) {
            const dateNow = new Date();
            const expDate = new Date(localStorage.getItem("expToken"));
            if (dateNow > expDate) {
                return await dispatch({type: REFRESH_SCHIBSTED_TOKEN, region: new Cookies().get("selectedSchibstedAccountRegion") ?? RegionLogin.Sweden })
            } else {
                return await dispatch({type: types.USER_IS_LOGGED_IN_WITH_SCHIBSTED})
            }
        }

        return await dispatch({ type: types.USER_IS_NOT_LOGGED_IN });
    };
}

export function loginUser(pageUri, reduxStore) {
    return async (dispatch) => {
        try {
            const hello = initHello(reduxStore);

            await dispatch({ type: types.LOGIN_USER });

            await hello(config.AZURE_PROFILE).login({
                display: "page",
                force: true,
                redirect_uri: "/redirect.html",
                page_uri: pageUri,
            });

            localStorage.setItem("loggedIn", "1");

            return await dispatch({ type: types.LOGIN_USER_SUCCESS, hello, userLoggedInWithSchibsted: false });
        } catch (error) {
            return await dispatch({ type: types.LOGIN_USER_FAILED, error });
        }
    };
}
