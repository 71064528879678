import styled from "styled-components";
import chevronImg from "../../assets/images/cmspreview/chevron.svg";

const Container = styled.div`
    background-image: url("${(props: { image: string }) => props.image}");
    background-size: 345px 112px;
    height: 112px;
    min-height: 112px;
    width: 345px;    
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 265px;
`;

const Title = styled.h4`
    padding: 0;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
`;

const Description = styled.p`
    padding: 0;
    margin: 0;
    color: #BABABA;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.01em;
`;

const Icon = styled.img`
    width: 14px;
    height: 24px;
    margin-left: 12px;
`;

interface BannerProps {
    data: {
        imageUrl: string;
        title: string;
        description: string;
    },
    onClick: (value: any) => void;
}

export default function Banner({ data, onClick }: BannerProps) {
    return (
        <Container image={data.imageUrl} onClick={onClick}>
            <TextContainer>
                <Title>{data.title}</Title>
                <Description>{data.description}</Description>
            </TextContainer>
            <Icon alt="chevron" src={chevronImg} />
        </Container>
    );
}