import {
    Button,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    useDisclosure,
    Text,
    Box,
    Flex,
} from "@chakra-ui/react";
import { PaymentTransaction, PaymentTransactionState } from "../redux/types";
import { useDispatch } from "react-redux";
import { ReactElement, useEffect } from "react";
import { actions } from "../redux";
import { PodmeColor } from "../../redux/types";

interface Props {
    paymentTransaction: PaymentTransaction;
    subscriptionId: number;
    userAccountId: string;
}

function RefundConfirmationModal({ paymentTransaction, userAccountId, subscriptionId }: Props): ReactElement {
    const dispatch = useDispatch();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleRefundTransaction = (transactionId: number) => {
        dispatch(actions.refundTransaction(transactionId, userAccountId, subscriptionId));
    };

    const isRefundButtonDisabled = (trans: PaymentTransaction) =>
        trans.isRefundingTransaction ||
        trans.state != PaymentTransactionState.Succeeded ||
        trans.amount === undefined ||
        trans.amount === 0 ||
        (trans.refundedAmount !== undefined && trans.refundedAmount > 0);

    useEffect(() => {
        if (!paymentTransaction.isRefundingTransaction && paymentTransaction.refundedAmount && paymentTransaction.refundedAmount > 0) {
            onClose();
        }
    }, [paymentTransaction.isRefundingTransaction, paymentTransaction.refundedAmount]);

    return (
        <>
            <Button isDisabled={isRefundButtonDisabled(paymentTransaction)} onClick={onOpen}>
                {paymentTransaction.isRefundingTransaction && (
                    <Spinner thickness="2px" speed="0.65s" color={PodmeColor.Silver} size="sm" marginRight="5px" />
                )}
                <Text>Refund</Text>
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
                <ModalOverlay backgroundColor="rgba(0,0,0,0.7)" />
                <ModalContent>
                    <>
                        <ModalHeader />
                        <ModalCloseButton />
                        <ModalBody textAlign="center">
                            <Box padding="0.5em">
                                <Heading textAlign="center" size="md">
                                    Refund transaction {paymentTransaction.paymentTransactionId}
                                </Heading>
                            </Box>
                            <Box padding="0.5em">
                                <Text>
                                    You will refund {paymentTransaction.amount} {paymentTransaction.currency} for transaction id:{" "}
                                    {paymentTransaction.paymentTransactionId}.
                                </Text>
                                <Text>Are you sure you want to proceed?</Text>
                            </Box>
                        </ModalBody>
                        <Box padding={6}>
                            <Flex flexDirection="row" justifyContent="center">
                                <Button
                                    colorScheme="green"
                                    size="md"
                                    margin="0 0.5em 0 0.5em"
                                    isDisabled={isRefundButtonDisabled(paymentTransaction)}
                                    onClick={() => handleRefundTransaction(paymentTransaction.paymentTransactionId)}
                                    isLoading={paymentTransaction.isRefundingTransaction}
                                >
                                    <Text>Yes</Text>
                                </Button>
                                <Button
                                    colorScheme="red"
                                    size="md"
                                    margin="0 0.5em 0 0.5em"
                                    isDisabled={isRefundButtonDisabled(paymentTransaction)}
                                    onClick={onClose}
                                >
                                    No
                                </Button>
                            </Flex>
                        </Box>
                    </>
                </ModalContent>
            </Modal>
        </>
    );
}

export default RefundConfirmationModal;
