import hellojs from "hellojs";
import Cookies from "universal-cookie";

import config from ".";
import types from "../redux/types";

let _store: any = null;

export function initHello(store?: any) {
    const tenantName = config.TENANT_NAME || "";
    const signInPolicyName = config.SIGN_IN_POLICY_NAME || "";
    localStorage.setItem("loggedIn", "0");

    _store = store;

    hellojs.init({
        adminAdB2CSignIn: {
            name: "Azure Active Directory B2C",
            oauth: {
                version: 2,
                auth:
                    "https://reacthello.b2clogin.com/" +
                    tenantName +
                    "/" +
                    signInPolicyName +
                    "/oauth2/v2.0/authorize?ui_locales=sv&prompt=login",
                grant:
                    "https://reacthello.b2clogin.com/" +
                    tenantName +
                    "/" +
                    signInPolicyName +
                    "/oauth2/v2.0/token?ui_locales=sv&prompt=login",
            },
            refresh: true,
            ui_locales: "sv",
            scope_delim: " ",
            form: false,
        },
    });

    hellojs.init(
        {
            adminAdB2CSignIn: config.APPLICATION_ID,
        },
        {
            scope: config.SCOPE_ID,
            response_type: "token id_token",
        }
    );

    hellojs.on("auth.login", (response: any) => {
        const auth = response.authResponse;
        const cookies = new Cookies();
        cookies.set(config.COOKIE_NAME, auth.access_token, {
            path: "/",
            maxAge: auth.expires_in,
        });
        localStorage.setItem("loggedIn", "1");
        _store.dispatch({ type: types.LOGIN_USER_SUCCESS, userLoggedInWithSchibsted: false });
    });

    hellojs.on("auth.logout", function () {
        // we are doing logout with one action in redux.
    });

    return hellojs;
}
