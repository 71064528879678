import { Heading, ModalCloseButton, ModalHeader } from "@chakra-ui/react";

export default function PodmeModalHeader() {
    return (
        <>
            <ModalHeader>
                <Heading size="lg">Manage user access to Admin Panel</Heading>
            </ModalHeader>
            <ModalCloseButton />
        </>
    );
}
