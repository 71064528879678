import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, ChakraProps, Flex, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { MdSearch, MdClose } from "react-icons/md";
import { colors } from "../../constants";
import { PodmeColor } from "../../redux/types";
import QueryTypesDropdown from "./QueryTypesDropdown";
import { hideTypeTag, showTypeTag } from "../../styles/animations";

interface Props {
    label: string;
    searchValue?: string;
    searchType?: string;
    queryTypes?: string[];
    isExpandable?: boolean;
    changeSearchType?: (searchType: string) => void;
    handleKeyDown?: (event: React.KeyboardEvent<any>) => void;
    handleChange: (query: string) => void;
}

export default function SearchField({
    label,
    searchValue,
    searchType,
    queryTypes,
    isExpandable = false,
    handleChange,
    handleKeyDown,
    changeSearchType,
    ...rest
}: Props & ChakraProps) {
    const ANIMATION_DURATION = 200;

    const [inputValue, setInputValue] = useState(searchValue);
    const [inputFocused, setInputFocused] = useState(false);
    const [typeTagAnimation, setTypeTagAnimation] = useState(showTypeTag);

    const searchTypeSelected = searchType && searchType.trim() !== "";
    const searchFieldNotEmpty = inputValue && inputValue.trim() !== "";

    const inputRef = useRef<HTMLInputElement>(null);
    const filterTypeRef = useRef<HTMLDivElement>(null);
    const canvasRef = useRef(document.createElement("canvas"));

    const handleChangeSearchType = useCallback(
        (type: string) => {
            if (!changeSearchType) return;
            setTypeTagAnimation(hideTypeTag);

            if (type === "") {
                changeSearchType(type);
            } else {
                setTimeout(() => {
                    changeSearchType(type);
                }, ANIMATION_DURATION);
            }
        },
        [changeSearchType]
    );

    const handleSearchInputChange = useCallback(
        (evt: ChangeEvent<HTMLInputElement>) => {
            setInputValue(evt.target.value);
            handleChange(evt.target.value);
        },
        [handleChange]
    );

    const getTextWidth = useCallback(
        (text: string, font = "normal 16px sans-serif") => {
            const context = canvasRef.current.getContext("2d");

            if (!context || !inputRef.current) return 0;

            context.font = getComputedStyle(inputRef.current).font ?? font;

            return context.measureText(text).width;
        },
        [inputRef.current]
    );

    const getDynamicWidth = useMemo(() => {
        const textWidth = getTextWidth(inputValue ?? "");
        const typeWidth = filterTypeRef.current ? filterTypeRef.current.clientWidth + 8 : 0;
        const offset = 140;

        return Math.ceil(textWidth + typeWidth + offset) + "px";
    }, [inputValue, searchType, filterTypeRef.current]);

    useEffect(() => {
        if (searchType && searchType.trim() !== "") {
            setTypeTagAnimation(showTypeTag);
        }
    }, [searchType]);

    useEffect(() => {
        setInputValue(searchValue);
    }, [searchValue]);

    useEffect(() => {
        inputRef.current?.focus();

        return () => {
            const canvas = canvasRef.current;
            if (canvas.parentNode) {
                canvas.parentNode.removeChild(canvas);
            }
        };
    }, []);

    return (
        <Flex
            gridGap="0.5rem"
            minWidth={isExpandable ? "50%" : undefined}
            width={isExpandable ? ["100%", null, null, null, getDynamicWidth] : undefined}
            maxWidth={isExpandable ? ["100%", null, null, "calc(100vw - 20rem)"] : undefined}
            {...rest}
        >
            <InputGroup
                backgroundColor={inputFocused ? PodmeColor.LightBlue + "!important" : PodmeColor.SoftWhite + "!important"}
                display="flex"
                alignItems="center"
                size="md"
                bg="white"
                borderRadius="2rem"
                border="2px solid transparent"
                borderColor={inputFocused ? colors.aquablue : "transparent"}
                transition="border-color 0.2s ease-in-out"
            >
                <InputLeftElement width="3rem" top="50%" transform="translateY(-50%)">
                    <MdSearch style={{ fontSize: "1.5em", color: colors.black }} />
                </InputLeftElement>
                {searchTypeSelected && (
                    <Flex
                        ref={filterTypeRef}
                        animation={`${typeTagAnimation} ${ANIMATION_DURATION}ms ease-out forwards`}
                        alignItems="center"
                        backgroundColor={PodmeColor.Cyan}
                        rounded="3rem"
                        marginLeft="2.75rem"
                        paddingLeft="1rem"
                        cursor="pointer"
                        transition="filter 100ms ease-in-out"
                    >
                        <Box as="span" color={PodmeColor.BlackPearl} fontSize="0.875rem" fontWeight="700">
                            {searchType}
                        </Box>
                        <Flex
                            fontSize="0.975rem"
                            padding="0.4rem 0.5rem"
                            rounded="50%"
                            onClick={() => handleChangeSearchType("")}
                            _hover={{
                                backgroundColor: PodmeColor.LicoriceBlue + "10",
                            }}
                        >
                            <MdClose />
                        </Flex>
                    </Flex>
                )}
                <Input
                    ref={inputRef}
                    variant="filled"
                    background="none !important"
                    _hover={{ bgColor: PodmeColor.Silver + "50" }}
                    value={inputValue}
                    placeholder={label}
                    padding="1.25rem"
                    paddingLeft={searchTypeSelected ? "0.5rem" : "2.75rem"}
                    paddingRight={searchFieldNotEmpty ? "0" : "1rem"}
                    borderRadius="0 2rem 2rem 0"
                    border="none"
                    onFocus={() => setInputFocused(true)}
                    onBlur={() => setInputFocused(false)}
                    onKeyDown={handleKeyDown}
                    onChange={(evt) => handleSearchInputChange(evt)}
                />
                {searchFieldNotEmpty && (
                    <Flex
                        style={{ cursor: "pointer" }}
                        fontSize="1.25rem"
                        padding="0.5rem"
                        marginRight="0.25rem"
                        rounded="50%"
                        onClick={() => {
                            handleSearchInputChange({ target: { value: "" } } as ChangeEvent<HTMLInputElement>);
                        }}
                        _hover={{
                            backgroundColor: PodmeColor.LicoriceBlue + "10",
                        }}
                    >
                        <MdClose color={PodmeColor.BlackPearl} />
                    </Flex>
                )}
            </InputGroup>
            <QueryTypesDropdown queryTypes={queryTypes} changeSearchType={handleChangeSearchType} />
        </Flex>
    );
}
