import { UserRole } from "../../redux/types";
import { capitalizeFirstLetter } from "../../utils";
import {
    ManageAccessAndPermissionsState,
    ManageAccessAndPermissionsActionTypes,
    GET_GROUPS_SUCCESS,
    UPDATE_USER_GROUP_SUCCESS,
    GET_USER_GROUPS_SUCCESS,
    DELETE_USER_GROUP_SUCCESS,
    UserGroup,
    GET_USER_PODCAST_PERMISSIONS_SUCCESS,
    DELETE_USER_FROM_PODCASTER_GROUP_SUCCESS,
    UPDATE_USER_PODCASTER_GROUP_SUCCESS,
    GET_USER_PODCAST_PERMISSIONS,
    GET_USER_PODCAST_PERMISSIONS_FAILED,
} from "./types";

const initialState: ManageAccessAndPermissionsState = {
    groups: null,
    userGroups: [],
    isLoading: false,
    hasMoreUserGroups: true,
    userGroupUpdated: false,
    userPodcastPermissions: [],
    arePodcastsLoading: false,
};

export default function manageAccessAndPermissionsReducer(state = initialState, action: ManageAccessAndPermissionsActionTypes) {
    switch (action.type) {
        case GET_GROUPS_SUCCESS:
            return {
                ...state,
                groups: action.groups.names,
            };
        case GET_USER_GROUPS_SUCCESS:
            return {
                ...state,
                userGroups: state.userGroups.concat(action.userGroups.userGroups),
                hasMoreUserGroups: action.userGroups.userGroups?.length > 0,
                isLoading: false,
            };
        case UPDATE_USER_GROUP_SUCCESS:
            const userGroupToUpdate = state.userGroups.find((userGroup) => userGroup.email === action.values.email);
            if (userGroupToUpdate) {
                userGroupToUpdate.name = action.values.userGroup;
                userGroupToUpdate.validUntil = action.values.validUntil;
            } else {
                state.userGroups.push({
                    email: action.values.email,
                    name: action.values.userGroup,
                    validUntil: action.values.validUntil,
                } as UserGroup);
            }
            return {
                ...state,
                usergroups: [...state.userGroups],
                userGroupUpdates: true,
            };
        case UPDATE_USER_PODCASTER_GROUP_SUCCESS:
            const userPodcasterGroupToUpdate = state.userGroups.find((userGroup) => userGroup.email === action.values.email);
            if (userPodcasterGroupToUpdate) {
                userPodcasterGroupToUpdate.name = capitalizeFirstLetter(UserRole.Podcaster);
                userPodcasterGroupToUpdate.validUntil = action.values.validUntil;
            } else {
                state.userGroups.push({
                    email: action.values.email,
                    name: capitalizeFirstLetter(UserRole.Podcaster),
                    validUntil: action.values.validUntil,
                } as UserGroup);
            }
            return {
                ...state,
                usergroups: [...state.userGroups],
                userGroupUpdates: true,
            };
        case DELETE_USER_GROUP_SUCCESS:
            const indexOfUserGroupToDelete = [...state.userGroups].map((userGroup) => userGroup.userId).indexOf(action.userId);
            state.userGroups.splice(indexOfUserGroupToDelete, 1);
            return {
                ...state,
                userGroups: [...state.userGroups],
            };
        case DELETE_USER_FROM_PODCASTER_GROUP_SUCCESS:
            const indexOfPodcasterUserGroupToDelete = [...state.userGroups]
                .map((userGroup) => userGroup.userId)
                .indexOf(action.userId);
            state.userGroups.splice(indexOfPodcasterUserGroupToDelete, 1);
            return {
                ...state,
                userGroups: [...state.userGroups],
            };
        case GET_USER_PODCAST_PERMISSIONS:
            return {
                ...state,
                arePodcastsLoading: true,
                userPodcastPermissions: [],
            };
        case GET_USER_PODCAST_PERMISSIONS_SUCCESS:
            return {
                ...state,
                arePodcastsLoading: false,
                userPodcastPermissions: action.userPodcastPermissions.userPodcastPermissions,
            };
        case GET_USER_PODCAST_PERMISSIONS_FAILED:
            return {
                ...state,
                arePodcastsLoading: false,
                userPodcastPermissions: [],
            };
        default: {
            return state;
        }
    }
}
