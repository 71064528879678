import {
    DELETE_USER_FROM_PODCASTER_GROUP,
    DELETE_USER_GROUP,
    DeleteUserFromPodcasterGroup,
    DeleteUserGroup,
    GET_GROUPS,
    GET_USER_GROUPS,
    GET_USER_PODCAST_PERMISSIONS,
    GetGroups,
    GetUserGroups,
    GetUserPodcastPermissions,
    UPDATE_USER_GROUP,
    UPDATE_USER_PODCASTER_GROUP,
    UpdateUserGroup,
    UpdateUserGroupFormValues,
    UpdateUserPodcasterGroup,
} from "./types";

function getGroups(): GetGroups {
    return {
        type: GET_GROUPS,
    };
}

function getUserGroups(pageSize: number, currentPage: number): GetUserGroups {
    return {
        type: GET_USER_GROUPS,
        pageSize,
        currentPage,
    };
}

function updateUserGroup(values: UpdateUserGroupFormValues): UpdateUserGroup {
    return {
        type: UPDATE_USER_GROUP,
        values: values,
    };
}

function updatePodcasterUserGroup(values: UpdateUserGroupFormValues): UpdateUserPodcasterGroup {
    return {
        type: UPDATE_USER_PODCASTER_GROUP,
        values: values,
    };
}

function deleteUserGroup(userId: string): DeleteUserGroup {
    return {
        type: DELETE_USER_GROUP,
        userId: userId,
    };
}

function deleteUserFromPodcasterGroup(userId: string): DeleteUserFromPodcasterGroup {
    return {
        type: DELETE_USER_FROM_PODCASTER_GROUP,
        userId: userId,
    };
}

function getUserPodcastPermissions(email: string): GetUserPodcastPermissions {
    return {
        type: GET_USER_PODCAST_PERMISSIONS,
        email: email,
    };
}

export const actions = {
    getGroups,
    getUserGroups,
    updateUserGroup,
    updatePodcasterUserGroup,
    deleteUserGroup,
    deleteUserFromPodcasterGroup,
    getUserPodcastPermissions,
};
