import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.css";

interface Props {
    isClearable?: boolean;
    onChange: (date: Date) => any;
    form: any;
    field: any;
    minDate?: Date;
}

function DatePicker({ form, field, minDate }: Props) {
    return (
        <ReactDatePicker
            {...field}
            selected={field.value}
            dateFormat='yyyy-MM-dd'
            onChange={(option) => form.setFieldValue(field.name, option)}
            minDate={minDate || new Date()}
        />
    );
}

export default DatePicker;
