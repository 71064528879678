import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Checkbox,
    CheckboxGroup,
    Input,
    Box,
    Heading,
} from "@chakra-ui/react";
import {Formik, Form, Field} from "formik";
import {filterOutPremiumCategory, getCategoryName, initialPrimaryCategories} from "../../utils";

const editRssPodcastState = (state) => state.managePodcasts.editRssPodcast;

export default function EditRssPodcast({ onSubmit, resetForm }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { categories, podcast, isLoading, updateSuccessful } = useSelector(editRssPodcastState);
    const [formSubmitted, setFormsubmitted] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            setFormsubmitted(false);
            resetForm();
        }
    }, [isOpen]);

    useEffect(() => {
        if (updateSuccessful) {
            onClose();
        }
    }, [updateSuccessful]);

    if (!podcast) return null;

    return (
        <>
            <Button colorScheme='teal' size='md' onClick={onOpen}>
                Edit podcast
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size='xl'>
                <ModalOverlay backgroundColor='rgba(0,0,0,0.7)' />
                <Formik
                    initialValues={{
                        rss: podcast.rssLink,
                        categories: podcast && podcast.categories ? podcast.categories.map((cat) => cat.id.toString()) : [],
                        primaryCategory: initialPrimaryCategories(podcast),
                        regions: podcast.regions
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.rss) {
                            errors.rss = "You must enter RSS feed";
                        }

                        if (values.categories.length === 0) {
                            errors.categories = "You must select at least one category";
                        }

                        if(values.primaryCategory === 0){
                            errors.primaryCategory = 'You have to select primary category';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            onSubmit(values);
                            setSubmitting(false);
                            setFormsubmitted(false);
                        }, 800);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <ModalContent>
                            {false ? (
                                <>
                                    <ModalHeader>
                                        <Box display='flex' justifyContent='center'>
                                            <Heading size='lg' display='inline-block'>
                                                {"createdPodcast.name"} is now added!{" "}
                                                <span role='img' aria-label='emoji'>
                                                    🥳
                                                </span>
                                            </Heading>
                                        </Box>
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Box pb={4} display='flex' justifyContent='center'>
                                            <Link to={`/podcasts/${"createdPodcast.id"}`}>
                                                <Button colorScheme='teal' size='lg' onClick={onClose}>
                                                    Go to podcast
                                                </Button>
                                            </Link>
                                        </Box>
                                    </ModalBody>
                                </>
                            ) : (
                                <>
                                    <ModalHeader>
                                        <Heading size='lg'>Edit podcast</Heading>
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Form>
                                            <Box paddingBottom={6}>
                                                <FormControl isRequired isInvalid={errors.rss && touched}>
                                                    <FormLabel htmlFor='rss'>RSS feed</FormLabel>
                                                    <Input
                                                        id='rss'
                                                        placeholder='Enter RSS feed'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.rss}
                                                    />
                                                    <FormErrorMessage>{errors.rss}</FormErrorMessage>
                                                </FormControl>
                                            </Box>

                                            <Box paddingBottom={6}>
                                                <FormControl as='fieldset' isRequired isInvalid={errors.categories && formSubmitted}>
                                                    <FormLabel as='legend' marginBottom={2}>
                                                        Select categories that matches the podcast
                                                    </FormLabel>
                                                    <CheckboxGroup
                                                        id='categories'
                                                        colorScheme='teal'
                                                        isInline
                                                        defaultValue={values.categories}
                                                        onChange={(cat) => {
                                                            const catArray = cat.map((item) => parseInt(item));
                                                            setFieldValue("categories", catArray);

                                                            const filteredOut = filterOutPremiumCategory(catArray);
                                                            let primaryIsNull = false;
                                                            if(filteredOut.filter(cat => cat === values.primaryCategory).length === 0){
                                                                setFieldValue("primaryCategory",0);
                                                                primaryIsNull = true; // had to to it like this beacuse of formik
                                                            }

                                                            if((values.primaryCategory === 0 || primaryIsNull) && filteredOut.length > 0)
                                                                setFieldValue("primaryCategory", filteredOut[0]);
                                                        }}
                                                    >
                                                        {categories.map((category) => (
                                                            <Checkbox key={category.id} value={`${category.id}`} marginRight={4}>
                                                                {category.name}
                                                            </Checkbox>
                                                        ))}
                                                    </CheckboxGroup>
                                                    <FormErrorMessage>{errors.categories}</FormErrorMessage>
                                                </FormControl>
                                            </Box>
                                            <Box paddingBottom={6}>
                                                <FormControl as='fieldset' isInvalid={errors.primaryCategory && formSubmitted}>
                                                    <FormLabel as='legend' marginBottom={2}>
                                                        Select primary category for podcast
                                                    </FormLabel>
                                                    <Field
                                                        as="select"
                                                        name="primaryCategory"
                                                        onChange={(cat) => {
                                                            setFieldValue("primaryCategory", parseInt(cat.target.value));
                                                        }}
                                                        placeholder="Select category"
                                                        value={values.primaryCategory}
                                                    >
                                                        <option defaultValue>Select category</option>
                                                        {values.categories.filter(x => x !== "222" && x !== 222).map((category) => (
                                                            <option key={category} value={`${category}`}>
                                                                {getCategoryName(category, categories)}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                </FormControl>
                                            </Box>
                                        </Form>
                                    </ModalBody>
                                    <ModalFooter display='flex' justifyContent='center'>
                                        <Box padding={4}>
                                            <Button
                                                colorScheme='green'
                                                size='lg'
                                                onClick={() => {
                                                    handleSubmit();
                                                    setFormsubmitted(true);
                                                }}
                                                isLoading={isSubmitting || isLoading}
                                            >
                                                Update podcast
                                            </Button>
                                        </Box>
                                    </ModalFooter>
                                </>
                            )}
                        </ModalContent>
                    )}
                </Formik>
            </Modal>
        </>
    );
}
