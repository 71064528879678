import React, { useEffect, useState, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { Box, Grid, Heading, Text } from "@chakra-ui/react";

import SearchField from "../../components/SearchField/SearchField";
import UploadEpisode from "../components/UploadEpisode";
import EpisodeItem from "./EpisodeItem";

import { Podcast } from "../../redux/types";
import { RemoveEpisode, UploadEpisode as UploadEpisodeType, ResetForm } from "../redux/types";
import { actions } from "../redux";

interface EpisodesListProps {
    episodes: any[] | null;
    podcast: Podcast | null;
    deleteAction: (episodeId: any) => RemoveEpisode;
    uploadEpisode: (values: any) => UploadEpisodeType;
    resetForm: () => ResetForm;
    isLoading: boolean;
}

export default function EpisodesList({ episodes, podcast, deleteAction, uploadEpisode, resetForm, isLoading }: EpisodesListProps) {
    const dispatch = useDispatch();
    const [episodeLists, updateEpisodeLists] = useState<SetStateAction<any>>({
        unpublished: episodes?.filter((ep) => !ep.isPublished && !ep.isArchived),
        published: episodes?.filter((ep) => ep.isPublished && !ep.isArchived),
    });

    useEffect(() => {
        if (episodes) {
            const unpublishedEps = episodes.filter((ep) => !ep.isPublished && !ep.isArchived);
            const publishedEps = episodes.filter((ep) => ep.isPublished && !ep.isArchived);

            updateEpisodeLists({ unpublished: unpublishedEps, published: publishedEps });
        }
    }, [episodes]);

    function onSearch(value: string) {
        updateEpisodeLists({
            unpublished: episodes?.filter(
                (ep) => ep.title.toLowerCase().includes(value.toLowerCase()) && !ep.isPublished && !ep.isArchived
            ),
            published: episodes?.filter(
                (ep) => ep.title.toLowerCase().includes(value.toLowerCase()) && ep.isPublished && !ep.isArchived
            ),
        });
    }

    return (
        <Box>
            <Grid
                templateColumns={["1fr", null, "repeat(3, 1fr)"]}
                alignItems="center"
                padding={["1rem 0.5rem", null, "1rem 0"]}
                paddingBottom={8}
            >
                <Heading size="lg" pb={[2, null, 0]}>
                    Episodes
                </Heading>
                <Box>
                    <SearchField label="Search episode" handleChange={onSearch} />
                </Box>
                {podcast && podcast.isPremium && !podcast.isManagedBySchibsted && (
                    <Box gridRow={[2, null, "unset"]} display="flex" justifyContent="flex-end" pb={[2, null, 0]}>
                        <UploadEpisode
                            onSubmit={(values: any) => uploadEpisode(values)}
                            resetForm={resetForm}
                            podcastId={podcast?.id}
                            authorId={podcast?.author.id}
                        />
                    </Box>
                )}
            </Grid>

            {isLoading ? (
                Array.from({ length: 5 }, (x, i) => <EpisodeItem key={i} episode={null} podcast={null} />)
            ) : episodes !== null && episodes.length > 0 ? (
                <>
                    {episodeLists.unpublished.length > 0 && (
                        <Box>
                            <Text mb={2}>To be published</Text>
                            {episodeLists.unpublished?.map((episode: any) => (
                                <EpisodeItem
                                    key={episode?.id}
                                    episode={episode}
                                    podcast={podcast}
                                    getEpisode={() => dispatch(actions.getEpisode(episode.id))}
                                    resetForm={() => dispatch(actions.resetForm())}
                                    handleSubmit={(values: any) => dispatch(actions.editEpisode(episode.id, podcast!.id, values))}
                                    deleteEpisode={() => deleteAction(episode.id)}
                                />
                            ))}
                        </Box>
                    )}

                    {episodeLists.published.length > 0 && (
                        <Box paddingTop={episodeLists.unpublished.length > 0 ? 8 : 0}>
                            {episodeLists.unpublished.length > 0 && <Text mb={2}>Published</Text>}
                            {episodeLists.published.map((episode: any) => (
                                <EpisodeItem
                                    key={episode?.id}
                                    episode={episode}
                                    podcast={podcast}
                                    getEpisode={() => dispatch(actions.getEpisode(episode.id))}
                                    resetForm={() => dispatch(actions.resetForm())}
                                    handleSubmit={(values: any) => dispatch(actions.editEpisode(episode.id, podcast!.id, values))}
                                    deleteEpisode={() => deleteAction(episode.id)}
                                />
                            ))}
                        </Box>
                    )}
                </>
            ) : (
                <Box display="flex" justifyContent="center" padding={4}>
                    <Text>This podcast has no episodes. Consider uploading an episode.</Text>
                </Box>
            )}
        </Box>
    );
}
