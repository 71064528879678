import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Stack,
    Stat,
    StatLabel,
    StatNumber,
} from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CopyableText from "../../components/CopyableText/CopyableText";
import { RootState } from "../../redux/reducers/rootReducer";
import TableContainer from "../components/TableContainer";
import { GenericVoucherData, GetVoucherByCode, RewardVoucherData, UniqueVoucherData, VoucherCampaignType } from "../redux/types";

const voucherCampaignSelector = (state: RootState) => state.manageVoucherCampaigns;
const globalState = (state: RootState) => state.global;

export default function ValidateVoucherPage() {
    const dispatch = useDispatch();
    const { region } = useSelector(globalState);
    const { validateVoucherData, validateVoucherIsLoading } = useSelector(voucherCampaignSelector);

    const memoRenderVoucherResult = useMemo(() => renderVoucherResult(validateVoucherData), [validateVoucherData]);

    return (
        <Formik
            initialValues={{ code: "" }}
            validate={(values) => {
                const errors: { code?: string } = {};
                if (!values.code) errors.code = "You must enter a voucher code";
                return errors;
            }}
            onSubmit={(values, { resetForm }) => {
                dispatch<GetVoucherByCode>({ type: "GET_VOUCHER_BY_CODE", code: values.code, region });
                resetForm();
            }}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <TableContainer isLoading={false} title="Check voucher code">
                    <Box padding="0 1.75rem 2rem">
                        <Form>
                            <FormControl isRequired isInvalid={Boolean(errors.code)}>
                                <FormLabel htmlFor="code">Voucher code:</FormLabel>
                                <Flex gridGap={4} maxWidth="500px">
                                    <Input
                                        name="code"
                                        value={values.code}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errorBorderColor="red.300"
                                        placeholder="Enter voucher code"
                                    />
                                    <Button
                                        onClick={() => handleSubmit()}
                                        type="submit"
                                        colorScheme="teal"
                                        variant="outline"
                                        isLoading={validateVoucherIsLoading}
                                    >
                                        Check
                                    </Button>
                                </Flex>
                                <FormErrorMessage>{errors.code}</FormErrorMessage>
                            </FormControl>
                        </Form>
                        {memoRenderVoucherResult}
                    </Box>
                </TableContainer>
            )}
        </Formik>
    );
}

function renderVoucherResult(voucherData: UniqueVoucherData | GenericVoucherData | RewardVoucherData | null) {
    if (!voucherData) return;

    if (voucherData.voucherType === VoucherCampaignType.Unique) {
        const uniqueVoucherData = voucherData as UniqueVoucherData;
        return (
            <Box maxWidth="50%" paddingTop={8}>
                <Stack gridGap={4}>
                    <Stat>
                        <StatLabel>Code</StatLabel>
                        <StatNumber fontSize="16px">{uniqueVoucherData.voucherCode}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Type</StatLabel>
                        <StatNumber fontSize="16px">{VoucherCampaignType[uniqueVoucherData.voucherType]}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Campaign Name</StatLabel>
                        <StatNumber fontSize="16px">{uniqueVoucherData.voucherCampaignName}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Started</StatLabel>
                        <StatNumber fontSize="16px">{format(parseISO(uniqueVoucherData.validFrom), "yyyy-MM-dd HH:mm")}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>End date</StatLabel>
                        <StatNumber fontSize="16px">{format(parseISO(uniqueVoucherData.validUntil), "yyyy-MM-dd HH:mm")}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Voucher discount plan</StatLabel>
                        <StatNumber fontSize="16px">{uniqueVoucherData.discountTypeName}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Has been redeemed?</StatLabel>
                        <StatNumber fontSize="16px">{uniqueVoucherData.hasBeenRedeemed ? "Yes" : "No"}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Churn eligibility</StatLabel>
                        <StatNumber fontSize="16px">
                            {uniqueVoucherData.churnEligibilityInDays ? `${uniqueVoucherData.churnEligibilityInDays} days` : "N/A"}
                        </StatNumber>
                    </Stat>

                    {uniqueVoucherData.hasBeenRedeemed && (
                        <>
                            <Stat>
                                <StatLabel>Redeemed at</StatLabel>
                                <StatNumber fontSize="16px">
                                    {format(parseISO(String(uniqueVoucherData.redeemedAt)), "yyyy-MM-dd HH:mm")}
                                </StatNumber>
                            </Stat>
                            <Stat>
                                <StatLabel>Redeemed by (user id)</StatLabel>
                                <StatNumber fontSize="16px">
                                    <CopyableText text={uniqueVoucherData.redeemedByUserId} />
                                </StatNumber>
                            </Stat>
                        </>
                    )}
                </Stack>
            </Box>
        );
    } else if (voucherData.voucherType === VoucherCampaignType.Reward) {
        const rewardVoucherData = voucherData as RewardVoucherData;
        return (
            <Box maxWidth="50%" paddingTop={8}>
                <Stack gridGap={4}>
                    <Stat>
                        <StatLabel>Code</StatLabel>
                        <StatNumber fontSize="16px">{rewardVoucherData.voucherCode}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Type</StatLabel>
                        <StatNumber fontSize="16px">{VoucherCampaignType[rewardVoucherData.voucherType]}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Campaign Name</StatLabel>
                        <StatNumber fontSize="16px">{rewardVoucherData.voucherCampaignName}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Started</StatLabel>
                        <StatNumber fontSize="16px">{format(parseISO(rewardVoucherData.validFrom), "yyyy-MM-dd HH:mm")}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>End date</StatLabel>
                        <StatNumber fontSize="16px">{format(parseISO(rewardVoucherData.validUntil), "yyyy-MM-dd HH:mm")}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Month credit</StatLabel>
                        <StatNumber fontSize="16px">{rewardVoucherData.monthCredit}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Has been redeemed?</StatLabel>
                        <StatNumber fontSize="16px">{rewardVoucherData.hasBeenRedeemed ? "Yes" : "No"}</StatNumber>
                    </Stat>

                    {rewardVoucherData.hasBeenRedeemed && (
                        <>
                            <Stat>
                                <StatLabel>Redeemed at</StatLabel>
                                <StatNumber fontSize="16px">
                                    {format(parseISO(String(rewardVoucherData.redeemedAt)), "yyyy-MM-dd HH:mm")}
                                </StatNumber>
                            </Stat>
                            <Stat>
                                <StatLabel>Redeemed by (user id)</StatLabel>
                                <StatNumber fontSize="16px">
                                    <CopyableText text={rewardVoucherData.redeemedByUserId} />
                                </StatNumber>
                            </Stat>
                        </>
                    )}
                </Stack>
            </Box>
        );
    } else if (voucherData.voucherType === VoucherCampaignType.Generic) {
        const genericVoucherData = voucherData as GenericVoucherData;
        return (
            <Box maxWidth="50%" paddingTop={8}>
                <Stack gridGap={4}>
                    <Stat>
                        <StatLabel>Code</StatLabel>
                        <StatNumber fontSize="16px">{genericVoucherData.voucherCode}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Type</StatLabel>
                        <StatNumber fontSize="16px">{VoucherCampaignType[genericVoucherData.voucherType]}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Campaign Name</StatLabel>
                        <StatNumber fontSize="16px">{genericVoucherData.voucherCampaignName}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Created</StatLabel>
                        <StatNumber fontSize="16px">{format(parseISO(genericVoucherData.validFrom), "yyyy-MM-dd HH:mm")}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Expires</StatLabel>
                        <StatNumber fontSize="16px">{format(parseISO(genericVoucherData.validUntil), "yyyy-MM-dd HH:mm")}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Voucher discount plan</StatLabel>
                        <StatNumber fontSize="16px">{genericVoucherData.discountTypeName}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Redemption count</StatLabel>
                        <StatNumber fontSize="16px">{genericVoucherData.redemptionCount}</StatNumber>
                    </Stat>
                    <Stat>
                        <StatLabel>Churn eligibility</StatLabel>
                        <StatNumber fontSize="16px">
                            {genericVoucherData.churnEligibilityInDays ? `${genericVoucherData.churnEligibilityInDays} days` : "N/A"}
                        </StatNumber>
                    </Stat>
                </Stack>
            </Box>
        );
    }
}
