import { ShowErrorToastAction, ShowSuccessToastAction } from "../../redux/types";

export const GET_GROUPS = "AccessAndPermissions/GET_GROUPS";
export const GET_GROUPS_SUCCESS = "AccessAndPermissions/GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAILED = "AccessAndPermissions/GET_GROUPS_FAILED";

export const UPDATE_USER_GROUP = "AccessAndPermissions/UPDATE_USER_GROUP";
export const UPDATE_USER_GROUP_SUCCESS = "AccessAndPermissions/UPDATE_USER_GROUP_SUCCESS";
export const UPDATE_USER_GROUP_FAILED = "AccessAndPermissions/UPDATE_USER_GROUP_FAILED";

export const UPDATE_USER_PODCASTER_GROUP = "AccessAndPermissions/UPDATE_USER_PODCASTER_GROUP";
export const UPDATE_USER_PODCASTER_GROUP_SUCCESS = "AccessAndPermissions/UPDATE_USER_PODCASTER_GROUP_SUCCESS";
export const UPDATE_USER_PODCASTER_GROUP_FAILED = "AccessAndPermissions/UPDATE_USER_PODCASTER_GROUP_FAILED";

export const GET_USER_GROUPS = "AccessAndPermissions/GET_USER_GROUPS";
export const GET_USER_GROUPS_SUCCESS = "AccessAndPermissions/GET_USER_GROUPS_SUCCESS";
export const GET_USER_GROUPS_FAILED = "AccessAndPermissions/GET_USER_GROUPS_FAILED";

export const DELETE_USER_GROUP = "AccessAndPermissions/DELETE_USER_GROUP";
export const DELETE_USER_GROUP_SUCCESS = "AccessAndPermissions/DELETE_USER_GROUP_SUCCESS";
export const DELETE_USER_GROUP_FAILED = "AccessAndPermissions/DELETE_USER_GROUP_FAILED";

export const DELETE_USER_FROM_PODCASTER_GROUP = "AccessAndPermissions/DELETE_USER_FROM_PODCASTER_GROUP";
export const DELETE_USER_FROM_PODCASTER_GROUP_SUCCESS = "AccessAndPermissions/DELETE_USER_FROM_PODCASTER_GROUP_SUCCESS";
export const DELETE_USER_FROM_PODCASTER_GROUP_FAILED = "AccessAndPermissions/DELETE_USER_FROM_PODCASTER_GROUP_FAILED";

export const GET_USER_PODCAST_PERMISSIONS = "AccessAndPermissions/GET_USER_PODCAST_PERMISSIONS";
export const GET_USER_PODCAST_PERMISSIONS_SUCCESS = "AccessAndPermissions/GET_USER_PODCAST_PERMISSIONS_SUCCESS";
export const GET_USER_PODCAST_PERMISSIONS_FAILED = "AccessAndPermissions/GET_USER_PODCAST_PERMISSIONS_FAILED";

export interface Groups {
    names: string[];
}

export interface GetGroups {
    type: typeof GET_GROUPS;
}

export interface GetGroupsSuccess {
    type: typeof GET_GROUPS_SUCCESS;
    groups: {
        names: string[];
    };
}

export interface GetGroupsFailed {
    type: typeof GET_GROUPS_FAILED;
}

export interface UserGroups {
    userGroups: UserGroup[];
}

export interface UserGroup {
    userId: string;
    email: string;
    name: string;
    validUntil: Date;
}

export interface GetUserGroups {
    type: typeof GET_USER_GROUPS;
    pageSize: number;
    currentPage: number;
}

export interface GetUserGroupsSuccess {
    type: typeof GET_USER_GROUPS_SUCCESS;
    userGroups: {
        userGroups: UserGroup[];
    };
}

export interface GetUserGroupsFailed {
    type: typeof GET_USER_GROUPS_FAILED;
}

export interface UpdateUserGroupFormValues {
    email: string;
    userGroup: string;
    validUntil: Date;
    userPodcastPermissions: PodcastPermissionForUser[];
}

export interface UpdateUserGroup {
    type: typeof UPDATE_USER_GROUP;
    values: {
        email: string;
        userGroup: string;
        validUntil: Date;
        userPodcastPermissions: PodcastPermissionForUser[];
    };
}

export interface UpdateUserGroupSuccess extends ShowSuccessToastAction {
    type: typeof UPDATE_USER_GROUP_SUCCESS;
    values: {
        email: string;
        userGroup: string;
        validUntil: Date;
    };
}

export interface UpdateUserGroupFailed extends ShowErrorToastAction {
    type: typeof UPDATE_USER_GROUP_FAILED;
}

export interface UpdateUserPodcasterGroup {
    type: typeof UPDATE_USER_PODCASTER_GROUP;
    values: {
        email: string;
        validUntil: Date;
        userPodcastPermissions: PodcastPermissionForUser[];
    };
}

export interface UpdateUserPodcasterGroupSuccess extends ShowSuccessToastAction {
    type: typeof UPDATE_USER_PODCASTER_GROUP_SUCCESS;
    values: {
        email: string;
        validUntil: Date;
    };
}

export interface UpdateUserPodcasterGroupFailed extends ShowErrorToastAction {
    type: typeof UPDATE_USER_PODCASTER_GROUP_FAILED;
}

export interface DeleteUserGroup {
    type: typeof DELETE_USER_GROUP;
    userId: string;
}

export interface DeleteUserGroupSuccess extends ShowSuccessToastAction {
    type: typeof DELETE_USER_GROUP_SUCCESS;
    userId: string;
}

export interface DeleteUserGroupFailed extends ShowErrorToastAction {
    type: typeof DELETE_USER_GROUP_FAILED;
}

export interface DeleteUserFromPodcasterGroup {
    type: typeof DELETE_USER_FROM_PODCASTER_GROUP;
    userId: string;
}

export interface DeleteUserFromPodcasterGroupSuccess extends ShowSuccessToastAction {
    type: typeof DELETE_USER_FROM_PODCASTER_GROUP_SUCCESS;
    userId: string;
}

export interface DeleteUserFromPodcasterGroupFailed extends ShowErrorToastAction {
    type: typeof DELETE_USER_FROM_PODCASTER_GROUP_FAILED;
}

export interface PodcastPermissionForUser {
    email: string;
    podcastId: number;
    manageEpisodes: boolean;
    readStatistics: boolean;
}

export interface GetUserPodcastPermissions {
    type: typeof GET_USER_PODCAST_PERMISSIONS;
    email: string;
}

export interface GetUserPodcastPermissionsSuccess {
    type: typeof GET_USER_PODCAST_PERMISSIONS_SUCCESS;
    userPodcastPermissions: {
        userPodcastPermissions: PodcastPermissionForUser[];
    };
}

export interface GetUserPodcastPermissionsFailed extends ShowErrorToastAction {
    type: typeof GET_USER_PODCAST_PERMISSIONS_FAILED;
    error: any;
}

export interface ManageAccessAndPermissionsState {
    groups: string[] | null;
    userGroups: UserGroup[];
    userPodcastPermissions: PodcastPermissionForUser[];
    isLoading: boolean;
    hasMoreUserGroups: boolean;
    userGroupUpdated: boolean;
    arePodcastsLoading: boolean;
}

export type ManageAccessAndPermissionsActionTypes =
    | GetGroups
    | GetGroupsSuccess
    | GetGroupsFailed
    | GetUserGroups
    | GetUserGroupsSuccess
    | GetUserGroupsFailed
    | UpdateUserGroup
    | UpdateUserGroupSuccess
    | UpdateUserGroupFailed
    | UpdateUserPodcasterGroup
    | UpdateUserPodcasterGroupSuccess
    | UpdateUserPodcasterGroupFailed
    | DeleteUserGroup
    | DeleteUserGroupSuccess
    | DeleteUserGroupFailed
    | DeleteUserFromPodcasterGroup
    | DeleteUserFromPodcasterGroupSuccess
    | DeleteUserFromPodcasterGroupFailed
    | GetUserPodcastPermissions
    | GetUserPodcastPermissionsSuccess
    | GetUserPodcastPermissionsFailed;
