import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useCookies } from "react-cookie";

import { CheckAuthStatus } from "./redux/authActions";
import { useSession } from "./hooks";
import { actions } from "./ManageUsers/redux";
import { UserRole } from "./ManageUsers/redux/types";

import PrivateRoute from "./components/PrivateRoute";
import Welcome from "./components/welcome/Welcome";
import ManageUsers from "./ManageUsers/ManageUsers";
import ManagePodcasts from "./ManagePodcasts/ManagePodcasts";
import Statistics from "./Statistics/Statistics";
import ManageVoucherCampaigns from "./ManageVoucherCampaigns/ManageVoucherCampaigns";
import CmsPreview from "./CmsPreview/CmsPreview";
import BrazePlayground from "./BrazePlayground/BrazePlayground";
import { RootState } from "./redux/reducers/rootReducer";
import { Region } from "./redux/types";
import OpensearchPreview from "./OpensearchPreview/OpensearchPreview";
import SchibstedLogin from "./components/SchibstedLogin";
import { Store } from "redux";
import AccessAndPermissions from "./AccessAndPermissions/AccessAndPermissions";
import UserDetails from "./pages/UserDetails";
import EncodingQueuePage from "./EncodingQueue/EncodingQueuePage";
const globalState = (state: RootState) => state.global;
const authState = (state: RootState) => state.auth;
const manageUsersState = (state: RootState) => state.manageUsers;
const toastMessageState = (state: RootState) => state.toastMessage;

interface Props {
    store: Store;
}

export default function Root({ store }: Props) {
    const dispatch = useDispatch();
    const { region } = useSelector(globalState);
    const { userLoggedIn, authChecked } = useSelector(authState);
    const { username, userRole, userRoleChecked } = useSelector(manageUsersState);
    const { showErrorToast, showSuccessToast, message } = useSelector(toastMessageState);
    const [, setCookie] = useCookies(["selectedRegion"]);
    const toast = useToast();
    useSession();

    useEffect(() => {
        !username && dispatch(actions.getUsername());
        dispatch(actions.getUserRole());
    }, []);

    useEffect(() => {
        dispatch(CheckAuthStatus());
    }, [userLoggedIn]);

    useEffect(() => {
        const selectedRegion: Region = region;
        setCookie("selectedRegion", selectedRegion ? selectedRegion : "1", { path: "/", sameSite: true });
    }, [region]);

    useEffect(() => {
        if (showErrorToast) {
            toast({
                title: "An error occurred.",
                description: message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
                variant: "left-accent",
            });
        }
    }, [showErrorToast]);

    useEffect(() => {
        if (showSuccessToast) {
            toast({
                title: "Success!",
                description: message,
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top",
                variant: "left-accent",
            });
        }
    }, [showSuccessToast]);

    return (
        <>
            <Routes
                userRoleChecked={userRoleChecked}
                userRole={userRole}
                userLoggedIn={userLoggedIn}
                authChecked={authChecked}
                store={store}
            />
        </>
    );
}

interface RoutesProps {
    userRoleChecked: boolean;
    userRole: UserRole | undefined;
    userLoggedIn: boolean;
    authChecked: boolean;
    store: Store;
}

function Routes({ userRoleChecked, userRole, userLoggedIn, authChecked, store }: RoutesProps): any {
    if (!userRoleChecked) return null;

    return (
        <Switch>
            <Route exact path="/auth/handleSchibstedLogin" render={() => <SchibstedLogin />} />
            {(userRole === UserRole.Admin || userRole === UserRole.Editor || userRole === UserRole.Support) && (
                <PrivateRoute
                    exact
                    path="/users"
                    component={ManageUsers}
                    isAuthenticated={userLoggedIn}
                    authChecked={authChecked}
                    isAdmin={userRole === UserRole.Admin || userRole === UserRole.Editor}
                />
            )}
            {(userRole === UserRole.Admin || userRole === UserRole.Editor || userRole === UserRole.Support) && (
                <PrivateRoute
                    exact
                    path="/users/:userId?"
                    component={UserDetails}
                    isAuthenticated={userLoggedIn}
                    authChecked={authChecked}
                    isAdmin={userRole === UserRole.Admin || userRole === UserRole.Editor}
                />
            )}
            {(userRole === UserRole.Admin || userRole === UserRole.Editor || userRole === UserRole.Podcaster) && (
                <PrivateRoute
                    path="/podcasts/:id?"
                    component={ManagePodcasts}
                    isAuthenticated={userLoggedIn}
                    authChecked={authChecked}
                    isAdmin={userRole === UserRole.Admin || userRole === UserRole.Editor}
                />
            )}
            {(userRole === UserRole.Admin || userRole === UserRole.Editor || userRole === UserRole.Support) && (
                <PrivateRoute
                    path="/encoding-queue"
                    component={EncodingQueuePage}
                    isAuthenticated={userLoggedIn}
                    authChecked={authChecked}
                    isAdmin={userRole === UserRole.Admin || userRole === UserRole.Editor}
                />
            )}
            {(userRole === UserRole.Admin || userRole === UserRole.Editor) && (
                <PrivateRoute
                    path="/statistics"
                    component={Statistics}
                    isAuthenticated={userLoggedIn}
                    authChecked={authChecked}
                    isAdmin={userRole === UserRole.Admin || userRole === UserRole.Editor}
                />
            )}
            {(userRole === UserRole.Admin || userRole === UserRole.Editor || userRole === UserRole.Support) && (
                <PrivateRoute
                    path="/voucher-campaigns"
                    component={ManageVoucherCampaigns}
                    isAuthenticated={userLoggedIn}
                    authChecked={authChecked}
                    isAdmin={userRole === UserRole.Admin || userRole === UserRole.Editor}
                />
            )}
            {(userRole === UserRole.Admin || userRole === UserRole.Editor) && (
                <PrivateRoute
                    path="/cms-preview"
                    component={CmsPreview}
                    isAuthenticated={userLoggedIn}
                    authChecked={authChecked}
                    isAdmin={userRole === UserRole.Admin || userRole === UserRole.Editor}
                />
            )}
            {(userRole === UserRole.Admin || userRole === UserRole.Editor) && (
                <PrivateRoute
                    path="/access-and-permissions"
                    component={AccessAndPermissions}
                    isAuthenticated={userLoggedIn}
                    authChecked={authChecked}
                    isAdmin={userRole === UserRole.Admin || userRole === UserRole.Editor}
                />
            )}
            {(userRole === UserRole.Admin || userRole === UserRole.Editor || userRole === UserRole.Support) && (
                <PrivateRoute
                    path="/braze"
                    component={BrazePlayground}
                    isAuthenticated={userLoggedIn}
                    authChecked={authChecked}
                    isAdmin={userRole === UserRole.Admin || userRole === UserRole.Editor}
                />
            )}
            {(userRole === UserRole.Admin || userRole === UserRole.Editor) && (
                <PrivateRoute
                    path="/opensearch-preview"
                    component={OpensearchPreview}
                    isAuthenticated={userLoggedIn}
                    authChecked={authChecked}
                    isAdmin={userRole === UserRole.Admin || userRole === UserRole.Editor}
                />
            )}
            <Route exact path="/login" render={() => <Welcome isAuthenticated={userLoggedIn} role={userRole} reduxStore={store} />} />

            <Redirect from="/" to="/login" />
        </Switch>
    );
}
