import { Box, Button } from "@chakra-ui/react";
import { actions } from "../redux";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { UserRole } from "../../ManageUsers/redux/types";
import { isUserAuthorizedForAction } from "../../utils";

interface Props {
    userId: string;
    userGroup: string;
}

export default function RemoveUserGroupButton({ userId, userGroup }: Props) {
    const dispatch = useDispatch();
    const manageUsersState = (state: RootState) => state.manageUsers;
    const { userRole } = useSelector(manageUsersState);
    const isUserAuthorized = isUserAuthorizedForAction(userRole, userGroup);

    const deleteUserGroup = () => {
        if (!window.confirm("Are you sure you want to delete this user group?")) return;
        if (userRole === UserRole.Admin) dispatch(actions.deleteUserGroup(userId));
        else dispatch(actions.deleteUserFromPodcasterGroup(userId));
    };

    return (
        <Box padding={4}>
            <Button disabled={!isUserAuthorized} colorScheme="red" size="md" onClick={() => deleteUserGroup()}>
                Remove
            </Button>
        </Box>
    );
}
