import { createStore, applyMiddleware, compose, Middleware } from "redux";
import thunkMiddleware from "redux-thunk";
import createSagaMiddleware from "redux-saga";

import createRootReducer, { RootState } from "./reducers/rootReducer";
import { rootSaga } from "./rootSaga";
import { adminApi } from "../services/adminApi/adminApi";

const sagaMiddleware = createSagaMiddleware();

function configureStoreProd(history: any, initialState?: RootState) {
    const store = createStore(
        createRootReducer(history),
        initialState,
        compose(applyMiddleware(thunkMiddleware, sagaMiddleware, adminApi.middleware as Middleware))
    );

    sagaMiddleware.run(rootSaga);

    return store;
}

function configureStoreDev(history: any, initialState?: RootState) {
    const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

    const store = createStore(
        createRootReducer(history),
        initialState,
        composeEnhancers(applyMiddleware(thunkMiddleware, sagaMiddleware, adminApi.middleware as Middleware))
    );

    sagaMiddleware.run(rootSaga);

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept("./reducers/rootReducer", () => {
            const nextRootReducer = require("./reducers/rootReducer");
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}

const configureStore = process.env.NODE_ENV === "production" ? configureStoreProd : configureStoreDev;

export default configureStore;
