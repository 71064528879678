import React, {useEffect, useState} from "react";
import {useLocation} from 'react-router';
import {useSelector, useDispatch} from "react-redux";
import {GET_SCHIBSTED_TOKEN, GET_USER_ROLE, GET_USERNAME} from "../ManageUsers/redux/types";
import podMeLogo from "../assets/images/logoWithAnimation.svg";
import {Redirect} from "react-router-dom";
import { toast } from 'react-toastify';

export default function SchibstedLogin() {
    const { search } = useLocation();
    const { accountRegion } = useSelector((state) => state.global);
    const { userLoggedInWithSchibsted } = useSelector((state) => state.auth);
    const { userRole } = useSelector((state) => state.manageUsers);
    const [ internalStateUserLoggedIn, setInternalState ] = useState(false);
    const dispatch = useDispatch();

    useEffect( () => {

        const queryParams = new URLSearchParams(search);

        const code  = queryParams.get('code');
        const state = queryParams.get('state');
        dispatch({type: GET_SCHIBSTED_TOKEN, code, codeVerifier: state, region: accountRegion })

    }, []);

    useEffect(() => {
        setInternalState(userLoggedInWithSchibsted);

        setTimeout(() => {
            dispatch({type: GET_USER_ROLE});
            dispatch({type: GET_USERNAME});
        }, 2000);

    }, [userLoggedInWithSchibsted])

    if (userLoggedInWithSchibsted && userRole && internalStateUserLoggedIn) {
        toast.success("Successfully logged in.", { position: "top-right" });
        return <Redirect to='/users'/>;
    }
    if(!userLoggedInWithSchibsted && internalStateUserLoggedIn){
        toast.error("This account don't have permission to access this page.");
        return <Redirect to='/login'/>;
    }

    return (
        <div style={{display:'flex', alignItems:'center', justifyContent:"center", height:'100%'}}>
            <div style={{height:'20em',alignItems:'center', justifyContent:"center",}}>
                <span style={{color:'white', flex:'0 0 100%',fontSize:'2em'}}>Wait for redirect</span>

                <img src={podMeLogo}
                       alt='podme'
                       width='250px'
                        style={{height:'8em', padding:'1em 0'}}
                       maxWidth='100%'
                       zIndex={1001}/>
            </div>

        </div>
    );

}