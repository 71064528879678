import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Box, Text, Image, Grid, Skeleton } from "@chakra-ui/react";

import { colors } from "../../constants";
import { useMedia } from "../../hooks";

import premiumBadge from "../../assets/images/premium-badge.png";
import { Podcast } from "../../redux/types";

interface PodcastTableProps {
    data: Podcast[];
}

export default function PodcastTable({ data }: PodcastTableProps) {
    if (!data || (data && data.length === 0)) {
        return Array.from({ length: 5 }, (x, i) => <PodcastListItem key={i} />);
    }

    return data && data.map((podcast: Podcast, i: number) => <PodcastListItem key={podcast.createdAt?.toString()} podcast={podcast} />);
}

interface PodcastListItemProps {
    podcast?: Podcast;
}

function PodcastListItem({ podcast }: PodcastListItemProps) {
    const { isLargeScreen } = useMedia();
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <Box backgroundColor='white' height='77px' width='100%' borderBottom={`1px solid ${colors.lightGray}`} padding={2}>
            <Link to={podcast ? `/podcasts/${podcast.id}` : ""}>
                <Grid templateColumns={["80% 20%", null, null, "50% 25% 25%"]}>
                    <Box display='flex' alignItems='center'>
                        <Box boxSize='60px' position='relative' marginRight={4}>
                            <Skeleton boxSize='60px' isLoaded={imageLoaded}>
                                {podcast && podcast.isPremium && (
                                    <Image
                                        src={premiumBadge}
                                        position='absolute'
                                        top='-3px'
                                        right='5px'
                                        boxSize='20px'
                                        objectFit='contain'
                                    />
                                )}

                                <Image
                                    onLoad={() => setImageLoaded(true)}
                                    src={podcast ? podcast.smallImageUrl : ""}
                                    alt={podcast ? podcast.title : ""}
                                    mr={4}
                                />
                            </Skeleton>
                        </Box>

                        <Skeleton isLoaded={podcast && !!podcast.title} height='20px' width='300px' display='flex' alignItems='center'>
                            <Text color='black'>{podcast ? podcast.title : ""}</Text>
                        </Skeleton>
                    </Box>
                    <Box display='flex' alignItems='center' justifyContent={["flex-end", "flex-start"]}>
                        <Text color='gray.400'>{podcast && podcast.isPremium ? "Premium" : "RSS"}</Text>
                    </Box>
                    {isLargeScreen && (
                        <Box display='flex' alignItems='center' justifyContent='center' justifySelf='start'>
                            {podcast && podcast.isArchived ? (
                                <Text color='red.400'>Archived</Text>
                            ) : (
                                <Text color='gray.400'>{podcast && podcast.isPublished ? "Published" : "Not published"}</Text>
                            )}
                        </Box>
                    )}
                </Grid>
            </Link>
        </Box>
    );
}

PodcastTable.propTypes = {
    data: PropTypes.array.isRequired,
};
