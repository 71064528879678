import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@chakra-ui/react";
import VoucherActivationsGraph from "../components/VoucherActivationsGraph";
import ActiveVoucherCampaignsGraph from "../components/ActiveVoucherCampaignsGraph";

import { actions } from "../redux";
import { RootState } from "../../redux/reducers/rootReducer";

const globalState = (state: RootState) => state.global;
const manageVoucherCampaignsState = (state: RootState) => state.manageVoucherCampaigns;

export default function OverviewPage() {
    const dispatch = useDispatch();
    const { region } = useSelector(globalState);
    const { statistics } = useSelector(manageVoucherCampaignsState);

    useEffect(() => {
        dispatch(actions.getOverviewContent(region));
    }, [region]);

    return (
        <Box>
            <Box pb={8}>
                <ActiveVoucherCampaignsGraph data={statistics?.activeVoucherCampaigns} isLoading={false} />
            </Box>
            <Box>
                <VoucherActivationsGraph data={statistics?.topVoucherCampaigns} isLoading={false} />
            </Box>
        </Box>
    );
}
