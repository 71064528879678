import React from "react";
import { Switch, Route } from "react-router-dom";
import PodcastView from "./PodcastView/PodcastView";
import PodcastListView from "./PodcastListView/PodcastListView";
import MigrateView from "./MigrateView/MigrateView";

export default function ManagePodcasts() {
    return (
        <>
            <Switch>
                <Route
                    path="/podcasts/:id"
                    render={({ match }) => (
                        <Switch>
                            <Route
                                exact
                                path={`/podcasts/${match.params.id}/migrate`}
                                render={() => <MigrateView podcastId={match.params.id} />}
                            />
                            <Route exact component={PodcastView} />
                        </Switch>
                    )}
                />
                <Route exact component={PodcastListView} />
            </Switch>
        </>
    );
}
