import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Box,
    Heading,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { RootState } from "../../../redux/reducers/rootReducer";

interface Props {
    onSubmit: (voucherCode: string) => void;
}

export default function RedeemFreeVoucherCode({ onSubmit }: Props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [, setFormsubmitted] = useState(false);
    const manageUserState = (state: RootState) => state.manageUsers;
    const { isRedeemingVoucher } = useSelector(manageUserState);

    useEffect(() => {
        if (!isOpen) {
            setFormsubmitted(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (!isRedeemingVoucher) {
            onClose();
        }
    }, [isRedeemingVoucher]);

    return (
        <>
            <Button colorScheme="teal" size="xs" fontSize="0.75rem" onClick={onOpen}>
                Redeem Free Voucher Code
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
                <ModalOverlay backgroundColor="rgba(0,0,0,0.7)" />
                <Formik
                    initialValues={{
                        voucherCode: "",
                    }}
                    validate={(values) => {
                        const errors: {
                            voucherCode?: string;
                        } = {};

                        if (!values.voucherCode) {
                            errors.voucherCode = "You must enter a voucher code";
                        }

                        return errors;
                    }}
                    onSubmit={(values) => {
                        onSubmit(values.voucherCode);
                        setFormsubmitted(false);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <ModalContent>
                            <>
                                <ModalHeader>
                                    <Heading size="lg">Grant Podme Premium Access</Heading>
                                </ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Form>
                                        <Box paddingBottom={6}>
                                            <FormControl isRequired isInvalid={!!errors.voucherCode && touched.voucherCode}>
                                                <FormLabel htmlFor="voucherCode">Voucher code</FormLabel>
                                                <Input
                                                    id="voucherCode"
                                                    type="text"
                                                    placeholder="Voucher code"
                                                    onChange={(evt) => {
                                                        evt.target.value = evt.target.value?.replace(/[^\w0-9]+/g, "").toUpperCase();
                                                        handleChange(evt);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.voucherCode}
                                                />
                                                <FormErrorMessage>{errors.voucherCode}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                    </Form>
                                </ModalBody>
                                <ModalFooter display="flex" justifyContent="center">
                                    <Box padding={4}>
                                        <Button
                                            colorScheme="green"
                                            size="lg"
                                            onClick={() => {
                                                handleSubmit();
                                                setFormsubmitted(true);
                                            }}
                                            isLoading={isSubmitting}
                                        >
                                            Redeem
                                        </Button>
                                    </Box>
                                </ModalFooter>
                            </>
                        </ModalContent>
                    )}
                </Formik>
            </Modal>
        </>
    );
}
