import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@chakra-ui/react";

import { colors } from "../../constants";

function GraphContainer({ children, isLoading }) {
    return (
        <Box position='relative' backgroundColor='white' borderRadius='15px' padding='1rem' boxShadow='0 1px 2px #ccc'>
            <div>{children}</div>
            {isLoading && <Loading />}
        </Box>
    );
}

const style = {
    spinnerContainer: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 15px;
    `,
};

function Loading() {
    return (
        <style.spinnerContainer>
            <CircularProgress style={{ color: colors.aquablue }} value={50} />
        </style.spinnerContainer>
    );
}

export default GraphContainer;
