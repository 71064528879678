import { call, put, takeLatest } from "redux-saga/effects";
import { createAxiosRequest, createPatchAxiosRequest } from "../../utils";
import {
    DELETE_USER_FROM_PODCASTER_GROUP,
    DELETE_USER_FROM_PODCASTER_GROUP_FAILED,
    DELETE_USER_FROM_PODCASTER_GROUP_SUCCESS,
    DELETE_USER_GROUP,
    DELETE_USER_GROUP_FAILED,
    DELETE_USER_GROUP_SUCCESS,
    DeleteUserFromPodcasterGroup,
    DeleteUserFromPodcasterGroupSuccess,
    DeleteUserGroup,
    DeleteUserGroupSuccess,
    GET_GROUPS,
    GET_GROUPS_FAILED,
    GET_GROUPS_SUCCESS,
    GET_USER_GROUPS,
    GET_USER_GROUPS_FAILED,
    GET_USER_GROUPS_SUCCESS,
    GET_USER_PODCAST_PERMISSIONS,
    GET_USER_PODCAST_PERMISSIONS_FAILED,
    GET_USER_PODCAST_PERMISSIONS_SUCCESS,
    GetGroupsSuccess,
    GetUserGroups,
    GetUserGroupsSuccess,
    GetUserPodcastPermissions,
    GetUserPodcastPermissionsSuccess,
    UPDATE_USER_GROUP,
    UPDATE_USER_GROUP_FAILED,
    UPDATE_USER_GROUP_SUCCESS,
    UPDATE_USER_PODCASTER_GROUP,
    UPDATE_USER_PODCASTER_GROUP_FAILED,
    UPDATE_USER_PODCASTER_GROUP_SUCCESS,
    UpdateUserGroup,
    UpdateUserGroupSuccess,
    UpdateUserPodcasterGroup,
    UpdateUserPodcasterGroupSuccess,
} from "./types";
import { AxiosError } from "axios";

function* updateUserGroup({ values }: UpdateUserGroup): any {
    try {
        const body = {
            email: values.email,
            userGroup: values.userGroup,
            validUntil: values.validUntil,
            userPodcastPermissions: values.userPodcastPermissions,
        };

        yield call(createPatchAxiosRequest, `api/access-and-permissions/assign-user-to-group`, body);

        return yield put<UpdateUserGroupSuccess>({
            type: UPDATE_USER_GROUP_SUCCESS,
            values: values,
            message: "User role updated successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        return yield put({
            type: UPDATE_USER_GROUP_FAILED,
            message: e?.response?.data?.detail,
            showToastErrorMessage: true,
        });
    }
}

function* updateUserPodcasterGroup({ values }: UpdateUserPodcasterGroup): any {
    try {
        const body = {
            email: values.email,
            validUntil: values.validUntil,
            userPodcastPermissions: values.userPodcastPermissions,
        };

        yield call(createPatchAxiosRequest, `api/access-and-permissions/assign-user-to-podcaster-group`, body);

        return yield put<UpdateUserPodcasterGroupSuccess>({
            type: UPDATE_USER_PODCASTER_GROUP_SUCCESS,
            values: values,
            message: "Podcaster updated successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        return yield put({
            type: UPDATE_USER_PODCASTER_GROUP_FAILED,
            message: e?.response?.data?.detail,
            showToastErrorMessage: true,
        });
    }
}
function* getGroups(): any {
    try {
        const result = yield call(createAxiosRequest, `api/access-and-permissions/get-groups`);

        return yield put<GetGroupsSuccess>({ type: GET_GROUPS_SUCCESS, groups: result.data });
    } catch (e) {
        const error: AxiosError = e.response;
        return yield put({
            type: GET_GROUPS_FAILED,
            message: error?.message,
            status: error?.response?.status,
        });
    }
}

function* getUserGroups({ pageSize, currentPage }: GetUserGroups): any {
    try {
        const result = yield call(
            createAxiosRequest,
            `api/access-and-permissions/get-user-groups?pageSize=${pageSize}&${currentPage ? `&currentPage=${currentPage}` : 0}`
        );

        return yield put<GetUserGroupsSuccess>({ type: GET_USER_GROUPS_SUCCESS, userGroups: result.data });
    } catch (e) {
        const error: AxiosError = e.response;
        return yield put({
            type: GET_USER_GROUPS_FAILED,
            message: error?.message,
            status: error?.response?.status,
        });
    }
}

function* deleteUserGroup({ userId }: DeleteUserGroup): any {
    try {
        yield call(createAxiosRequest, `api/access-and-permissions/delete-user-from-group/${userId}`, "", "delete");

        return yield put<DeleteUserGroupSuccess>({
            type: DELETE_USER_GROUP_SUCCESS,
            userId: userId,
            message: "User role removed successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        const error: AxiosError = e.response;
        return yield put({
            type: DELETE_USER_GROUP_FAILED,
            message: error?.message,
            status: error?.response?.status,
        });
    }
}

function* deleteUserFromPodcasterGroup({ userId }: DeleteUserFromPodcasterGroup): any {
    try {
        yield call(createAxiosRequest, `api/access-and-permissions/delete-user-from-podcaster-group/${userId}`, "", "delete");

        return yield put<DeleteUserFromPodcasterGroupSuccess>({
            type: DELETE_USER_FROM_PODCASTER_GROUP_SUCCESS,
            userId: userId,
            message: "Podcaster removed successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        const error: AxiosError = e.response;
        return yield put({
            type: DELETE_USER_FROM_PODCASTER_GROUP_FAILED,
            message: error?.message,
            status: error?.response?.status,
        });
    }
}

function* getUserPodcastPermissions({ email }: GetUserPodcastPermissions): any {
    try {
        const result = yield call(createAxiosRequest, `api/access-and-permissions/get-user-podcast-permissions/${email}`);

        return yield put<GetUserPodcastPermissionsSuccess>({
            type: GET_USER_PODCAST_PERMISSIONS_SUCCESS,
            userPodcastPermissions: result.data,
        });
    } catch (e) {
        const error: AxiosError = e.response;
        return yield put({
            type: GET_USER_PODCAST_PERMISSIONS_FAILED,
            message: error?.message,
            status: error?.response?.status,
        });
    }
}

export default [
    takeLatest(UPDATE_USER_GROUP, updateUserGroup),
    takeLatest(UPDATE_USER_PODCASTER_GROUP, updateUserPodcasterGroup),
    takeLatest(GET_GROUPS, getGroups),
    takeLatest(GET_USER_GROUPS, getUserGroups),
    takeLatest(DELETE_USER_GROUP, deleteUserGroup),
    takeLatest(DELETE_USER_FROM_PODCASTER_GROUP, deleteUserFromPodcasterGroup),
    takeLatest(GET_USER_PODCAST_PERMISSIONS, getUserPodcastPermissions),
];
