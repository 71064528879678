import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { StyledComponent } from "styled-components";

interface Props {
    toggle: () => any;
    close: boolean;
}

function HamburgerButton({ toggle, close }: Props) {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (close) {
            setAnimate(false);
        } else {
            setAnimate(true);
        }
    }, [close]);

    return (
        <style.wrapper
            onClick={() => {
                setAnimate(!animate);
                toggle();
            }}
        >
            <style.hamburgerIcon className={animate ? "animate" : ""} />
        </style.wrapper>
    );
}

interface Style {
    wrapper: StyledComponent<"button", any, {}, never>;
    hamburgerIcon: StyledComponent<"div", any, {}, never>;
}

const style: Style = (function () {
    const barWidth = "30px";
    const barHeight = "3px";
    const barSpacing = "8px";
    const menuHeight = "22px";

    const wrapper = styled.button`
        background: none;
        border: none;
        outline: none;
        width: ${barWidth};
        height: ${menuHeight};
        cursor: pointer;
        display: block;
        position: relative;
        z-index: 1001;
    `;

    const hamburgerIcon = styled.div`
        position: absolute;
        top: 2px;
        left: 0;
        transform: translateY(${barSpacing});
        background: rgba(255, 255, 255, 1);
        transition: all 0ms 300ms;
        width: ${barWidth};
        height: ${barHeight};

        &:after,
        &:before {
            width: ${barWidth};
            height: ${barHeight};
            content: "";
            position: absolute;
            left: 0;
            background: rgba(255, 255, 255, 1);
            transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
        }

        &:before {
            bottom: ${barSpacing};
        }

        &:after {
            top: ${barSpacing};
        }

        &.animate {
            background: rgba(255, 255, 255, 0);
        }

        &.animate:after {
            top: 0;
            transform: rotate(45deg);
            transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
        }

        &.animate:before {
            bottom: 0;
            transform: rotate(-45deg);
            transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
        }
    `;

    return {
        wrapper,
        hamburgerIcon,
    };
})();

HamburgerButton.propTypes = {
    toggle: PropTypes.func,
    close: PropTypes.bool,
};

export default HamburgerButton;
