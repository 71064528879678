import { Text, Tbody, Tr, Td } from "@chakra-ui/react";
import { AudioProcessingJob } from "../services/adminApi/types";
import EncodingQueueTableRowsSkeleton from "./EncodingQueueTableRowsSkeleton";

const EncodingQueueTableRows = ({ isLoading, data }: { isLoading?: boolean; data?: AudioProcessingJob[] }) => {
    if (isLoading) return <EncodingQueueTableRowsSkeleton rowHeight="42px" />;

    if (!data) return null;
    if (data?.length === 0) {
        return (
            <Text fontSize="lg" mb={6} mt={6} pl={6}>
                Encoding queue is empty
            </Text>
        );
    }

    return (
        <Tbody>
            {data?.map((item) => (
                <Tr key={item.id}>
                    <Td>{item.id}</Td>
                    <Td>{item.assetParentTitle ? `${item.assetParentTitle} - ${item.assetTitle}` : item.assetParentTitle}</Td>
                    <Td>{item.assetType}</Td>
                    <Td>{item.assetId}</Td>
                    <Td>{item.queuedAt ? new Date(item.queuedAt).toLocaleString() : ""}</Td>
                    <Td>{item.priorityWeight}</Td>
                    <Td>{Boolean(item.isFinished).toString()}</Td>
                    <Td>{Boolean(item.isProcessing).toString()}</Td>
                    <Td>{item.failuresCount}</Td>
                </Tr>
            ))}
        </Tbody>
    );
};

export default EncodingQueueTableRows;
