import { Podcast, Region, ShowErrorToastAction, ShowSuccessToastAction } from "../../redux/types";

export const GET_ALL_PODCASTS = "ManagePodcasts/GET_ALL_PODCASTS";
export const GET_ALL_PODCASTS_SUCCESS = "ManagePodcasts/GET_ALL_PODCASTS_SUCCESS";
export const GET_ALL_PODCASTS_FAILED = "ManagePodcasts/GET_ALL_PODCASTS_FAILED";

export const SEARCH_PODCAST = "ManagePodcasts/SEARCH_PODCAST";
export const SEARCH_PODCAST_SUCCESS = "ManagePodcasts/SEARCH_PODCAST_SUCCESS";
export const SEARCH_PODCAST_FAILED = "ManagePodcasts/SEARCH_PODCAST_FAILED";

export const GET_ALL_CATEGORIES = "ManagePodcasts/GET_ALL_CATEGORIES";
export const GET_ALL_CATEGORIES_SUCCESS = "ManagePodcasts/GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_FAILED = "ManagePodcasts/GET_ALL_CATEGORIES_FAILED";

export const CREATE_PREMIUM_PODCAST = "ManagePodcasts/CREATE_PREMIUM_PODCAST";
export const CREATE_PREMIUM_PODCAST_SUCCESS = "ManagePodcasts/CREATE_PREMIUM_PODCAST_SUCCESS";
export const CREATE_PREMIUM_PODCAST_FAILED = "ManagePodcasts/CREATE_PREMIUM_PODCAST_FAILED";

export const ADD_RSS_PODCAST = "ManagePodcasts/ADD_RSS_PODCAST";
export const ADD_RSS_PODCAST_SUCCESS = "ManagePodcasts/ADD_RSS_PODCAST_SUCCESS";
export const ADD_RSS_PODCAST_FAILED = "ManagePodcasts/ADD_RSS_PODCAST_FAILED";

export const RESET_PODCASTS = "ManagePodcasts/RESET_PODCASTS";
export const RESET_FORM = "ManagePodcasts/RESET_FORM";

export const GET_PODCAST = "ManagePodcasts/GET_PODCAST";
export const GET_PODCAST_SUCCESS = "ManagePodcasts/GET_PODCAST_SUCCESS";
export const GET_PODCAST_FAILED = "ManagePodcasts/GET_PODCAST_FAILED";

export const GET_EPISODES = "ManagePodcasts/GET_EPISODES";
export const GET_EPISODES_SUCCESS = "ManagePodcasts/GET_EPISODES_SUCCESS";
export const GET_EPISODES_FAILED = "ManagePodcasts/GET_EPISODES_FAILED";

export const PUBLISH_PODCAST = "ManagePodcasts/PUBLISH_PODCAST";
export const PUBLISH_PODCAST_SUCCESS = "ManagePodcasts/PUBLISH_PODCAST_SUCCESS";
export const PUBLISH_PODCAST_FAILED = "ManagePodcasts/PUBLISH_PODCAST_FAILED";

export const UPLOAD_EPISODE = "ManagePodcasts/UPLOAD_EPISODE";
export const UPLOAD_EPISODE_SUCCESS = "ManagePodcasts/UPLOAD_EPISODE_SUCCESS";
export const UPLOAD_EPISODE_FAILED = "ManagePodcasts/UPLOAD_EPISODE_FAILED";

export const REMOVE_PODCAST = "ManagePodcasts/REMOVE_PODCAST";
export const REMOVE_PODCAST_SUCCESS = "ManagePodcasts/REMOVE_PODCAST_SUCCESS";
export const REMOVE_PODCAST_FAILED = "ManagePodcasts/REMOVE_PODCAST_FAILED";

export const REMOVE_EPISODE = "ManagePodcasts/REMOVE_EPISODE";
export const REMOVE_EPISODE_SUCCESS = "ManagePodcasts/REMOVE_EPISODE_SUCCESS";
export const REMOVE_EPISODE_FAILED = "ManagePodcasts/REMOVE_EPISODE_FAILED";

export const EDIT_RSS_PODCAST = "ManagePodcasts/EDIT_RSS_PODCAST";
export const EDIT_RSS_PODCAST_SUCCESS = "ManagePodcasts/EDIT_RSS_PODCAST_SUCCESS";
export const EDIT_RSS_PODCAST_FAILED = "ManagePodcasts/EDIT_RSS_PODCAST_FAILED";

export const EDIT_PREMIUM_PODCAST = "ManagePodcasts/EDIT_PREMIUM_PODCAST";
export const EDIT_PREMIUM_PODCAST_SUCCESS = "ManagePodcasts/EDIT_PREMIUM_PODCAST_SUCCESS";
export const EDIT_PREMIUM_PODCAST_FAILED = "ManagePodcasts/EDIT_PREMIUM_PODCAST_FAILED";

export const GET_EPISODE = "ManagePodcasts/GET_EPISODE";
export const GET_EPISODE_SUCCESS = "ManagePodcasts/GET_EPISODE_SUCCESS";
export const GET_EPISODE_FAILED = "ManagePodcasts/GET_EPISODE_FAILED";

export const EDIT_EPISODE = "ManagePodcasts/EDIT_EPISODE";
export const EDIT_EPISODE_SUCCESS = "ManagePodcasts/EDIT_EPISODE_SUCCESS";
export const EDIT_EPISODE_FAILED = "ManagePodcasts/EDIT_EPISODE_FAILED";

export interface GetAllPodcasts {
    type: typeof GET_ALL_PODCASTS;
    page: number;
    podcastType: number;
    region: any;
}

export interface GetAllPodcastsSuccess {
    type: typeof GET_ALL_PODCASTS_SUCCESS;
    podcasts: any;
    podcastType: any;
    region: any;
}

export interface GetAllPodcastsFailed {
    type: typeof GET_ALL_PODCASTS_FAILED;
    error: any;
}

export interface SearchPodcast {
    type: typeof SEARCH_PODCAST;
    query: string;
}

export interface SearchPodcastSuccess {
    type: typeof SEARCH_PODCAST_SUCCESS;
    searchResult: any;
}

export interface SearchPodcastFailed {
    type: typeof SEARCH_PODCAST_FAILED;
    error: any;
}

export interface GetAllCategories {
    type: typeof GET_ALL_CATEGORIES;
}

export interface GetAllCategoriesSuccess {
    type: typeof GET_ALL_CATEGORIES_SUCCESS;
    categories: any;
}

export interface GetAllCategoriesFailed {
    type: typeof GET_ALL_PODCASTS_FAILED;
    error: any;
}

export interface GetEpisodes {
    type: typeof GET_EPISODES;
    podcastId: number | string;
    page: number;
    pageSize: number;
}

export interface GetEpisodesSuccess {
    type: typeof GET_EPISODES_SUCCESS;
    episodes: any;
}

export interface GetEpisodesFailed extends ShowErrorToastAction {
    type: typeof GET_EPISODES_FAILED;
}

export interface CreatePremiumPodcast {
    type: typeof CREATE_PREMIUM_PODCAST;
    formData: any;
}

export interface CreatePremiumPodcastSuccess {
    type: typeof CREATE_PREMIUM_PODCAST_SUCCESS;
    createdPodcast: any;
}

export interface CreatePremiumPodcastFailed extends ShowErrorToastAction {
    type: typeof CREATE_PREMIUM_PODCAST_FAILED;
}

export interface CreateRssPodcast {
    type: typeof ADD_RSS_PODCAST;
    formData: any;
}

export interface CreateRssPodcastSuccess {
    type: typeof ADD_RSS_PODCAST_SUCCESS;
    createdPodcast: any;
}

export interface CreateRssPodcastFailed extends ShowErrorToastAction {
    type: typeof ADD_RSS_PODCAST_FAILED;
}

export interface ResetPodcasts {
    type: typeof RESET_PODCASTS;
}

export interface ResetForm {
    type: typeof RESET_FORM;
}

export interface Getpodcast {
    type: typeof GET_PODCAST;
    id: number | string;
}

export interface GetpodcastSuccess {
    type: typeof GET_PODCAST_SUCCESS;
    podcast: any;
}

export interface GetpodcastFailed {
    type: typeof GET_PODCAST_FAILED;
    error: any;
}

export interface RemovePodcast {
    type: typeof REMOVE_PODCAST;
    id: number | string;
}

export interface RemovePodcastSuccess extends ShowSuccessToastAction {
    type: typeof REMOVE_PODCAST_SUCCESS;
}

export interface RemovePodcastFailed extends ShowErrorToastAction {
    type: typeof REMOVE_PODCAST_FAILED;
}

export interface RemoveEpisode {
    type: typeof REMOVE_EPISODE;
    id: string | number;
}

export interface RemoveEpisodeSuccess extends ShowSuccessToastAction {
    type: typeof REMOVE_EPISODE_SUCCESS;
}

export interface RemoveEpisodeFailed {
    type: typeof REMOVE_EPISODE_FAILED;
}

export interface PublishPodcast {
    type: typeof PUBLISH_PODCAST;
    id: string | number;
}

export interface PublishPodcastSuccess extends ShowSuccessToastAction {
    type: typeof PUBLISH_PODCAST_SUCCESS;
}

export interface PublishPodcastFailed extends ShowErrorToastAction {
    type: typeof PUBLISH_PODCAST_FAILED;
}

export interface UploadEpisode {
    type: typeof UPLOAD_EPISODE;
    formData: any;
}

export interface UploadEpisodeSuccess extends ShowSuccessToastAction {
    type: typeof UPLOAD_EPISODE_SUCCESS;
}

export interface UploadEpisodeFailed extends ShowErrorToastAction {
    type: typeof UPLOAD_EPISODE_FAILED;
}

export interface EditRssPodcast {
    type: typeof EDIT_RSS_PODCAST;
    podcastId: string | number;
    formData: any;
}

export interface EditRssPodcastSuccess extends ShowSuccessToastAction {
    type: typeof EDIT_RSS_PODCAST_SUCCESS;
}

export interface EditRssPodcastFailed extends ShowErrorToastAction {
    type: typeof EDIT_RSS_PODCAST_FAILED;
}

export interface EditPremiumPodcast {
    type: typeof EDIT_PREMIUM_PODCAST;
    podcastId: string | number;
    formData: any;
}

export interface EditPremiumPodcastSuccess extends ShowSuccessToastAction {
    type: typeof EDIT_PREMIUM_PODCAST_SUCCESS;
}

export interface EditPremiumPodcastFailed extends ShowErrorToastAction {
    type: typeof EDIT_PREMIUM_PODCAST_FAILED;
}

export interface GetEpisode {
    type: typeof GET_EPISODE;
    id: string | number;
}

export interface GetEpisodeSuccess {
    type: typeof GET_EPISODE_SUCCESS;
    episode: any;
}

export interface GetEpisodeFailed {
    type: typeof GET_EPISODE_FAILED;
}

export interface EditEpisode {
    type: typeof EDIT_EPISODE;
    episodeId: number;
    podcastId: number;
    formData: any;
}

export interface EditEpisodeSuccess extends ShowSuccessToastAction {
    type: typeof EDIT_EPISODE_SUCCESS;
}

export interface EditEpisodeFailed extends ShowErrorToastAction {
    type: typeof EDIT_EPISODE_FAILED;
}

export interface LocationChange {
    type: "@@router/LOCATION_CHANGE";
}

export interface UploadAudioFileResponse {
    audioFileLength: string;
    audioFileUrl: string;
}

export interface UploadImageFileResponse {
    largeImageUrl: string;
    mediumImageUrl: string;
    originalImageUrl: string;
    smallImageUrl: string;
}

export interface CreatePremiumPodcastRequest {
    title: string;
    authorName: string;
    description: string;
    categoryIds: number[];
    primaryCategoryIds: number[];
    smallImageUrl: string;
    mediumImageUrl: string;
    largeImageUrl: string;
    originalImageUrl: string;
    rssFeedLink?: string;
    regions: Region[];
    languageCode: string;
    isPodmeOriginal: boolean;
}

export interface CreateRssPodcastRequest {
    rssFeedLink: string;
}

export interface CreateRssPodcastResult {
    podcastId: number;
    podcastTitle: string;
}

export interface CreateEpisodeRequest {
    title: string;
    podcastId: number;
    description: string;
    authorId: number;
    isPremium: boolean;
    isMinicast: boolean;
    publishDate: Date;
    audioFileUrl?: string;
    audioFileLength?: string;
    smallImageUrl?: string;
    mediumImageUrl?: string;
}

export interface EditRssPodcastRequest {
    id: string | number;
    rssFeedLink: string;
    categoryIds: number[];
    primaryCategoryIds: number[];
    regions: number[];
}

export interface EditPremiumPodcastRequest {
    id: string | number;
    title?: string;
    description?: string;
    authorName?: string;
    categoryIds?: number[];
    primaryCategoryIds?: number[];
    rssFeedLink?: string;
    regions?: Region[];
    smallImageUrl?: string;
    mediumImageUrl?: string;
    largeImageUrl?: string;
    originalImageUrl?: string;
    languageCode?: string;
    isPodmeOriginal: boolean;
}

export interface EditEpisodeRequest {
    id: string | number;
    title?: string;
    description?: string;
    podcastId: number;
    isPremium?: boolean;
    isMinicast?: boolean;
    publishDate?: Date;
    smallImageUrl?: string;
    mediumImageUrl?: string;
    audioFileUrl?: string;
    audioFileLength?: string;
}

export interface ManagePodcastsState {
    podcastListView: {
        isLoading: boolean;
        loadMorePodcasts: boolean;
        podcasts: Podcast[];
        currentRegion: Region;
        categories: any[] | null;
    };
    podcastView: {
        isLoading: boolean;
        podcast: Podcast | null;
        episodes: any[];
        hasMoreEpisodes: boolean;
        podcastRemoved: boolean;
        episodeRemoved: boolean;
        podcastPublished: boolean;
        podcastUpdated: boolean;
        episodeUpdated: boolean;
        episodeUploaded: boolean;
        isLoadingEpisodes: boolean;
    };
    addRssPodcast: {
        isLoading: boolean;
        isSubmitting: boolean;
        createdPodcast: any;
    };
    createPremiumPodcast: {
        createdPodcast: any;
        isLoading: boolean;
        isSubmitting: boolean;
    };
    createEpisode: {
        isLoading: boolean;
        uploadSuccessful: boolean;
    };
    publishPodcast: {
        isLoading: boolean;
        isPublished: boolean;
    };
    editRssPodcast: {
        categories: any[] | null;
        podcast: Podcast | null;
        isLoading: boolean;
        updateSuccessful: boolean;
    };
    editPremiumPodcast: {
        categories: any[] | null;
        podcast: Podcast | null;
        isLoading: boolean;
        updateSuccessful: boolean;
    };
    editEpisode: {
        updateSuccessful: boolean;
        isLoading: boolean;
    };
}

export type ManagePodcastActionTypes =
    | GetAllPodcasts
    | GetAllPodcastsSuccess
    | GetAllPodcastsFailed
    | SearchPodcast
    | SearchPodcastSuccess
    | SearchPodcastFailed
    | GetAllCategories
    | GetAllCategoriesSuccess
    | GetAllCategoriesFailed
    | GetEpisodes
    | GetEpisodesSuccess
    | GetEpisodesFailed
    | GetEpisode
    | GetEpisodeSuccess
    | GetEpisodeFailed
    | CreatePremiumPodcast
    | CreatePremiumPodcastSuccess
    | CreatePremiumPodcastFailed
    | CreateRssPodcast
    | CreateRssPodcastSuccess
    | CreateRssPodcastFailed
    | ResetPodcasts
    | ResetForm
    | Getpodcast
    | GetpodcastSuccess
    | GetpodcastFailed
    | RemovePodcast
    | RemovePodcastSuccess
    | RemovePodcastFailed
    | RemoveEpisodeFailed
    | RemoveEpisode
    | RemoveEpisodeSuccess
    | RemoveEpisodeFailed
    | PublishPodcast
    | PublishPodcastSuccess
    | PublishPodcastFailed
    | UploadEpisode
    | UploadEpisodeSuccess
    | UploadEpisodeFailed
    | EditRssPodcast
    | EditRssPodcastSuccess
    | EditRssPodcastFailed
    | EditPremiumPodcast
    | EditPremiumPodcastSuccess
    | EditPremiumPodcastFailed
    | EditEpisode
    | EditEpisodeSuccess
    | EditEpisodeFailed
    | LocationChange;
