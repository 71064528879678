import { Box, Text, Flex, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import UpdateUserGroupModal from "./compontents/UpdateUserGroupModal";
import { UpdateUserGroupFormValues } from "./redux/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { actions } from "../AccessAndPermissions/redux/actions";
import { UserRole } from "../redux/types";
import UserGroupsTable from "./compontents/UserGroupsTable";
import { colors } from "../constants";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "../components/CircularProgress";
import { actions as statisticsActions } from "../Statistics/redux";

const manageUsersState = (state: RootState) => state.manageUsers;
const manageAccessAndPermissions = (state: RootState) => state.manageAccessAndPermissions;
const manageStatisticsState = (state: RootState) => state.statistics;
const globalState = (state: RootState) => state.global;

export default function AccessAndPermissions() {
    const dispatch = useDispatch();
    const { userRole } = useSelector(manageUsersState);
    const { groups } = useSelector(manageAccessAndPermissions);
    const { premiumPodcasts } = useSelector(manageStatisticsState);
    const { userGroups, isLoading, hasMoreUserGroups } = useSelector(manageAccessAndPermissions);
    const { region } = useSelector(globalState);
    const [currentPage, setPage] = useState(0);
    const [pageSize] = useState(40);
    const [shownUserGroups, setShownUserGroups] = useState(userGroups);

    useEffect(() => {
        if (region) dispatch(statisticsActions.getPremiumPodcasts(region));
    }, [region]);

    useEffect(() => {
        if (!groups && (userRole === UserRole.Admin || userRole === UserRole.Editor)) dispatch(actions.getGroups());
    }, [userRole]);

    useEffect(() => {
        setShownUserGroups(userGroups);
    }, [userGroups]);

    useEffect(() => {
        dispatch(actions.getUserGroups(pageSize, currentPage));
    }, [currentPage]);

    const submitModal = (values: UpdateUserGroupFormValues) => {
        if (userRole === UserRole.Admin) dispatch(actions.updateUserGroup(values));
        else dispatch(actions.updatePodcasterUserGroup(values));
    };

    const fetchMoreUsers = () => {
        setPage(currentPage + 1);
    };

    return (
        <Box width="100%" margin="0 auto">
            <Heading as="h1" size={"xl"} mb={8} color="white">
                Access & Permissions
            </Heading>
            <UpdateUserGroupModal
                emailFieldDisabled={false}
                userGroups={groups}
                premiumPodcasts={premiumPodcasts}
                onSubmit={(values: UpdateUserGroupFormValues) => submitModal(values)}
                buttonLabel={userRole === UserRole.Admin ? "Add role" : "Add podcaster"}
            />
            <Box bg={colors.darkblue} padding="0.5rem 1rem" mt={4}>
                <Flex>
                    <Box display="flex" alignItems="center" flexBasis="25%">
                        <Text color="white">Email</Text>
                    </Box>
                    <Box display="flex" alignItems="center" flexBasis="10%">
                        <Text color="white">Role</Text>
                    </Box>
                    <Box display="flex" alignItems="center" flexBasis="10%">
                        <Text color="white">Valid until</Text>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Text display="none">Edit button</Text>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Text display="none">Remove button</Text>
                    </Box>
                </Flex>
            </Box>
            <Box>
                {isLoading && hasMoreUserGroups ? (
                    <CircularProgress />
                ) : (
                    <InfiniteScroll
                        dataLength={shownUserGroups?.length}
                        next={fetchMoreUsers}
                        hasMore={hasMoreUserGroups}
                        loader={<CircularProgress />}
                        style={{ overflowX: "hidden" }}
                    >
                        <UserGroupsTable
                            userGroups={userGroups}
                            premiumPodcasts={premiumPodcasts}
                            groups={groups}
                            onSubmit={(values: UpdateUserGroupFormValues) => submitModal(values)}
                        />
                    </InfiniteScroll>
                )}
            </Box>
        </Box>
    );
}
