import { Box, Heading } from "@chakra-ui/react";

import Pagination from "./Pagination";

interface Props {
    isLoading: boolean;
    title?: string;
    children: any;
    showPagination?: boolean;
    currentPage?: number;
    currentPageSize?: number;
    numberOfPages?: number;
    setCurrentPage?: (page: number) => any;
}

export default function TableContainer({
    isLoading,
    title,
    children,
    showPagination,
    currentPage,
    numberOfPages,
    currentPageSize,
    setCurrentPage,
}: Props) {
    return (
        <>
            <Box position='relative' backgroundColor='white' borderRadius='15px' padding='1rem' boxShadow='0 1px 2px #ccc'>
                <Heading as='h2' size='lg' mb={8} pl={6} pt={2}>
                    {title}
                </Heading>
                <div>{children}</div>
                {isLoading && <Loading />}
                {showPagination && numberOfPages! > 0 && (
                    <Pagination
                        currentPage={currentPage ?? 1}
                        pages={numberOfPages ?? 1}
                        setCurrentPage={(page) => (setCurrentPage ? setCurrentPage(page) : null)}
                        currentPageSize={currentPageSize ?? 1}
                    />
                )}
            </Box>
        </>
    );
}

function Loading() {
    return <Box>Loading...</Box>;
}
