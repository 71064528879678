import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Heading, Text } from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";
import { PodmeColor } from "../../redux/types";
import { SubscriptionEvent, SubscriptionEventType } from "../redux/types";

interface Props {
    groupedEvents: { year: string; events: SubscriptionEvent[] }[];
}

function SubscriptionEvents({ groupedEvents }: Props) {
    if (!groupedEvents || groupedEvents.length < 1) return null;

    return (
        <Flex direction="column" gridGap="1rem">
            <Heading as="h3" fontSize="1.25rem" fontWeight="700">
                Events
            </Heading>
            <Flex direction="column" gridGap="0.5rem">
                {groupedEvents.map(({ year, events }, idx) => (
                    <Flex flexDirection="column" key={idx} backgroundColor="#e9e9e9" rounded="0.75rem" border="1px solid #ccc">
                        <Accordion allowToggle key={year}>
                            <AccordionItem border="none">
                                <AccordionButton
                                    padding="0.75rem 1rem"
                                    rounded="0.75rem"
                                    transition="border-radius 0.5s ease-in-out"
                                    sx={{
                                        "&[aria-expanded=true]": {
                                            rounded: "0.75rem 0.75rem 0 0",
                                        },
                                    }}
                                    _focus={{ outline: "none" }}
                                >
                                    <Box as="span" flex="1" fontWeight="700" textAlign="left">
                                        {year}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel p="0">
                                    <Flex flexDirection="column">
                                        {events?.map((sub) => (
                                            <Flex
                                                padding="0.75rem 1rem"
                                                gridGap={4}
                                                justifyContent="space-between"
                                                key={sub.date}
                                                borderTop="1px solid #cfcfcf"
                                                sx={{
                                                    "&:last-of-type": {
                                                        rounded: "0 0 0.75rem 0.75rem",
                                                    },
                                                }}
                                                _hover={{
                                                    bgColor: "#e0e0e0",
                                                }}
                                            >
                                                <Text fontWeight="400" fontSize="sm" color={PodmeColor.LicoriceBlue}>
                                                    {SubscriptionEventType[sub.subscriptionType as keyof typeof SubscriptionEventType]}
                                                </Text>
                                                <Text fontWeight="400" fontSize="sm">
                                                    {format(new Date(sub.date), "yyyy-MM-dd HH:mm")}
                                                </Text>
                                            </Flex>
                                        ))}
                                    </Flex>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );
}

export default SubscriptionEvents;
