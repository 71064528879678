import { Region } from "../../redux/types";
import {
    ArchiveVoucherCampaign,
    CreateVoucherCampaign,
    GetActiveVoucherCampaigns,
    GetVoucherCampaignHistory,
    GetOverviewContent,
    UpdateVoucherCampaign,
    GetVoucherCampaignDiscountTypes,
    CreateVoucherCampaignFormValues,
    UpdateVoucherCampaignFormValues,
    ArchiveVoucherCampaignFormValues,
    ListVoucherCampaignVouchers,
} from "./types";

function getOverviewContent(region?: Region): GetOverviewContent {
    return {
        type: "GET_OVERVIEW_CONTENT",
        region,
    };
}

function getActiveVoucherCampaigns(page: number, pageSize: number, region?: Region): GetActiveVoucherCampaigns {
    return {
        type: "GET_ACTIVE_VOUCHERCAMPAIGNS",
        page,
        pageSize,
        region,
    };
}

function createVoucherCampaign(values: CreateVoucherCampaignFormValues): CreateVoucherCampaign {
    return {
        type: "CREATE_VOUCHERCAMPAIGN",
        values: values,
    };
}

function updateVoucherCampaign(values: UpdateVoucherCampaignFormValues): UpdateVoucherCampaign {
    return {
        type: "UPDATE_VOUCHERCAMPAIGN",
        values: values,
    };
}

function getVoucherCampaignHistory(page: number, pageSize: number, region?: Region): GetVoucherCampaignHistory {
    return {
        type: "GET_VOUCHERCAMPAIGN_HISTORY",
        page,
        pageSize,
        region,
    };
}

function archiveVoucherCampaign(values: ArchiveVoucherCampaignFormValues): ArchiveVoucherCampaign {
    return {
        type: "ARCHIVE_VOUCHERCAMPAIGN",
        values: values,
    };
}

function getVoucherCampaignDiscountTypes(): GetVoucherCampaignDiscountTypes {
    return {
        type: "GET_VOUCHERCAMPAIGN_DISCOUNT_TYPES",
    };
}

function listVoucherCampaignVouchers(id: number, voucherCampaignName: string): ListVoucherCampaignVouchers {
    return {
        type: "LIST_VOUCHERCAMPAIGNVOUCHERS",
        values: { id: id, voucherCampaignName: voucherCampaignName },
    };
}

export const actions = {
    getOverviewContent,
    getActiveVoucherCampaigns,
    createVoucherCampaign,
    updateVoucherCampaign,
    getVoucherCampaignHistory,
    archiveVoucherCampaign,
    getVoucherCampaignDiscountTypes,
    listVoucherCampaignVouchers,
};
