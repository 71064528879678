import { call, put, takeLatest } from "redux-saga/effects";
import { ApiError, Region } from "../../redux/types";
import { AxiosResponse } from "axios";

import {
    createAxiosRequest,
    createPostAxiosRequest,
    createDeleteAxiosRequest,
    createPutAxiosRequest,
    uploadAudioFileRequest,
    uploadImageFileRequest,
} from "../../utils";

import {
    GET_ALL_PODCASTS,
    GET_ALL_PODCASTS_SUCCESS,
    GET_ALL_PODCASTS_FAILED,
    SEARCH_PODCAST,
    SEARCH_PODCAST_SUCCESS,
    SEARCH_PODCAST_FAILED,
    GET_ALL_CATEGORIES,
    GET_ALL_CATEGORIES_SUCCESS,
    GET_ALL_CATEGORIES_FAILED,
    GET_EPISODES,
    GET_EPISODES_SUCCESS,
    GET_EPISODES_FAILED,
    GET_PODCAST,
    GET_PODCAST_SUCCESS,
    GET_PODCAST_FAILED,
    PUBLISH_PODCAST,
    PUBLISH_PODCAST_SUCCESS,
    PUBLISH_PODCAST_FAILED,
    EDIT_RSS_PODCAST,
    EDIT_RSS_PODCAST_SUCCESS,
    EDIT_RSS_PODCAST_FAILED,
    EDIT_PREMIUM_PODCAST,
    EDIT_PREMIUM_PODCAST_SUCCESS,
    EDIT_PREMIUM_PODCAST_FAILED,
    CREATE_PREMIUM_PODCAST,
    CREATE_PREMIUM_PODCAST_SUCCESS,
    CREATE_PREMIUM_PODCAST_FAILED,
    ADD_RSS_PODCAST,
    ADD_RSS_PODCAST_SUCCESS,
    ADD_RSS_PODCAST_FAILED,
    REMOVE_PODCAST,
    REMOVE_PODCAST_SUCCESS,
    REMOVE_PODCAST_FAILED,
    REMOVE_EPISODE,
    REMOVE_EPISODE_SUCCESS,
    REMOVE_EPISODE_FAILED,
    UPLOAD_EPISODE,
    UPLOAD_EPISODE_SUCCESS,
    UPLOAD_EPISODE_FAILED,
    EDIT_EPISODE,
    EDIT_EPISODE_SUCCESS,
    EDIT_EPISODE_FAILED,
    GET_EPISODE,
    GET_EPISODE_SUCCESS,
    GET_EPISODE_FAILED,
    GetAllPodcasts,
    SearchPodcast,
    GetEpisodes,
    CreatePremiumPodcast,
    CreateRssPodcast,
    Getpodcast,
    RemovePodcast,
    RemoveEpisode,
    PublishPodcast,
    UploadEpisode,
    EditRssPodcast,
    EditPremiumPodcast,
    GetEpisode,
    EditEpisode,
    CreatePremiumPodcastRequest,
    CreateRssPodcastRequest,
    CreateEpisodeRequest,
    EditRssPodcastRequest,
    EditPremiumPodcastRequest,
    EditEpisodeRequest,
    CreateRssPodcastResult,
    PublishPodcastSuccess,
    UploadEpisodeSuccess,
    RemovePodcastSuccess,
    RemoveEpisodeSuccess,
    EditRssPodcastSuccess,
    EditPremiumPodcastSuccess,
    EditEpisodeSuccess,
    CreatePremiumPodcastFailed,
    CreatePremiumPodcastSuccess,
    PublishPodcastFailed,
    UploadEpisodeFailed,
    RemovePodcastFailed,
    EditRssPodcastFailed,
    EditPremiumPodcastFailed,
    EditEpisodeFailed,
    GetEpisodesSuccess,
    GetEpisodesFailed,
} from "./types";

function* getAllPodcasts({ page, podcastType, region = Region.sweden }: GetAllPodcasts): any {
    try {
        const { data } = yield createAxiosRequest(
            `api/podcast?page=${page}&pageSize=30&type=${podcastType ? podcastType : 2}&region=${region}`
        );

        return yield put({ type: GET_ALL_PODCASTS_SUCCESS, podcasts: data, podcastType: podcastType, region });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put({ type: GET_ALL_PODCASTS_FAILED, message: error.message, status: error.status });
        }
    }
}

function* searchPodcast({ query }: SearchPodcast): any {
    try {
        const { data } = yield createAxiosRequest(`api/podcast/search?searchString=${query}&page=0&pageSize=30`);

        return yield put({ type: SEARCH_PODCAST_SUCCESS, searchResult: data });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put({ type: SEARCH_PODCAST_FAILED, message: error.message, status: error.status });
        }
    }
}

function* getAllCategories(): any {
    try {
        const { data } = yield createAxiosRequest(`api/podcast/categories`);

        return yield put({ type: GET_ALL_CATEGORIES_SUCCESS, categories: data });
    } catch (error) {
        return yield put({ type: GET_ALL_CATEGORIES_FAILED, message: error.response });
    }
}

function* getEpisodes({ podcastId, page, pageSize }: GetEpisodes): any {
    try {
        const { data } = yield call(createAxiosRequest, `api/episode/podcast/${podcastId}?page=${page}&pageSize=${pageSize}`);

        return yield put<GetEpisodesSuccess>({ type: GET_EPISODES_SUCCESS, episodes: data });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<GetEpisodesFailed>({ type: GET_EPISODES_FAILED, message: error.message, showToastErrorMessage: true });
        }
    }
}

function* createPremiumPodcast({ formData }: CreatePremiumPodcast): any {
    try {
        const imageData = new FormData();
        imageData.append("image", formData.image);

        const fileResponse = yield uploadImageFileRequest(imageData);
        const { smallImageUrl, mediumImageUrl, largeImageUrl, originalImageUrl } = fileResponse.data;

        const postData: CreatePremiumPodcastRequest = {
            title: formData.title,
            description: formData.description,
            authorName: formData.author,
            smallImageUrl,
            mediumImageUrl,
            largeImageUrl,
            originalImageUrl,
            categoryIds: formData.categories,
            rssFeedLink: formData.rss,
            regions: formData.regions,
            languageCode: formData.language,
            isPodmeOriginal: formData.isPodmeOriginal,
            primaryCategoryIds: formData.primaryCategory ? [formData.primaryCategory] : [],
        };

        const { data } = yield createPostAxiosRequest(`api/podcast/createPremium`, postData);

        return yield put<CreatePremiumPodcastSuccess>({ type: CREATE_PREMIUM_PODCAST_SUCCESS, createdPodcast: data });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<CreatePremiumPodcastFailed>({
                type: CREATE_PREMIUM_PODCAST_FAILED,
                message: error.message,
                showToastErrorMessage: true,
            });
        }
    }
}

function* createRssPodcast({ formData }: CreateRssPodcast): any {
    try {
        const postData: CreateRssPodcastRequest = {
            rssFeedLink: formData.rss,
        };

        const { data }: AxiosResponse<CreateRssPodcastResult> = yield createPostAxiosRequest(
            `api/podcast/create-rss-with-autoimport`,
            postData
        );

        return yield put({ type: ADD_RSS_PODCAST_SUCCESS, createdPodcast: data });
    } catch (e) {
        if (e && e.stack && e.message) {
            return yield put({
                type: ADD_RSS_PODCAST_FAILED,
                message: e?.response?.data?.detail,
                showToastErrorMessage: true,
            });
        }
    }
}

function* getPodcast({ id }: Getpodcast): any {
    try {
        const { data } = yield createAxiosRequest(`api/podcast/${id}`);

        return yield put({ type: GET_PODCAST_SUCCESS, podcast: data });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put({ type: GET_PODCAST_FAILED, message: error.message, status: error.status });
        }
    }
}

function* removePodcast({ id }: RemovePodcast): any {
    try {
        yield createDeleteAxiosRequest(`api/podcast/archive/${id}`);

        return yield put<RemovePodcastSuccess>({
            type: REMOVE_PODCAST_SUCCESS,
            message: "Podcast removed successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<RemovePodcastFailed>({
                type: REMOVE_PODCAST_FAILED,
                message: error.message,
                showToastErrorMessage: true,
            });
        }
    }
}

function* removeEpisode({ id }: RemoveEpisode): any {
    try {
        yield createDeleteAxiosRequest(`api/episode/archive/${id}`);

        return yield put<RemoveEpisodeSuccess>({
            type: REMOVE_EPISODE_SUCCESS,
            message: "Episode removed successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put({ type: REMOVE_EPISODE_FAILED, message: error.message, status: error.status });
        }
    }
}

function* publishPodcast({ id }: PublishPodcast): any {
    try {
        yield createPutAxiosRequest(`api/podcast/publish/${id}`, "");

        return yield put<PublishPodcastSuccess>({
            type: PUBLISH_PODCAST_SUCCESS,
            message: "Podcast published successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<PublishPodcastFailed>({
                type: PUBLISH_PODCAST_FAILED,
                message: error.message,
                showToastErrorMessage: true,
            });
        }
    }
}

function* uploadEpisode({ formData }: UploadEpisode): any {
    try {
        const postData: CreateEpisodeRequest = {
            title: formData.title,
            podcastId: formData.podcastId,
            description: formData.description,
            authorId: formData.authorId,
            isPremium: formData.isPremium,
            isMinicast: formData.isMinicast && formData.isPremium,
            publishDate: formData.publishDate,
        };

        if (formData.image) {
            const imageFileData = new FormData();
            imageFileData.append("image", formData.image);
            const imageFileResponse = yield uploadImageFileRequest(imageFileData);
            const { smallImageUrl, mediumImageUrl } = imageFileResponse.data;

            postData.smallImageUrl = smallImageUrl;
            postData.mediumImageUrl = mediumImageUrl;
        }
        if (formData.audio) {
            const audioFileData = new FormData();
            audioFileData.append("audio", formData.audio);
            const audioFileResponse = yield uploadAudioFileRequest(audioFileData);
            const { audioFileUrl, audioFileLength } = audioFileResponse.data;

            postData.audioFileLength = audioFileLength;
            postData.audioFileUrl = audioFileUrl;
        }

        yield createPostAxiosRequest(`api/episode/create`, postData);

        return yield put<UploadEpisodeSuccess>({
            type: UPLOAD_EPISODE_SUCCESS,
            message: "Episode uploaded successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<UploadEpisodeFailed>({
                type: UPLOAD_EPISODE_FAILED,
                message: error.message,
                showToastErrorMessage: true,
            });
        }
    }
}

function* editRssPodcast({ podcastId, formData }: EditRssPodcast): any {
    try {
        const postData: EditRssPodcastRequest = {
            id: podcastId,
            rssFeedLink: formData.rss,
            categoryIds: formData.categories,
            primaryCategoryIds: formData.primaryCategory ? [formData.primaryCategory] : [],
            regions: formData.regions,
        };

        yield createPutAxiosRequest(`api/podcast/update`, postData);

        return yield put<EditRssPodcastSuccess>({
            type: EDIT_RSS_PODCAST_SUCCESS,
            message: "Podcast updated successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<EditRssPodcastFailed>({
                type: EDIT_RSS_PODCAST_FAILED,
                message: error.message,
                showToastErrorMessage: true,
            });
        }
    }
}

function* editPremiumPodcast({ podcastId, formData }: EditPremiumPodcast): any {
    try {
        const postData: EditPremiumPodcastRequest = {
            id: podcastId,
            title: formData.title,
            description: formData.description,
            authorName: formData.author,
            categoryIds: formData.categories,
            rssFeedLink: formData.rss,
            regions: formData.regions,
            primaryCategoryIds: formData.primaryCategory ? [formData.primaryCategory] : [],
            languageCode: formData.language,
            isPodmeOriginal: formData.isPodmeOriginal,
        };

        if (formData.image) {
            const fileData = new FormData();
            fileData.append("image", formData.image);

            const fileResponse = yield uploadImageFileRequest(fileData);
            const { smallImageUrl, mediumImageUrl, largeImageUrl, originalImageUrl } = fileResponse.data;

            postData.smallImageUrl = smallImageUrl;
            postData.mediumImageUrl = mediumImageUrl;
            postData.largeImageUrl = largeImageUrl;
            postData.originalImageUrl = originalImageUrl;
        }

        yield createPutAxiosRequest(`api/podcast/update`, postData);

        return yield put<EditPremiumPodcastSuccess>({
            type: EDIT_PREMIUM_PODCAST_SUCCESS,
            message: "Podcast updated successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<EditPremiumPodcastFailed>({
                type: EDIT_PREMIUM_PODCAST_FAILED,
                message: error.message,
                showToastErrorMessage: true,
            });
        }
    }
}

function* getEpisode({ id }: GetEpisode): any {
    try {
        const { data } = yield createAxiosRequest(`api/episode/${id}`);

        return yield put({ type: GET_EPISODE_SUCCESS, episode: data });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put({ type: GET_EPISODE_FAILED, message: error.message, status: error.status });
        }
    }
}

function* editEpisode({ episodeId, podcastId, formData }: EditEpisode): any {
    try {
        const postData: EditEpisodeRequest = {
            id: episodeId,
            title: formData.title,
            description: formData.description,
            podcastId: podcastId,
            isPremium: formData.isPremium,
            isMinicast: formData.isMinicast && formData.isPremium,
            publishDate: formData.publishDate,
        };

        if (formData.audio) {
            const fileData = new FormData();
            fileData.append("audio", formData.audio);

            const fileResponse = yield uploadAudioFileRequest(fileData);
            const { audioFileUrl, audioFileLength } = fileResponse.data;

            postData.audioFileUrl = audioFileUrl;
            postData.audioFileLength = audioFileLength;
        }
        if (formData.image) {
            const fileData = new FormData();
            fileData.append("image", formData.image);

            const fileResponse = yield uploadImageFileRequest(fileData);
            const { smallImageUrl, mediumImageUrl } = fileResponse.data;

            postData.smallImageUrl = smallImageUrl;
            postData.mediumImageUrl = mediumImageUrl;
        }

        yield createPutAxiosRequest(`api/episode/update`, postData);

        return yield put<EditEpisodeSuccess>({
            type: EDIT_EPISODE_SUCCESS,
            message: "Episode updated successfully",
            showToastSuccessMessage: true,
        });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error = new ApiError(e.response);
            return yield put<EditEpisodeFailed>({ type: EDIT_EPISODE_FAILED, message: error.message, showToastErrorMessage: true });
        }
    }
}

export default [
    takeLatest(GET_ALL_PODCASTS, getAllPodcasts),
    takeLatest(SEARCH_PODCAST, searchPodcast),
    takeLatest(GET_ALL_CATEGORIES, getAllCategories),
    takeLatest(GET_EPISODES, getEpisodes),
    takeLatest(CREATE_PREMIUM_PODCAST, createPremiumPodcast),
    takeLatest(ADD_RSS_PODCAST, createRssPodcast),
    takeLatest(GET_PODCAST, getPodcast),
    takeLatest(REMOVE_PODCAST, removePodcast),
    takeLatest(REMOVE_EPISODE, removeEpisode),
    takeLatest(PUBLISH_PODCAST, publishPodcast),
    takeLatest(UPLOAD_EPISODE, uploadEpisode),
    takeLatest(EDIT_RSS_PODCAST, editRssPodcast),
    takeLatest(EDIT_PREMIUM_PODCAST, editPremiumPodcast),
    takeLatest(GET_EPISODE, getEpisode),
    takeLatest(EDIT_EPISODE, editEpisode),
];
