import { LoginType, RegionLogin, ShowErrorToastAction, ShowSuccessToastAction } from "../../redux/types";

export const CHECK_AUTH_STATUS = "ManageUsers/CHECK_AUTH_STATUS";
export const USER_IS_LOGGED_IN = "ManageUsers/USER_IS_LOGGED_IN";
export const USER_IS_NOT_LOGGED_IN = "ManageUsers/USER_IS_NOT_LOGGED_IN";
export const LOGIN_USER = "ManageUsers/LOGIN_USER";
export const LOGIN_USER_SUCCESS = "ManageUsers/LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "ManageUsers/LOGIN_USER_FAILED";
export const LOGOUT_USER = "ManageUsers/LOGOUT_USER";

export const GET_USER_ROLE = "ManageUsers/GET_USER_ROLE";
export const GET_USER_ROLE_SUCCESS = "ManageUsers/GET_USER_ROLE_SUCCESS";
export const GET_USER_ROLE_FAILED = "ManageUsers/GET_USER_ROLE_FAILED";

export const GET_USERS = "ManageUsers/GET_USERS";
export const GET_USERS_SUCCESS = "ManageUsers/GET_USERS_SUCCESS";
export const GET_USERS_FAILED = "ManageUsers/GET_USERS_FAILED";

export const SEARCH_USER = "ManageUsers/SEARCH_USER";
export const SEARCH_USER_SUCCESS = "ManageUsers/SEARCH_USER_SUCCESS";
export const SEARCH_USER_FAILED = "ManageUsers/SEARCH_USER_FAILED";

export const GET_USER_QUERY_TYPES = "ManageUsers/GET_USER_QUERY_TYPES";
export const GET_USER_QUERY_TYPES_SUCCESS = "ManageUsers/GET_USER_QUERY_TYPES_SUCCESS";
export const GET_USER_QUERY_TYPES_FAILED = "ManageUsers/GET_USER_QUERY_TYPES_FAILED";

export const CANCEL_SUBSCRIPTION = "ManageUsers/CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "ManageUsers/CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILED = "ManageUsers/CANCEL_SUBSCRIPTION_FAILED";

export const RENEW_SUBSCRIPTION = "ManageUsers/RENEW_SUBSCRIPTION";
export const RENEW_SUBSCRIPTION_SUCCESS = "ManageUsers/RENEW_SUBSCRIPTION_SUCCESS";
export const RENEW_SUBSCRIPTION_FAILED = "ManageUsers/RENEW_SUBSCRIPTION_FAILED";

export const GET_USERNAME = "ManageUsers/GET_USERNAME";
export const GET_USERNAME_SUCCESS = "ManageUsers/GET_USERNAME_SUCCESS";
export const GET_USERNAME_FAILED = "ManageUsers/GET_USERNAME_FAILED";

export const GET_USER_DETAILS = "ManageUsers/GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "ManageUsers/GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILED = "ManageUsers/GET_USER_DETAILS_FAILED";

export const DELETE_USER = "ManageUsers/DELETE_USER";
export const DELETE_USER_SUCCESS = "ManageUsers/DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "ManageUsers/DELETE_USER_FAILED";

export const ADD_PODCASTER_PERMISSION = "ManageUsers/ADD_PODCASTER_PERMISSION";
export const ADD_PODCASTER_PERMISSION_SUCCESS = "ManageUsers/ADD_PODCASTER_PERMISSION_SUCCESS";
export const ADD_PODCASTER_PERMISSION_FAILED = "ManageUsers/ADD_PODCASTER_PERMISSION_FAILED";

export const DELETE_PODCASTER_PERMISSION = "ManageUsers/DELETE_PODCASTER_PERMISSION";
export const DELETE_PODCASTER_PERMISSION_SUCCESS = "ManageUsers/DELETE_PODCASTER_PERMISSION_SUCCESS";
export const DELETE_PODCASTER_PERMISSION_FAILED = "ManageUsers/DELETE_PODCASTER_PERMISSION_FAILED";

export const ADD_PODCAST_TO_USER = "ManageUsers/ADD_PODCAST_TO_USER";
export const ADD_PODCAST_TO_USER_SUCCESS = "ManageUsers/ADD_PODCAST_TO_USER_SUCCESS";
export const ADD_PODCAST_TO_USER_FAILED = "ManageUsers/ADD_PODCAST_TO_USER_FAILED";

export const DELETE_PODCAST_FROM_USER = "ManageUsers/DELETE_PODCAST_FROM_USER";
export const DELETE_PODCAST_FROM_USER_SUCCESS = "ManageUsers/DELETE_PODCAST_FROM_USER_SUCCESS";
export const DELETE_PODCAST_FROM_USER_FAILED = "ManageUsers/DELETE_PODCAST_FROM_USER_FAILED";

export const UPDATE_PODCAST_STATISTICS_PERMISSION = "ManageUsers/UPDATE_PODCAST_STATISTICS_PERMISSION";
export const UPDATE_PODCAST_STATISTICS_PERMISSION_SUCCESS = "ManageUsers/UPDATE_PODCAST_STATISTICS_PERMISSION_SUCCESS";
export const UPDATE_PODCAST_STATISTICS_PERMISSION_FAILED = "ManageUsers/UPDATE_PODCAST_STATISTICS_PERMISSION_FAILED";

export const REFRESH_SUBSCRIPTIONS = "ManageUsers/REFRESH_SUBSCRIPTIONS";
export const REFRESH_SUBSCRIPTIONS_SUCCESS = "ManageUsers/REFRESH_SUBSCRIPTIONS_SUCCESS";
export const REFRESH_SUBSCRIPTIONS_FAILED = "ManageUsers/REFRESH_SUBSCRIPTIONS_FAILED";

export const UNMIGRATE = "ManageUsers/UNMIGRATE";
export const UNMIGRATE_SUCCESS = "ManageUsers/UNMIGRATE_SUCCESS";
export const UNMIGRATE_FAILED = "ManageUsers/UNMIGRATE_FAILED";

export const MIGRATE_USER = "ManageUsers/MIGRATE_USER";
export const MIGRATE_USER_SUCCESS = "ManageUsers/MIGRATE_USER_SUCCESS";
export const MIGRATE_USER_FAILED = "ManageUsers/MIGRATE_USER_FAILED";

export const FETCH_SUBSCRIPTION_EVENTS = "ManageUsers/FETCH_SUBSCRIPTION_EVENTS";
export const FETCH_SUBSCRIPTION_EVENTS_SUCCESS = "ManageUsers/FETCH_SUBSCRIPTION_EVENTS_SUCCESS";
export const FETCH_SUBSCRIPTION_EVENTS_FAILED = "ManageUsers/FETCH_SUBSCRIPTION_EVENTS_FAILED";
export const START_FETCH_SUBSCRIPTION_EVENTS_SEQUENCE = "ManageUsers/START_FETCH_SUBSCRIPTION_EVENTS_SEQUENCE";

export const FETCH_PAYMENT_TRANSACTIONS = "ManageUsers/FETCH_PAYMENT_TRANSACTIONS";
export const FETCH_PAYMENT_TRANSACTIONS_SUCCESS = "ManageUsers/FETCH_PAYMENT_TRANSACTIONS_SUCCESS";
export const FETCH_PAYMENT_TRANSACTIONS_FAILED = "ManageUsers/FETCH_PAYMENT_TRANSACTIONS_FAILED";
export const START_FETCH_PAYMENT_TRANSACTIONS_SEQUENCE = "ManageUsers/START_FETCH_PAYMENT_TRANSACTIONS_SEQUENCE";

export const REFUND_TRANSACTION = "ManageUsers/REFUND_TRANSACTION";
export const REFUND_TRANSACTION_SUCCESS = "ManageUsers/REFUND_TRANSACTION_SUCCESS";
export const REFUND_TRANSACTION_FAILED = "ManageUsers/REFUND_TRANSACTION_FAILED";

export const TOGGLE_SOFT_MIGRATION = "ManageUsers/TOGGLE_SOFT_MIGRATION";
export const TOGGLE_SOFT_MIGRATION_SUCCESS = "ManageUsers/TOGGLE_SOFT_MIGRATION_SUCCESS";
export const TOGGLE_SOFT_MIGRATION_FAILED = "ManageUsers/TOGGLE_SOFT_MIGRATION_FAILED";

export const GET_SOFT_MIGRATION_STATUS = "ManageUsers/GET_SOFT_MIGRATION_STATUS";
export const GET_SOFT_MIGRATION_STATUS_SUCCESS = "ManageUsers/GET_SOFT_MIGRATION_STATUS_SUCCESS";
export const GET_SOFT_MIGRATION_STATUS_FAILED = "ManageUsers/GET_SOFT_MIGRATION_STATUS_FAILED";

export const LOGIN_WITH_SCHIBSTED = "auth/LOGIN_WITH_SCHIBSTED";
export const LOGIN_WITH_SCHIBSTED_SUCCESS = "auth/LOGIN_WITH_SCHIBSTED_SUCCESS";
export const LOGIN_WITH_SCHIBSTED_FAILED = "auth/LOGIN_WITH_SCHIBSTED_FAILED";

export const GET_SCHIBSTED_TOKEN = "auth/GET_SCHIBSTED_TOKEN_SCHIBSTED";
export const GET_SCHIBSTED_TOKEN_SUCCESS = "auth/GET_SCHIBSTED_TOKEN_SUCCESS";
export const GET_SCHIBSTED_TOKEN_FAILED = "auth/GET_SCHIBSTED_TOKEN_FAILED";

export const REFRESH_SCHIBSTED_TOKEN = "auth/REFRESH_SCHIBSTED_TOKEN";

export const ADD_FREE_SUBSCRIPTION = "ManageUsers/ADD_FREE_SUBSCRIPTION";
export const ADD_FREE_SUBSCRIPTION_SUCCESS = "ManageUsers/ADD_FREE_SUBSCRIPTION_SUCCESS";
export const ADD_FREE_SUBSCRIPTION_FAILED = "ManageUsers/ADD_FREE_SUBSCRIPTION_FAILED";

export type ObjectValues<T> = T[keyof T];

export enum SubscriptionType {
    Package = "Package",
    Single = "Single",
}

export enum PaymentTransactionState {
    Created = "Created",
    Pending = "Pending",
    Succeeded = "Succeeded",
    Failed = "Failed",
    Cancelled = "Cancelled",
}

export enum SubscriptionPlatform {
    Adyen = 0,
    Apple = 1,
    Google = 2,
    SchibstedSE = 3,
    SchibstedFI = 4,
    SchibstedNO = 5,
    Bundler = 6,
    VippsMobilePay = 7,
}

export enum SubscriptionStatus {
    Error = 0,
    Active = 1,
    TestPeriod = 2,
    FailedToWithdrawMoney = 3,
    Cancelled = 4,
    PaymentProblem = 5,
    Inactive = 6,
}

export enum SubscriptionEventType {
    SubscriptionStarted = "Started",
    SubscriptionInTrial = "Trial started",
    SubscriptionRenewed = "Renewed",
    SubscriptionCanceled = "Cancelled",
    SubscriptionRecoveredFromCanceled = "Activated",
    SubscriptionRecoveredFromInactive = "Activated",
    SubscriptionInactivated = "Inactivated",
    SubscriptionMigrated = "Migrated",
    SubscriptionInGrace = "In grace period (renewal not successful)",
    SubscriptionOnHold = "On hold",
    SubscriptionValidated = "Validated",
    SubscriptionConvertedTrialToActive = "Trial converted to active",
    SubscriptionChurnedInTrialToCancelled = "Trial converted to cancelled",
    SubscriptionRecoveredFromInGrace = "Recovered from in grace - renewed",
    SubscriptionRecoveredFromOnHold = "Recovered from on hold - renewed",
    SubscriptionRecoveredFromError = "Recovered from error - renewed",
    SubscriptionUpdated = "Updated",
    CardExpired = "Card expired",
    Error = "Error",
    NotEnoughFunds = "Not enough funds",
    PaymentMethodUpdateFailure = "Payment method update failure",
    PaymentMethodChanged = "Payment method changed",
    PaymentMethodRemoved = "Payment method removed",
    RewardVoucherApplied = "Reward voucher applied",
    RewardVoucherStarted = "Reward voucher started",
    RewardVoucherEnded = "Reward voucher ended",
}

export interface AddPodcastToUser {
    type: typeof ADD_PODCAST_TO_USER;
    userAccountId: string;
    podcastId: number;
}

export interface AddPodcastToUserSuccess {
    type: typeof ADD_PODCAST_TO_USER_SUCCESS;
    podcastPermission: PodcastPermissionForUser;
}

export interface AddPodcastToUserFailed {
    type: typeof ADD_PODCAST_TO_USER_FAILED;
    error: any;
}

export interface DeletePodcastFromUser {
    type: typeof DELETE_PODCAST_FROM_USER;
    userAccountId: string;
    podcastId: number;
}

export interface DeletePodcastFromUserSuccess {
    type: typeof DELETE_PODCAST_FROM_USER_SUCCESS;
    podcastId: number;
}

export interface DeletePodcastFromUserFailed {
    type: typeof DELETE_PODCAST_FROM_USER_FAILED;
    error: any;
}

export interface UpdatePodcastStatisticsPermission {
    type: typeof UPDATE_PODCAST_STATISTICS_PERMISSION;
    userAccountId: string;
    podcastId: number;
    value: boolean;
}

export interface UpdatePodcastStatisticsPermissionSuccess {
    type: typeof UPDATE_PODCAST_STATISTICS_PERMISSION_SUCCESS;
    podcastId: number;
    value: boolean;
}

export interface UpdatePodcastStatisticsPermissionFailed {
    type: typeof UPDATE_PODCAST_STATISTICS_PERMISSION_FAILED;
    error: any;
}

export interface RefreshSubscriptions {
    type: typeof REFRESH_SUBSCRIPTIONS;
    userAccountId: string;
}

export interface Unmigrate {
    type: typeof UNMIGRATE;
    userAccountId: string;
}

export interface RefundTransaction {
    type: typeof REFUND_TRANSACTION;
    userAccountId: string;
    transactionId: number;
    subscriptionId: number;
}

export interface RefundTransactionSuccess extends ShowSuccessToastAction {
    type: typeof REFUND_TRANSACTION_SUCCESS;
    transactionId: number;
    userAccountId: string;
    subscriptionId: number;
    refundedAmount: number;
    currency: string;
    refundedAt: string;
}

export interface RefundTransactionFailed extends ShowErrorToastAction {
    type: typeof REFUND_TRANSACTION_FAILED;
    userAccountId: string;
    transactionId: number;
    subscriptionId: number;
}

export interface MigrateUser {
    type: typeof MIGRATE_USER;
    masterAccountId: string;
    schibstedUserAccountId: string;
}

export interface MigrateUserSuccess {
    type: typeof MIGRATE_USER_SUCCESS;
}

export interface MigrateUserFailed extends ShowErrorToastAction {
    type: typeof MIGRATE_USER_FAILED;
}

export interface RefreshSubscriptionsSuccess extends ShowSuccessToastAction {
    type: typeof REFRESH_SUBSCRIPTIONS_SUCCESS;
}

export interface RefreshSubscriptionsFailed {
    type: typeof REFRESH_SUBSCRIPTIONS_FAILED;
    error: any;
}

export enum UserRole {
    Admin = "admin",
    Editor = "editor",
    Podcaster = "podcaster",
    Support = "support",
}

export interface GetUsername {
    type: typeof GET_USERNAME;
}

export interface GetUsernameSuccess {
    type: typeof GET_USERNAME_SUCCESS;
    username: any;
}

export interface GetUsernameFailed {
    type: typeof GET_USERNAME_FAILED;
    error: any;
}

export interface GetUserRole {
    type: typeof GET_USER_ROLE;
}

export interface GetUserRoleSuccess {
    type: typeof GET_USER_ROLE_SUCCESS;
    userRole: string;
}

export interface GetUserRoleFailed {
    type: typeof GET_USER_ROLE_FAILED;
    error: any;
}

export interface GetUser {
    type: typeof GET_USER_DETAILS;
    userId: string;
}

export interface GetUserSuccess {
    type: typeof GET_USER_DETAILS_SUCCESS;
    user: any;
    softMigration: boolean;
}

export interface GetUserFailed extends ShowErrorToastAction {
    type: typeof GET_USER_DETAILS_FAILED;
}

export interface DeleteUser {
    type: typeof DELETE_USER;
    userAccountId: string;
}

export interface DeleteUserSuccess extends ShowSuccessToastAction {
    type: typeof DELETE_USER_SUCCESS;
    deletedUserAccountId: string;
}

export interface DeleteUserFailed extends ShowErrorToastAction {
    type: typeof DELETE_USER_FAILED;
    deletedUserAccountId: string;
}

export interface GetUsers {
    type: typeof GET_USERS;
    pageSize: number;
    currentPage: number;
}

export interface GetUsersSuccess {
    type: typeof GET_USERS_SUCCESS;
    users: UsersFetchResponse;
}

export interface GetUsersFailed {
    type: typeof GET_USERS_FAILED;
}

export interface SearchUser {
    type: typeof SEARCH_USER;
    searchQuery: string;
    searchType: string | null;
    context: SearchContext;
}

export const SEARCH_CONTEXT = {
    UsersTable: "usersTable",
    ManualMigration: "manualMigration",
} as const;

export type SearchContext = ObjectValues<typeof SEARCH_CONTEXT>;

export interface SearchUserSuccess {
    type: typeof SEARCH_USER_SUCCESS;
    users: any;
    context: SearchContext;
}

export interface SearchUserFailed {
    type: typeof SEARCH_USER_FAILED;
    context: SearchContext;
}

export interface GetUserQueryTypes {
    type: typeof GET_USER_QUERY_TYPES;
}

export interface GetUserQueryTypesSuccess {
    type: typeof GET_USER_QUERY_TYPES_SUCCESS;
    queryTypes: string[];
}

export interface GetUserQueryTypesFailed {
    type: typeof GET_USER_QUERY_TYPES_FAILED;
}

export interface AddPodcasterPermission {
    type: typeof ADD_PODCASTER_PERMISSION;
    userAccountId: string;
}

export interface AddPodcasterPermissionSuccess {
    type: typeof ADD_PODCASTER_PERMISSION_SUCCESS;
}

export interface AddPodcasterPermissionFailed {
    type: typeof ADD_PODCASTER_PERMISSION_FAILED;
}

export interface DeletePodcasterPermission {
    type: typeof DELETE_PODCASTER_PERMISSION;
    userAccountId: string;
}

export interface DeletePodcasterPermissionSuccess {
    type: typeof DELETE_PODCASTER_PERMISSION_SUCCESS;
}

export interface DeletePodcasterPermissionFailed {
    type: typeof DELETE_PODCASTER_PERMISSION_FAILED;
}

export interface RenewSubscription {
    type: typeof RENEW_SUBSCRIPTION;
}

export interface RenewSubscriptionSuccess {
    type: typeof RENEW_SUBSCRIPTION_SUCCESS;
    renewedSubscription: any;
}

export interface RenewSubscriptionFailed {
    type: typeof RENEW_SUBSCRIPTION_FAILED;
}

export interface CancelSubscription {
    type: typeof CANCEL_SUBSCRIPTION;
    userAccountId: string;
    podcastId?: string;
}

export interface CancelSubscriptionSuccess extends ShowSuccessToastAction {
    type: typeof CANCEL_SUBSCRIPTION_SUCCESS;
    cancelledSubscription: any;
}

export interface CancelSubscriptionFailed extends ShowErrorToastAction {
    type: typeof CANCEL_SUBSCRIPTION_FAILED;
}

export interface ManageUsersState {
    users: UsersFetchResponse | null;
    noUsersFound: boolean;
    hasMoreUsers: boolean;
    userQueryTypes: string[];
    user: UserDetails | null;
    username?: string;
    userRoleChecked: boolean;
    userRole: UserRole | undefined;
    isDeletingUser: boolean;
    userUpdated: boolean;
    userNotFound: boolean;
    [SEARCH_CONTEXT.UsersTable]: { searchedUsers?: UsersFetchResponse; isLoading?: boolean } | null;
    [SEARCH_CONTEXT.ManualMigration]: {
        searchedUsers?: UsersFetchResponse;
        isLoading?: boolean;
        isManuallyMigrating?: boolean;
    } | null;
    manualMigrationStatus: "idle" | "migrating" | "success" | "failed";
    softMigrationStatus: "idle" | "loading" | "success" | "failed";
    isRedeemingVoucher: boolean;
}

export interface User {
    accountEnabled: boolean;
    accountType: string;
    email: string;
    externalAccount: boolean;
    name: string;
    userAccountId: string;
}

export type Account = {
    userAccountId: string;
    name: string;
    email: string;
    accountType: string;
    loginType: LoginType;
    externalAccount: boolean;
    migrated: boolean;
    originatingFromSchibsted: boolean;
    createdAt: string;
    subscriptions: any[];
    languageId: number;
    regionId: number;
    preferredLanguage: number;
    preferredRegion: number;
    updatedAt: string;
    userId: string;
};

export type UsersFetchResponse = {
    userAccounts?: Account[];
};

export type AccountWithUserId = {
    userId: string;
    account: Account;
};

export interface UserDetails {
    accounts: UserAccount[];
    id: string;
    prefferedLanguage: number;
    prefferedRegion: number;
    segments: UserSegment[];
    softMigrationEnabled: boolean;
    isUserEligibleForTrial: IsUserEligibleForTrial;
}

export interface UserAccount {
    migrated: boolean;
    originatingFromSchibsted: boolean;
    accountType: string;
    loginType: LoginType;
    email: string;
    externalAccount: boolean;
    name: string;
    userAccountId: string;
    subscriptions: Subscription[];
    paymentTransactions: PaymentTransaction[];
    podcasts: PodcastPermissionForUser[];
    voucherRedemptionHistory: VoucherRedemptionHistory[];
}

export interface UserSegment {
    userSegmentType: UserSegmentType;
    createdAt: string;
    validUntil: string;
}

export interface IsUserEligibleForTrial {
    userIsEligibleForFreeTrial: boolean;
    reason: string;
}

export interface VoucherRedemptionHistory {
    voucherCampaignId: number;
    voucherCampaignName: string;
    voucherCode: string;
    redeemedAt: string;
}

export const USER_SEGMENT_TYPE = {
    Student: 0,
} as const;

export type UserSegmentType = ObjectValues<typeof USER_SEGMENT_TYPE>;

type SubscriptionPlan = {
    currency: string;
    name: string;
    packageId: number;
    priceDecimal: number;
    nextPlanProductId: string;
};

export interface Subscription {
    subscriptionId: number;
    discriminator: string;
    startDate: Date;
    expirationDate: Date | null;
    bundlerPartnerName?: string;
    rewardMonthCredit?: number;
    lastOrderId?: string;
    podcastId?: string;
    subscriptionEvents: SubscriptionEvent[] | null;
    paymentTransactions?: PaymentTransaction[] | null;
    subscriptionType: SubscriptionType;
    subscriptionState: SubscriptionStatus;
    subscriptionPlatform: SubscriptionPlatform;
    subscriptionPlan: SubscriptionPlan;
}

export interface SubscriptionTransaction {
    timeStamp: Date;
    amount: number;
    retries: number;
    status: string;
}

export interface PodcastPermissionForUser {
    podcastId: number;
    userAccountId: string;
    podcastTitle: string;
    manageEpisodes: boolean;
    readStatistics: boolean;
}

export interface SubscriptionEvent {
    date: string;
    subscriptionType: string;
}

export interface PaymentTransaction {
    paymentTransactionId: number;
    state: string;
    pspReference: string;
    amount: number;
    currency: string;
    createdAt: string;
    capturedAt: string;
    refundedAt: string;
    refundedAmount?: number;
    isRefundingTransaction: boolean;
}

export interface LoginWithSchibstedFailed {
    type: typeof LOGIN_WITH_SCHIBSTED_FAILED;
    errorResponse: string;
}

export interface LoginWithSchibsted {
    type: typeof LOGIN_WITH_SCHIBSTED;
    region: RegionLogin;
}

export interface LoginWithSchibstedSuccess {
    type: typeof LOGIN_WITH_SCHIBSTED_SUCCESS;
    username?: string;
}

export interface GetSchibstedToken {
    type: typeof GET_SCHIBSTED_TOKEN;
    code: string;
    codeVerifier: string;
    region: RegionLogin;
}

export interface RefreshSchibstedToken {
    type: typeof REFRESH_SCHIBSTED_TOKEN;
    region: RegionLogin;
}

export interface GetSchibstedTokenSuccess {
    type: typeof GET_SCHIBSTED_TOKEN_SUCCESS;
    token: string;
}

export interface GetSchibstedTokenFailed {
    type: typeof GET_SCHIBSTED_TOKEN_FAILED;
    errorResponse?: string;
}

export interface FetchPaymentTransactions {
    type: typeof FETCH_PAYMENT_TRANSACTIONS;
    subscriptionId: number;
}

export interface FetchPaymentTransactionsSuccess {
    type: typeof FETCH_PAYMENT_TRANSACTIONS_SUCCESS;
    data: PaymentTransaction[];
    subscriptionId: number;
}

export interface FetchPaymentTransactionsFailed {
    type: typeof FETCH_PAYMENT_TRANSACTIONS_FAILED;
}

export interface FetchSubscriptionEvents {
    type: typeof FETCH_SUBSCRIPTION_EVENTS;
    subscription: Subscription;
    userAccountId: string;
}

export interface FetchSubscriptionEventsSuccess {
    type: typeof FETCH_SUBSCRIPTION_EVENTS_SUCCESS;
    data: SubscriptionEvent[];
    userAccountId: string;
    subscriptionId: number;
}

export interface FetchSubscriptionEventsFailed {
    type: typeof FETCH_SUBSCRIPTION_EVENTS_FAILED;
}

export interface StartFetchSubscriptionEventsSequence {
    type: typeof START_FETCH_SUBSCRIPTION_EVENTS_SEQUENCE;
    subscriptions: Subscription[];
    userAccountId: string;
}

export interface StartFetchPaymentTransactionsSequence {
    type: typeof START_FETCH_PAYMENT_TRANSACTIONS_SEQUENCE;
    subscriptionIds: number[];
}

export interface ToggleSoftMigration {
    type: typeof TOGGLE_SOFT_MIGRATION;
    userId: string;
    softMigrationEnabled: boolean;
}

export interface ToggleSoftMigrationSuccess {
    type: typeof TOGGLE_SOFT_MIGRATION_SUCCESS;
    softMigrationEnabled: boolean;
}

export interface ToggleSoftMigrationFailed extends ShowErrorToastAction {
    type: typeof TOGGLE_SOFT_MIGRATION_FAILED;
}

export interface GetSoftMigrationStatus {
    type: typeof GET_SOFT_MIGRATION_STATUS;
    userId: string;
}

export interface GetSoftMigrationStatusSuccess {
    type: typeof GET_SOFT_MIGRATION_STATUS_SUCCESS;
    softMigrationEnabled: boolean;
}

export interface GetSoftMigrationStatusFailed extends ShowErrorToastAction {
    type: typeof GET_SOFT_MIGRATION_STATUS_FAILED;
}

export interface AddFreeSubscriptionValues {
    type: typeof ADD_FREE_SUBSCRIPTION;
    userId: string;
    voucherCode: string;
}

export interface AddFreeSubscription {
    type: typeof ADD_FREE_SUBSCRIPTION;
    userId: string;
    voucherCode: string;
}

export interface AddFreeSubscriptionSuccess extends ShowSuccessToastAction {
    type: typeof ADD_FREE_SUBSCRIPTION_SUCCESS;
}

export interface AddFreeSubscriptionFailed extends ShowErrorToastAction {
    type: typeof ADD_FREE_SUBSCRIPTION_FAILED;
}

export type ManageUsersActionTypes =
    | GetUsername
    | GetUsernameSuccess
    | GetUsernameFailed
    | GetUserRole
    | GetUserRoleSuccess
    | GetUserRoleFailed
    | GetUser
    | GetUserSuccess
    | GetUserFailed
    | DeleteUser
    | DeleteUserSuccess
    | DeleteUserFailed
    | GetUsers
    | GetUsersSuccess
    | GetUsersFailed
    | SearchUser
    | SearchUserSuccess
    | SearchUserFailed
    | GetUserQueryTypes
    | GetUserQueryTypesSuccess
    | GetUserQueryTypesFailed
    | CancelSubscription
    | CancelSubscriptionSuccess
    | CancelSubscriptionFailed
    | RenewSubscription
    | RenewSubscriptionSuccess
    | RenewSubscriptionFailed
    | AddPodcasterPermissionSuccess
    | DeletePodcasterPermissionSuccess
    | UpdatePodcastStatisticsPermissionSuccess
    | AddPodcastToUserSuccess
    | DeletePodcastFromUserSuccess
    | RefreshSubscriptionsSuccess
    | RefreshSubscriptions
    | LoginWithSchibsted
    | LoginWithSchibstedFailed
    | LoginWithSchibstedSuccess
    | GetSchibstedToken
    | GetSchibstedTokenFailed
    | GetSchibstedTokenSuccess
    | FetchSubscriptionEvents
    | FetchSubscriptionEventsSuccess
    | FetchSubscriptionEventsFailed
    | FetchPaymentTransactions
    | FetchPaymentTransactionsSuccess
    | FetchPaymentTransactionsFailed
    | MigrateUser
    | MigrateUserSuccess
    | MigrateUserFailed
    | ToggleSoftMigration
    | ToggleSoftMigrationSuccess
    | ToggleSoftMigrationFailed
    | GetSoftMigrationStatus
    | GetSoftMigrationStatusSuccess
    | GetSoftMigrationStatusFailed
    | AddFreeSubscription
    | AddFreeSubscriptionSuccess
    | AddFreeSubscriptionFailed
    | AddFreeSubscriptionValues
    | RefundTransaction
    | RefundTransactionSuccess
    | RefundTransactionFailed;
