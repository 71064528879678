import { useState, useRef, ReactElement, ComponentType } from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    ChakraProps,
    Tooltip,
    Box,
} from "@chakra-ui/react";
import { colors } from "../constants";
import { PodmeColor } from "../redux/types";

interface Props {
    buttonLabel: string;
    confirmBtnLabel?: string;
    title: string;
    text: string;
    deleteAction: any;
    buttonVariant: "link" | "solid" | "outline" | "ghost" | "unstyled" | undefined;
    buttonVariantColor: string;
    buttonSize: "sm" | "xs" | "md" | "lg" | undefined;
    icon?: ReactElement<any, any>;
    renderAs?: ComponentType<{ onClick: () => void }>;
}

export default function DeleteDialog({
    buttonLabel,
    confirmBtnLabel = "Confirm",
    title,
    text,
    deleteAction,
    buttonVariant = "solid",
    buttonVariantColor = "red",
    buttonSize = "sm",
    renderAs: Component,
    icon,
    ...rest
}: Props & ChakraProps) {
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const cancelRef = useRef<HTMLButtonElement>(null);

    return (
        <Box {...rest}>
            {Component ? (
                <Component onClick={() => setIsOpen(true)}>{buttonLabel}</Component>
            ) : icon ? (
                <Tooltip placement="top" label="Delete account" bg="gray.300" color="black">
                    <Button
                        onClick={() => setIsOpen(true)}
                        size={buttonSize}
                        variant={buttonVariant}
                        colorScheme={buttonVariantColor}
                        backgroundColor={colors.red}
                        borderRadius="0.75rem"
                        height="auto"
                        padding="0.75rem 1.5rem"
                        _hover={{
                            bgColor: PodmeColor.Silver + "50",
                        }}
                    >
                        {icon}
                    </Button>
                </Tooltip>
            ) : (
                <Button
                    onClick={() => setIsOpen(true)}
                    size={buttonSize}
                    variant={buttonVariant}
                    colorScheme={buttonVariantColor}
                    backgroundColor={colors.red}
                    borderRadius="0.75rem"
                    height="auto"
                    padding="0.75rem 1.5rem"
                >
                    {buttonLabel}
                    {icon}
                </Button>
            )}
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {title}
                    </AlertDialogHeader>

                    <AlertDialogBody>{text}</AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            colorScheme="red"
                            onClick={() => {
                                deleteAction();
                                onClose();
                            }}
                            ml={3}
                        >
                            {confirmBtnLabel}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </Box>
    );
}
