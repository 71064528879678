import React from "react";
import { Heading, Text, Box, Flex } from "@chakra-ui/react";
import { useSpring, animated } from "react-spring";

import GraphContainer from "../components/GraphContainer";

function SchibstedSubscriptionsDisplay({ displayData, title }) {
    const { total } = displayData;

    return (
        <GraphContainer>
            <Heading as='h2' size='lg' mb={4}>
                {title}
            </Heading>

            <Flex justifyContent='space-between' padding={[0, null, "1rem 2rem"]}>
                <Box>
                    <Text textAlign='center' fontSize={["14px", null, "16px"]}>
                        Total
                    </Text>
                    <Counter number={total} />
                </Box>
            </Flex>
        </GraphContainer>
    );
}

function Counter({ number, color = "black" }) {
    const spring = useSpring({
        from: { val: 0 },
        to: { val: number ? number : 0 },
    });
    return (
        <animated.div style={{ fontSize: "2em", color: color, textAlign: "center" }}>
            {spring.val.interpolate((val) => Math.floor(val))}
        </animated.div>
    );
}

export default SchibstedSubscriptionsDisplay;
