import { Action } from "redux";
import { ObjectValues } from "../ManageUsers/redux/types";

export const CHECK_AUTH_STATUS = "CHECK_AUTH_STATUS";
export const USER_IS_LOGGED_IN = "USER_IS_LOGGED_IN";
export const USER_IS_LOGGED_IN_WITH_SCHIBSTED = "USER_IS_LOGGED_IN_WITH_SCHIBSTED";
export const USER_IS_NOT_LOGGED_IN = "USER_IS_NOT_LOGGED_IN";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
export const LOGOUT_USER = "LOGOUT_USER";

export const INIT_REGION = "global/INIT_REGION";
export const CHANGE_REGION = "global/CHANGE_REGION";
export const CHANGE_REGION_SUCCESS = "global/CHANGE_REGION_SUCCESS";
export const CHANGE_REGION_FAILED = "global/CHANGE_REGION_FAILED";

export const CHANGE_ACCOUNT_REGION = "global/CHANGE_ACCOUNT_REGION";
export const CHANGE_ACCOUNT_REGION_SUCCESS = "global/CHANGE_ACCOUNT_REGION_SUCCESS";
export const CHANGE_ACCOUNT_REGION_FAILED = "global/CHANGE_ACCOUNT_REGION_FAILED";

export interface ErrorAction {
    type: string;
    message: string;
    status: number;
}

export interface ErrorState {
    showErrorToast: boolean;
    errorMessage: string | null;
}

export enum Region {
    unspecified = 0,
    sweden = 1,
    norway = 2,
    finland = 3,
}

export enum RegionLogin {
    Sweden = "se",
    Norway = "no",
    Finland = "fi",
}

export const LOGIN_TYPE = {
    PodMeAccount: 0,
    Apple: 1,
    Google: 2,
    Facebook: 3,
    SchibstedSE: 4,
    SchibstedFI: 5,
    SchibstedNO: 6,
} as const;

export type LoginType = ObjectValues<typeof LOGIN_TYPE>;

export const LOGIN_TYPE_LABELS = {
    [LOGIN_TYPE.PodMeAccount]: "Podme Account",
    [LOGIN_TYPE.Apple]: "Apple",
    [LOGIN_TYPE.Google]: "Google",
    [LOGIN_TYPE.Facebook]: "Facebook",
    [LOGIN_TYPE.SchibstedSE]: "Schibsted SE",
    [LOGIN_TYPE.SchibstedFI]: "Schibsted FI",
    [LOGIN_TYPE.SchibstedNO]: "Schibsted NO",
} as const;

export class ApiError {
    public message: string = "";
    public errorCode: number = 0;
    public status: number = 0;

    constructor(errorResponse: any) {
        if (errorResponse.status) {
            this.status = errorResponse.status;
        }

        if (errorResponse.data && errorResponse.data.errors) {
            const errorKeys = Object.keys(errorResponse.data.errors);
            this.message = errorKeys.length > 0 ? errorResponse.data.errors[errorKeys[0]][0] : "";
        }
    }
}

export interface Podcast {
    id: number;
    title: string;
    slug: string;
    author: any;
    description: string;
    categories: any[];
    createdAt: Date;
    updateAt: Date;
    imageUrl: string;
    smallImageUrl: string;
    mediumImageUrl: string;
    largeImageUrl: string;
    regions: Region[];
    uploaderId: string;
    link: string;
    isPremium: boolean;
    isPublished: boolean;
    isArchived: boolean;
    isPodmeOriginal: boolean;
    isManagedBySchibsted: boolean;
}

export enum UserRole {
    Admin = "admin",
    Podcaster = "podcaster",
    Support = "support",
    Editor = "editor",
}

export enum PodmeColor {
    LightRed = "#F17575",
    Red = "#E76A42",
    Warning = "#FFC83C",
    Success = "#0eb044",
    MaintenanceYellow = "#fffc75",
    Transparent = "transparent",
    White = "#fbfbfb",
    LicoriceBlue = "#28303D",
    Silver = "#C0C0C0",
    SoftWhite = "#F5F5F0",
    BlackPearl = "#15191F",
    Cinder = "#21252A",
    Cyan = "#75EAF1",
    Sand = "#EFDEC6",
    LightBlue = "#E3F3F4",
    Woodsmoke = " #0E1014",
}

export type SagaReturnType<T> = Generator<any, T, any>;

export interface ShowSuccessToastAction extends Action {
    showToastSuccessMessage: boolean;
    message: string;
}

export interface ShowErrorToastAction extends Action {
    showToastErrorMessage: boolean;
    message: string;
    status?: number;
}

export default {
    CHECK_AUTH_STATUS,
    USER_IS_LOGGED_IN,
    USER_IS_LOGGED_IN_WITH_SCHIBSTED,
    USER_IS_NOT_LOGGED_IN,
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    INIT_REGION,
    CHANGE_REGION,
    CHANGE_REGION_SUCCESS,
    CHANGE_REGION_FAILED,
    CHANGE_ACCOUNT_REGION,
    CHANGE_ACCOUNT_REGION_SUCCESS,
    CHANGE_ACCOUNT_REGION_FAILED,
};
