import { PromoItem, PromoItemProps } from './promoItem';
import { Header, HorizontalList } from './commonStyles';

interface EpisodePromoCarouselProps {
    data: {
        title: string;
        promotedEpisodes: [PromoItemProps["data"]];
        isToplist: boolean;
    }
}

export default function EpisodePromoCarousel({ data }: EpisodePromoCarouselProps) {
    return (
        <div>
            <Header>
                {data.title}
            </Header>
            <HorizontalList>
                {data.promotedEpisodes.map((e, i) => <PromoItem key={i} data={{ ...e, isToplist: data.isToplist, index: i }} />)}
            </HorizontalList>
        </div>
    )
}