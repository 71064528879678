import { EpisodeImage, EpisodeImageProps } from "./episodeImage";
import { Header, HorizontalList } from './commonStyles';
import styled from "styled-components";

const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 10px;
`;

interface EpisodeCarouselProps {
    data: {
        title: string;
        destination: string;
        episodes: [EpisodeImageProps["data"]];
    }
}

export default function EpisodeCarousel({ data }: EpisodeCarouselProps) {
    return (
        <div>
            <TopContainer>
                <Header>
                    {data.title}
                </Header>
                {data.destination && <button onClick={() => alert(data.destination)}>{'View all >'}</button>}
            </TopContainer>
            <HorizontalList>
                {data.episodes.map((e, i) => <EpisodeImage key={i} data={e} />)}
            </HorizontalList>
        </div>
    )
}