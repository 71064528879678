// colors
const aquablue = "#5FCDD0";
const podmegreen = "#78F5CE";
const darkblue = "#121821";
const lightblue = "#779CB2";
const graphite = "#E1E9F5";
const lightGray = "#F8F8F8";
const mediumBlue = "#1C2430";
const normalBlue = "#26354A";
const blackPearl = "#15191F";
const softWhite = "#F5F5F5";
const cyan = "#75EAF1";
const red = "#b31926";

const backgroundColor = "#364B6D";

const white = "#FFFFFF";
const black = "#000000";

// Media queries
export const XL_SCREEN = "only screen and (min-width: 2000px)";
export const LARGE_SCREEN = "only screen and (min-width: 1025px)";
export const SMALL_SCREEN = "only screen and (max-width: 479px)";
export const MEDIUM_SCREEN = "only screen and (min-width: 480px) and (max-width: 1024px)";
export const MEDIUM_DOWN = "only screen and (max-width: 1024px)";

export const colors = {
    aquablue,
    podmegreen,
    normalBlue,
    lightblue,
    graphite,
    mediumBlue,
    darkblue,
    lightGray,
    backgroundColor,
    white,
    black,
    blackPearl,
    softWhite,
    cyan,
    red,
};
