import { Box, FormControl, FormErrorMessage, FormLabel, Select } from "@chakra-ui/react";
import RoleTooltip from "./RoleTooltip";
import { FormikErrors, FormikTouched } from "formik";
import { ChangeEventHandler } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { UserRole } from "../../ManageUsers/redux/types";
import { capitalizeFirstLetter } from "../../utils";

interface Props {
    userGroups: string[] | null;
    errors: FormikErrors<{
        email: string;
        userGroup: string;
        validUntil: Date;
    }>;
    touched: FormikTouched<{
        email: string;
        userGroup: string;
        validUntil: Date;
    }>;
    handleChange: ChangeEventHandler<HTMLSelectElement>;
    values: {
        email: string;
        userGroup: string;
        validUntil: Date;
    };
}

export default function RoleSelect({ userGroups, errors, touched, handleChange, values }: Props) {
    const manageUsersState = (state: RootState) => state.manageUsers;
    const { userRole } = useSelector(manageUsersState);

    const isEmailProvided = (): boolean => (!values.email || values.email === "" || errors.email ? false : true);

    return (
        <Box paddingBottom={6}>
            <FormControl isRequired isInvalid={Boolean(errors.userGroup)} id="userGroup">
                <Box display={"flex"} margin={"0.5em 0"}>
                    <FormLabel margin={0} htmlFor="userGroup">
                        Role
                    </FormLabel>
                    <RoleTooltip />
                </Box>
                <Select
                    disabled={!isEmailProvided()}
                    isRequired
                    isInvalid={!!errors.userGroup && touched.userGroup}
                    id="userGroup"
                    name="userGroup"
                    onChange={handleChange}
                    value={values.userGroup}
                >
                    <option key={""} value="">
                        Select user role
                    </option>
                    {userRole === UserRole.Admin ? (
                        <>
                            {userGroups?.map((userGroup) => (
                                <option value={userGroup} key={userGroup}>
                                    {capitalizeFirstLetter(userGroup)}
                                </option>
                            ))}
                        </>
                    ) : (
                        <option value="Podcaster" key="Podcaster">
                            Podcaster
                        </option>
                    )}
                </Select>
                <FormErrorMessage>{errors.userGroup}</FormErrorMessage>
            </FormControl>
        </Box>
    );
}
