import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Grid } from "@chakra-ui/react";

import OptionsMenu from "./OptionsMenu";
import { actions } from "../redux";
import { Region } from "../../redux/types";

const statisticsState = (state) => state.statistics;

function SubscriptionsFilter({ podcasts }) {
    const dispatch = useDispatch();
    const { filter } = useSelector(statisticsState);

    useEffect(() => {
        dispatch(actions.updateFilter({ ...filter, podcastId: "", subscriptionType: "package", platform: 0 }));
    }, []);

    useEffect(() => {
        if (filter.region === Region.norway) {
            dispatch(actions.updateFilter({ ...filter, podcastId: "", subscriptionType: "package" }));
        }
    }, [filter.region]);

    function setChecked(evt) {
        dispatch(actions.updateFilter({ ...filter, platform: evt.target.checked ? evt.target.value : null }));

        const allCheckboxes = document.querySelectorAll("input[name='platform']");

        allCheckboxes.forEach((item) => {
            if (item !== evt.target) {
                item.checked = false;
            }
        });
    }

    return (
        <FilterContainer>
            <Grid templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]} gap={2}>
                <FilterGroup>
                    <ToggleButton>
                        <input
                            type='radio'
                            id='package'
                            name='subscriptionType'
                            value='package'
                            checked={filter.subscriptionType === "package"}
                            onChange={(evt) => dispatch(actions.updateFilter({ ...filter, subscriptionType: evt.target.value }))}
                        />
                        <label htmlFor='package'>Package</label>
                    </ToggleButton>
                    {filter.region === Region.sweden && (
                        <ToggleButton>
                            <input
                                type='radio'
                                id='single'
                                name='subscriptionType'
                                value='single'
                                checked={filter.subscriptionType === "single"}
                                onChange={(evt) => dispatch(actions.updateFilter({ ...filter, subscriptionType: evt.target.value }))}
                            />
                            <label htmlFor='single'>Single</label>
                        </ToggleButton>
                    )}
                </FilterGroup>
                <FilterGroup>
                    <ToggleButton>
                        <input type='checkbox' id='web' name='platform' value={0} onChange={setChecked} />
                        <label htmlFor='web'>Web</label>
                    </ToggleButton>
                    <ToggleButton>
                        <input type='checkbox' id='ios' name='platform' value={1} onChange={setChecked} />
                        <label htmlFor='ios'>iTunes</label>
                    </ToggleButton>
                    <ToggleButton>
                        <input type='checkbox' id='google' name='platform' value={2} onChange={setChecked} />
                        <label htmlFor='google'>Google</label>
                    </ToggleButton>
                </FilterGroup>
                {filter.subscriptionType === "single" && (
                    <FilterGroup>
                        <OptionsMenu
                            handleChange={(evt) => dispatch(actions.updateFilter({ ...filter, podcastId: evt.target.value }))}
                            value={filter.podcastId}
                            data={podcasts || []}
                        />
                    </FilterGroup>
                )}
            </Grid>
        </FilterContainer>
    );
}

const FilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 1rem;
    border-radius: 15px;
    width: 100%;
    margin-bottom: 2rem;
`;

const ToggleButton = styled.div`
    input {
        display: none;
    }

    input + label {
        padding: 0.3rem 1rem;
        border: 1px solid gray;
        color: gray;
        border-radius: 20px;
        cursor: pointer;
        display: inline-block;
        height: 100%;
    }

    input:checked + label {
        background: #071930;
        color: white;
        border: none;
        border-radius: 20px;
    }
`;

const FilterGroup = styled.div`
    display: flex;
    margin-right: 2rem;

    ${ToggleButton} {
        margin-right: 0.2rem;
    }

    ${ToggleButton}:last-child {
        margin: 0;
    }
`;

export default SubscriptionsFilter;
