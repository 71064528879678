import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import Login from "./login/Login";
import LogoReveal from "./logo-reveal/LogoReveal";
import { WelcomeContainer, LoginContainer } from "./styled";
import { UserRole } from "../../redux/types";

export default function Welcome({ isAuthenticated, reduxStore, role }) {
    if (isAuthenticated) {
        return (
            <Redirect
                to={{
                    pathname: role === UserRole.Support ? "/users" : "/podcasts",
                }}
            />
        );
    }

    return (
        <WelcomeContainer>
            <LoginContainer>
                <Login reduxStore={reduxStore} />
            </LoginContainer>
            <LogoReveal />
        </WelcomeContainer>
    );
}

Welcome.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
};
