import { Region, ShowErrorToastAction, ShowSuccessToastAction } from "../../redux/types";

// export const GET_ACTIVE_VOUCHERCAMPAIGNS = "voucherCampaigns/GET_ACTIVE_VOUCHERCAMPAIGNS";
// export const GET_ACTIVE_VOUCHERCAMPAIGNS_SUCCESS = "voucherCampaigns/GET_ACTIVE_VOUCHERCAMPAIGNS_SUCCESS";
// export const GET_ACTIVE_VOUCHERCAMPAIGNS_FAILED = "voucherCampaigns/GET_ACTIVE_VOUCHERCAMPAIGNS_FAILED";

// export const GET_UPCOMING_VOUCHERCAMPAIGNS = "voucherCampaigns/GET_UPCOMING_VOUCHERCAMPAIGNS";
// export const GET_UPCOMING_VOUCHERCAMPAIGNS_SUCCESS = "voucherCampaigns/GET_UPCOMING_VOUCHERCAMPAIGNS_SUCCESS";
// export const GET_UPCOMING_VOUCHERCAMPAIGNS_FAILED = "voucherCampaigns/GET_UPCOMING_VOUCHERCAMPAIGNS_FAILED";

// export const CREATE_VOUCHERCAMPAIGN = "voucherCampaigns/CREATE_VOUCHERCAMPAIGN";
// export const CREATE_VOUCHERCAMPAIGN_SUCCESS = "voucherCampaigns/CREATE_VOUCHERCAMPAIGN_SUCCESS";
// export const CREATE_VOUCHERCAMPAIGN_FAILED = "voucherCampaigns/CREATE_VOUCHERCAMPAIGN_FAILED";

// export const UPDATE_VOUCHERCAMPAIGN = "voucherCampaigns/UPDATE_VOUCHERCAMPAIGN";
// export const UPDATE_VOUCHERCAMPAIGN_SUCCESS = "voucherCampaigns/UPDATE_VOUCHERCAMPAIGN_SUCCESS";
// export const UPDATE_VOUCHERCAMPAIGN_FAILED = "voucherCampaigns/UPDATE_VOUCHERCAMPAIGN_FAILED";

// export const GET_OVERVIEW_CONTENT = "voucherCampaigns/GET_OVERVIEW_CONTENT";
// export const GET_OVERVIEW_CONTENT_SUCCESS = "voucherCampaigns/GET_OVERVIEW_CONTENT_SUCCESS";
// export const GET_OVERVIEW_CONTENT_FAILED = "voucherCampaigns/GET_OVERVIEW_CONTENT_FAILED";

// export const GET_VOUCHERCAMPAIGN_HISTORY = "voucherCampaigns/GET_VOUCHERCAMPAIGN_HISTORY";
// export const GET_VOUCHERCAMPAIGN_HISTORY_SUCCESS = "voucherCampaigns/GET_VOUCHERCAMPAIGN_HISTORY_SUCCESS";
// export const GET_VOUCHERCAMPAIGN_HISTORY_FAILED = "voucherCampaigns/GET_VOUCHERCAMPAIGN_HISTORY_FAILED";

// export const ARCHIVE_VOUCHERCAMPAIGN = "voucherCampaigns/ARCHIVE_VOUCHERCAMPAIGN";
// export const ARCHIVE_VOUCHERCAMPAIGN_SUCCESS = "voucherCampaigns/ARCHIVE_VOUCHERCAMPAIGN_SUCCESS";
// export const ARCHIVE_VOUCHERCAMPAIGN_FAILED = "voucherCampaigns/ARCHIVE_VOUCHERCAMPAIGN_FAILED";

// export const GET_VOUCHERCAMPAIGN_DISCOUNT_TYPES = "voucherCampaigns/GET_VOUCHERCAMPAIGN_DISCOUNT_TYPES";
// export const GET_VOUCHERCAMPAIGN_DISCOUNT_TYPES_SUCCESS = "voucherCampaigns/GET_VOUCHERCAMPAIGN_DISCOUNT_TYPES_SUCCESS";
// export const GET_VOUCHERCAMPAIGN_DISCOUNT_TYPES_FAILED = "voucherCampaigns/GET_VOUCHERCAMPAIGN_DISCOUNT_TYPES_FAILED";

// export const LIST_VOUCHERCAMPAIGNVOUCHERS = "voucherCampaigns/LIST_VOUCHERCAMPAIGNVOUCHERS";
// export const LIST_VOUCHERCAMPAIGNVOUCHERS_SUCCESS = "voucherCampaigns/LIST_VOUCHERCAMPAIGNVOUCHERS_SUCCESS";
// export const LIST_VOUCHERCAMPAIGNVOUCHERS_FAILED = "voucherCampaigns/LIST_VOUCHERCAMPAIGNVOUCHERS_FAILED";

export interface VoucherCampaign {
    id: number;
    type: number;
    name: string;
    discountTypeId: number;
    validFrom: string;
    validUntil: string;
    redemptionLimit?: number | undefined;
    redemptionCount: number;
    isArchived: boolean;
    voucherCode?: string;
    monthCredit: number | undefined;
    churnEligibilityInDays?: number | undefined;
}

export interface VoucherCampaignDiscountType {
    id: number;
    name: string;
    regionId: number;
}

export enum VoucherCampaignType {
    Generic = 0,
    Unique = 1,
    Reward = 2,
}

export interface VoucherCampaignTypeName {
    type: VoucherCampaignType;
    name: string;
}

export interface CreateVoucherCampaignFormValues {
    region: Region;
    voucherCampaignType: VoucherCampaignType;
    voucherCampaignName: string;
    voucherCode: string;
    validFrom: Date;
    validUntil: Date;
    discountTypeId: number;
    redemptionLimit: number | undefined;
    monthCredit: number | undefined;
    churnEligibilityInDays: number | undefined;
}

export interface UpdateVoucherCampaignFormValues {
    voucherCampaignType: VoucherCampaignType;
    id: number;
    validUntil: Date;
    redemptionLimit: number | undefined;
    monthCredit: number | undefined;
}

export interface ArchiveVoucherCampaignFormValues {
    id: number;
}

export interface VoucherData {
    voucherType: VoucherCampaignType;
    voucherCode: string;
    voucherCampaignName: string;
    validFrom: string;
    validUntil: string;
}

export interface UniqueVoucherData extends VoucherData {
    discountTypeName: string;
    hasBeenRedeemed: boolean;
    redeemedByUserId?: string;
    redeemedAt?: string;
    churnEligibilityInDays?: number;
}

export interface RewardVoucherData extends VoucherData {
    hasBeenRedeemed: boolean;
    redeemedByUserId?: string;
    redeemedAt?: string;
    monthCredit: number;
}

export interface GenericVoucherData extends VoucherData {
    discountTypeName: string;
    redemptionCount: number;
    churnEligibilityInDays?: number;
}

export interface GetOverviewContent {
    type: "GET_OVERVIEW_CONTENT";
    region?: Region;
}

export interface GetOverviewContentSuccess {
    type: "GET_OVERVIEW_CONTENT_SUCCESS";
    activeVoucherCampaigns: { voucherCampaignName: string; redemptionCount: number }[] | null;
    topVoucherCampaigns: { voucherCampaignName: string; redemptionCount: number }[] | null;
}

export interface GetOverviewContentFailed extends ShowErrorToastAction {
    type: "GET_OVERVIEW_CONTENT_FAILED";
}
export interface GetActiveVoucherCampaigns {
    type: "GET_ACTIVE_VOUCHERCAMPAIGNS";
    page: number;
    pageSize: number;
    region?: Region;
}

export interface GetActiveVoucherCampaignsSuccess {
    type: "GET_ACTIVE_VOUCHERCAMPAIGNS_SUCCESS";
    activeVoucherCampaignsResult: PagedResult;
    upcomingVoucherCampaignsResult: PagedResult;
}

export interface GetActiveVoucherCampaignsFailed extends ShowErrorToastAction {
    type: "GET_ACTIVE_VOUCHERCAMPAIGNS_FAILED";
}

export interface CreateVoucherCampaign {
    type: "CREATE_VOUCHERCAMPAIGN";
    values: {
        region?: Region;
        voucherCampaignType: VoucherCampaignType;
        voucherCampaignName: string;
        validFrom: Date;
        validUntil: Date;
        discountTypeId?: number;
        voucherCode?: string;
        redemptionLimit?: number;
        monthCredit?: number;
        churnEligibilityInDays?: number;
    };
}

export interface CreateVoucherCampaignSuccess extends ShowSuccessToastAction {
    type: "CREATE_VOUCHERCAMPAIGN_SUCCESS";
    data: VoucherCampaign;
}

export interface CreateVoucherCampaignFailed extends ShowErrorToastAction {
    type: "CREATE_VOUCHERCAMPAIGN_FAILED";
}

export interface UpdateVoucherCampaign {
    type: "UPDATE_VOUCHERCAMPAIGN";
    values: {
        id: number;
        voucherCampaignType: VoucherCampaignType;
        validUntil: Date;
        redemptionLimit?: number;
        monthCredit?: number;
    };
}

export interface UpdateVoucherCampaignSuccess extends ShowSuccessToastAction {
    type: "UPDATE_VOUCHERCAMPAIGN_SUCCESS";
}

export interface UpdateVoucherCampaignFailed extends ShowErrorToastAction {
    type: "UPDATE_VOUCHERCAMPAIGN_FAILED";
}

export interface GetVoucherCampaignHistory {
    type: "GET_VOUCHERCAMPAIGN_HISTORY";
    page: number;
    pageSize: number;
    region?: Region;
}

export interface GetVoucherCampaignHistorySuccess {
    type: "GET_VOUCHERCAMPAIGN_HISTORY_SUCCESS";
    data: any;
}

export interface GetVoucherCampaignHistoryFailed extends ShowErrorToastAction {
    type: "GET_VOUCHERCAMPAIGN_HISTORY_FAILED";
}

export interface ArchiveVoucherCampaign {
    type: "ARCHIVE_VOUCHERCAMPAIGN";
    values: {
        id: number;
    };
}

export interface ArchiveVoucherCampaignSuccess extends ShowSuccessToastAction {
    type: "ARCHIVE_VOUCHERCAMPAIGN_SUCCESS";
}

export interface ArchiveVoucherCampaignFailed extends ShowErrorToastAction {
    type: "ARCHIVE_VOUCHERCAMPAIGN_FAILED";
}

export interface GetVoucherCampaignDiscountTypes {
    type: "GET_VOUCHERCAMPAIGN_DISCOUNT_TYPES";
}

export interface GetVoucherCampaignDiscountTypesSuccess {
    type: "GET_VOUCHERCAMPAIGN_DISCOUNT_TYPES_SUCCESS";
    discountTypes: VoucherCampaignDiscountType[];
}

export interface GetVoucherCampaignDiscountTypesFailed extends ShowErrorToastAction {
    type: "GET_VOUCHERCAMPAIGN_DISCOUNT_TYPES_FAILED";
}

export interface ListVoucherCampaignVouchers {
    type: "LIST_VOUCHERCAMPAIGNVOUCHERS";
    values: {
        id: number;
        voucherCampaignName: string;
    };
}

export interface ListVoucherCampaignVouchersSuccess {
    type: "LIST_VOUCHERCAMPAIGNVOUCHERS_SUCCESS";
}

export interface ListVoucherCampaignVouchersFailed extends ShowErrorToastAction {
    type: "LIST_VOUCHERCAMPAIGNVOUCHERS_FAILED";
}

export interface ValidateVoucher {
    type: "VALIDATE_VOUCHER";
    voucherCode: string;
}

export interface ValidateVoucherSuccess {
    type: "VALIDATE_VOUCHER_SUCCESS";
}

export interface ValidateVoucherFailed {
    type: "VALIDATE_VOUCHER_FAILED";
}

export interface GetVoucherByCode {
    type: "GET_VOUCHER_BY_CODE";
    code: string;
    region: Region;
}

export interface GetVoucherByCodeSuccess {
    type: "GET_VOUCHER_BY_CODE_SUCCESS";
    data: any;
}

export interface GetVoucherByCodeFailed extends ShowErrorToastAction {
    type: "GET_VOUCHER_BY_CODE_FAILED";
}

export interface ManageVoucherCampaignsState {
    voucherCampaignTypes: VoucherCampaignType[];
    activeVoucherCampaignsData: PagedResult | null;
    upcomingVoucherCampaignsData: PagedResult | null;
    statistics: {
        activeVoucherCampaigns: { voucherCampaignName: string; redemptionCount: number }[] | null;
        topVoucherCampaigns: { voucherCampaignName: string; redemptionCount: number }[] | null;
    };
    historyData: PagedResult | null;
    discountTypes: VoucherCampaignDiscountType[] | null;
    voucherCampaignCreated: boolean;
    voucherCampaignUpdated: boolean;
    voucherCampaignArchived: boolean;
    voucherListIsLoading: boolean;
    validateVoucherData: UniqueVoucherData | GenericVoucherData | null;
    validateVoucherIsLoading: boolean;
}

export type ManageVoucherCampaignsActionTypes =
    | GetActiveVoucherCampaigns
    | GetActiveVoucherCampaignsSuccess
    | GetActiveVoucherCampaignsFailed
    | CreateVoucherCampaign
    | CreateVoucherCampaignSuccess
    | CreateVoucherCampaignFailed
    | GetOverviewContent
    | GetOverviewContentSuccess
    | GetOverviewContentFailed
    | GetVoucherCampaignHistory
    | GetVoucherCampaignHistorySuccess
    | GetVoucherCampaignHistoryFailed
    | GetVoucherCampaignDiscountTypes
    | GetVoucherCampaignDiscountTypesSuccess
    | GetVoucherCampaignDiscountTypesFailed
    | UpdateVoucherCampaign
    | UpdateVoucherCampaignSuccess
    | UpdateVoucherCampaignFailed
    | ArchiveVoucherCampaign
    | ArchiveVoucherCampaignSuccess
    | ArchiveVoucherCampaignFailed
    | ListVoucherCampaignVouchers
    | ListVoucherCampaignVouchersSuccess
    | ListVoucherCampaignVouchersFailed
    | GetVoucherByCode
    | GetVoucherByCodeSuccess
    | GetVoucherByCodeFailed;

export interface PagedResult {
    currentPageNumber: number;
    currentPageSize: number;
    hasMore: boolean;
    numberOfPages: number;
    pageSize: number;
    items: VoucherCampaign[];
}
