import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";

import manageUsersReducer from "../../ManageUsers/redux";
import managePodcastsReducer from "../../ManagePodcasts/redux";
import statisticsReducer from "../../Statistics/redux";
import manageVoucherCampaignsReducer from "../../ManageVoucherCampaigns/redux/reducer";
import authReducer from "./authReducer";
import toastMessageReducer, { toastMessageState } from "./toastMessageReducer";
import globalReducer from "./globalReducer";
import { ManagePodcastsState } from "../../ManagePodcasts/redux/types";
import { ManageUsersState } from "../../ManageUsers/redux/types";
import { ManageVoucherCampaignsState } from "../../ManageVoucherCampaigns/redux/types";
import { ManageAccessAndPermissionsState } from "../../AccessAndPermissions/redux/types";
import manageAccessAndPermissionsReducer from "../../AccessAndPermissions/redux/reducer";
import { adminApi } from "../../services/adminApi/adminApi";

export interface RootState {
    router: RouterState<unknown>;
    adminApi: any;
    manageUsers: ManageUsersState;
    managePodcasts: ManagePodcastsState;
    statistics: any;
    manageVoucherCampaigns: ManageVoucherCampaignsState;
    manageAccessAndPermissions: ManageAccessAndPermissionsState;
    auth: any;
    toastMessage: toastMessageState;
    global: any;
}

export const createCombined = (history?: any) =>
    combineReducers<RootState>({
        router: connectRouter(history),
        [adminApi.reducerPath]: adminApi.reducer,
        manageUsers: manageUsersReducer,
        managePodcasts: managePodcastsReducer,
        statistics: statisticsReducer,
        manageVoucherCampaigns: manageVoucherCampaignsReducer,
        manageAccessAndPermissions: manageAccessAndPermissionsReducer,
        auth: authReducer,
        toastMessage: toastMessageReducer,
        global: globalReducer,
    });

export default (history: any) => {
    const combinedReducer = createCombined(history);
    return (state: any, action: any) => {
        return combinedReducer(state, action);
    };
};
