import { ComponentType, useCallback } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";
import { RootState } from "../redux/reducers/rootReducer";
import { UserRole } from "../redux/types";

interface Props {
    requiredRoles: UserRole[];
    component: ComponentType<unknown>;
    redirectPath: string;
    [x: string]: any;
}

const userRoleSelector = (state: RootState) => state.manageUsers.userRole;

export default function AuthRoute({ requiredRoles: requiredRoles, component: Component, redirectPath, ...rest }: Props) {
    const userRole = useSelector(userRoleSelector);

    const renderComponent = useCallback(
        (props) => {
            if (requiredRoles.find((role) => role === userRole) || userRole === UserRole.Admin) {
                return <Component {...props} />;
            }

            return <Redirect to={redirectPath} />;
        },
        [userRole, requiredRoles]
    );

    return <Route {...rest} render={(props) => renderComponent(props)} />;
}
