import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Badge, Text, Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { formatInTimeZone } from "date-fns-tz";
import { RiArrowDownSLine } from "react-icons/ri";

import TableContainer from "../components/TableContainer";
import UpdateVoucherCampaign from "../components/UpdateVoucherCampaign";
import { actions } from "../redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { VoucherCampaign, VoucherCampaignDiscountType, UpdateVoucherCampaignFormValues, VoucherCampaignType } from "../redux/types";
import DeleteDialog from "../../components/DeleteDialog";

const globalState = (state: RootState) => state.global;
const manageVoucherCampaignsState = (state: RootState) => state.manageVoucherCampaigns;

export default function UpcomingAndActivePage() {
    const dispatch = useDispatch();
    const { region, timezone } = useSelector(globalState);
    const {
        discountTypes,
        activeVoucherCampaignsData,
        upcomingVoucherCampaignsData,
        voucherCampaignCreated,
        voucherCampaignUpdated,
        voucherCampaignArchived,
        voucherListIsLoading,
    } = useSelector(manageVoucherCampaignsState);

    useEffect(() => {
        if (!discountTypes) dispatch(actions.getVoucherCampaignDiscountTypes());
        dispatch(actions.getActiveVoucherCampaigns(0, 99, region));
    }, [voucherCampaignCreated, voucherCampaignUpdated, voucherCampaignArchived, region]);

    return (
        <Box>
            <Box pb={8}>
                <TableContainer isLoading={false} title="Upcoming voucher campaigns">
                    {upcomingVoucherCampaignsData && upcomingVoucherCampaignsData.items.length > 0 ? (
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>Id</Th>
                                    <Th>Name</Th>
                                    <Th>Type</Th>
                                    <Th>Limit</Th>
                                    <Th>Start date</Th>
                                    <Th>End date</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {upcomingVoucherCampaignsData.items?.map((c: VoucherCampaign) => (
                                    <Tr key={c.id}>
                                        <Td>{c.id}</Td>
                                        <Td>
                                            <Box display="flex" flexDirection="column">
                                                <Box>
                                                    <Badge mb={4} mt="-5px" variant="subtle" colorScheme="green">
                                                        {c.type === (VoucherCampaignType.Reward as number)
                                                            ? `Month credit: ${c.monthCredit}`
                                                            : getVoucherCampaignDiscountType(discountTypes, c.discountTypeId)}
                                                    </Badge>
                                                </Box>
                                                <Text fontWeight="600">
                                                    {c.name}
                                                    {c.type == VoucherCampaignType.Generic ? `, code: ${c.voucherCode}` : null}
                                                </Text>
                                            </Box>
                                        </Td>
                                        <Td>{VoucherCampaignType[c.type]}</Td>
                                        <Td>{!c.redemptionLimit || c.redemptionLimit < 1 ? "none" : c.redemptionLimit}</Td>
                                        <Td>{formatInTimeZone(c.validFrom, timezone, "yyyy-MM-dd")}</Td>
                                        <Td>{formatInTimeZone(c.validUntil, timezone, "yyyy-MM-dd")}</Td>
                                        <Td isNumeric>
                                            <ManageVoucherCampaignOptions voucherCampaign={c} isLoading={voucherListIsLoading} />
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    ) : (
                        <Text fontSize="lg" mb={4} pl={6}>
                            No upcoming voucher campaigns
                        </Text>
                    )}
                </TableContainer>
            </Box>

            <TableContainer isLoading={false} title="Active voucher campaigns">
                {activeVoucherCampaignsData && activeVoucherCampaignsData.items.length > 0 ? (
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Id</Th>
                                <Th>Name</Th>
                                <Th>Type</Th>
                                <Th>Activations</Th>
                                <Th>Limit</Th>
                                <Th>Started</Th>
                                <Th>End date</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {activeVoucherCampaignsData.items?.map((c: VoucherCampaign) => (
                                <Tr key={c.id}>
                                    <Td>{c.id}</Td>
                                    <Td>
                                        <Box display="flex" flexDirection="column">
                                            <Box>
                                                {c.type != VoucherCampaignType.Reward && (
                                                    <Badge mb={4} mt="-5px" variant="subtle" colorScheme="green">
                                                        {getVoucherCampaignDiscountType(discountTypes, c.discountTypeId)}
                                                    </Badge>
                                                )}
                                                {c.type == VoucherCampaignType.Reward && (
                                                    <Badge mb={4} mt="-5px" variant="subtle" colorScheme="green">
                                                        Month credit: {c.monthCredit}
                                                    </Badge>
                                                )}
                                            </Box>
                                            <Text fontWeight="600">
                                                {c.name}
                                                {c.type == VoucherCampaignType.Generic ? `, code: ${c.voucherCode}` : null}
                                            </Text>
                                        </Box>
                                    </Td>
                                    <Td>{VoucherCampaignType[c.type]}</Td>
                                    <Td>{c.redemptionCount ?? 0}</Td>
                                    <Td>{!c.redemptionLimit || c.redemptionLimit < 1 ? "none" : c.redemptionLimit}</Td>
                                    <Td>{formatInTimeZone(c.validFrom, timezone, "yyyy-MM-dd")}</Td>
                                    <Td>{formatInTimeZone(c.validUntil, timezone, "yyyy-MM-dd")}</Td>
                                    <Td isNumeric>
                                        <ManageVoucherCampaignOptions voucherCampaign={c} isLoading={voucherListIsLoading} />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                ) : (
                    <Text fontSize="lg" mb={4} pl={6}>
                        No active voucher campaigns
                    </Text>
                )}
            </TableContainer>
        </Box>
    );
}

function getVoucherCampaignDiscountType(discountTypes: VoucherCampaignDiscountType[] | null, id: number) {
    const discountType = discountTypes?.filter((p) => p.id === id)[0]?.name;

    return discountType;
}

interface ManageVoucherCampaignOptionsProps {
    voucherCampaign: VoucherCampaign;
    isLoading: boolean;
}

function ManageVoucherCampaignOptions({ voucherCampaign, isLoading }: ManageVoucherCampaignOptionsProps) {
    const dispatch = useDispatch();
    const [downloadVoucherCodesCampaignId, setDownloadVoucherCodesCampaignId] = useState<number>();

    return (
        <>
            <Menu>
                <MenuButton
                    as={Button}
                    size="sm"
                    rightIcon={<RiArrowDownSLine />}
                    isLoading={isLoading && downloadVoucherCodesCampaignId === voucherCampaign.id}
                >
                    Manage
                </MenuButton>
                <MenuList>
                    <UpdateVoucherCampaign
                        renderAs={MenuItem}
                        voucherCampaign={voucherCampaign}
                        onSubmit={(values: UpdateVoucherCampaignFormValues) => dispatch(actions.updateVoucherCampaign(values))}
                        marginRight={2}
                    />
                    <MenuItem
                        onClick={() => {
                            setDownloadVoucherCodesCampaignId(voucherCampaign.id);
                            dispatch(actions.listVoucherCampaignVouchers(voucherCampaign.id, voucherCampaign.name));
                        }}
                    >
                        Download codes
                    </MenuItem>
                    <DeleteDialog
                        renderAs={MenuItem}
                        buttonLabel="Archive"
                        title="Archive voucher campaign"
                        text="Are you sure you want to archive the voucher campaign?"
                        deleteAction={() => dispatch(actions.archiveVoucherCampaign({ id: voucherCampaign.id }))}
                        buttonVariant="solid"
                        buttonVariantColor="orange"
                        buttonSize="sm"
                    />
                </MenuList>
            </Menu>
        </>
    );
}
