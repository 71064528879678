import React, { useEffect, useMemo } from "react";
import { Accordion, Flex } from "@chakra-ui/react";

import SubscriptionItem from "./SubscriptionItem";
import { CancelSubscription, Subscription } from "../redux/types";
import { getActiveSubscriptions, getInactiveSubscriptions } from "../../utils";
import { useDispatch } from "react-redux";
import { actions } from "../redux";
import { LoginType } from "../../redux/types";

interface Props {
    showInactiveSubscriptions: boolean;
    subscriptions: Subscription[] | undefined;
    userAccountId: string;
    selectedId: number;
    accountLoginType: LoginType;
    onSelect: () => void;
    cancelSubscription: (podcastId?: string) => CancelSubscription;
}

function SubscriptionList({
    showInactiveSubscriptions,
    subscriptions,
    userAccountId,
    selectedId,
    accountLoginType,
    onSelect,
    cancelSubscription,
}: Props) {
    const activeSubscriptions = useMemo(() => getActiveSubscriptions(subscriptions), [subscriptions]);
    const inactiveSubscriptions = useMemo(() => getInactiveSubscriptions(subscriptions), [subscriptions]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (subscriptions && subscriptions.length) {
            dispatch(actions.startFetchSubscriptionEventsSequence(subscriptions, userAccountId));
            dispatch(actions.fetchPaymentTransactionsSequence(subscriptions.map((sub) => sub.subscriptionId)));
        }
    }, [subscriptions?.length]);

    return (
        <>
            {!!subscriptions && !!subscriptions.length && (
                <>
                    {activeSubscriptions && !!activeSubscriptions?.length && (
                        <Flex direction="column" gridGap="0.5rem">
                            {activeSubscriptions.map((sub: Subscription, idx: number) => (
                                <Accordion allowToggle key={sub.subscriptionId}>
                                    <SubscriptionItem
                                        key={idx}
                                        highlightElement={selectedId === sub.subscriptionId}
                                        onSelect={onSelect}
                                        accountLoginType={accountLoginType}
                                        name={sub?.subscriptionPlan?.name ?? "N/A"}
                                        status={sub.subscriptionState}
                                        platform={sub.subscriptionPlatform}
                                        startDate={sub.startDate}
                                        endDate={sub.expirationDate}
                                        isActive
                                        subscriptionEvents={sub.subscriptionEvents}
                                        paymentTransactions={sub.paymentTransactions}
                                        cancelSubscription={() => cancelSubscription(sub.podcastId)}
                                        lastOrderId={sub.lastOrderId}
                                        bundlerPartnerName={sub.bundlerPartnerName}
                                        rewardMonthCredit={sub.rewardMonthCredit}
                                        subscriptionId={sub.subscriptionId}
                                        userAccountId={userAccountId}
                                        convertsToStudentSubscriptionOnNextRenewal={isNextPlanProductIdStudent(
                                            sub.subscriptionPlan?.nextPlanProductId
                                        )}
                                    />
                                </Accordion>
                            ))}
                        </Flex>
                    )}
                    {inactiveSubscriptions && !!inactiveSubscriptions?.length && showInactiveSubscriptions && (
                        <Flex direction="column" gridGap="0.5rem">
                            {inactiveSubscriptions.map((sub: Subscription, idx: number) => (
                                <Accordion allowToggle key={sub.subscriptionId}>
                                    <SubscriptionItem
                                        key={idx}
                                        highlightElement={selectedId === sub.subscriptionId}
                                        hideElement={!showInactiveSubscriptions}
                                        onSelect={onSelect}
                                        accountLoginType={accountLoginType}
                                        name={sub?.subscriptionPlan?.name ?? "N/A"}
                                        status={sub.subscriptionState}
                                        platform={sub.subscriptionPlatform}
                                        startDate={sub.startDate}
                                        endDate={sub.expirationDate}
                                        lastOrderId={sub.lastOrderId}
                                        subscriptionEvents={sub.subscriptionEvents}
                                        paymentTransactions={sub.paymentTransactions}
                                        cancelSubscription={() => cancelSubscription(sub.podcastId)}
                                        bundlerPartnerName={sub.bundlerPartnerName}
                                        subscriptionId={sub.subscriptionId}
                                        userAccountId={userAccountId}
                                    />
                                </Accordion>
                            ))}
                        </Flex>
                    )}
                </>
            )}
        </>
    );
}

function isNextPlanProductIdStudent(nextPlanProductId: string): boolean {
    return nextPlanProductId === "student-offer-plan";
}

export default SubscriptionList;
