import { useState, useEffect, useContext } from "react";
import { SMALL_SCREEN, MEDIUM_SCREEN, LARGE_SCREEN, XL_SCREEN } from "../constants";
import { UserAgentContext } from "../contexts";

export interface MediaMatchList {
    isSmallScreen: boolean;
    isMediumScreen: boolean;
    isLargeScreen: boolean;
    isXLScreen: boolean;
}

export default function useMedia(): MediaMatchList {
    const userAgent = useContext(UserAgentContext);
    const isServerSide = typeof window === "undefined";
    const queries = [SMALL_SCREEN, MEDIUM_SCREEN, LARGE_SCREEN, XL_SCREEN];
    const mediaQueryList = isServerSide ? undefined : queries.map((q) => window.matchMedia(q));

    const [values, setValues] = useState<MediaMatchList>(() => getValues(mediaQueryList));

    useEffect(() => {
        if (mediaQueryList) {
            const handler = () => setValues(() => getValues(mediaQueryList));
            mediaQueryList.forEach((mql) => mql.addListener(handler));

            return () => mediaQueryList.forEach((mql) => mql.removeListener(handler));
        }
    }, []);

    if (isServerSide) {
        const device = userAgent || "";
        const isMobile = /iPhone|iPad|iPod|Android/i.test(device);

        return {
            isSmallScreen: isMobile ? true : false,
            isMediumScreen: false,
            isLargeScreen: isMobile ? false : true,
            isXLScreen: false,
        };
    }

    return values;
}

function getValues(mediaQueries: globalThis.MediaQueryList[] | undefined): MediaMatchList {
    const values: MediaMatchList = { isSmallScreen: false, isMediumScreen: false, isLargeScreen: true, isXLScreen: false };
    if (mediaQueries === undefined) return values;

    let index = 0;
    for (const key in values) {
        values[key as keyof MediaMatchList] = mediaQueries[index].matches;
        index++;
    }

    return values;
}
