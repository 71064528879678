import React, { useCallback, useMemo, useState } from "react";
import { Box, Button, Grid, Select } from "@chakra-ui/react";
import { createPostAxiosRequest } from "../utils";
import SearchField from "../components/SearchField/SearchField";
import { debounce } from "lodash";

export default function FreeTextSearchTableV1(props: any) {
    const [query, setQueryInput] = useState<string>("");
    const [region, setRegionInput] = useState<number | null>(null);
    const [episodes, setEpisodes] = useState<any>([]);
    const [podcasts, setPodcasts] = useState<any>([]);
    const [execTime, setExecTime] = useState(0.0);

    const podcastsColumnsTemplate = ["65% 15% 20%"];
    const episodesColumnsTemplate = ["40% 40% 20%"];

    const handleQueryChange = useCallback((searchValue: string) => {
        setQueryInput(searchValue);
    }, []);

    const debouncedSearchHandler = useMemo(() => debounce(handleQueryChange, 350), []);

    const handleRegionChange = (e: React.ChangeEvent<any>) => {
        let region = e.target.value;
        if (!region) region = null;
        setRegionInput(region);
    };

    const handleSearch = async () => {
        if (query.length != 0) {
            const response = await createPostAxiosRequest(`api/search/opensearch/free-text-search`, {
                query: `${query}`,
                pageSize: 100,
                skip: 0,
                region: region,
            });
            setEpisodes(response.data.value["episodes"]);
            setPodcasts(response.data.value["podcasts"]);
            setExecTime(response.data.value["took"] !== undefined ? response.data.value["took"] : 0.0);
        } else {
            setEpisodes([]);
            setPodcasts([]);
            setExecTime(0.0);
        }
    };

    return (
        <Box>
            <Box display={"inline-flex"} width={"100%"} order={[3, null, null, 1]} paddingBottom={4}>
                <SearchField label="Search podcast" handleChange={debouncedSearchHandler} />
                <Select width={"20%"} onChange={handleRegionChange} backgroundColor={"white"} padding={"3px"}>
                    <option key="" value="">
                        No region
                    </option>
                    <option key="se" value={1}>
                        se
                    </option>
                    <option key="no" value={2}>
                        no
                    </option>
                    <option key="fi" value={3}>
                        fi
                    </option>
                </Select>
                <Button width={"30%"} onClick={handleSearch}>
                    Search
                </Button>
            </Box>
            <Box style={{ flex: "auto" }} flexDirection={"row"} display={"flex"}>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    marginRight={"7px"}
                    padding={"3px"}
                    backgroundColor={"white"}
                    flex={"4"}
                >
                    <Box>Podcasts</Box>
                    <Grid templateColumns={podcastsColumnsTemplate} border={"solid"} width={"100%"}>
                        <Box>Title</Box>
                        <Box>Lang</Box>
                        <Box>Score</Box>
                    </Grid>
                    {podcasts.map((x: any) => (
                        <Grid templateColumns={podcastsColumnsTemplate}>
                            <Box>{x["title"]}</Box>
                            <Box>{x["language"]}</Box>
                            <Box>{x["score"]}</Box>
                        </Grid>
                    ))}
                </Box>
                <Box display={"flex"} flexDirection={"column"} backgroundColor={"white"} padding={"3px"} flex={"6"}>
                    <Box>Episodes</Box>
                    <Grid templateColumns={episodesColumnsTemplate} border={"solid"} width={"100%"}>
                        <Box>Episode title</Box>
                        <Box>Podcast title</Box>
                        <Box>Score</Box>
                    </Grid>
                    {episodes.map((x: any) => (
                        <Grid templateColumns={episodesColumnsTemplate}>
                            <Box>{x["title"]}</Box>
                            <Box>{x["podcastTitle"]}</Box>
                            <Box>{x["score"]}</Box>
                        </Grid>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}
