import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { colors } from "../../../constants";
import { PodmeColor } from "../../../redux/types";

export type BreadcrumbType =
    | {
          type: "span";
          label: string;
          isCurrentPage: true;
      }
    | {
          type: "button";
          label: string;
          onClick: () => void;
          isCurrentPage: false;
      }
    | {
          type: "link";
          label: string;
          link: string;
          isCurrentPage: false;
      };

interface Props {
    breadcrumbsArr: BreadcrumbType[];
}

function Breadcrumbs({ breadcrumbsArr }: Props) {
    return (
        <Box position="absolute" top="1rem" left="1rem">
            <Breadcrumb spacing="8px" separator={">"} color={colors.graphite} fontSize="0.75rem">
                {breadcrumbsArr.map((breadcrumb, index) => {
                    if (breadcrumb.type === "span") {
                        return (
                            <BreadcrumbItem key={index} isCurrentPage>
                                <Box as="span">{breadcrumb.label}</Box>
                            </BreadcrumbItem>
                        );
                    } else if (breadcrumb.type === "button") {
                        return (
                            <BreadcrumbItem key={index}>
                                <Box as="button" onClick={breadcrumb.onClick} cursor="pointer" height="100%">
                                    <Box
                                        as="span"
                                        color={PodmeColor.SoftWhite}
                                        fontWeight="500"
                                        lineHeight="1.2"
                                        _hover={{
                                            borderBottom: `1px solid ${PodmeColor.SoftWhite + "99"}`,
                                        }}
                                    >
                                        {breadcrumb.label}
                                    </Box>
                                </Box>
                            </BreadcrumbItem>
                        );
                    }
                    return (
                        <BreadcrumbItem key={index}>
                            <BreadcrumbLink as={Link} to={breadcrumb.link}>
                                {breadcrumb.label}
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    );
                })}
            </Breadcrumb>
        </Box>
    );
}

export default Breadcrumbs;
