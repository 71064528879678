import { Region } from "../../redux/types";

export const GET_SINGLE_SUBSCRIPTIONS = "Statistics/GET_SINGLE_SUBSCRIPTIONS";
export const GET_SINGLE_SUBSCRIPTIONS_SUCCESS = "Statistics/GET_SINGLE_SUBSCRIPTIONS_SUCCESS";
export const GET_SINGLE_SUBSCRIPTIONS_FAILED = "Statistics/GET_SINGLE_SUBSCRIPTIONS_FAILED";

export const GET_PACKAGE_SUBSCRIPTIONS = "Statistics/GET_PACKAGE_SUBSCRIPTIONS";
export const GET_PACKAGE_SUBSCRIPTIONS_SUCCESS = "Statistics/GET_PACKAGE_SUBSCRIPTIONS_SUCCESS";
export const GET_PACKAGE_SUBSCRIPTIONS_FAILED = "Statistics/GET_PACKAGE_SUBSCRIPTIONS_FAILED";

export const UPDATE_FILTER = "Statistics/UPDATE_FILTER";
export const RESET_FILTER = "Statistics/RESET_FILTER";

export const GET_PREMIUM_PODCASTS = "Statistics/GET_PREMIUM_PODCASTS";
export const GET_PREMIUM_PODCASTS_SUCCESS = "Statistics/GET_PREMIUM_PODCASTS_SUCCESS";
export const GET_PREMIUM_PODCASTS_FAILED = "Statistics/GET_PREMIUM_PODCASTS_FAILED";

export interface GetSingleSubscriptions {
    type: typeof GET_SINGLE_SUBSCRIPTIONS;
    podcastId: string | number;
    platform: number;
}

export interface GetSingleSubscriptionsSuccess {
    type: typeof GET_SINGLE_SUBSCRIPTIONS_SUCCESS;
    singleSubscriptions: any;
}

export interface GetSingleSubscriptionsFailed {
    type: typeof GET_SINGLE_SUBSCRIPTIONS_FAILED;
}

export interface GetPackageSubscriptions {
    type: typeof GET_PACKAGE_SUBSCRIPTIONS;
    platform: any;
    region: Region;
}

export interface GetPackageSubscriptionsSuccess {
    type: typeof GET_PACKAGE_SUBSCRIPTIONS_SUCCESS;
    packageSubscriptions: any;
}

export interface GetPackageSubscriptionsFailed {
    type: typeof GET_PACKAGE_SUBSCRIPTIONS_FAILED;
}
export interface UpdateFilter {
    type: typeof UPDATE_FILTER;
    filter: Filter;
}

export interface ResetFilter {
    type: typeof RESET_FILTER;
    filter: Filter;
}

export interface GetPremiumPodcasts {
    type: typeof GET_PREMIUM_PODCASTS;
    region: Region;
}

export interface GetPremiumPodcastsSuccess {
    type: typeof GET_PREMIUM_PODCASTS_SUCCESS;
    premiumPodcasts: any;
}

export interface GetPremiumPodcastsFailed {
    type: typeof GET_PREMIUM_PODCASTS_FAILED;
}

export interface Filter {
    podcastId: string | number;
    episodeId: string | number;
    platform: number;
    episodeTitle: string;
    from: Date;
    to: Date;
    onlyPremiumEpisodes: false;
    subscriptionType: string;
}

export type StatisticsActionTypes =
    | GetSingleSubscriptions
    | GetSingleSubscriptionsSuccess
    | GetPackageSubscriptionsFailed
    | GetPackageSubscriptions
    | GetPackageSubscriptionsSuccess
    | UpdateFilter
    | ResetFilter
    | GetPremiumPodcasts
    | GetPremiumPodcastsSuccess
    | GetPremiumPodcastsFailed;
