import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Text,
    Input,
    Box,
    Heading,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";

const addRssPodcastState = (state) => state.managePodcasts.addRssPodcast;

function AddRssPodcast({ onSubmit, resetForm }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { createdPodcast, isLoading } = useSelector(addRssPodcastState);
    const [formSubmitted, setFormsubmitted] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            setFormsubmitted(false);
            resetForm();
        }
    }, [isOpen]);

    return (
        <>
            <Button colorScheme="teal" size="md" height="100%" borderRadius="1rem" onClick={onOpen}>
                Add RSS podcast
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
                <ModalOverlay backgroundColor="rgba(0,0,0,0.7)" />
                <Formik
                    initialValues={{
                        rss: "",
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.rss) {
                            errors.rss = "You must add RSS feed";
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            onSubmit(values);
                            setSubmitting(false);
                            setFormsubmitted(false);
                        }, 800);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <ModalContent>
                            {createdPodcast ? (
                                <>
                                    <ModalHeader>
                                        <Box display="flex" justifyContent="center">
                                            <Heading size="lg" display="inline-block">
                                                {createdPodcast.podcastTitle} is now added!{" "}
                                                <span role="img" aria-label="emoji">
                                                    🥳
                                                </span>
                                            </Heading>
                                        </Box>
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Box pb={4} display="flex" justifyContent="center">
                                            <Link to={`/podcasts/${createdPodcast.podcastId}`}>
                                                <Button colorScheme="teal" size="lg" onClick={onClose}>
                                                    Go to podcast
                                                </Button>
                                            </Link>
                                        </Box>
                                    </ModalBody>
                                </>
                            ) : (
                                <>
                                    <ModalHeader>
                                        <Heading size="lg">Add RSS podcast</Heading>
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Form>
                                            <Box paddingBottom={6}>
                                                <FormControl isRequired isInvalid={errors.rss && touched}>
                                                    <FormLabel htmlFor="rss">RSS feed</FormLabel>
                                                    <Input
                                                        id="rss"
                                                        placeholder="RSS feed"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.rss}
                                                    />
                                                    <FormErrorMessage>{errors.rss}</FormErrorMessage>
                                                </FormControl>
                                            </Box>

                                            <Box paddingBottom={6}>
                                                <Text>Categories will be added automatically from the RSS feed.</Text>
                                            </Box>
                                        </Form>
                                    </ModalBody>
                                    <ModalFooter display="flex" justifyContent="center">
                                        <Box padding={4}>
                                            <Button
                                                colorScheme="green"
                                                size="lg"
                                                onClick={() => {
                                                    handleSubmit();
                                                    setFormsubmitted(true);
                                                }}
                                                isLoading={isSubmitting || isLoading}
                                            >
                                                Add RSS podcast
                                            </Button>
                                        </Box>
                                    </ModalFooter>
                                </>
                            )}
                        </ModalContent>
                    )}
                </Formik>
            </Modal>
        </>
    );
}

export default AddRssPodcast;
