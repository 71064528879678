import { Flex, Text, Heading } from "@chakra-ui/react";
import { format } from "date-fns";
import { PodmeColor } from "../../redux/types";
import { VoucherRedemptionHistory } from "../redux/types";
import RedeemFreeVoucherCode from "./RedeemFreeVoucherCode/RedeemFreeVoucherCode";

interface Props {
    voucherRedemptionHistory: VoucherRedemptionHistory[];
    onSubmitForRedeemFreeVoucher: (voucherCode: string) => void;
}

function UsedVouchers({ voucherRedemptionHistory, onSubmitForRedeemFreeVoucher }: Props) {
    return (
        <Flex direction="column" gridGap="0.5rem">
            <Flex flex="2" justifyContent="start" alignContent="baseline" direction="row" gridGap="1rem">
                <Heading as="h2" fontSize="1.5rem">
                    Used vouchers
                </Heading>
                <RedeemFreeVoucherCode onSubmit={onSubmitForRedeemFreeVoucher} />
            </Flex>

            {!voucherRedemptionHistory ||
                (voucherRedemptionHistory.length < 1 && (
                    <Text as="span" fontWeight="500" fontSize="1rem" color={PodmeColor.Silver}>
                        No used vouchers found
                    </Text>
                ))}

            {voucherRedemptionHistory &&
                voucherRedemptionHistory.length >= 1 &&
                voucherRedemptionHistory.map((usedVoucher) => {
                    return (
                        <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            gridGap="1rem"
                            padding="1rem"
                            backgroundColor="#eee"
                            zIndex="2"
                            borderRadius="1rem"
                            borderWidth="1px"
                            borderStyle="solid"
                            borderColor="#e0e0e0"
                        >
                            <Flex flex="2" justifyContent="space-between" direction="column" gridGap="0.25rem">
                                <Text as="span" fontWeight="900" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                                    Vocher Campaign Id
                                </Text>
                                <Text fontWeight="700" fontSize="1rem">
                                    {usedVoucher.voucherCampaignId}
                                </Text>
                            </Flex>
                            <Flex flex="2" justifyContent="space-between" direction="column" gridGap="0.25rem">
                                <Text as="span" fontWeight="900" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                                    Vocher Campaign Name
                                </Text>
                                <Text fontWeight="700" fontSize="1rem">
                                    {usedVoucher.voucherCampaignName}
                                </Text>
                            </Flex>
                            <Flex flex="2" justifyContent="space-between" direction="column" gridGap="0.25rem">
                                <Text as="span" fontWeight="900" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                                    Vocher Code
                                </Text>
                                <Text fontWeight="700" fontSize="1rem">
                                    {usedVoucher.voucherCode}
                                </Text>
                            </Flex>
                            <Flex flex="2" justifyContent="space-between" direction="column" gridGap="0.25rem">
                                <Text as="span" fontWeight="900" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                                    Redeemed At
                                </Text>
                                <Text fontWeight="700" fontSize="1rem">
                                    {format(new Date(usedVoucher.redeemedAt), "yyyy-MM-dd HH:mm")}
                                </Text>
                            </Flex>
                        </Flex>
                    );
                })}
        </Flex>
    );
}

export default UsedVouchers;
