import { BaseQueryFn } from "@reduxjs/toolkit/query/react";
import axios, { AxiosRequestConfig, AxiosError } from "axios";
import cookie from "react-cookies";

const axiosAuthQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: "" }
    ): BaseQueryFn<
        {
            url: string;
            method?: AxiosRequestConfig["method"];
            data?: AxiosRequestConfig["data"];
            params?: AxiosRequestConfig["params"];
            headers?: AxiosRequestConfig["headers"];
        },
        unknown,
        unknown
    > =>
    async ({ url, method, data, params, headers: baseHeaders }) => {
        const token = cookie.load("podme_admincookie");
        const session = JSON.parse(localStorage.getItem("hello")!);
        let headers = {};

        if (token?.access_Token) {
            headers = { Authorization: `Bearer ${token.access_Token}`, ...baseHeaders };
        } else if (token || session) {
            headers = { Authorization: `Bearer ${token || session.adminAdB2CSignIn.access_token}`, ...baseHeaders };
        }

        try {
            const result = await axios({
                url: baseUrl + url,
                method,
                data,
                params,
                headers,
            });
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };

export default axiosAuthQuery;
