import { useState, useEffect, ReactElement, ComponentType } from "react";
import { useSelector } from "react-redux";

import {
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Box,
    Heading,
    FormHelperText,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { format, parseISO, addDays } from "date-fns";

import DatePicker from "../../components/DatePicker/DatePicker";
import { VoucherCampaign } from "../redux/types";
import { RootState } from "../../redux/reducers/rootReducer";

interface Props {
    onSubmit: any;
    voucherCampaign: VoucherCampaign;
    marginRight?: "string" | number;
    renderAs?: ComponentType<{ onClick: () => void }>;
}

export default function UpdateVoucherCampaign({ voucherCampaign, onSubmit, marginRight, renderAs: Component }: Props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [, setFormsubmitted] = useState(false);
    const manageVoucherCampaignsState = (state: RootState) => state.manageVoucherCampaigns;
    const { voucherCampaignUpdated } = useSelector(manageVoucherCampaignsState);

    useEffect(() => {
        if (!isOpen) {
            setFormsubmitted(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (voucherCampaignUpdated) {
            onClose();
        }
    }, [voucherCampaignUpdated]);

    return (
        <>
            {Component ? (
                <Component onClick={onOpen}>Edit</Component>
            ) : (
                <Button colorScheme="teal" size="sm" onClick={onOpen} marginRight={marginRight}>
                    Edit
                </Button>
            )}
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
                <ModalOverlay backgroundColor="rgba(0,0,0,0.7)" />
                <Formik
                    initialValues={{
                        voucherCampaignType: voucherCampaign.type,
                        id: voucherCampaign.id,
                        validUntil: parseISO(voucherCampaign.validUntil),
                        redemptionLimit: voucherCampaign.redemptionLimit,
                        churnEligibilityInDays: voucherCampaign.churnEligibilityInDays,
                    }}
                    validate={(values) => {
                        const errors: { validUntil?: string; usageLimit?: string } = {};

                        if (!values.validUntil) {
                            errors.validUntil = "You must set an expiration date";
                        }
                        if (new Date(values.validUntil).getTime() < new Date(voucherCampaign.validFrom).getTime()) {
                            errors.validUntil = "End date must be later than start date";
                        }

                        const limitMaxValue = 50000;
                        if (Number(values.redemptionLimit) > limitMaxValue) {
                            errors.usageLimit = `Limit cannot exceed ${limitMaxValue}`;
                        }

                        const limitMinValue = 0;
                        if (Number(values.redemptionLimit) < limitMinValue) {
                            errors.usageLimit = `Limit must be at least ${limitMinValue}`;
                        }

                        return errors;
                    }}
                    onSubmit={(values, {}) => {
                        onSubmit(values);
                        setFormsubmitted(false);
                    }}
                >
                    {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <ModalContent>
                            <>
                                <ModalHeader>
                                    <Heading size="lg">Edit voucher campaign</Heading>
                                </ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Form>
                                        <Box paddingBottom={6} display="flex">
                                            <FormControl marginRight={8}>
                                                <FormLabel htmlFor="validFrom">Start date</FormLabel>
                                                <Input
                                                    id="validFrom"
                                                    value={format(parseISO(voucherCampaign.validFrom), "yyyy-MM-dd")}
                                                    type="text"
                                                    isDisabled
                                                />
                                            </FormControl>
                                            <FormControl isRequired isInvalid={Boolean(errors.validUntil)}>
                                                <FormLabel htmlFor="validUntil">End date</FormLabel>
                                                <Field
                                                    name="validUntil"
                                                    value={values.validUntil}
                                                    minDate={addDays(parseISO(voucherCampaign.validFrom), 1)}
                                                    component={DatePicker}
                                                />
                                                <FormErrorMessage>{errors.validUntil}</FormErrorMessage>
                                            </FormControl>
                                        </Box>

                                        <Box paddingBottom={6}>
                                            <FormControl>
                                                <FormLabel htmlFor="churnEligibilityInDays">Churn eligibility</FormLabel>
                                                <Input
                                                    isDisabled
                                                    id="churnEligibilityInDays"
                                                    value={
                                                        values.churnEligibilityInDays ? `${values.churnEligibilityInDays} days` : "N/A"
                                                    }
                                                    type="text"
                                                    width="20%"
                                                />
                                            </FormControl>
                                        </Box>

                                        <Box paddingBottom={6}>
                                            <FormControl isInvalid={Boolean(errors.redemptionLimit)}>
                                                <FormLabel htmlFor="redemptionLimit">Update limit</FormLabel>
                                                <FormHelperText mb={1}>
                                                    Voucher campaign will expire when this number of activations is reached
                                                </FormHelperText>
                                                <Input
                                                    id="redemptionLimit"
                                                    placeholder="Limit"
                                                    onChange={(evt) => {
                                                        evt.target.value = evt.target.value?.replace(/[^0-9]+/g, "");
                                                        if (evt.target.value) evt.target.value = parseInt(evt.target.value).toString();

                                                        handleChange(evt);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.redemptionLimit}
                                                    type="text"
                                                    width="20%"
                                                />

                                                <FormErrorMessage>{errors.redemptionLimit}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                    </Form>
                                </ModalBody>
                                <ModalFooter display="flex" justifyContent="center">
                                    <Box padding={4}>
                                        <Button
                                            colorScheme="teal"
                                            size="lg"
                                            onClick={() => {
                                                handleSubmit();
                                                setFormsubmitted(true);
                                            }}
                                            isLoading={isSubmitting}
                                        >
                                            Update voucher campaign
                                        </Button>
                                    </Box>
                                </ModalFooter>
                            </>
                        </ModalContent>
                    )}
                </Formik>
            </Modal>
        </>
    );
}
