import { Box, Select } from "@chakra-ui/react";
import { useState } from "react";
import FreeTextSearchTableV1 from "./FreeTextSearchV1";

export default function OpensearchPreview() {
    const [input, setInput] = useState<string>("");
    const [searchResult, setSearchResult] = useState<any>([]);
    const [execTime, setExecTime] = useState(0.0);
    const [filterPodcast, setFilterPodcast] = useState(false);
    const [errorEpisodeName, setErrorEpisodeName] = useState("");

    const searchMapper = new Map([["Free text search", 1]]);

    const [searchType, setSearchType] = useState(1);
    const handleRegionChange = (ev: any) => {
        const newSearch = ev.target.value;
        setSearchType(newSearch);
    };

    return (
        <Box>
            <label>Select search type</label>
            <Select value={searchType} onChange={handleRegionChange} backgroundColor={"white"} padding={"3px"}>
                {Array.from(searchMapper.keys()).map((key) => (
                    <option key={key} value={searchMapper.get(key)}>
                        {key}
                    </option>
                ))}
            </Select>

            {errorEpisodeName !== "" ? <Box>{`Episode currently not supported: ${errorEpisodeName} `}</Box> : <Box></Box>}
            {execTime !== 0.0 ? <Box>{`Took: ${execTime} ms`}</Box> : <Box></Box>}
            {searchType == 1 ? <FreeTextSearchTableV1 /> : <Box>No viable search option chosen</Box>}
        </Box>
    );
}
