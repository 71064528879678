import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Grid from "react-css-grid";

import SubscriptionsDisplay from "../components/SubscriptionsDisplay";
import Spinner from "../../components/Spinner";
import { useMedia } from "../../hooks";

import { actions } from "../redux";

const statisticsState = (state) => state.statistics;
const globalState = (state) => state.global;

function OverviewPage() {
    const dispatch = useDispatch();
    const { filter, subscriptionsData, loading } = useSelector(statisticsState);
    const { region } = useSelector(globalState);
    const { isLargeScreen } = useMedia();

    useEffect(() => {
        dispatch(actions.getPackageSubscriptions(filter, region));
    }, [filter, region]);

    return (
        <PageContainer>
            {loading ? (
                <Spinner />
            ) : (
                <Grid width={"100%"} gap={isLargeScreen ? "2rem" : "1rem"}>
                    <SubscriptionsDisplay
                        title="Package subscriptions"
                        displayData={subscriptionsData ? { ...subscriptionsData } : {}}
                    />
                </Grid>
            )}
        </PageContainer>
    );
}

const PageContainer = styled.div`
    overflow: hidden;
    max-width: 100%;
    margin: 0 auto;

    h1 {
        color: white;
    }
`;

export default OverviewPage;
