import React from "react";
import PropTypes from "prop-types";

import { Input, Select, FormControl, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export default function OptionsMenu({ data, value, handleChange, placeholder }) {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <Select
                value={value}
                onChange={handleChange}
                name={value}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{
                    classes: {
                        icon: classes.icon,
                    },
                }}
                input={
                    <Input
                        classes={{
                            underline: classes.underline,
                        }}
                    />
                }
            >
                <MenuItem className={classes.menuItem} value=''>
                    {placeholder || "Select a podcast"}
                </MenuItem>
                {data.map((item, i) => {
                    return (
                        <MenuItem className={classes.menuItem} key={i} value={item.id}>
                            {item.title}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        width: "200px",
        marginRight: "1rem",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    selectEmpty: {
        fontSize: "inherit",
        fontFamily: "inherit",
        color: "black",
        justifyContent: "center",
    },
    icon: {
        fill: "black",
    },
    underline: {
        borderBottom: "0.5px solid rgb(22, 225, 227)",
        "&:after": {
            borderBottom: "0.5px solid rgb(20, 220, 220)",
        },
    },
    menuItem: {
        fontSize: "inherit",
        fontFamily: "inherit",
    },
}));

OptionsMenu.propTypes = {
    data: PropTypes.array.isRequired,
    value: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
};
