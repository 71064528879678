import { ReactElement, useEffect, useState } from "react";
import { Box, Button, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { formatInTimeZone } from "date-fns-tz";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useLocation } from "react-router-dom";
import { FaRegFrownOpen } from "react-icons/fa";

import { RootState } from "../../../redux/reducers/rootReducer";
import { PodmeColor } from "../../../redux/types";
import { UsersFetchResponse } from "../../redux/types";
import CircularProgress from "../../../components/CircularProgress";
import { colors } from "../../../constants";
import LoginMethodImg from "../../../components/LoginMethodImg/LoginMethodImg";

const globalState = (state: RootState) => state.global;

interface Props {
    isLoading: boolean;
    hasMoreUsers: boolean;
    noUsersFound: boolean;
    fetchMoreUsers: () => void;
    shownUsers: UsersFetchResponse | null;
}

export default function UserTable({ hasMoreUsers, isLoading, noUsersFound, shownUsers, fetchMoreUsers }: Props): ReactElement {
    const { timezone } = useSelector(globalState);
    const location = useLocation();

    const [isFetchingMoreUserAccounts, setIsFetchingMoreUserAccounts] = useState(false);

    const handleFetchingMoreUserAccounts = () => {
        setIsFetchingMoreUserAccounts(true);
        fetchMoreUsers();
    };

    useEffect(() => {
        if (isFetchingMoreUserAccounts && !isLoading) {
            setIsFetchingMoreUserAccounts(false);
        }
    }, [isLoading]);

    return (
        <Box
            padding="1rem"
            backgroundColor={colors.softWhite}
            borderRadius="1rem"
            height="calc(100vh - 15rem)"
            width={["100%", null, null, "calc(100vw - 20rem)"]}
            maxWidth="100rem"
            overflowY="scroll"
            minHeight={!shownUsers ? "41rem" : undefined}
            position="relative"
            id="scrollableDiv"
        >
            {noUsersFound ? (
                <Flex alignItems="center" justifyContent="center" height="100%">
                    <Flex direction="column" alignItems="center" gridGap="0.5rem">
                        <FaRegFrownOpen color={PodmeColor.Silver} fontSize="2rem" />
                        <Text as="span" color={PodmeColor.Silver} fontWeight="500">
                            No user accounts found
                        </Text>
                    </Flex>
                </Flex>
            ) : (
                <InfiniteScroll
                    dataLength={shownUsers?.userAccounts?.length ?? 0}
                    next={handleFetchingMoreUserAccounts}
                    hasMore={hasMoreUsers}
                    loader={<CircularProgress />}
                    scrollableTarget="scrollableDiv"
                >
                    <TableContainer overflowX="unset" overflowY="unset">
                        <Table variant="simple">
                            <Thead
                                position="sticky"
                                top="0"
                                sx={{
                                    span: {
                                        color: colors.blackPearl,
                                    },
                                }}
                            >
                                <Tr color={colors.blackPearl}>
                                    <Th>
                                        <Box display="flex" alignItems="center" flexBasis="25%">
                                            <Text as="span">Email</Text>
                                        </Box>
                                    </Th>
                                    <Th>
                                        <Box display="flex" alignItems="center" flexBasis="10%">
                                            <Text as="span">Login method</Text>
                                        </Box>
                                    </Th>
                                    <Th>
                                        <Box display="flex" alignItems="center" flexBasis="25%">
                                            <Text as="span">Created at</Text>
                                        </Box>
                                    </Th>
                                    <Th>
                                        <Box display="flex" alignItems="center" flexBasis="10%">
                                            <Text as="span">Originating from Schibsted</Text>
                                        </Box>
                                    </Th>
                                    <Th>
                                        <Box display="flex" alignItems="center" flexBasis="10%">
                                            <Text as="span">Is migrated</Text>
                                        </Box>
                                    </Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody minHeight="41rem">
                                {isLoading && !isFetchingMoreUserAccounts ? (
                                    <Td>
                                        <Flex
                                            zIndex="10"
                                            position="absolute"
                                            inset="0"
                                            bgColor={PodmeColor.SoftWhite}
                                            justifyContent="center"
                                        >
                                            <Flex zIndex="10">
                                                <CircularProgress />
                                            </Flex>
                                        </Flex>
                                    </Td>
                                ) : (
                                    shownUsers?.userAccounts &&
                                    shownUsers.userAccounts.map((userAccount, idx: number) => (
                                        <Tr key={idx} borderRadius="0.5rem" _hover={{ backgroundColor: "#eee" }}>
                                            <Th>
                                                <Box as="span" display="flex" alignItems="center">
                                                    {userAccount.email}
                                                </Box>
                                            </Th>
                                            <Th>
                                                <Box display="flex" alignItems="center" justifyContent={["left", null, "flex-start"]}>
                                                    <LoginMethodImg loginType={userAccount.loginType} />
                                                </Box>
                                            </Th>
                                            <Th>
                                                <Box as="span" display="flex" alignItems="center" width="100%" height="100%">
                                                    {formatInTimeZone(new Date(userAccount.createdAt), timezone, "yyyy-MM-dd HH:mm")}
                                                </Box>
                                            </Th>
                                            <Th>
                                                <Box as="span" display="flex" alignItems="center">
                                                    {userAccount.originatingFromSchibsted ? "Yes" : "No"}
                                                </Box>
                                            </Th>
                                            <Th>
                                                <Box as="span" display="flex" alignItems="center">
                                                    {userAccount.migrated ? "Yes" : "No"}
                                                </Box>
                                            </Th>
                                            <Th>
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent={["flex-end", null, "flex-end"]}
                                                >
                                                    <Link
                                                        to={{
                                                            pathname: `users/${userAccount.userId}`,
                                                            state: {
                                                                from: location.pathname + location.search,
                                                            },
                                                        }}
                                                    >
                                                        <Button
                                                            color={colors.blackPearl}
                                                            backgroundColor={colors.cyan}
                                                            transition="transform 150ms cubic-bezier(0.215, 0.61, 0.355, 1)"
                                                            _hover={{
                                                                filter: "brightness(0.98)",
                                                                opacity: 0.9,
                                                                transform: "scale(0.98)",
                                                                backgroundColor: colors.cyan,
                                                            }}
                                                        >
                                                            Show details
                                                        </Button>
                                                    </Link>
                                                </Box>
                                            </Th>
                                        </Tr>
                                    ))
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </InfiniteScroll>
            )}
        </Box>
    );
}
