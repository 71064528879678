import styled from "styled-components";
import bigPlayIcon from "../../assets/images/cmspreview/bigplay.svg";
import bigPlusIcon from "../../assets/images/cmspreview/bigplus.svg";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 310px;
`;

const HeroImg = styled.img`
    width: 310px;
    height: 310px;
    border-radius: 16px;
    filter: drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.24));
`;

const IconsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    margin-bottom: 16px;
`;

const Icon = styled.img`
    margin-left: auto;
    margin-left: 16px;
    align-self: center;
    height: 40px;
    width: 40px;
`;

const Title = styled.h5`
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: -0.01em;
    padding: 0;
    margin: 0;
`;

const Description = styled.p`
    font-size: 12px;
    line-height: 15px;
    font-weight: normal;
    letter-spacing: -0.01em;
    padding: 0;
    margin: 0;
    margin-top: 4px;
    margin-bottom: 42px;
    text-align: center;
`;

export interface HeroCardProps {
    data: {
        mainHeader: string;
        imageUrl: string;
        shortDescription: string;
    },
}


export function HeroCard({ data }: HeroCardProps) {
    return (
        <Container>
            <HeroImg alt="main" src={data.imageUrl} />
            <IconsContainer>
                <Icon alt="big play" src={bigPlayIcon} />
                <Icon alt="big plus" src={bigPlusIcon} />
            </IconsContainer>
            <Title>{data.mainHeader}</Title>
            <Description>{data.shortDescription}</Description>
        </Container>
    )
}