import { Header } from './commonStyles';

interface PromoCardProps {
    data: {
        mainHeader: string;
        shortDescription: string;
        imageUrl: string;
    }
}

export default function PromoCard({ data }: PromoCardProps) {
    return (
        <div>
            <Header>{data.mainHeader}</Header>
            <p>{data.shortDescription}</p>
            <img alt="promo" src={data.imageUrl}></img>
        </div>
    )
}

