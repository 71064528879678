import moment from "moment";
import { Region } from "../../redux/types";

import {
    GET_SINGLE_SUBSCRIPTIONS,
    GET_PACKAGE_SUBSCRIPTIONS,
    GET_PACKAGE_SUBSCRIPTIONS_SUCCESS,
    GET_SINGLE_SUBSCRIPTIONS_SUCCESS,
    GET_PREMIUM_PODCASTS_SUCCESS,
    UPDATE_FILTER,
    RESET_FILTER,
    StatisticsActionTypes,
} from "./types";

const initialState = {
    filter: {
        podcastId: "",
        platform: "",
        episodeId: "",
        episodeTitle: "",
        from: moment().subtract(6, "d").format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        onlyPremiumEpisodes: false,
        region: Region.sweden,
        subscriptionType: "package",
    },
    premiumPodcasts: null,
    loadingPodcastListenings: true,
    loadingListenings: true,
    loadingEpisodes: true,
    loadingListeningLengthData: false,
    loadingFreeAndPremiumNumbers: true,
    subscriptionsData: {
        total: 0,
        paid: 0,
        freeMonth: 0,
        cancellations: 0,
        schibstedTotal: 0,
    },
};

export default function reducer(state = initialState, action: StatisticsActionTypes) {
    switch (action.type) {
        case UPDATE_FILTER:
            return {
                ...state,
                filter: action.filter,
            };

        case RESET_FILTER:
            return {
                ...state,
                filter: initialState.filter,
            };

        case GET_PREMIUM_PODCASTS_SUCCESS:
            return {
                ...state,
                premiumPodcasts: action.premiumPodcasts,
            };

        case GET_PACKAGE_SUBSCRIPTIONS:
        case GET_SINGLE_SUBSCRIPTIONS:
            return {
                ...state,
                loading: true,
            };

        case GET_PACKAGE_SUBSCRIPTIONS_SUCCESS:
            const { payingSubscriptionsCount, freemonthsCount, activeSubscriptionsCount, schibstedCount, cancellationsCount } =
                action.packageSubscriptions;

            return {
                ...state,
                packageSubscriptions: action.packageSubscriptions,
                loading: false,
                subscriptionsData: {
                    ...state.subscriptionsData,
                    total: activeSubscriptionsCount,
                    paid: payingSubscriptionsCount,
                    freeMonth: freemonthsCount,
                    schibstedTotal: schibstedCount,
                    cancellations: cancellationsCount,
                },
            };

        case GET_SINGLE_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                singleSubscriptions: action.singleSubscriptions,
                subscriptionsData: {
                    ...state.subscriptionsData,
                    total: action.singleSubscriptions,
                },
                loading: false,
            };

        default:
            return state;
    }
}
