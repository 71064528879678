import types, {Region, RegionLogin} from "../types";
import Cookies from "universal-cookie";

const cookie = new Cookies();

const initialState = {
    region: cookie.get("selectedRegion") ? cookie.get("selectedRegion") : Region.sweden,
    timezone: getTimezone(cookie.get("selectedRegion") ? cookie.get("selectedRegion") : Region.sweden),
    accountRegion: cookie.get("selectedSchibstedAccountRegion") ? cookie.get("selectedSchibstedAccountRegion") : RegionLogin.Sweden,
};

interface GlobalAction {
    type: string;
    region: Region;
    accountRegion: RegionLogin;
}

export default function (state = initialState, action: GlobalAction) {
    switch (action.type) {
        case types.CHANGE_REGION: {
            return {
                ...state,
                region: action.region,
                timezone: getTimezone(action.region),
            };
        }
        case types.CHANGE_ACCOUNT_REGION: {
            return {
                ...state,
                accountRegion: action.region
            }
        }

        default:
            return state;
    }
}

function getTimezone(region: Region) {
    if (region == Region.sweden) return "Europe/Stockholm";
    if (region == Region.norway) return "Europe/Oslo";
    if (region == Region.finland) return "Europe/Helsinki";
    return "Europe/Stockholm";
}
