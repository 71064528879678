import { Flex, Heading, Box } from "@chakra-ui/react";
import Skeleton from "../../../components/Skeleton";

function UserDetailsSkeleton() {
    return (
        <Flex direction="column" gridGap="3rem">
            <Flex direction="column" gridGap="1rem">
                <Heading as="h2" fontSize="1.5rem">
                    Master account
                </Heading>
                <Skeleton bgColor="#eee" rounded="1rem" border="1px solid #e0e0e0">
                    <Box minWidth="100%" minHeight="15rem" />
                </Skeleton>
            </Flex>
            <Flex direction="column" gridGap="1rem">
                <Heading as="h2" fontSize="1.5rem">
                    Migration
                </Heading>
                <Flex gap="1.5rem" width="100%">
                    <Skeleton bgColor="#eee" rounded="1rem" border="1px solid #e0e0e0" width="100%">
                        <Box minWidth="100%" minHeight="11.25rem" />
                    </Skeleton>
                    <Skeleton bgColor="#eee" rounded="1rem" border="1px solid #e0e0e0" width="100%">
                        <Box minWidth="100%" minHeight="11.25rem" />
                    </Skeleton>
                    <Skeleton bgColor="#eee" rounded="1rem" border="1px solid #e0e0e0" width="100%">
                        <Box minWidth="100%" minHeight="11.25rem" />
                    </Skeleton>
                </Flex>
            </Flex>
            <Flex direction="column" gridGap="1rem">
                <Heading as="h2" fontSize="1.5rem">
                    Subscriptions
                </Heading>
                <Skeleton bgColor="#eee" rounded="1rem" border="1px solid #e0e0e0">
                    <Box minWidth="100%" minHeight="5rem" />
                </Skeleton>
            </Flex>
            <Flex direction="column" gridGap="1rem">
                <Heading as="h2" fontSize="1.5rem">
                    Used vouchers
                </Heading>
                <Skeleton bgColor="#eee" rounded="1rem" border="1px solid #e0e0e0">
                    <Box minWidth="100%" minHeight="5rem" />
                </Skeleton>
            </Flex>
            <Flex direction="column" gridGap="1rem">
                <Heading as="h2" fontSize="1.5rem">
                    Linked accounts
                </Heading>
                <Skeleton bgColor="#eee" rounded="1rem" border="1px solid #e0e0e0">
                    <Box minWidth="100%" minHeight="5rem" />
                </Skeleton>
            </Flex>
        </Flex>
    );
}

export default UserDetailsSkeleton;
