import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Heading,
    Modal,
    ModalContent,
    ModalOverlay,
    Switch,
    Text,
} from "@chakra-ui/react";

import infoIcon from "../../../assets/images/icons/alert_info_black_icon.svg";
import checkmarkIcon from "../../../assets/images/icons/check-black.svg";
import closeIcon from "../../../assets/images/icons/close-black.svg";
import { PodmeColor, UserRole } from "../../../redux/types";
import { useCallback, useEffect, useState } from "react";
import ManualMigrationModal from "../ManualMigrationModal";
import { RootState } from "../../../redux/reducers/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux";
import useAuthorizationStatus from "../../../hooks/useAuthorizationStatus";
import UnauthorizedView from "../ManualMigrationModal/UnauthorizedView";

interface Props {
    isMigrated: boolean;
}

const manageUsersState = (state: RootState) => state.manageUsers;

function MigrationInfo({ isMigrated }: Props) {
    return (
        <Flex direction="column" gridGap="1rem" width="100%">
            <Heading as="h2" fontSize="1.5rem">
                Migration
            </Heading>
            <Grid gap="1rem" templateColumns={isMigrated ? "1fr" : ["1fr", null, "repeat(2, 1fr)", "repeat(3, 1fr)"]}>
                <MigrationStatus isMigrated={isMigrated} />
                {!isMigrated && <ManualMigration isMigrated={isMigrated} />}
                {!isMigrated && <SoftMigration isMigrated={isMigrated} />}
            </Grid>
        </Flex>
    );
}

function MigrationStatus({ isMigrated }: Props) {
    const paragraphCopy = isMigrated
        ? "This user has connected to a Schibsted account successfully."
        : "This user has not connected to a Schibsted account yet.";

    return (
        <Flex
            bgColor="#eee"
            border={`1px solid ${isMigrated ? PodmeColor.Success + 90 : PodmeColor.Red + 90}`}
            padding={isMigrated ? "1rem 1.5rem" : "1.5rem"}
            rounded="1rem"
            width="100%"
            gap="1.5rem"
        >
            {isMigrated ? <CheckmarkIconCircle /> : <CloseIconCirle />}
            <Flex direction="column" gridGap="1rem">
                <Flex direction="column" gap="0.25rem">
                    {!isMigrated && (
                        <Box as="span" fontWeight="600" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                            Status
                        </Box>
                    )}
                    <Text as="span" fontWeight="500">
                        {paragraphCopy}
                    </Text>
                </Flex>
                {/* Unmigrate button is removed for now. The feature does not work as intended. */}
                {/* <Box>{isMigrated && <UnmigrateModal handleUnmigration={handleUnmigration} />}</Box> */}
            </Flex>
        </Flex>
    );
}

function ManualMigration({ isMigrated }: { isMigrated: boolean }) {
    const { manualMigrationStatus } = useSelector(manageUsersState);
    const [isMigrationModalOpen, setIsMigrationModalOpen] = useState(false);
    const paragraphCopy = isMigrated
        ? "You cannot manually migrate this user. They're already connected to a Schibsted account."
        : "Start the migration process for this user manually by choosing a specific Schibsted account.";

    useEffect(() => {
        if (manualMigrationStatus === "success" || manualMigrationStatus === "failed") {
            setTimeout(() => {
                setIsMigrationModalOpen(false);
            }, 2500);
        }
    }, [manualMigrationStatus]);

    return (
        <Flex
            opacity={isMigrated ? 0.5 : 1}
            bgColor="#eee"
            border={`1px solid ${isMigrated ? PodmeColor.Red + 90 : "#e0e0e0"}`}
            padding="1.5rem"
            rounded="1rem"
            width="100%"
            gap="1.5rem"
        >
            {isMigrated ? <CloseIconCirle /> : <InfoIconCircle />}
            <Flex direction="column" gridGap="1rem" width="100%">
                <Flex direction="column" gridGap="0.25rem">
                    <Box as="span" fontWeight="600" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                        Manual migration
                    </Box>
                    <Text as="span" fontWeight="500">
                        {paragraphCopy}
                    </Text>
                </Flex>
                {!isMigrated && (
                    <Button
                        width="fit-content"
                        height="auto"
                        rounded="3rem"
                        padding="0.75rem 1.5rem"
                        lineHeight="1.4"
                        colorScheme="cyan"
                        fontWeight="500"
                        bgColor={PodmeColor.Cyan}
                        disabled={isMigrated}
                        onClick={() => setIsMigrationModalOpen(true)}
                    >
                        Migrate
                    </Button>
                )}
                {isMigrationModalOpen && <ManualMigrationModal closeModal={() => setIsMigrationModalOpen(false)} />}
            </Flex>
        </Flex>
    );
}

function SoftMigration({ isMigrated }: { isMigrated: boolean }) {
    const dispatch = useDispatch();
    const { user, softMigrationStatus } = useSelector(manageUsersState);
    const { softMigrationEnabled } = user || {};
    const [showLoadingView, setShowLoadingView] = useState(false);
    const [showUnauthorizedView, setShowUnauthorizedView] = useState(false);
    const isAuthorized = useAuthorizationStatus({ requiredRoles: [UserRole.Admin, UserRole.Support] });
    const paragraphCopy = isMigrated
        ? "You cannot enable soft migration for this user. They're already connected to a Schibsted account."
        : "Allow this user to use their social login accounts on Podme by enabling soft migration.";

    const handleToggleSoftMigration = useCallback(
        (checked: boolean) => {
            const userAccounts = user?.accounts;
            if (!userAccounts) return;
            if (!isAuthorized) {
                setShowUnauthorizedView(true);
                return;
            }
            setShowLoadingView(true);

            userAccounts.forEach((account) => {
                dispatch(actions.toggleSoftMigration(account.userAccountId, checked));
            });
        },
        [user?.accounts, isAuthorized]
    );

    useEffect(() => {
        if ((showLoadingView && softMigrationStatus === "success") || softMigrationStatus === "failed") {
            setShowLoadingView(false);
        }
    }, [showLoadingView, softMigrationStatus]);

    return (
        <>
            {showUnauthorizedView && (
                <Modal isOpen onClose={() => setShowUnauthorizedView(false)} isCentered size="lg">
                    <ModalOverlay />
                    <ModalContent gap="0.5rem" rounded="1.5rem" padding="1.5rem" bgColor={PodmeColor.SoftWhite} position="relative">
                        <UnauthorizedView />
                    </ModalContent>
                </Modal>
            )}
            <Flex
                position="relative"
                opacity={isMigrated ? 0.5 : 1}
                bgColor="#eee"
                border={`1px solid ${isMigrated ? PodmeColor.Red + 90 : softMigrationEnabled ? PodmeColor.Success + "90" : "#d0d0d0"}`}
                padding="1.5rem"
                rounded="1rem"
                width="100%"
                gap="1.5rem"
                gridColumn={["auto", null, "span 2", "auto"]}
            >
                {isMigrated ? <CloseIconCirle /> : softMigrationEnabled ? <CheckmarkIconCircle /> : <InfoIconCircle />}
                <Flex
                    gap="1rem"
                    direction={["column", null, "row", "column"]}
                    width="100%"
                    alignItems={["flex-start", null, "center", "flex-start"]}
                >
                    <Flex direction="column" gridGap="0.25rem" width="100%">
                        <Box as="span" fontWeight="600" fontSize="0.75rem" color={"#999"} textTransform="uppercase">
                            Soft migration
                        </Box>
                        <Text as="span" fontWeight="500">
                            {paragraphCopy}
                        </Text>
                    </Flex>
                    {!isMigrated && (
                        <SoftMigrationSwitch
                            checked={!!softMigrationEnabled}
                            handleChange={handleToggleSoftMigration}
                            showLoadingView={showLoadingView}
                        />
                    )}
                </Flex>
            </Flex>
        </>
    );
}

function CloseIconCirle() {
    return (
        <Box width="100%" maxWidth="1.5rem">
            <Flex
                alignItems="center"
                justifyContent="center"
                rounded="50%"
                border={`2px solid ${PodmeColor.Red}`}
                height="100%"
                maxH="1.5rem"
            >
                <Box
                    height="0.5rem"
                    width="0.5rem"
                    bgColor={PodmeColor.Red}
                    sx={{
                        WebkitMask: `url(${closeIcon}) no-repeat center`,
                    }}
                />
            </Flex>
        </Box>
    );
}

function InfoIconCircle() {
    return (
        <Box width="100%" maxWidth="1.5rem">
            <Box
                height="100%"
                maxH="1.5rem"
                bgColor={PodmeColor.BlackPearl + "75"}
                sx={{
                    WebkitMask: `url(${infoIcon}) no-repeat center`,
                }}
            />
        </Box>
    );
}

function CheckmarkIconCircle() {
    return (
        <Box width="100%" maxWidth="1.5rem">
            <Box rounded="50%" border={`2px solid ${PodmeColor.Success}`} height="100%" maxH="1.5rem">
                <Box
                    height="100%"
                    maxH="1.5rem"
                    bgColor={PodmeColor.Success}
                    sx={{
                        WebkitMask: `url(${checkmarkIcon}) no-repeat center`,
                    }}
                />
            </Box>
        </Box>
    );
}

function SoftMigrationSwitch({
    checked,
    handleChange,
    showLoadingView,
}: {
    checked: boolean;
    handleChange: (checked: boolean) => void;
    showLoadingView: boolean;
}) {
    return (
        <FormControl
            display="flex"
            justifyContent="space-between"
            rounded="3rem"
            backgroundColor="#e5e5e5"
            p="0.75rem 1.5rem"
            border="1px solid #d0d0d0"
            lineHeight="1.4"
            opacity={showLoadingView ? 0.75 : 1}
            maxWidth={["100%", "fit-content", null, "100%"]}
            gap="1rem"
        >
            <FormLabel m="0" htmlFor="toggleSoftMigration" noOfLines={1} wordBreak="break-all">
                Enable soft migration
            </FormLabel>
            <Flex alignItems="center" gridGap="0.5rem">
                <Switch
                    disabled={showLoadingView}
                    id="toggleSoftMigration"
                    isChecked={checked}
                    onChange={(e) => handleChange(e.target.checked)}
                    sx={{
                        "> span[data-checked]": {
                            bgColor: checked ? PodmeColor.Success + "!important" : PodmeColor.Cyan + "!important",
                        },
                    }}
                />
            </Flex>
        </FormControl>
    );
}

export default MigrationInfo;
