import { ModalHeader, ModalCloseButton, ModalBody, Flex, ModalFooter, Box, Text } from "@chakra-ui/react";
import { PodmeColor } from "../../../redux/types";

function UnauthorizedView() {
    return (
        <>
            <ModalHeader p="0">Authorization error</ModalHeader>
            <ModalCloseButton top="1rem" right="1rem" rounded="50%" />
            <ModalBody p="0">
                <Flex direction="column" gridGap="1.5rem">
                    <Text>You are not authorized to perform this action. Please contact an administrator for assistance.</Text>
                </Flex>
            </ModalBody>
            <ModalFooter p="0">
                <ModalCloseButton
                    padding="0.75rem 2rem"
                    rounded="0.75rem"
                    bgColor={PodmeColor.Cyan}
                    position="static"
                    width="unset"
                    height="unset"
                    colorScheme="cyan"
                    _hover={{ filter: "brightness(0.9)" }}
                >
                    <Box as="span" fontSize="1rem" fontWeight="500">
                        Okay
                    </Box>
                </ModalCloseButton>
            </ModalFooter>
        </>
    );
}

export default UnauthorizedView;
