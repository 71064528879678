import { HorizontalList } from './commonStyles';
import { PodcastPremiumPromo, PodcastPremiumPromoProps } from './podcastPremiumPromo';
import styled from 'styled-components'

const PromoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px;
    background: #F8F8F8;
`;

const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
`;

const PromoTitle = styled.h3`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #121821;
`;

const PromoButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 10px;
    margin-bottom: 16px;
    margin-top: 16px;
    position: static;
    width: 343px;
    height: 46px;
    left: 0px;
    top: 160px;
    background: #1C2430;
    border-radius: 70px;
`;

const PromoDisclaimer = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #121821;
`;


interface PremiumPromoProps {
    data: {
        title: string;
        titleBoldSection: string;
        podcasts: [PodcastPremiumPromoProps["data"]];
        buttonText: string;
        disclaimer: string;
    }
}

export default function PremiumPromo({ data }: PremiumPromoProps) {
    return (
        <PromoContainer>
            <TopContainer>
                <PromoTitle>{data.title} <strong>{data.titleBoldSection}</strong></PromoTitle>
            </TopContainer>
            <HorizontalList>
                {data.podcasts.map((e, i) => <PodcastPremiumPromo key={i} data={e} />)}
            </HorizontalList>
            <PromoButton>{data.buttonText}</PromoButton>
            <PromoDisclaimer>{data.disclaimer}</PromoDisclaimer>
        </PromoContainer>
    )
}

