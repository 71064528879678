import Banner from "./banner";

interface BannerLinkProps {
    data: {
        imageUrl: string;
        title: string;
        description: string;
        destination: string;
    },
}

export default function BannerLink({ data }: BannerLinkProps) {
    const handleClick = () => {
        alert(`Link to ${data.destination}`);
    };
    return (
        <Banner data={data} onClick={handleClick} />
    );
}