import {
    GET_ALL_PODCASTS,
    GET_ALL_CATEGORIES,
    SEARCH_PODCAST,
    GET_EPISODES,
    CREATE_PREMIUM_PODCAST,
    ADD_RSS_PODCAST,
    RESET_PODCASTS,
    RESET_FORM,
    GET_PODCAST,
    REMOVE_PODCAST,
    REMOVE_EPISODE,
    PUBLISH_PODCAST,
    UPLOAD_EPISODE,
    EDIT_RSS_PODCAST,
    EDIT_PREMIUM_PODCAST,
    EDIT_EPISODE,
    GET_EPISODE,
    GetAllPodcasts,
    SearchPodcast,
    GetAllCategories,
    GetEpisodes,
    CreatePremiumPodcast,
    CreateRssPodcast,
    ResetPodcasts,
    ResetForm,
    Getpodcast,
    RemoveEpisode,
    PublishPodcast,
    UploadEpisode,
    EditRssPodcast,
    EditPremiumPodcast,
    GetEpisode,
    EditEpisode,
} from "./types";

function getAllPodcasts(page: number, podcastType: number, region: number): GetAllPodcasts {
    return { type: GET_ALL_PODCASTS, page, podcastType, region };
}

function searchPodcast(query: string): SearchPodcast {
    return { type: SEARCH_PODCAST, query };
}

function getAllCategories(): GetAllCategories {
    return { type: GET_ALL_CATEGORIES };
}

function getEpisodes(podcastId: string | number, page: number, pageSize: number): GetEpisodes {
    return { type: GET_EPISODES, podcastId, page, pageSize };
}

function createPremiumPodcast(formData: any): CreatePremiumPodcast {
    return { type: CREATE_PREMIUM_PODCAST, formData };
}

function createRssPodcast(formData: any): CreateRssPodcast {
    return { type: ADD_RSS_PODCAST, formData };
}

function resetPodcasts(): ResetPodcasts {
    return { type: RESET_PODCASTS };
}

function resetForm(): ResetForm {
    return { type: RESET_FORM };
}

function getPodcast(id: number | string): Getpodcast {
    return { type: GET_PODCAST, id };
}

function removePodcast(id: number | string) {
    return { type: REMOVE_PODCAST, id };
}

function removeEpisode(id: number | string): RemoveEpisode {
    return { type: REMOVE_EPISODE, id };
}

function publishPodcast(id: number | string): PublishPodcast {
    return { type: PUBLISH_PODCAST, id };
}

function uploadEpisode(formData: any): UploadEpisode {
    return { type: UPLOAD_EPISODE, formData };
}

function editRssPodcast(podcastId: number | string, formData: any): EditRssPodcast {
    return { type: EDIT_RSS_PODCAST, podcastId, formData };
}

function editPremiumPodcast(podcastId: number | string, formData: any): EditPremiumPodcast {
    return { type: EDIT_PREMIUM_PODCAST, podcastId, formData };
}

function getEpisode(id: string | number): GetEpisode {
    return { type: GET_EPISODE, id };
}

function editEpisode(episodeId: number, podcastId: number, formData: any): EditEpisode {
    return { type: EDIT_EPISODE, episodeId, podcastId, formData };
}

export default {
    getAllPodcasts,
    searchPodcast,
    getAllCategories,
    getEpisodes,
    createPremiumPodcast,
    createRssPodcast,
    resetPodcasts,
    resetForm,
    getPodcast,
    removePodcast,
    removeEpisode,
    publishPodcast,
    uploadEpisode,
    editRssPodcast,
    editPremiumPodcast,
    getEpisode,
    editEpisode,
};
