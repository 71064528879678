import { all } from "redux-saga/effects";

import { saga as ManageUsersSaga } from "../ManageUsers/redux";
import { saga as ManagePodcastSaga } from "../ManagePodcasts/redux";
import { saga as StatisticsSaga } from "../Statistics/redux";
import { saga as ManageVoucherCampaignsSaga } from "../ManageVoucherCampaigns/redux";
import { saga as ManageAccessAndPermissionsSaga } from "../AccessAndPermissions/redux";

export function* rootSaga() {
    yield all([...ManageUsersSaga]);
    yield all([...ManagePodcastSaga]);
    yield all([...StatisticsSaga]);
    yield all([...ManageVoucherCampaignsSaga]);
    yield all([...ManageAccessAndPermissionsSaga]);
}
