import { Box, Heading, Switch, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { PodcastPermissionForUser } from "../redux/types";
import DeleteDialog from "../../components/DeleteDialog";
import { Podcast } from "../../redux/types";
import CircularProgress from "../../components/CircularProgress";

interface Props {
    userPodcastPermissions: PodcastPermissionForUser[];
    premiumPodcasts: Podcast[];
    arePodcastsLoading: boolean;
}

function PodcastList({ userPodcastPermissions, premiumPodcasts, arePodcastsLoading }: Props) {
    const handleChangeStatisticsPermissions = (podcastId: number) => {
        const podcastToEdit = userPodcastPermissions.find((p) => p.podcastId === podcastId)!;
        podcastToEdit.readStatistics = !podcastToEdit.readStatistics;
    };

    const deletePodcastFromUser = (podcastId: number) => {
        const indexOfPodcastPermission = userPodcastPermissions
            .map((podcastPermission) => podcastPermission.podcastId)
            .indexOf(podcastId);
        userPodcastPermissions.splice(indexOfPodcastPermission, 1);
    };

    return (
        <Box>
            <Box pb={4} pt={2}>
                <Box pb={4} pt={2}>
                    <Heading size="md">Users podcasts</Heading>
                </Box>
                {userPodcastPermissions?.length === 0 ? (
                    <>{arePodcastsLoading ? <CircularProgress /> : <Text>No podcasts found</Text>}</>
                ) : (
                    <Table variant="striped">
                        <Thead>
                            <Tr>
                                <Th>Podcast title</Th>
                                <Th>Can manage episodes?</Th>
                                <Th>Can see statistics?</Th>
                                <Th>Delete</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {userPodcastPermissions &&
                                userPodcastPermissions.map((userPodcastPermission: PodcastPermissionForUser) => (
                                    <Tr>
                                        <Td>
                                            {premiumPodcasts.find((x) => x.id === userPodcastPermission.podcastId)?.title ??
                                                userPodcastPermission.podcastId}
                                        </Td>
                                        <Td>
                                            <Box display={"flex"} justifyContent="space-between">
                                                {userPodcastPermission.manageEpisodes ? "Yes" : "No"}
                                                <Switch isDisabled={true} isChecked={userPodcastPermission.manageEpisodes}></Switch>
                                            </Box>
                                        </Td>
                                        <Td>
                                            <Box display={"flex"} justifyContent="space-between">
                                                {userPodcastPermission.readStatistics ? "Yes" : "No"}
                                                <Switch
                                                    onChange={() => handleChangeStatisticsPermissions(userPodcastPermission.podcastId)}
                                                    isChecked={userPodcastPermission.readStatistics}
                                                ></Switch>
                                            </Box>
                                        </Td>
                                        <Td>
                                            <DeleteDialog
                                                buttonLabel="Remove"
                                                title="Remove"
                                                text="Are you sure you want to delete podcast from this user? This action cannot be reversed."
                                                deleteAction={() => {
                                                    deletePodcastFromUser(userPodcastPermission.podcastId);
                                                }}
                                                buttonVariant="link"
                                                buttonVariantColor="red"
                                                buttonSize="sm"
                                            />
                                        </Td>
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                )}
            </Box>
        </Box>
    );
}

export default PodcastList;
