import { Flex, Box, CloseButton, Text } from "@chakra-ui/react";
import { LOGIN_TYPE_LABELS, PodmeColor } from "../../../redux/types";
import { Account, UserAccount } from "../../redux/types";

interface Props {
    text: string;
    showCloseButton?: boolean;
    selectedAccount: Account;
    setSelectedAccount?: (user: Account | null) => void;
}

function SelectedAccountSection({ text, showCloseButton, selectedAccount, setSelectedAccount }: Props) {
    return (
        <Flex direction="column" gap="0.5rem" marginTop="0.5rem">
            <Text>{text}</Text>
            <Flex direction="column" gap="1.25rem" borderRadius="1rem" bgColor="#eee" position="relative" marginTop="0.5rem">
                {showCloseButton && setSelectedAccount && (
                    <CloseButton
                        rounded="50%"
                        position="absolute"
                        top="0.5rem"
                        right="0.5rem"
                        color={PodmeColor.BlackPearl}
                        zIndex="1"
                        onClick={() => {
                            setSelectedAccount(null);
                        }}
                    />
                )}
                <SelectedAccountInfo selectedAccount={selectedAccount} />
            </Flex>
        </Flex>
    );
}

export function SelectedAccountInfo({ selectedAccount }: { selectedAccount: Account | UserAccount }) {
    return (
        <Flex
            direction="column"
            gap="1.25rem"
            borderRadius="1rem"
            bgColor="#eee"
            padding="1rem"
            border="1px solid #e0e0e0"
            position="relative"
            width="100%"
        >
            <Flex direction="column" gap="0rem">
                <Box as="span" fontWeight="600" fontSize="0.75rem" color={"#999"}>
                    USER ACCOUNT MAIL
                </Box>
                <Box as="span" fontSize="1rem" fontWeight="500">
                    {selectedAccount.email}
                </Box>
            </Flex>
            <Flex direction="column" gap="0rem">
                <Box as="span" fontWeight="600" fontSize="0.75rem" color={"#999"}>
                    USER ACCOUNT ID
                </Box>
                <Box as="span" fontSize="1rem" fontWeight="500">
                    {selectedAccount.userAccountId}
                </Box>
            </Flex>
            <Flex direction="column" gap="0rem">
                <Box as="span" fontWeight="600" fontSize="0.75rem" color={"#999"}>
                    LOGIN TYPE
                </Box>
                <Box as="span" fontSize="1rem" fontWeight="500">
                    {LOGIN_TYPE_LABELS[selectedAccount.loginType]}
                </Box>
            </Flex>
        </Flex>
    );
}

export default SelectedAccountSection;
