import { extendTheme } from "@chakra-ui/react";

const breakpoints = {
    sm: "480px",
    md: "1024px",
    lg: "1280px",
    xl: "1921px",
    "2xl": "3000px",
};

export default extendTheme({
    breakpoints,
    fonts: {
        body: "Inter, Arial, Helvetica, sans-serif",
        heading: "Inter, Arial, Helvetica, sans-serif",
        mono: "Menlo, monospace",
    },
    colors: {
        white: "#f7f7f7",
        black: "#000",
        green: {
            50: "#78F5CE",
        },
        blue: {
            50: "#5FCDD0",
            100: "#779CB2",
            200: "#1C2430",
            300: "#26354A",
            400: "#121821",
        },
    },
});
