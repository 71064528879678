import { Box, Button, ModalBody, Select } from "@chakra-ui/react";
import { Podcast, UserRole } from "../../redux/types";
import PodcastList from "./PodcastList";
import { PodcastPermissionForUser } from "../redux/types";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { FormikErrors } from "formik";

interface Props {
    premiumPodcasts: Podcast[];
    userGroup: string;
    values: {
        email: string;
        userGroup: string;
        validUntil: Date;
        userPodcastPermissions: PodcastPermissionForUser[];
    };
    errors: FormikErrors<{
        email: string;
        userGroup: string;
        validUntil: Date;
    }>;
}

function PodcastSelect({ premiumPodcasts, userGroup, values, errors }: Props) {
    const dispatch = useDispatch();
    const manageAccessAndPermissionsState = (state: RootState) => state.manageAccessAndPermissions;
    const [currentlySelectedPodcast, setSelectedPodcast] = useState("");
    const { userPodcastPermissions } = useSelector(manageAccessAndPermissionsState);
    const { arePodcastsLoading } = useSelector(manageAccessAndPermissionsState);
    const isPodcaster = useMemo(() => userGroup.toLowerCase() === UserRole.Podcaster, [userGroup]);

    useEffect(() => {
        if (values.email && !errors.email) {
            dispatch(actions.getUserPodcastPermissions(values.email));
        }
    }, [values.email, errors.email]);

    useEffect(() => {
        if (userPodcastPermissions) {
            values.userPodcastPermissions = userPodcastPermissions;
        }
    }, [userPodcastPermissions]);

    const handleChangeOfPodcast = (e: React.ChangeEvent<any>) => {
        const podcast = e?.target?.value;
        setSelectedPodcast(podcast);
    };

    const addPodcastToUser = () => {
        if (!currentlySelectedPodcast) return;

        const podcastId = JSON.parse(currentlySelectedPodcast)?.id;
        values.userPodcastPermissions.push({
            podcastId: podcastId,
            manageEpisodes: true,
            readStatistics: false,
        } as PodcastPermissionForUser);

        setSelectedPodcast("");
    };

    return (
        <>
            <ModalBody>
                {isPodcaster ? (
                    <PodcastList
                        premiumPodcasts={premiumPodcasts}
                        userPodcastPermissions={values.userPodcastPermissions}
                        arePodcastsLoading={arePodcastsLoading}
                    />
                ) : (
                    ""
                )}
            </ModalBody>
            <ModalBody>
                {isPodcaster ? (
                    <Box>
                        <Select id="podcasts" name="podcasts" placeholder="Select" size="sm" onChange={handleChangeOfPodcast}>
                            {(premiumPodcasts as Podcast[])
                                ?.filter((podcast) => !values?.userPodcastPermissions?.map((p) => p.podcastId).includes(podcast.id))
                                .sort((textA, textB) => (textA.title < textB.title ? -1 : textA.title > textB.title ? 1 : 0))
                                .map((podcast) => (
                                    <option key={podcast.id} value={JSON.stringify(podcast)}>
                                        {podcast.title}
                                    </option>
                                ))}
                        </Select>
                        <br />
                        <Button disabled={!currentlySelectedPodcast} onClick={addPodcastToUser}>
                            Add podcast to user
                        </Button>
                    </Box>
                ) : (
                    ""
                )}
            </ModalBody>
        </>
    );
}

export default PodcastSelect;
