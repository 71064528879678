import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Checkbox,
    CheckboxGroup,
    Input,
    Box,
    Textarea,
    Heading,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";

import UploadImageBox from "./UploadImageBox";
import { UserRole } from "../../ManageUsers/redux/types";
import { getCategoryName, filterOutPremiumCategory, initialPrimaryCategories } from "../../utils";

import { LanguageCodes } from "../constants";

const editPremiumPodcastState = (state) => state.managePodcasts.editPremiumPodcast;
const manageUsersState = (state) => state.manageUsers;

function EditPremiumPodcast({ onSubmit, resetForm }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { categories, podcast, isLoading, updateSuccessful } = useSelector(editPremiumPodcastState);
    const { userRole } = useSelector(manageUsersState);
    const [regions] = useState([
        { id: 1, name: "Sweden" },
        { id: 2, name: "Norway" },
        { id: 3, name: "Finland" },
    ]);
    const [formSubmitted, setFormsubmitted] = useState(false);
    const [languages] = useState(LanguageCodes);

    useEffect(() => {
        if (!isOpen) setFormsubmitted(false);
        resetForm();
    }, [isOpen]);

    useEffect(() => {
        if (updateSuccessful) {
            onClose();
        }
    }, [updateSuccessful]);

    if (!podcast) return null;

    return (
        <>
            <Button colorScheme="teal" size="md" onClick={onOpen}>
                Edit podcast
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
                <ModalOverlay backgroundColor="rgba(0,0,0,0.7)" />
                <Formik
                    initialValues={{
                        title: podcast.title,
                        author: `${podcast.author.firstName} ${podcast.author.lastName}`,
                        description: podcast.description,
                        categories: podcast && podcast.categories ? podcast.categories.map((cat) => cat.id.toString()) : [],
                        price: 29,
                        image: null,
                        imageUrl: podcast.smallImageUrl,
                        regions: podcast && podcast.regions ? podcast.regions.map((reg) => reg.toString()) : [],
                        rss: podcast.rssLink,
                        primaryCategory: initialPrimaryCategories(podcast),
                        language: podcast.languageCode ? podcast.languageCode : 0,
                        isPodmeOriginal: podcast.isPodmeOriginal,
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.title) {
                            errors.title = "The field cannot be empty";
                        }
                        if (!values.author) {
                            errors.author = "The field cannot be empty";
                        }
                        if (!values.description) {
                            errors.description = "The field cannot be empty";
                        }
                        if (!values.image && !values.imageUrl) {
                            errors.image = "You must select an image";
                        }
                        if (!values.price) {
                            errors.price = "You must enter price";
                        }

                        if (filterOutPremiumCategory(values.categories).length === 0) {
                            errors.categories = "You have to select at least one category";
                        }

                        if (values.primaryCategory === 0) {
                            errors.primaryCategory = "You have to select primary category";
                        }

                        if (values.language === 0) {
                            errors.language = "You have to select language";
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            onSubmit(values);
                            setSubmitting(false);
                            setFormsubmitted(false);
                        }, 800);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                        <ModalContent>
                            <ModalHeader>
                                <Heading size="lg">Edit podcast</Heading>
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Form>
                                    <Box paddingBottom={6}>
                                        <FormControl isInvalid={errors.title && touched.title} isRequired>
                                            <FormLabel htmlFor="title">Podcast title</FormLabel>
                                            <Input
                                                id="title"
                                                placeholder="Podcast title"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.title}
                                            />
                                            <FormErrorMessage>{errors.title}</FormErrorMessage>
                                        </FormControl>
                                    </Box>
                                    <Box paddingBottom={6}>
                                        <FormControl isInvalid={errors.author && touched.author} isRequired>
                                            <FormLabel htmlFor="author">Author</FormLabel>
                                            <Input
                                                id="author"
                                                placeholder="Who is the author of the podcast?"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.author}
                                            />
                                            <FormErrorMessage>{errors.author}</FormErrorMessage>
                                        </FormControl>
                                    </Box>

                                    <Box paddingBottom={6}>
                                        <FormControl isRequired isInvalid={errors.image && formSubmitted}>
                                            <FormLabel htmlFor="image">Podcast image</FormLabel>
                                            <UploadImageBox
                                                id="image"
                                                imageUrl={values.imageUrl}
                                                setValue={(value) => setFieldValue("image", value.file)}
                                            />
                                            <FormHelperText>The resolution should be 1024x1024</FormHelperText>
                                            <FormErrorMessage>{errors.image}</FormErrorMessage>
                                        </FormControl>
                                    </Box>

                                    <Box paddingBottom={6}>
                                        <FormControl isRequired isInvalid={errors.description && touched.description}>
                                            <FormLabel htmlFor="description">Description</FormLabel>
                                            <Textarea
                                                id="description"
                                                placeholder="Describe the podcast"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.description}
                                            />
                                            <FormErrorMessage>{errors.description}</FormErrorMessage>
                                        </FormControl>
                                    </Box>
                                    <Box paddingBottom={6}>
                                        <FormControl as="fieldset" isRequired isInvalid={errors.categories && formSubmitted}>
                                            <FormLabel as="legend" marginBottom={2}>
                                                Select categories that match the podcast
                                            </FormLabel>
                                            <CheckboxGroup
                                                id="categories"
                                                colorScheme="teal"
                                                defaultValue={values.categories}
                                                isInline
                                                onChange={(cat) => {
                                                    const catArray = cat.map((item) => parseInt(item));
                                                    setFieldValue("categories", catArray);
                                                    const filteredOut = filterOutPremiumCategory(catArray);
                                                    let primaryIsNull = false;
                                                    if (filteredOut.filter((cat) => cat === values.primaryCategory).length === 0) {
                                                        setFieldValue("primaryCategory", 0);
                                                        primaryIsNull = true; // had to to it like this beacuse of formik
                                                    }

                                                    if ((values.primaryCategory === 0 || primaryIsNull) && filteredOut.length > 0)
                                                        setFieldValue("primaryCategory", filteredOut[0]);
                                                }}
                                            >
                                                {categories.map((category) => (
                                                    <Checkbox key={category.id} value={`${category.id}`} marginRight={4}>
                                                        {category.name}
                                                    </Checkbox>
                                                ))}
                                            </CheckboxGroup>
                                            <FormErrorMessage>{errors.categories}</FormErrorMessage>
                                        </FormControl>
                                    </Box>
                                    <Box paddingBottom={6}>
                                        <FormControl as="fieldset" isInvalid={errors.primaryCategory && formSubmitted}>
                                            <FormLabel as="legend" marginBottom={2}>
                                                Select primary category for podcast
                                            </FormLabel>
                                            <Field
                                                as="select"
                                                name="primaryCategory"
                                                onChange={(cat) => {
                                                    setFieldValue("primaryCategory", parseInt(cat.target.value));
                                                }}
                                                placeholder="Select category"
                                                value={values.primaryCategory}
                                            >
                                                <option defaultValue>Select category</option>
                                                {filterOutPremiumCategory(values.categories).map((category) => (
                                                    <option key={category} value={`${category}`}>
                                                        {getCategoryName(category, categories)}
                                                    </option>
                                                ))}
                                            </Field>
                                        </FormControl>
                                    </Box>
                                    <Box paddingBottom={6}>
                                        <FormControl display="flex" alignItems="center">
                                            <FormLabel as="legend" htmlFor="isPodmeOriginal">
                                                Is PodmeOriginal
                                            </FormLabel>
                                            <Checkbox
                                                id="isPodmeOriginal"
                                                name="isPodmeOriginal"
                                                defaultChecked={values.isPodmeOriginal}
                                                value={values.isPodmeOriginal}
                                                onChange={handleChange}
                                            ></Checkbox>
                                        </FormControl>
                                    </Box>
                                    <Box paddingBottom={6}>
                                        <FormControl as="fieldset" isRequired isInvalid={errors.language && formSubmitted}>
                                            <FormLabel as="legend" marginBottom={2}>
                                                Select language for podcast
                                            </FormLabel>
                                            <Field as="select" name="language" onChange={handleChange} value={values.language}>
                                                <option defaultValue hidden value="0">
                                                    Select language
                                                </option>
                                                {languages.map((language) => (
                                                    <option key={language.LanguageCode} value={`${language.LanguageCode}`}>
                                                        {language.LanguageCode} ({language.Name})
                                                    </option>
                                                ))}
                                            </Field>
                                            <FormErrorMessage>{errors.language}</FormErrorMessage>
                                        </FormControl>
                                    </Box>
                                    {(userRole === UserRole.Admin || userRole === UserRole.Editor) && (
                                        <Box paddingBottom={6}>
                                            <FormControl as="fieldset" isRequired isInvalid={errors.regions && formSubmitted}>
                                                <FormLabel as="legend" marginBottom={2}>
                                                    Select to which region(s) the podcast should belong
                                                </FormLabel>
                                                <CheckboxGroup
                                                    id="regions"
                                                    colorScheme="teal"
                                                    defaultValue={values.regions}
                                                    isInline
                                                    spacing={4}
                                                    onChange={(reg) => {
                                                        setFieldValue("regions", reg);
                                                    }}
                                                >
                                                    {regions.map((region) => (
                                                        <Checkbox key={region.id} value={`${region.id}`}>
                                                            {region.name}
                                                        </Checkbox>
                                                    ))}
                                                </CheckboxGroup>
                                                <FormErrorMessage>{errors.regions}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                    )}
                                    <Box paddingBottom={6}>
                                        <FormControl>
                                            <FormLabel htmlFor="rss">RSS feed</FormLabel>
                                            <Input
                                                id="rss"
                                                placeholder="RSS feed"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.rss}
                                            />
                                        </FormControl>
                                    </Box>
                                </Form>
                            </ModalBody>
                            <ModalFooter display="flex" justifyContent="center">
                                <Box padding={4}>
                                    <Button
                                        colorScheme="green"
                                        size="lg"
                                        onClick={() => {
                                            handleSubmit();
                                            setFormsubmitted(true);
                                        }}
                                        isLoading={isSubmitting || isLoading}
                                    >
                                        Update podcast
                                    </Button>
                                </Box>
                            </ModalFooter>
                        </ModalContent>
                    )}
                </Formik>
            </Modal>
        </>
    );
}

export default EditPremiumPodcast;
