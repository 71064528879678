import moment from "moment";
import axios, { AxiosResponse, Method } from "axios";
import cookie from "react-cookies";

import config from "../config";
import { Subscription, SubscriptionStatus } from "../ManageUsers/redux/types";
import { UploadAudioFileResponse, UploadImageFileResponse } from "../ManagePodcasts/redux/types";
import { UserRole } from "../redux/types";
import { debounce } from "lodash";

export function formatDate(date: Date) {
    const formattedDate = moment(date).format("DD-MM-YYYY");

    return formattedDate;
}

export function formatDateAndTime(date: Date) {
    const formattedDate = moment(date).format("HH:mm DD-MM-YYYY");

    return formattedDate;
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isUserAuthorizedForAction(userRole?: UserRole, userToEditRole?: string): boolean {
    if (userRole === UserRole.Admin) return true;
    else if (!userToEditRole) return true;
    else if (userRole === UserRole.Editor && userToEditRole.toLowerCase() === UserRole.Podcaster.toLowerCase()) return true;
    return false;
}

export function sleep(milliseconds: Number | string) {
    const date = Date.now();
    let currentDate = null;
    do {
        currentDate = Date.now();
    } while (currentDate - date < milliseconds);
}

export function fancyTimeFormat(duration: number) {
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;
    let ret = "";
    ret += "" + (hrs < 10 ? "0" + hrs : hrs) + ":" + (mins < 10 ? "0" : "");
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

export function timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getCategoryName = (id: string, categories: any[]) => {
    return categories.find((cat) => cat.id === parseInt(id))?.name;
};
export const filterOutPremiumCategory = (categories: any[]) => {
    return categories.map((id) => parseInt(id)).filter((cc) => cc !== 222);
};

export const initialPrimaryCategories = (podcast: any) => {
    if (podcast && podcast.categories) {
        if (podcast.categories.filter((cat: any) => cat.isPrimary)?.map((cat: any) => cat.id.toString()).length > 0) {
            return podcast.categories.filter((cat: any) => cat.isPrimary)?.map((cat: any) => cat.id)[0];
        } else {
            return filterOutPremiumCategory(podcast.categories.map((category: any) => category.id))[0];
        }
    } else {
        return 0;
    }
};
export const createAxiosRequest = async (url: string, requestHeaders?: any, methodType?: Method): Promise<AxiosResponse> => {
    let headers = requestHeaders || {};

    const token = cookie.load("podme_admincookie");
    const session = JSON.parse(localStorage.getItem("hello")!);
    if (token?.access_Token) {
        headers = { Authorization: `Bearer ${token.access_Token}` };
    } else if (token || session) {
        headers = { Authorization: `Bearer ${token || session.adminAdB2CSignIn.access_token}` };
    }

    const response = await axios({
        method: methodType ?? "get",
        url: `${config.BACKEND_API_URL}/${url}`,
        headers,
    });

    return response;
};

export const createPostAxiosRequest = async (url: string, data: any): Promise<AxiosResponse> => {
    let headers = {};

    const token = cookie.load("podme_admincookie");
    const session = JSON.parse(localStorage.getItem("hello")!);

    if (token?.access_Token) {
        headers = { Authorization: `Bearer ${token.access_Token}` };
    } else if (token || session) {
        headers = { Authorization: `Bearer ${token || session.adminAdB2CSignIn.access_token}` };
    }

    const response = await axios({
        method: "post",
        url: `${config.BACKEND_API_URL}/${url}`,
        headers: headers,
        data: data,
    });

    return response;
};

export const createPutAxiosRequest = async (url: string, data: any): Promise<AxiosResponse> => {
    let headers = {};

    const token = cookie.load("podme_admincookie");
    const session = JSON.parse(localStorage.getItem("hello")!);

    if (token?.access_Token) {
        headers = { Authorization: `Bearer ${token.access_Token}` };
    } else if (token || session) {
        headers = { Authorization: `Bearer ${token || session.adminAdB2CSignIn.access_token}` };
    }

    const response = await axios({
        method: "put",
        url: `${config.BACKEND_API_URL}/${url}`,
        headers: headers,
        data: data,
    });

    return response;
};

export const createPatchAxiosRequest = async (url: string, data: any): Promise<AxiosResponse> => {
    let headers = {};

    const token = cookie.load("podme_admincookie");
    const session = JSON.parse(localStorage.getItem("hello")!);

    if (token?.access_Token) {
        headers = { Authorization: `Bearer ${token.access_Token}` };
    } else if (token || session) {
        headers = { Authorization: `Bearer ${token || session.adminAdB2CSignIn.access_token}` };
    }

    const response = await axios({
        method: "patch",
        url: `${config.BACKEND_API_URL}/${url}`,
        headers: headers,
        data: data,
    });
    return response;
};

export const createDeleteAxiosRequest = (url: string, data?: any): Promise<AxiosResponse> => {
    let headers = {};

    const token = cookie.load("podme_admincookie");
    const session = JSON.parse(localStorage.getItem("hello")!);

    if (token?.access_Token) {
        headers = { Authorization: `Bearer ${token.access_Token}` };
    } else if (token || session) {
        headers = { Authorization: `Bearer ${token || session.adminAdB2CSignIn.access_token}` };
    }

    return axios({
        method: "delete",
        url: `${config.BACKEND_API_URL}/${url}`,
        headers: headers,
        data: data,
    });
};

export const uploadAudioFileRequest = (formData?: any): Promise<AxiosResponse<UploadAudioFileResponse>> => {
    let headers = {};

    const token = cookie.load("podme_admincookie");
    const session = JSON.parse(localStorage.getItem("hello")!);

    if (token?.access_Token) {
        headers = { Authorization: `Bearer ${token.access_Token}` };
    } else if (token || session) {
        headers = { Authorization: `Bearer ${token || session.adminAdB2CSignIn.access_token}` };
    }

    return axios({
        method: "post",
        url: `${config.FILE_SERVICE}/api/file/upload/audio`,
        headers: { ...headers, "Content-type": `multipart/form-data; boundary=${formData._boundary}` },
        data: formData,
    });
};

export const uploadImageFileRequest = (formData?: any): Promise<AxiosResponse<UploadImageFileResponse>> => {
    let headers = {};

    const token = cookie.load("podme_admincookie");
    const session = JSON.parse(localStorage.getItem("hello")!);

    if (token?.access_Token) {
        headers = { Authorization: `Bearer ${token.access_Token}` };
    } else if (token || session) {
        headers = { Authorization: `Bearer ${token || session.adminAdB2CSignIn.access_token}` };
    }

    return axios({
        method: "post",
        url: `${config.FILE_SERVICE}/api/file/upload/image`,
        headers: { ...headers, "Content-type": `multipart/form-data; boundary=${formData._boundary}` },
        data: formData,
    });
};

export const getActiveSubscriptions = (subscriptions: Subscription[] | undefined) => {
    if (!subscriptions) return [];

    return subscriptions.filter((sub) =>
        [
            SubscriptionStatus.Active,
            SubscriptionStatus.Error,
            SubscriptionStatus.TestPeriod,
            SubscriptionStatus.FailedToWithdrawMoney,
            SubscriptionStatus.Cancelled,
        ].includes(sub.subscriptionState)
    );
};

export const getInactiveSubscriptions = (subscriptions: Subscription[] | undefined) => {
    if (!subscriptions) return [];

    return subscriptions?.filter((sub) =>
        [SubscriptionStatus.PaymentProblem, SubscriptionStatus.Inactive].includes(sub.subscriptionState)
    );
};

export const debouncedSearch = debounce((searchFn: () => void) => searchFn(), 350);
