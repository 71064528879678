import { Box, Stack, Tooltip, Text } from "@chakra-ui/react";
import { IoMdInformationCircleOutline } from "react-icons/io";

export default function RoleTooltip() {
    return (
        <Tooltip
            hasArrow
            placement="bottom"
            bg="#f7f7f7"
            color="black"
            label={
                <Stack>
                    <Text>Roles description:</Text>
                    <Text fontWeight="300">
                        Admin - can add/edit/remove users from admin panel, has access to all segments of Admin Panel with editing
                        rights
                    </Text>
                    <Text fontWeight="300">
                        Editor - cannot add/edit/remove users from admin panel, has access to all segments of Admin Panel.
                    </Text>
                    <Text fontWeight="300">
                        Support - can access Users and Voucher and campaign tabs and edit anything within them
                    </Text>
                    <Text fontWeight="300">
                        Podcaster - external content creator with access to Podcasts and Statistics tabs, limited by a number of
                        specified podcasts only. Can't see or edit any of the unrelated content.
                    </Text>
                </Stack>
            }
        >
            <Box padding={"0.3em"} color={"grey.300"}>
                <IoMdInformationCircleOutline />
            </Box>
        </Tooltip>
    );
}
