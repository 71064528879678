import React from "react";
import Datetime from "react-datetime";
import { Box, Text, Button } from "@chakra-ui/react";
import moment from "moment";

import "./DatePicker.css";

export default function DatePicker({ defaultValue, setDate }) {
    return (
        <Datetime
            dateFormat='DD-MM-YYYY'
            timeFormat='HH:mm'
            defaultValue={defaultValue ? moment(defaultValue) : new Date()}
            onChange={(val) => setDate(val.format("YYYY-MM-DD HH:mm:ss.SSSSSSSZ"))}
            renderInput={({ value }, openCalendar) => (
                <Box paddingTop={4} paddingBottom={2}>
                    <Text fontWeight='semibold' marginRight={2}>
                        {value}
                    </Text>
                    <Button colorScheme='teal' size='md' variant='link' onClick={openCalendar}>
                        Change publish date
                    </Button>
                </Box>
            )}
        />
    );
}
