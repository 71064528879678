import React, { useState } from "react";
import { Box, Image, Text, Skeleton } from "@chakra-ui/react";
import { colors } from "../../constants";

import { useMedia } from "../../hooks";
import { formatDate, formatDateAndTime } from "../../utils/index";
import DeleteDialog from "../../components/DeleteDialog";
import EditEpisode from "../components/EditEpisode";

import premiumBadge from "../../assets/images/premium-badge.png";
import { RemoveEpisode, ResetForm, GetEpisode, EditEpisode as EditEpisodeType } from "../redux/types";
import { Podcast } from "../../redux/types";

interface EpisodeItemProps {
    episode: any;
    podcast: Podcast | null;
    getEpisode?: () => GetEpisode;
    resetForm?: () => ResetForm;
    handleSubmit?: (values: any) => EditEpisodeType;
    deleteEpisode?: () => RemoveEpisode;
}

export default function EpisodeItem({ episode, podcast, getEpisode, resetForm, handleSubmit, deleteEpisode }: EpisodeItemProps) {
    const { isSmallScreen } = useMedia();
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            padding={3}
            border={`1px solid ${colors.lightGray}`}
            key={episode?.id}
        >
            <Box display='flex' width='80%'>
                <Box paddingRight={2} position='relative'>
                    <Skeleton isLoaded={imageLoaded}>
                        {episode?.isPremium && (
                            <Image src={premiumBadge} position='absolute' top='-2px' right='10px' boxSize='20px' objectFit='contain' />
                        )}
                        <Image src={episode?.smallImageUrl} alt={episode?.title} boxSize='50px' onLoad={() => setImageLoaded(true)} />
                    </Skeleton>
                </Box>
                <Box display='flex' flexDir='column' justifyContent='flex-start' width='100%'>
                    <Skeleton isLoaded={episode != null && episode.title} height='20px'>
                        <Text fontSize='sm' fontWeight='semibold' isTruncated maxWidth='80%'>
                            {episode?.title}
                        </Text>
                    </Skeleton>

                    <Box display='flex'>
                        <Text as='span' fontSize='sm' marginRight={4}>
                            {episode?.publishDate && !episode.isPublished
                                ? `Scheduled: ${formatDateAndTime(episode?.publishDate)}`
                                : `Published: ${formatDate(episode?.dateAdded)}`}
                        </Text>
                        {!isSmallScreen && (
                            <Text as='span' fontSize='sm' marginRight={1}>
                                {`Encoded: ${episode?.isEncoded ? "Yes" : "No"}`}
                            </Text>
                        )}
                    </Box>
                    <Box display='flex' pt={2}>
                        <Text as='span' fontSize='sm' marginRight={1}>
                            {`ID: ${episode?.id}`}
                        </Text>
                    </Box>
                </Box>
            </Box>

            <Box display='flex' flexDirection={["column", null, "row"]} justifyContent='flex-end' minWidth={[0, null, "182px"]}>
                {podcast?.isPremium && !podcast?.isManagedBySchibsted && !episode?.isRssEpisode && (
                    <Box mr={[0, 0, 4]}>
                        <EditEpisode
                            publishDate={episode?.publishDate}
                            getEpisode={getEpisode}
                            resetForm={resetForm}
                            onSubmit={handleSubmit}
                        />
                    </Box>
                )}
                {!podcast?.isManagedBySchibsted && !episode?.isArchived && (
                    <DeleteDialog
                        buttonLabel='Remove'
                        title={`Remove ${episode?.title}`}
                        text='Are you sure? This action cannot be reverted.'
                        deleteAction={deleteEpisode}
                        buttonVariant='solid'
                        buttonVariantColor='red'
                        buttonSize='sm'
                    />
                )}
            </Box>
        </Box>
    );
}
