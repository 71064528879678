import {Text} from "@chakra-ui/react";
import "./CopyableText.css";

const copyContent = (copyText: string | undefined) => {
    navigator.clipboard.writeText(String(copyText));
}

interface Props {
    text: string | undefined;
}

export default function CopyableText({text}: Props) {
    return (
        <>
            <Text
                as="div"
                className="hover-text"
                onClick={() => copyContent(text)}
            >{text}
                <Text
                    as="span"
                    className="tooltip-text">
                    Click to copy to clipboard
                </Text>
            </Text>
        </>
    );
}