import { Region } from "../../redux/types";
import {
    GET_SINGLE_SUBSCRIPTIONS,
    GET_PACKAGE_SUBSCRIPTIONS,
    UPDATE_FILTER,
    RESET_FILTER,
    GET_PREMIUM_PODCASTS,
    GetSingleSubscriptions,
    GetPackageSubscriptions,
    GetPremiumPodcasts,
    UpdateFilter,
    ResetFilter,
    Filter,
} from "./types";

function getSingleSubscriptions({ podcastId, platform }: Filter): GetSingleSubscriptions {
    return { type: GET_SINGLE_SUBSCRIPTIONS, podcastId, platform };
}

function getPackageSubscriptions({ platform }: Filter, region: Region): GetPackageSubscriptions {
    return { type: GET_PACKAGE_SUBSCRIPTIONS, platform, region };
}
function getPremiumPodcasts(region: Region): GetPremiumPodcasts {
    return { type: GET_PREMIUM_PODCASTS, region };
}

function updateFilter(filter: Filter): UpdateFilter {
    return { type: UPDATE_FILTER, filter };
}

function resetFilter(filter: Filter): ResetFilter {
    return { type: RESET_FILTER, filter };
}

export default {
    getSingleSubscriptions,
    getPackageSubscriptions,
    updateFilter,
    resetFilter,
    getPremiumPodcasts,
};
