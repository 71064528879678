import styled from "styled-components";

export const Header = styled.h4`
    padding: 0px 15px;
    margin-bottom: 10px;
    font-style: normal; 
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #F8F8F8;
`;

export const VerticalList = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HorizontalList = styled.div`
    padding-left: 15px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    & > div {
        flex: 0 0 auto;
 
    }
    width: 100%;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
`;