import { EpisodeList, EpisodeListProps } from "./episodeList";
import { HorizontalList } from './commonStyles';

import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    
`;
const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-bottom: 10px;
`;
const Title = styled.h4`
    padding: 0;
    margin: 0;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    letter-spacing: -0.02em;
`;

const Subtitle = styled.h5`
    padding: 0;
    margin: 0;
    color: #BABABA;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
`;

interface PodcastNuggetsProps {
    data: {
        title: string;
        subtitle: string;
        episodeLists: [EpisodeListProps["data"]];
    }
}

export default function PodcastNuggets({ data }: PodcastNuggetsProps) {
    return (
        <Container>
            <TopContainer>
                <Subtitle>{data.subtitle}</Subtitle>
                <Title>{data.title}</Title>
            </TopContainer>
            <HorizontalList>
                {data.episodeLists.map((e, i) => <EpisodeList isSmall key={i} data={e} />)}
            </HorizontalList>
        </Container>
    )
}