import { keyframes } from "@chakra-ui/react";

const showTypeTag = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-3rem);
    }
    90% {
        opacity: 1;
        transform: translateX(-0.25rem);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
    `;

const hideTypeTag = keyframes`
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    30% {
        opacity: 0;
        transform: translateX(-1rem);
    }
    100% {
        opacity: 0;
        transform: translateX(-3rem);
    }
    `;

const pulsate = keyframes`
    0% {
      background-color: #eee;
    }
    50% {
      background-color: #f0edda;
    }
    100% {
      background-color: #eee;
    }`;

const leftRight = keyframes`
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
  `;

const expand = keyframes`
    0% {
      transform: translateY(-5rem);
      filter: opacity(0);
    }
    30% {
      transform: translateY(-5rem);
      filter: opacity(0);
    },
    70% {
      transform: translateY(0);
      filter: opacity(0.25);
    },
    100% {
      transform: translateY(0);
      filter: opacity(1);
    }`;

const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }`;

export { showTypeTag, hideTypeTag, pulsate, leftRight, expand, fadeIn };
