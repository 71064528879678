import React, { useState } from 'react';
import Banner from "./banner";
import { EpisodeList } from "./episodeList";
import { EpisodeProps } from './episode';

interface BannerWithEpisodeListProps {
    data: {
        imageUrl: string;
        title: string;
        description: string;
        destination: string;
        episodes: [EpisodeProps["data"]];
    },
}

export default function BannerWithEpisodeList({ data }: BannerWithEpisodeListProps) {
    const [isExpanded, setExpanded] = useState(false);
    const handleClick = () => {
        setExpanded(!isExpanded);
    };
    return (
        isExpanded
            ? (<>
                <EpisodeList data={data} />
                <button onClick={handleClick}>Hide</button>
            </>)
            : (<Banner data={data} onClick={handleClick} />)
    );
}