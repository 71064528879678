import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Box,
    Heading,
    FormHelperText,
    Select,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import DatePicker from "../../components/DatePicker/DatePicker";
import { RootState } from "../../redux/reducers/rootReducer";

import { VoucherCampaignDiscountType, VoucherCampaignType, VoucherCampaignTypeName } from "../redux/types";
import { Region } from "../../redux/types";

interface Props {
    region: Region;
    voucherCampaignTypeNames: VoucherCampaignTypeName[];
    discountTypes: VoucherCampaignDiscountType[] | null;
    onSubmit: any;
}

export default function CreateVoucherCampaign({ region, voucherCampaignTypeNames, discountTypes, onSubmit }: Props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [, setFormsubmitted] = useState(false);
    const manageVoucherCampaignsState = (state: RootState) => state.manageVoucherCampaigns;
    const { voucherCampaignCreated } = useSelector(manageVoucherCampaignsState);

    useEffect(() => {
        if (!isOpen) {
            setFormsubmitted(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (voucherCampaignCreated) {
            onClose();
        }
    }, [voucherCampaignCreated]);

    return (
        <>
            <Button colorScheme="teal" size="md" onClick={onOpen}>
                Create voucher campaign
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
                <ModalOverlay backgroundColor="rgba(0,0,0,0.7)" />
                <Formik
                    initialValues={{
                        region,
                        voucherCampaignType: VoucherCampaignType.Generic,
                        voucherCampaignName: "",
                        voucherCode: "",
                        validFrom: new Date(),
                        validUntil: new Date(new Date().setMonth(new Date().getMonth() + 1)),
                        discountTypeId: undefined,
                        churnEligibilityInDays: "0",
                        redemptionLimit: "",
                        monthCredit: "",
                    }}
                    validate={(values) => {
                        const errors: {
                            voucherCampaignName?: string;
                            voucherCode?: string;
                            validFrom?: string;
                            validUntil?: string;
                            discountTypeId?: string;
                            churnEligibilityInDays?: string;
                            redemptionLimit?: string;
                            monthCredit?: string;
                        } = {};

                        if (values.voucherCampaignType == VoucherCampaignType.Generic) {
                            if (!values.voucherCode) {
                                errors.voucherCode = "You must enter a voucher campaign code";
                            }
                            if (values.voucherCode && values.voucherCode.length > 20) {
                                errors.voucherCode = "Voucher campaign code needs to be less than 20 characters";
                            }
                        }

                        if (!values.voucherCampaignName) {
                            errors.voucherCampaignName = "You must enter a voucher campaign name";
                        }
                        if (values.voucherCampaignName && values.voucherCampaignName.length > 20) {
                            errors.voucherCampaignName = "Voucher campaign name needs to be less than 20 characters";
                        }

                        if (!values.validFrom) {
                            errors.validFrom = "You must set an expiration date";
                        }
                        if (!values.validUntil) {
                            errors.validUntil = "You must set an expiration date";
                        }
                        if (new Date(values.validUntil).getTime() < new Date(values.validFrom).getTime()) {
                            errors.validUntil = "End date must be later than start date";
                        }
                        if (values.voucherCampaignType != VoucherCampaignType.Reward && !values.discountTypeId) {
                            errors.discountTypeId = "You must select a discount type";
                        }
                        if (values.voucherCampaignType != VoucherCampaignType.Reward && values.churnEligibilityInDays === "0") {
                            errors.churnEligibilityInDays = "You must select a churn eligibility value";
                        }

                        if (
                            values.voucherCampaignType == VoucherCampaignType.Unique ||
                            values.voucherCampaignType == VoucherCampaignType.Reward
                        ) {
                            if (!values.redemptionLimit) {
                                errors.redemptionLimit = "You must enter a number of vouchers to redeem";
                            }
                        }

                        if (values.voucherCampaignType == VoucherCampaignType.Reward) {
                            if (!values.monthCredit) {
                                values.monthCredit = "1";
                            }
                        }

                        const limitMinValue = 1;
                        const limitMaxValue = 50000;

                        if (values.redemptionLimit && Number(values.redemptionLimit) < limitMinValue) {
                            errors.redemptionLimit = `Limit must be at least ${limitMinValue}`;
                        }

                        if (values.redemptionLimit && Number(values.redemptionLimit) > limitMaxValue) {
                            errors.redemptionLimit = `Limit cannot exceed ${limitMaxValue}`;
                        }

                        return errors;
                    }}
                    onSubmit={(values) => {
                        values.voucherCampaignType = Number(values.voucherCampaignType);
                        onSubmit(values);
                        setFormsubmitted(false);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <ModalContent>
                            <>
                                <ModalHeader>
                                    <Heading size="lg">Create voucher campaign</Heading>
                                </ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Form>
                                        <Box paddingBottom={6}>
                                            <FormControl id="voucherCampaignType">
                                                <FormLabel htmlFor="voucherCampaignType">Campaign type</FormLabel>
                                                <Select
                                                    id="voucherCampaignType"
                                                    onChange={handleChange}
                                                    value={values.voucherCampaignType}
                                                >
                                                    {voucherCampaignTypeNames.map((p: VoucherCampaignTypeName) => (
                                                        <option value={p.type} key={p.type}>
                                                            {p.name}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box paddingBottom={6}>
                                            <FormControl
                                                isRequired
                                                isInvalid={!!errors.voucherCampaignName && touched.voucherCampaignName}
                                            >
                                                <FormLabel htmlFor="voucherCampaignName">Campaign name</FormLabel>
                                                <Input
                                                    id="voucherCampaignName"
                                                    type="text"
                                                    placeholder="Voucher campaign name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.voucherCampaignName}
                                                />
                                                <FormErrorMessage>{errors.voucherCampaignName}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                        <Box
                                            paddingBottom={6}
                                            display={values.voucherCampaignType == VoucherCampaignType.Generic ? "block" : "none"}
                                        >
                                            <FormControl isRequired isInvalid={!!errors.voucherCode && touched.voucherCode}>
                                                <FormLabel htmlFor="voucherCode">Voucher code</FormLabel>
                                                <Input
                                                    id="voucherCode"
                                                    type="text"
                                                    placeholder="Voucher code"
                                                    onChange={(evt) => {
                                                        evt.target.value = evt.target.value?.replace(/[^\w0-9]+/g, "").toUpperCase();

                                                        handleChange(evt);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.voucherCode}
                                                />
                                                <FormErrorMessage>{errors.voucherCode}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                        <Box paddingBottom={6} display="flex">
                                            <FormControl isRequired isInvalid={Boolean(errors.validFrom)} marginRight={8}>
                                                <FormLabel htmlFor="validFrom">Start date</FormLabel>
                                                <Field name="validFrom" component={DatePicker} />
                                                <FormErrorMessage>{errors.validFrom}</FormErrorMessage>
                                            </FormControl>
                                            <FormControl isRequired isInvalid={Boolean(errors.validUntil)}>
                                                <FormLabel htmlFor="validUntil">End date</FormLabel>
                                                <Field name="validUntil" component={DatePicker} />
                                                <FormErrorMessage>{errors.validUntil}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                        <Box
                                            paddingBottom={6}
                                            display={values.voucherCampaignType == VoucherCampaignType.Reward ? "block" : "none"}
                                        >
                                            <FormControl
                                                isRequired={values.voucherCampaignType == VoucherCampaignType.Reward}
                                                isInvalid={Boolean(errors.monthCredit) && touched.monthCredit}
                                            >
                                                <FormLabel htmlFor="monthCredit">Number of free months </FormLabel>
                                                <Select
                                                    id="monthCredit"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.monthCredit ?? 1}
                                                >
                                                    {[1, 2, 3].map((number: number) => (
                                                        <option value={number} key={number}>
                                                            {number}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box
                                            paddingBottom={6}
                                            display={values.voucherCampaignType != VoucherCampaignType.Reward ? "block" : "none"}
                                        >
                                            <FormControl
                                                isRequired
                                                isInvalid={Boolean(errors.discountTypeId) && touched.discountTypeId}
                                            >
                                                <FormLabel htmlFor="discountTypeId">Discount type</FormLabel>
                                                <Select
                                                    id="discountTypeId"
                                                    placeholder="Choose discount"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                >
                                                    {discountTypes
                                                        ?.filter((p) => p.regionId === Number(region))
                                                        ?.map((p: VoucherCampaignDiscountType) => (
                                                            <option value={p.id} key={p.id}>
                                                                {p.name}
                                                            </option>
                                                        ))}
                                                </Select>
                                                <FormErrorMessage>{errors.discountTypeId}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                        <Box
                                            paddingBottom={6}
                                            display={values.voucherCampaignType != VoucherCampaignType.Reward ? "block" : "none"}
                                        >
                                            <FormControl
                                                isRequired
                                                isInvalid={Boolean(errors.churnEligibilityInDays) && touched.churnEligibilityInDays}
                                            >
                                                <FormLabel htmlFor="churnEligibility">Churn eligibility</FormLabel>
                                                <Select
                                                    id="churnEligibilityInDays"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.churnEligibilityInDays}
                                                >
                                                    <option value={0}>Choose churn eligibility</option>
                                                    <option value={""}>N/A</option>
                                                    {[7, 14, 30, 90, 180, 365].map((number: number) => (
                                                        <option value={number} key={number}>
                                                            {number} days
                                                        </option>
                                                    ))}
                                                </Select>
                                                <FormErrorMessage>{errors.churnEligibilityInDays}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                        <Box paddingBottom={6}>
                                            <FormControl
                                                isRequired={values.voucherCampaignType != VoucherCampaignType.Generic}
                                                isInvalid={Boolean(errors.redemptionLimit) && touched.redemptionLimit}
                                            >
                                                <FormLabel htmlFor="redemptionLimit">
                                                    {values.voucherCampaignType == VoucherCampaignType.Generic
                                                        ? "Redemption limit (optional)"
                                                        : "Number of vouchers to redeem"}
                                                </FormLabel>
                                                {values.voucherCampaignType == VoucherCampaignType.Generic && (
                                                    <FormHelperText mb={1}>
                                                        Voucher campaign will expire when this number of activations is reached
                                                    </FormHelperText>
                                                )}
                                                <Input
                                                    id="redemptionLimit"
                                                    placeholder={
                                                        values.voucherCampaignType == VoucherCampaignType.Generic ? "Limit" : "Number"
                                                    }
                                                    onChange={(evt) => {
                                                        evt.target.value = evt.target.value?.replace(/[^0-9]+/g, "");
                                                        if (evt.target.value) evt.target.value = parseInt(evt.target.value).toString();

                                                        handleChange(evt);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.redemptionLimit}
                                                    type="text"
                                                    width="20%"
                                                />

                                                <FormErrorMessage>{errors.redemptionLimit}</FormErrorMessage>
                                            </FormControl>
                                        </Box>
                                    </Form>
                                </ModalBody>
                                <ModalFooter display="flex" justifyContent="center">
                                    <Box padding={4}>
                                        <Button
                                            colorScheme="green"
                                            size="lg"
                                            onClick={() => {
                                                handleSubmit();
                                                setFormsubmitted(true);
                                            }}
                                            isLoading={isSubmitting}
                                        >
                                            Create
                                        </Button>
                                    </Box>
                                </ModalFooter>
                            </>
                        </ModalContent>
                    )}
                </Formik>
            </Modal>
        </>
    );
}
