import { Flex, List, ListItem, Text } from "@chakra-ui/react";
import { Account } from "../../redux/types";
import { absoluteModalStyles } from "./ManualMigrationModal";

function SearchAccountList({
    filteredUsers,
    handleAccountSelection,
}: {
    filteredUsers: Account[];
    handleAccountSelection: (user: Account) => void;
}) {
    return (
        <List
            {...absoluteModalStyles}
            overflowY="auto"
            maxH="21rem"
            sx={{
                "&::-webkit-scrollbar": {
                    width: "0.5rem",
                },
                "&::-webkit-scrollbar-thumb": {
                    bgColor: "#e0e0e0",
                    borderRadius: "1rem",
                },
                "&::-webkit-scrollbar-track": {
                    bgColor: "#f9f9f9",
                    borderRadius: "1rem",
                    margin: "0.5rem 0",
                },
                "li:first-of-type": {
                    borderTopLeftRadius: "1rem",
                    borderTopRightRadius: "1rem",
                },
                "li:last-of-type": {
                    borderBottomLeftRadius: "1rem",
                    borderBottomRightRadius: "1rem",
                },
            }}
        >
            {filteredUsers.map((user) => (
                <ListItem
                    key={user.userAccountId}
                    padding="1rem 1.5rem"
                    onClick={() => handleAccountSelection(user)}
                    _hover={{
                        cursor: "pointer",
                        bgColor: "#e9e9e9",
                    }}
                >
                    <Flex gap="1.25rem">
                        <Text as="span" fontWeight="700" flexBasis="1.56rem">
                            {user.loginType === 4 ? "SE" : user.loginType === 5 ? "FI" : "NO"}
                        </Text>
                        <Text as="span">{user.email}</Text>
                    </Flex>
                </ListItem>
            ))}
        </List>
    );
}

export default SearchAccountList;
