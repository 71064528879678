import { Flex } from "@chakra-ui/react";
import CircularProgress from "./CircularProgress";

function Spinner() {
    return (
        <Flex justifyContent="center" alignItems="center" position="relative">
            <CircularProgress />
        </Flex>
    );
}

export default Spinner;
