import { Tbody, Tr, Td, Skeleton } from "@chakra-ui/react";

const EncodingQueueTableRowsSkeleton = ({
    rowCount = 10,
    columnCount = 9,
    rowHeight = "25px",
}: {
    rowCount?: number;
    columnCount?: number;
    rowHeight?: string;
}) => (
    <Tbody>
        {[...Array(rowCount)].map((_, rowIndex) => (
            <Tr key={rowIndex}>
                {[...Array(columnCount)].map((_, columnIndex) => (
                    <Td>
                        <Skeleton key={columnIndex} height={rowHeight} />
                    </Td>
                ))}
            </Tr>
        ))}
    </Tbody>
);

export default EncodingQueueTableRowsSkeleton;
