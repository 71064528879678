import {useDispatch, useSelector} from "react-redux";
import {Button, Select} from "@chakra-ui/react";
import {loginUser} from "../../../redux/authActions";
import { LOGIN_WITH_SCHIBSTED} from "../../../ManageUsers/redux/types";
import {CHANGE_ACCOUNT_REGION, RegionLogin} from "../../../redux/types";
import {useCookies} from "react-cookie/cjs";

const globalState = (state) => state.global;

function Login({ reduxStore }) {
    const dispatch = useDispatch();
    const [, setCookie] = useCookies(["selectedSchibstedAccountRegion"]);
    const regions = [RegionLogin.Sweden,RegionLogin.Norway,RegionLogin.Finland];
    const { accountRegion } = useSelector(globalState);

    const handleClick = () => {
        dispatch(loginUser("/podcasts", reduxStore));
    };

    const changeAccountRegion = (region) => {
        setCookie("selectedSchibstedAccountRegion", region ? region : RegionLogin.Sweden, { path: "/", sameSite: true });
        dispatch({type: CHANGE_ACCOUNT_REGION, region })
    }

    const loginWithSchibsted = () => {
        dispatch({type:LOGIN_WITH_SCHIBSTED, region: accountRegion});
    };

    return (
        <div style={{background: 'white', borderRadius: '1em', padding:'1em'}}>
            <div>
                <Button color='#000' variant='link' onClick={handleClick}>
                    Log in with Podme Account
                </Button>
            </div>
            <hr style={{margin:'.5em 0'}}></hr>
            <div>
                <span>Select region for Schibsted account:</span>
                <Select style={{marginBottom:'.5em'}} value={accountRegion} onChange={(ev) => changeAccountRegion(ev.target.value)}>
                    {regions.map((key) => <option key={key} value={key}>{key}</option>)}
                </Select>

                <Button color='black' variant='link' onClick={loginWithSchibsted}>
                    Log in with Schibsted Account
                </Button>
            </div>
        </div>

    );
}

export default Login;
