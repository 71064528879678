import React from "react";
import { Box, Spinner } from "@chakra-ui/react";

export default function CircularIndeterminate() {
    return (
        <Box display='flex' justifyContent='center' alignItems='center' margin={8}>
            <Spinner color='green.300' size='xl' />
        </Box>
    );
}
