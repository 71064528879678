export class FormValidation {
    private getNextYearDate = (): Date => {
        const day = new Date();
        day.setMonth(day.getMonth() + 12);
        return day;
    };

    setInitialValues(email: string | null | undefined, userGroup: string | null | undefined, validUntil: Date | null | undefined) {
        return {
            email: email ?? "",
            userGroup: userGroup ?? "",
            validUntil: !!validUntil ? new Date(validUntil) : this.getNextYearDate(),
            userPodcastPermissions: [],
        };
    }

    onValidation(values: { email: string; userGroup: string; validUntil: Date }) {
        const errors: {
            email?: string;
            userGroup?: string;
            validUntil?: string;
        } = {};

        if (!values.email || values.email === "") {
            errors.email = "You must enter email address";
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Incorrect email format";
        }
        if (!values.userGroup || values.userGroup === "") {
            errors.userGroup = "You must select a user group";
        }
        if (!values.validUntil) {
            errors.validUntil = "You must set a correct expiration date";
        }

        return errors;
    }
}
