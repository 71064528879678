import { Identity } from "@schibsted/account-sdk-browser";

import config from "./index";
import { RegionLogin } from "../redux/types";

const usePreEnv = process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "feature";

let identity: Identity | undefined;

function init(region: RegionLogin): void {
    if (typeof window !== "undefined") {
        switch (region) {
            case RegionLogin.Sweden:
                identity = new Identity({
                    clientId: usePreEnv ? config.SCHIBSTED_CLIENT_ID : config.SCHIBSTED_CLIENT_ID_SE,
                    redirectUri: config.SCHIBSTED_REDIRECT_URL,
                    env: usePreEnv ? config.SCHIBSTED_ENV : config.SCHIBSTED_ENV_SE,
                    sessionDomain: usePreEnv ? config.SCHIBSTED_SESSION_DOMAIN : config.SCHIBSTED_SESSION_DOMAIN_SE,
                });
                break;
            case RegionLogin.Norway:
                identity = new Identity({
                    clientId: usePreEnv ? config.SCHIBSTED_CLIENT_ID : config.SCHIBSTED_CLIENT_ID_NO,
                    redirectUri: config.SCHIBSTED_REDIRECT_URL,
                    env: usePreEnv ? config.SCHIBSTED_ENV : config.SCHIBSTED_ENV_NO,
                    sessionDomain: usePreEnv ? config.SCHIBSTED_SESSION_DOMAIN : config.SCHIBSTED_SESSION_DOMAIN_NO,
                });
                break;
            case RegionLogin.Finland:
                identity = new Identity({
                    clientId: usePreEnv ? config.SCHIBSTED_CLIENT_ID : config.SCHIBSTED_CLIENT_ID_FI,
                    redirectUri: config.SCHIBSTED_REDIRECT_URL,
                    env: usePreEnv ? config.SCHIBSTED_ENV : config.SCHIBSTED_ENV_FI,
                    sessionDomain: usePreEnv ? config.SCHIBSTED_SESSION_DOMAIN : config.SCHIBSTED_SESSION_DOMAIN_FI,
                });
                break;
        }
    }
}

export { identity, init };
