import { useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { UserRole } from "../redux/types";

interface Props {
    requiredRoles: UserRole[];
}

const manageUsersState = (state: RootState) => state.manageUsers;

const useAuthorization = ({ requiredRoles }: Props) => {
    const { userRole } = useSelector(manageUsersState);
    if (!userRole) return false;

    const isAuthorized = () => requiredRoles.includes(userRole);

    return isAuthorized();
};

export default useAuthorization;
