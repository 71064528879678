import { combineReducers } from "redux";
import { Region } from "../../redux/types";

import {
    GET_ALL_PODCASTS,
    GET_ALL_PODCASTS_SUCCESS,
    GET_EPISODE,
    GET_EPISODE_SUCCESS,
    GET_EPISODE_FAILED,
    GET_PODCAST,
    GET_PODCAST_SUCCESS,
    GET_PODCAST_FAILED,
    GET_EPISODES,
    GET_EPISODES_SUCCESS,
    SEARCH_PODCAST_SUCCESS,
    GET_ALL_CATEGORIES_SUCCESS,
    CREATE_PREMIUM_PODCAST,
    CREATE_PREMIUM_PODCAST_SUCCESS,
    CREATE_PREMIUM_PODCAST_FAILED,
    ADD_RSS_PODCAST,
    ADD_RSS_PODCAST_FAILED,
    ADD_RSS_PODCAST_SUCCESS,
    RESET_FORM,
    RESET_PODCASTS,
    REMOVE_EPISODE_SUCCESS,
    REMOVE_PODCAST_SUCCESS,
    UPLOAD_EPISODE,
    UPLOAD_EPISODE_SUCCESS,
    UPLOAD_EPISODE_FAILED,
    EDIT_EPISODE,
    EDIT_EPISODE_SUCCESS,
    EDIT_EPISODE_FAILED,
    EDIT_PREMIUM_PODCAST,
    EDIT_PREMIUM_PODCAST_SUCCESS,
    EDIT_PREMIUM_PODCAST_FAILED,
    EDIT_RSS_PODCAST,
    EDIT_RSS_PODCAST_SUCCESS,
    EDIT_RSS_PODCAST_FAILED,
    PUBLISH_PODCAST,
    PUBLISH_PODCAST_SUCCESS,
    PUBLISH_PODCAST_FAILED,
    ManagePodcastActionTypes,
    ManagePodcastsState,
} from "./types";

const initialState: ManagePodcastsState = {
    podcastListView: {
        isLoading: false,
        loadMorePodcasts: true,
        podcasts: [],
        currentRegion: Region.sweden,
        categories: null,
    },
    podcastView: {
        isLoading: true,
        podcast: null,
        episodes: [],
        hasMoreEpisodes: true,
        podcastRemoved: false,
        episodeRemoved: false,
        episodeUploaded: false,
        podcastPublished: false,
        podcastUpdated: false,
        episodeUpdated: false,
        isLoadingEpisodes: true,
    },
    addRssPodcast: {
        isLoading: false,
        isSubmitting: false,
        createdPodcast: null,
    },
    createPremiumPodcast: {
        createdPodcast: null,
        isLoading: false,
        isSubmitting: false,
    },
    createEpisode: {
        isLoading: false,
        uploadSuccessful: false,
    },
    publishPodcast: {
        isLoading: false,
        isPublished: false,
    },
    editRssPodcast: {
        categories: null,
        podcast: null,
        isLoading: false,
        updateSuccessful: false,
    },
    editPremiumPodcast: {
        categories: null,
        podcast: null,
        isLoading: false,
        updateSuccessful: false,
    },
    editEpisode: {
        updateSuccessful: false,
        isLoading: false,
    },
};

function podcastListView(state = initialState.podcastListView, action: ManagePodcastActionTypes) {
    switch (action.type) {
        case GET_ALL_PODCASTS:
            return {
                ...state,
                isLoading: true,
            };

        case RESET_PODCASTS:
            return {
                ...state,
                podcasts: [],
            };

        case GET_ALL_PODCASTS_SUCCESS:
            if (action.region !== state.currentRegion) {
                state.podcasts = action.podcasts;
            } else {
                state.podcasts = state.podcasts.concat(action.podcasts);
            }

            return {
                ...state,
                loadMorePodcasts: action.podcasts.length === 30,
                currentRegion: action.region,
                isLoading: false,
            };

        case SEARCH_PODCAST_SUCCESS:
            return {
                ...state,
                searchResult: action.searchResult && action.searchResult.length > 0 ? action.searchResult : [],
            };

        case GET_ALL_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.categories,
            };

        case "@@router/LOCATION_CHANGE":
            return {
                ...state,
                searchResult: null,
            };

        default:
            return state;
    }
}

function podcastView(state = initialState.podcastView, action: ManagePodcastActionTypes) {
    switch (action.type) {
        case GET_PODCAST:
            return {
                ...state,
                podcast: null,
                episodes: [],
                isLoading: true,
                podcastRemoved: false,
                episodeRemoved: false,
                episodeUploaded: false,
                podcastPublished: false,
                podcastUpdated: false,
                episodeUpdated: false,
            };

        case GET_PODCAST_SUCCESS:
            return {
                ...state,
                podcast: action.podcast,
                isLoading: false,
            };

        case GET_PODCAST_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case REMOVE_PODCAST_SUCCESS:
            return {
                ...state,
                podcastRemoved: true,
            };

        case GET_EPISODES:
            return {
                ...state,
                episodes: state.episodes ?? [],
                isLoadingEpisodes: true,
            };

        case GET_EPISODES_SUCCESS: {
            return {
                ...state,
                episodes: state.episodes.concat(action.episodes),
                hasMoreEpisodes: action.episodes.length >= 30,
                isLoadingEpisodes: false,
                isLoading: false,
            };
        }

        case REMOVE_EPISODE_SUCCESS:
            return {
                ...state,
                episodeRemoved: true,
                episodes: [],
            };

        case UPLOAD_EPISODE_SUCCESS:
            return {
                ...state,
                episodes: [],
                episodeUploaded: true,
            };

        case PUBLISH_PODCAST_SUCCESS:
            return {
                ...state,
                podcastPublished: true,
            };

        case EDIT_PREMIUM_PODCAST_SUCCESS:
        case EDIT_RSS_PODCAST_SUCCESS:
            return {
                ...state,
                podcastUpdated: true,
            };

        case EDIT_EPISODE:
            return {
                ...state,
                episodeUpdated: false,
            };

        case EDIT_EPISODE_SUCCESS:
            return {
                ...state,
                episodeUpdated: true,
                episodes: [],
            };

        case RESET_FORM:
            return {
                ...state,
                error: null,
                podcastRemoved: false,
                episodeRemoved: false,
                episodeUploaded: false,
            };

        case "@@router/LOCATION_CHANGE":
            return {
                ...state,
                isLoading: true,
                podcast: null,
            };

        default:
            return state;
    }
}

function createPremiumPodcast(state = initialState.createPremiumPodcast, action: ManagePodcastActionTypes) {
    switch (action.type) {
        case CREATE_PREMIUM_PODCAST:
            return {
                ...state,
                isLoading: true,
                isSubmitting: true,
            };

        case CREATE_PREMIUM_PODCAST_SUCCESS:
            return {
                ...state,
                createdPodcast: action.createdPodcast,
                isLoading: false,
                isSubmitting: false,
            };

        case CREATE_PREMIUM_PODCAST_FAILED:
            return {
                ...state,
                isLoading: false,
                isSubmitting: false,
            };

        case GET_ALL_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.categories,
            };

        case RESET_FORM:
            return {
                ...state,
                createdPodcast: false,
            };

        default:
            return state;
    }
}

function addRssPodcast(state = initialState.addRssPodcast, action: ManagePodcastActionTypes) {
    switch (action.type) {
        case ADD_RSS_PODCAST:
            return {
                ...state,
                isLoading: true,
                isSubmitting: true,
            };

        case ADD_RSS_PODCAST_SUCCESS:
            return {
                ...state,
                createdPodcast: action.createdPodcast,
                isLoading: false,
                isSubmitting: false,
            };

        case ADD_RSS_PODCAST_FAILED:
            return {
                ...state,
                isLoading: false,
                isSubmitting: false,
            };

        case GET_ALL_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.categories,
            };

        case RESET_FORM:
            return {
                ...state,
                createdPodcast: null,
            };

        default:
            return state;
    }
}

function createEpisode(state = initialState.createEpisode, action: ManagePodcastActionTypes) {
    switch (action.type) {
        case ADD_RSS_PODCAST:
            return {
                ...state,
                isLoading: true,
                isSubmitting: true,
            };

        case ADD_RSS_PODCAST_SUCCESS:
            return {
                ...state,
                createdPodcast: action.createdPodcast,
                isLoading: false,
                isSubmitting: false,
            };

        case ADD_RSS_PODCAST_FAILED:
            return {
                ...state,
                isLoading: false,
                isSubmitting: false,
            };

        case UPLOAD_EPISODE:
            return {
                ...state,
                isLoading: true,
            };

        case UPLOAD_EPISODE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                uploadSuccessful: true,
            };

        case UPLOAD_EPISODE_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case RESET_FORM:
            return {
                ...state,
                uploadSuccessful: false,
                isSubmitting: false,
                isLoading: false,
            };

        default:
            return state;
    }
}

function publishPodcast(state = initialState.publishPodcast, action: ManagePodcastActionTypes) {
    switch (action.type) {
        case GET_PODCAST:
            return {
                ...state,
                isPublished: false,
                isLoading: false,
            };

        case PUBLISH_PODCAST:
            return {
                ...state,
                isLoading: true,
            };

        case PUBLISH_PODCAST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isPublished: true,
            };

        case PUBLISH_PODCAST_FAILED:
            return {
                ...state,
                isLoading: false,
                isPublished: false,
            };

        default:
            return state;
    }
}

function editRssPodcast(state = initialState.editRssPodcast, action: ManagePodcastActionTypes) {
    switch (action.type) {
        case GET_ALL_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.categories,
            };

        case GET_PODCAST_SUCCESS:
            return {
                ...state,
                podcast: action.podcast,
            };

        case EDIT_RSS_PODCAST:
            return {
                ...state,
                isLoading: true,
            };

        case EDIT_RSS_PODCAST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                updateSuccessful: true,
            };
        case EDIT_RSS_PODCAST_FAILED:
            return {
                ...state,
                isLoading: false,
                updateSuccessful: true,
            };

        default:
            return state;
    }
}

function editPremiumPodcast(state = initialState.editRssPodcast, action: ManagePodcastActionTypes) {
    switch (action.type) {
        case GET_ALL_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.categories,
            };

        case GET_PODCAST_SUCCESS:
            return {
                ...state,
                podcast: action.podcast,
            };

        case EDIT_PREMIUM_PODCAST:
            return {
                ...state,
                isLoading: true,
            };

        case EDIT_PREMIUM_PODCAST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                updateSuccessful: true,
            };

        case EDIT_PREMIUM_PODCAST_FAILED:
            return {
                ...state,
                isLoading: false,
                updateSuccessful: true,
            };

        default:
            return state;
    }
}

function editEpisode(state = initialState.editEpisode, action: ManagePodcastActionTypes) {
    switch (action.type) {
        case GET_EPISODE:
            return {
                ...state,
                isLoading: true,
            };

        case GET_EPISODE_SUCCESS:
            return {
                ...state,
                episode: action.episode,
                isLoading: false,
            };

        case GET_EPISODE_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case EDIT_EPISODE:
            return {
                ...state,
                isLoading: true,
            };

        case EDIT_EPISODE_SUCCESS:
            return {
                ...state,
                updateSuccessful: true,
            };

        case EDIT_EPISODE_FAILED:
            return {
                ...state,
                isLoading: false,
            };

        case RESET_FORM:
            return {
                ...state,
                error: null,
                isLoading: false,
                episode: null,
                updateSuccessful: false,
            };

        default:
            return state;
    }
}

export default combineReducers<ManagePodcastsState>({
    podcastListView,
    podcastView,
    createPremiumPodcast,
    addRssPodcast,
    createEpisode,
    publishPodcast,
    editRssPodcast,
    editPremiumPodcast,
    editEpisode,
});
