import { ModalHeader, ModalCloseButton, ModalBody, Flex, Box, ModalFooter, Button, Text, Spinner } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { PodmeColor } from "../../../redux/types";
import { fadeIn } from "../../../styles/animations";
import { Account } from "../../redux/types";
import { ArrowRightAltRounded, WarningRounded } from "@material-ui/icons";
import { RootState } from "../../../redux/reducers/rootReducer";
import { useSelector } from "react-redux";
import { SelectedAccountInfo } from "./SelectedAccountSection";

interface Props {
    selectedSchibstedAccount: Account | null;
    setShowConfirmView: (value: boolean) => void;
    handleManualMigration: () => void;
}

const manageUsersState = (state: RootState) => state.manageUsers;

function ManualMigrationConfirmView({ selectedSchibstedAccount, setShowConfirmView, handleManualMigration }: Props) {
    const [isMigrating, setIsMigrating] = useState(false);
    const { user } = useSelector(manageUsersState);

    const masterAccount = useMemo(() => {
        return user?.accounts.find((acc) => acc.userAccountId === user?.id);
    }, [user?.id]);

    const handleClick = () => {
        setIsMigrating(true);

        setTimeout(() => {
            handleManualMigration();
        }, 1000);
    };

    if (!selectedSchibstedAccount || !masterAccount) return null;

    return (
        <>
            <ModalHeader p="0">Manual migration</ModalHeader>
            <ModalCloseButton top="1rem" right="1rem" rounded="50%" />
            <ModalBody p="0">
                <Flex direction="column" gridGap="1rem">
                    <Text>
                        Are you sure you want to manually migrate this user to the selected{" "}
                        <Box as="span" fontWeight="500">
                            Schibsted
                        </Box>{" "}
                        account?
                    </Text>
                    <Flex alignItems="center" gap="0.5rem">
                        <SelectedAccountInfo selectedAccount={masterAccount} />
                        <ArrowRightAltRounded fontSize="large" htmlColor={PodmeColor.Silver} />
                        <SelectedAccountInfo selectedAccount={selectedSchibstedAccount} />
                    </Flex>
                </Flex>
            </ModalBody>
            <ModalFooter pt="1rem !important" p="0" justifyContent="space-between">
                <Flex gap="0.5rem" alignContent="center">
                    <WarningRounded htmlColor={PodmeColor.Red} />
                    <Text lineHeight="1.7" fontWeight="700">
                        THIS PROCESS IS IRREVERSIBLE!
                    </Text>
                </Flex>
                <Flex gridGap="1rem">
                    <Button padding="1.5rem 2rem" rounded="0.75rem" bgColor="transparent" onClick={() => setShowConfirmView(false)}>
                        Go back
                    </Button>
                    <Button padding="1.5rem 2rem" rounded="0.75rem" bgColor={PodmeColor.Cyan} onClick={handleClick}>
                        Confirm
                    </Button>
                </Flex>
            </ModalFooter>
            {isMigrating && (
                <Flex
                    animation={`${fadeIn} 100ms ease-in forwards`}
                    position="absolute"
                    inset="0"
                    bgColor={PodmeColor.SoftWhite}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    zIndex="1"
                    rounded="1.5rem"
                    gap="1rem"
                >
                    <Spinner thickness="2px" speed="0.65s" color={PodmeColor.Silver} size="sm" />
                    <Text as="span" fontSize="1rem" fontWeight="500">
                        Migrating user...
                    </Text>
                </Flex>
            )}
        </>
    );
}

export default ManualMigrationConfirmView;
