import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Flex, Box, Image, Text, Button } from "@chakra-ui/react";
import { HiUsers, HiTicket } from "react-icons/hi";
import { FaKey, FaPodcast } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import { HiLogout } from "react-icons/hi";
import { SiContentful } from "react-icons/si";
import { HiQueueList } from "react-icons/hi2";

import { colors } from "../constants";
import { useMedia } from "../hooks";

import podmeLogo from "../assets/images/logo_white.svg";
import HamburgerButton from "./HamburgerButton/HamburgerButton";
import RegionFilter from "../components/RegionFilter/RegionFilter";
import { UserRole } from "../redux/types";
import { RootState } from "../redux/reducers/rootReducer";
import types from "../redux/types";
import { toast } from "react-toastify";

interface Props {
    children: any;
}

function Page({ children }: Props) {
    const { isLargeScreen } = useMedia();

    return (
        <Flex height="100%">
            {isLargeScreen ? <Navigation isAlwaysShown={true} setNavShown={() => null} /> : <Header />}

            <Box
                flexGrow={1}
                backgroundColor={colors.backgroundColor}
                padding={["1rem"]}
                maxWidth="100%"
                marginTop={!isLargeScreen ? "44px" : 0}
                position="relative"
            >
                <Box maxWidth="1600px" margin="0 auto" paddingTop={[8, null, 12]} paddingBottom={[8, null, 0]}>
                    {children}
                </Box>
            </Box>
        </Flex>
    );
}

function Header() {
    const { isLargeScreen } = useMedia();
    const [navIsShown, setNavShown] = useState(false);

    return (
        <>
            {!isLargeScreen && (
                <Box
                    as="header"
                    position="fixed"
                    top="0"
                    left="0"
                    right="0"
                    background={colors.darkblue}
                    height="44px"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    maxWidth="100%"
                    zIndex={99}
                >
                    <Box paddingLeft="1rem" zIndex={1001}>
                        <HamburgerButton toggle={() => setNavShown(!navIsShown)} close={!navIsShown} />
                    </Box>

                    <Image
                        src={podmeLogo}
                        alt="podme"
                        width="60px"
                        maxWidth="100%"
                        position="absolute"
                        left={`calc(50% - 30px)`}
                        zIndex={1001}
                    />

                    <Navigation navIsShown={navIsShown} setNavShown={setNavShown} />
                </Box>
            )}
        </>
    );
}

const manageUsersState = (state: RootState) => state.manageUsers;
const globalState = (state: RootState) => state.global;

function Navigation({ navIsShown, setNavShown, isAlwaysShown }: any) {
    const dispatch = useDispatch();
    const { isLargeScreen } = useMedia();
    const { userRole } = useSelector(manageUsersState);
    const { region } = useSelector(globalState);

    const linkStyle: any = {
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
    };
    const iconStyle: any = { position: "absolute", left: "-26px" };

    return (
        <>
            <Box
                as="nav"
                backgroundColor="#11223F"
                height="100dvh"
                minWidth={isLargeScreen ? "18rem" : "100%"}
                position={["fixed", null, "sticky"]}
                top="0"
                bottom="0"
                transform={navIsShown || isAlwaysShown ? "translateX(0)" : "translateX(-100%)"}
                transition="transform .3s"
                zIndex={999}
                paddingTop={!isLargeScreen ? "6rem" : 0}
            >
                {isLargeScreen && (
                    <Box padding="2rem 0 4rem 2rem">
                        <Image src={podmeLogo} alt="podme" width="150px" maxWidth="100%" />
                    </Box>
                )}

                <Flex alignItems="flex-start" flexDir="column" gridGap={2} paddingLeft="calc(3rem + 10px)">
                    {(userRole === UserRole.Admin || userRole === UserRole.Editor || userRole === UserRole.Support) && (
                        <NavLink
                            to={`/users`}
                            style={linkStyle}
                            onClick={() => setNavShown(false)}
                            activeStyle={{
                                color: colors.aquablue,
                                borderBottom: `2px solid ${colors.aquablue}`,
                            }}
                        >
                            <HiUsers style={iconStyle} />
                            <Text fontWeight="600" lineHeight="2">
                                Users
                            </Text>
                        </NavLink>
                    )}
                    {(userRole === UserRole.Admin || userRole === UserRole.Editor || userRole === UserRole.Podcaster) && (
                        <NavLink
                            to={`/podcasts`}
                            exact
                            style={linkStyle}
                            onClick={() => setNavShown(false)}
                            activeStyle={{
                                color: colors.aquablue,
                                borderBottom: `2px solid ${colors.aquablue}`,
                            }}
                        >
                            <FaPodcast style={iconStyle} />
                            <Text fontWeight="600" lineHeight="2">
                                Podcasts
                            </Text>
                        </NavLink>
                    )}
                    {(userRole === UserRole.Admin || userRole === UserRole.Editor || userRole === UserRole.Support) && (
                        <NavLink
                            to={`/encoding-queue`}
                            exact
                            style={linkStyle}
                            onClick={() => setNavShown(false)}
                            activeStyle={{
                                color: colors.aquablue,
                                borderBottom: `2px solid ${colors.aquablue}`,
                            }}
                        >
                            <HiQueueList style={iconStyle} />
                            <Text fontWeight="600" lineHeight="2">
                                Encoding queue
                            </Text>
                        </NavLink>
                    )}
                    {(userRole === UserRole.Admin || userRole === UserRole.Editor) && (
                        <NavLink
                            to={`/statistics`}
                            style={linkStyle}
                            onClick={() => setNavShown(false)}
                            activeStyle={{
                                color: colors.aquablue,
                                borderBottom: `2px solid ${colors.aquablue}`,
                            }}
                        >
                            <GoGraph style={iconStyle} />
                            <Text fontWeight="600" lineHeight="2">
                                Statistics
                            </Text>
                        </NavLink>
                    )}
                    {(userRole === UserRole.Admin || userRole === UserRole.Editor || userRole === UserRole.Support) && (
                        <NavLink
                            to={`/voucher-campaigns`}
                            style={linkStyle}
                            onClick={() => setNavShown(false)}
                            activeStyle={{
                                color: colors.aquablue,
                                borderBottom: `2px solid ${colors.aquablue}`,
                            }}
                        >
                            <HiTicket style={iconStyle} />
                            <Text fontWeight="600" lineHeight="2">
                                Voucher campaigns
                            </Text>
                        </NavLink>
                    )}
                    {(userRole === UserRole.Admin || userRole === UserRole.Editor) && (
                        <NavLink
                            to={`/cms-preview`}
                            style={linkStyle}
                            onClick={() => setNavShown(false)}
                            activeStyle={{
                                color: colors.aquablue,
                                borderBottom: `2px solid ${colors.aquablue}`,
                            }}
                        >
                            <SiContentful style={iconStyle} />
                            <Text fontWeight="600" lineHeight="2">
                                CMS Preview
                            </Text>
                        </NavLink>
                    )}
                    {(userRole === UserRole.Admin || userRole === UserRole.Editor) && (
                        <NavLink
                            to={`/access-and-permissions`}
                            style={linkStyle}
                            onClick={() => setNavShown(false)}
                            activeStyle={{
                                color: colors.aquablue,
                                borderBottom: `2px solid ${colors.aquablue}`,
                            }}
                        >
                            <FaKey style={iconStyle} />
                            <Text fontWeight="600" lineHeight="2">
                                Access & Permissions
                            </Text>
                        </NavLink>
                    )}
                </Flex>
                <Box position="absolute" bottom={0} left={0} right={0}>
                    <Flex alignItems="center" justifyContent="flex-start" paddingLeft={8} paddingBottom={8}>
                        <HiLogout color={colors.white} fontSize="1.2em" />
                        <Button
                            color={colors.white}
                            variant="link"
                            ml={2}
                            onClick={() => {
                                dispatch({ type: types.LOGOUT_USER });
                                toast.success("Successfully logged out.");
                            }}
                        >
                            <Text fontSize="18px" fontWeight="600">
                                Log out
                            </Text>
                        </Button>
                    </Flex>
                    <Box padding="0.5rem" bg={colors.lightblue}>
                        {(userRole === UserRole.Admin || userRole === UserRole.Editor) && (
                            <RegionFilter
                                defaultValue={region}
                                handleChange={(value) => dispatch({ type: types.CHANGE_REGION, region: value })}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
            {navIsShown && (
                <Box
                    as="span"
                    position="fixed"
                    top="0"
                    bottom="0"
                    left="0"
                    right="0"
                    background="rgba(0, 0, 0, 0.4)"
                    zIndex={998}
                    onClick={() => setNavShown(false)}
                />
            )}
        </>
    );
}

export default Page;
