import { Box, Grid } from "@chakra-ui/react";
import { ReactElement } from "react";
import { colors } from "../../constants";
import { UserGroup } from "../redux/types";
import UpdateUserGroupModal from "./UpdateUserGroupModal";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { formatInTimeZone } from "date-fns-tz";
import RemoveUserGroupButton from "./RemoveUserGroupButton";
import { Podcast } from "../../redux/types";

const globalState = (state: RootState) => state.global;

interface Props {
    userGroups: any;
    premiumPodcasts: Podcast[];
    groups: string[] | null;
    onSubmit: any;
}

export default function UserGroupsTable({ userGroups, premiumPodcasts, groups, onSubmit }: Props): ReactElement {
    const { timezone } = useSelector(globalState);

    return (
        userGroups &&
        userGroups.map((userGroup: UserGroup) => (
            <Box backgroundColor="white" width="100%" borderBottom={`1px solid ${colors.lightGray}`} padding={4} key={userGroup.email}>
                <Grid templateColumns={["25% 10% 25% 10% 10% 20%"]} height="60px">
                    <Box display="flex" alignItems="center">
                        {userGroup.email}
                    </Box>
                    <Box display="flex" alignItems="center">
                        {userGroup.name}
                    </Box>
                    <Box display="flex" alignItems="center">
                        {formatInTimeZone(new Date(userGroup.validUntil), timezone, "yyyy-MM-dd")}
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent={["flex-end", null, "flex-end"]}>
                        <UpdateUserGroupModal
                            userGroups={groups}
                            premiumPodcasts={premiumPodcasts}
                            onSubmit={onSubmit}
                            buttonLabel="Edit role"
                            emailFieldDisabled={true}
                            email={userGroup.email}
                            role={userGroup.name}
                            validUntil={userGroup.validUntil}
                        />
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent={["flex-end", null, "flex-end"]}>
                        <RemoveUserGroupButton userId={userGroup.userId} userGroup={userGroup.name} />
                    </Box>
                </Grid>
            </Box>
        ))
    );
}
