import { Button } from "@chakra-ui/react";

import { VoucherCampaign, VoucherCampaignType } from "../redux/types";

interface Props {
    onSubmit: any;
    voucherCampaign: VoucherCampaign;
    marginRight?: "string" | number;
    isLoading: boolean;
}

export default function DownloadVoucherCodes({ onSubmit, voucherCampaign, marginRight, isLoading }: Props) {
    return (
        <Button
            colorScheme="gray"
            size="sm"
            marginRight={marginRight}
            display={canCampaignContainMultipleVoucherCodes(voucherCampaign.type) ? "" : "none"}
            onClick={onSubmit}
            isLoading={isLoading}
        >
            Download codes
        </Button>
    );
}

function canCampaignContainMultipleVoucherCodes(voucherCampaignType: number) {
    return [VoucherCampaignType.Unique, VoucherCampaignType.Reward].includes(voucherCampaignType);
}
