import {
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogCloseButton,
    AlertDialogBody,
    AlertDialogFooter,
    Button,
    Flex,
    Box,
    Spinner,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { colors } from "../../../constants";
import { PodmeColor } from "../../../redux/types";

interface Props {
    isOpen: boolean;
    isDeletingUser: boolean;
    setDeleteAlertOpened: (arg0: boolean) => void;
    deleteUserFn: (id: string) => void;
    userAccountId: string;
}

function DeleteUserModal({ isOpen, isDeletingUser, userAccountId, setDeleteAlertOpened, deleteUserFn }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const cancelRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (isDeletingUser) {
            setIsLoading(true);
        } else if (!isDeletingUser && isLoading) {
            setDeleteAlertOpened(false);
            setIsLoading(false);
        }
    }, [isDeletingUser]);

    return (
        <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={() => setDeleteAlertOpened(false)}
            isOpen={isOpen}
            isCentered
            closeOnOverlayClick={!isLoading}
            closeOnEsc={!isLoading}
        >
            <AlertDialogOverlay bgColor={PodmeColor.BlackPearl + "50"} backdropBlur="20rem" />

            <AlertDialogContent position="relative" userSelect={isLoading ? "none" : "auto"} minHeight="11rem">
                {isLoading ? (
                    <Box position="absolute" inset="0" zIndex="10" bgColor={PodmeColor.SoftWhite} rounded="0.5rem">
                        <Flex
                            gap="0.5rem"
                            height="100%"
                            rounded="0.5rem"
                            justifyContent="center"
                            alignItems="center"
                            direction="column"
                        >
                            <Spinner /> <Box as="span">Deleting user account...</Box>
                        </Flex>
                    </Box>
                ) : (
                    <>
                        <AlertDialogHeader>Delete user account</AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>Are you sure you want to delete this User Account?</AlertDialogBody>
                        <AlertDialogFooter display="flex" gridGap="0.75rem">
                            <Button ref={cancelRef} onClick={() => setDeleteAlertOpened(false)}>
                                Cancel
                            </Button>
                            <Button
                                colorScheme="red"
                                backgroundColor={colors.red}
                                color={PodmeColor.SoftWhite}
                                _hover={{
                                    filter: "brightness(1.2)",
                                }}
                                onClick={() => deleteUserFn(userAccountId)}
                            >
                                Confirm
                            </Button>
                        </AlertDialogFooter>
                    </>
                )}
            </AlertDialogContent>
        </AlertDialog>
    );
}

export default DeleteUserModal;
