import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, NavLink } from "react-router-dom";
import { Box, Heading, Flex } from "@chakra-ui/react";

import SubscriptionsPage from "./SubscriptionsPage/SubscriptionsPage";
import OverviewPage from "./OverviewPage/OverviewPage";

import { actions } from "./redux";
import { RootState } from "../redux/reducers/rootReducer";
import { colors } from "../constants";
import { useMedia } from "../hooks";

const statisticsState = (state: RootState) => state.statistics;
const globalState = (state: RootState) => state.global;

export default function Statistics() {
    const dispatch = useDispatch();
    const { premiumPodcasts } = useSelector(statisticsState);
    const { region } = useSelector(globalState);
    const { isLargeScreen } = useMedia();

    useEffect(() => {
        if (region) {
            dispatch(actions.getPremiumPodcasts(region));
        }
    }, [region]);

    const linkStyle: any = {
        fontWeight: "bold",
        fontSize: isLargeScreen ? "20px" : "16px",
        color: colors.white,
        lineHeight: 2,
    };

    return (
        <Box>
            <Heading as="h1" size={"xl"} mb={8} color="white">
                Statistics
            </Heading>

            <Flex width={["100%", null, "40%"]} justifyContent="space-between" alignItems="center" paddingBottom={[8, null, 16]}>
                <NavLink
                    to={`/statistics`}
                    exact
                    style={linkStyle}
                    activeStyle={{
                        color: colors.aquablue,
                        borderBottom: `2px solid ${colors.aquablue}`,
                    }}
                >
                    Overview
                </NavLink>
                <NavLink
                    to={`/statistics/subscriptions`}
                    exact
                    style={linkStyle}
                    activeStyle={{
                        color: colors.aquablue,
                        borderBottom: `2px solid ${colors.aquablue}`,
                    }}
                >
                    Subscriptions
                </NavLink>
            </Flex>

            <Switch>
                <Route exact path="/statistics" render={() => <OverviewPage />} />
                <Route exact path="/statistics/subscriptions" render={() => <SubscriptionsPage podcasts={premiumPodcasts} />} />
            </Switch>
        </Box>
    );
}
