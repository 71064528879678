import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { HeroCard, HeroCardProps } from './heroCard';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
`;

const SmallList = styled.div`
    display: flex;
    flex-direction: row;
`;

const SmallImg = styled.img`
    width: 40px;
    height: 40px;
    margin-right:16px;
    border-radius: 4px;
`;

interface HeroCardsProps {
    data: {
        mainHeader: string;
        imageUrl: string;
        heroCards: [HeroCardProps["data"]]
    },
}

export default function HeroCards({ data }: HeroCardsProps) {
    const [active, setActive] = useState(data.heroCards[0]);
    useEffect(() => setActive(data.heroCards[0]), [data.heroCards]);
    const handleClick = (card: HeroCardProps["data"]) => {
        setActive(card);
    };
    return (
        <Container>
            <HeroCard data={active} />
            <SmallList>
                {data.heroCards.map((h, i) => <SmallImg alt="small hero" key={i} src={h.imageUrl} onClick={() => handleClick(h)} />)}
            </SmallList>
        </Container>
    )
}