import { Box, Image } from "@chakra-ui/react";

import sweden_flag from "../../assets/images/sweden_flag.svg";
import norway_flag from "../../assets/images/norway_flag.svg";
import finland_flag from "../../assets/images/finland_flag.svg";
import { Region } from "../../redux/types";

import styles from "./RegionFilter.module.css";

interface RegionFilterProps {
    handleChange: (value: any) => void;
    defaultValue: Region;
}

export default function RegionFilter({ handleChange, defaultValue }: RegionFilterProps) {
    return (
        <Box display='flex' justifyContent='center' padding={2}>
            <Box
                onChange={(e: any) => {
                    e.persist();
                    const { value } = e.target;
                    handleChange(value);
                }}
                defaultValue={defaultValue}
                height='30px'
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                gridGap='1rem'
            >
                <FlagButton name='region' value={Region.sweden} imageSrc={sweden_flag} isChecked={Number(defaultValue) === Region.sweden} />
                <FlagButton name='region' value={Region.norway} imageSrc={norway_flag} isChecked={Number(defaultValue) === Region.norway} />
                <FlagButton
                    name='region'
                    value={Region.finland}
                    imageSrc={finland_flag}
                    isChecked={Number(defaultValue) === Region.finland}
                />
            </Box>
        </Box>
    );
}

interface FlagButtonProps {
    isChecked?: boolean;
    value: any;
    name: string;
    imageSrc: string;
}

function FlagButton({ isChecked, value, name, imageSrc }: FlagButtonProps) {
    return (
        <label className={styles.flag}>
            <input className={styles.flag} type='radio' name={name} value={value} defaultChecked={isChecked} />
            <Image
                border={isChecked ? `2px solid black` : ""}
                opacity={isChecked ? 1 : 0.5}
                height={isChecked ? "30px" : "29px"}
                objectFit='cover'
                src={imageSrc}
            />
        </label>
    );
}
