import types from "../types";
import Cookies from "universal-cookie";
import config from "../../config/index";

const initialState = {
    hello: null,
    session: null,
    userLoggedIn: false,
    authChecked: false,
    userRoleChecked: false,
    userLoggedInWithSchibsted: false
};

export default function (state = initialState, action = []) {
    switch (action.type) {
        case types.USER_IS_LOGGED_IN:
            return {
                ...state,
                userLoggedIn: true,
                authChecked: true,
                hello: action.hello,
                session: action.session,
            };
        case types.USER_IS_LOGGED_IN_WITH_SCHIBSTED:
            return {
                ...state,
                userLoggedIn: true,
                authChecked: true,
                hello: null,
                session: null
            }
        case types.USER_IS_NOT_LOGGED_IN: {
            if(state.userLoggedInWithSchibsted) {
                return {
                    ...state
                };
            }

            const cookies = new Cookies();
            cookies.remove(config.COOKIE_NAME, { path: "/" });
            localStorage.setItem("loggedIn", 0);
            localStorage.removeItem("hello");
            localStorage.removeItem("expToken");

            return {
                ...state,
                userLoggedIn: false,
                authChecked: true,
                userLoggedInWithSchibsted: false
            };
        }

        case types.LOGIN_USER_SUCCESS: {
            return {
                ...state,
                userLoggedIn: true,
                userLoggedInWithSchibsted: action.userLoggedInWithSchibsted
            };
        }
        case types.LOGOUT_USER: {
            const cookies = new Cookies();
            cookies.remove(config.COOKIE_NAME, { path: "/" });
            cookies.remove(config.COOKIE_NAME, { path: "/podcasts" });
            localStorage.setItem("loggedIn", "0");
            localStorage.removeItem("hello");
            localStorage.removeItem("expToken");

            return {
                ...state,
                userLoggedIn: false,
                authChecked: true,
                userLoggedInWithSchibsted: false
            }

        }
        default:
            return state;
    }
}
