import { useState } from 'react';
import styled from "styled-components";
import { Box, Heading, Divider, Select, Text, Button, Input } from "@chakra-ui/react";
import { Braze } from '../utils/braze';
import config from "../config";
import { SubscriptionBrazeModel } from './SubscriptionBrazeModel';
import { BrazeApi } from './BrazeApi';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 20px;
`;

const InputContainer = styled.div`
    display:flex;
    flex-direction: row;
`;

const apiKey = config.BRAZE_API_KEY;
const options = {
    baseUrl: config.BRAZE_SDK_ENDPOINT,
    enableLogging: false,
    localization: "en"
};

const episodes = [
    {
        episodeId: 1246522,
        episodeTitle: 'Hur får man bröllopstalet att flyga?',
        podcastId: 4649,
        podcastTitle: 'Filip & Fredrik Svarar',
        isPremium: true
    },
    {
        episodeId: 1251996,
        episodeTitle: '728. Svart Iphone',
        podcastId: 645,
        podcastTitle: 'Tutto Balutto',
        isPremium: true
    },
    {
        episodeId: 1246486,
        episodeTitle: 'Kvinnan i gruvhålet - Del 1: Den värsta skräcken',
        podcastId: 3652,
        podcastTitle: 'Podme Dokumentär',
        isPremium: true
    },
    {
        episodeId: 1319488,
        episodeTitle: 'Great Grey Wolf',
        podcastId: 2162,
        podcastTitle: 'Creepy',
        isPremium: false
    },
    {
        episodeId: 1319113,
        episodeTitle: '20: The Joy of Going Out to Lunch',
        podcastId: 8330,
        podcastTitle: 'Joy Comes in the Morning by Amanda',
        isPremium: false
    },
];

const subscriptionPlans = [
    {
        id: 68,
        name: 'Package Sweden Adyen - 99',
        price: 99,
        currency: 'SEK',
        paymentType: 'Adyen',
        monthLimit: null
    },
    {
        id: 3,
        name: 'Package Norway Apple',
        price: 99,
        currency: 'NOK',
        paymentType: 'Apple',
        monthLimit: null
    },
    {
        id: 4,
        name: 'Package Norway Google',
        price: 79,
        currency: 'NOK',
        paymentType: 'Google',
        monthLimit: null
    },
    {
        id: 69,
        name: 'Package Sweden Adyen - 1 month free - then 99 kr',
        price: 0,
        currency: 'SEK',
        paymentType: 'Adyen',
        monthLimit: 1
    },
    {
        id: 74,
        name: 'Package Sweden Adyen - 3 months 50% - then 99 kr',
        price: 49.50,
        currency: 'SEK',
        paymentType: 'Adyen',
        monthLimit: 3
    },
    {
        id: 65,
        name: 'Package Sweden Bundler',
        price: 0,
        currency: 'SEK',
        paymentType: 'Bundler',
        monthLimit: null
    },
];

const brazeService = new Braze(apiKey, options);
const brazeApi = new BrazeApi();

export default function BrazePlayground() {
    const [cards, setCards] = useState<any>([]);
    const [episodeId, setEpisode] = useState(episodes[0].episodeId);
    const [userAccountId, setUserId] = useState('');
    const [loginType, setLoginType] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [hasUser, setHasUser] = useState(false);

    const handleLoginType = (ev: any) => setLoginType(ev.target.value);
    const handleEmail = (ev: any) => setEmail(ev.target.value);
    const handleCountry = (ev: any) => setCountry(ev.target.value);

    const [subscriptions, setSubscriptions] = useState<SubscriptionBrazeModel[]>([]);
    const [category, setCategory] = useState('');
    const [optedOutFromPersonalization, setOptedOutFromPersonalization] = useState('');
    const [inactivatedSubscription12monthsAgo, setInactivatedSubscription12monthsAgo] = useState('');
    const [userHasSchibstedSubscriptionOutsidePodme, setUserHasSchibstedSubscriptionOutsidePodme] = useState('');
    const [listenedPodcasts, setListenedPodcasts] = useState<string[]>([]);
    const [followedPodcasts, setFollowedPodcasts] = useState<string[]>([]);
    const [premiumListenedPodcastsCount, setPremiumListenedPodcastCount] = useState<number>(0);

    const handleCategory = (ev: any) => setCategory(ev.target.value);
    const handleOptedOutFromPersonalization = (ev: any) => setOptedOutFromPersonalization(ev.target.value);
    const handleInactivatedSubscription12monthsAgo = (ev: any) => setInactivatedSubscription12monthsAgo(ev.target.value);
    const handleUserHasSchibstedSubscriptionOutsidePodme = (ev: any) => setUserHasSchibstedSubscriptionOutsidePodme(ev.target.value);
    const handleSelectEpisode = (ev: any) => setEpisode(ev.target.value);

    const handleSchibstedGdprFlags = (ev: any) => {
        if (category?.length > 0) {
            brazeService.setUserAttribute('interests_top_category', category);
        }
        if (optedOutFromPersonalization?.length) {
            brazeService.setUserAttribute('opted_out_from_personalization', optedOutFromPersonalization);
        }
        if (inactivatedSubscription12monthsAgo?.length) {
            brazeService.setUserAttribute('inactivated_subscription_12_months_ago', inactivatedSubscription12monthsAgo);
        }
        if (userHasSchibstedSubscriptionOutsidePodme?.length) {
            brazeService.setUserAttribute('user_has_schibsted_subscription_outside_podme', userHasSchibstedSubscriptionOutsidePodme);
        }
        alert('Attributes added');
    }

    const handleCustomAttributes = (ev: any) => {
        if (category?.length > 0) {
            brazeService.setUserAttribute('interests_top_category', category);
        }
        alert('Attributes added');
    }

    const handleUserId = async (ev: any) => {
        const userId = ev.target.value;
        const exportedUser = await brazeApi.exportUsers(userId);

        if (exportedUser) {
            const topCategory = getCustomAttributeByKey<string>(exportedUser.custom_attributes, 'interests_top_category');
            const loginType = getCustomAttributeByKey<string>(exportedUser.custom_attributes, 'account_login_method');
            const inactivatedSubscription12MonthsAgo = getCustomAttributeByKey<string>(exportedUser.custom_attributes, 'inactivated_subscription_12_months_ago');
            const userHasSchibstedSubscriptionOutsidePodme = getCustomAttributeByKey<string>(exportedUser.custom_attributes, 'user_has_schibsted_subscription_outside_podme');
            const optedOutFromPersonalization = getCustomAttributeByKey<string>(exportedUser.custom_attributes, 'opted_out_from_personalization');
            const podcastFollowed = getCustomAttributeByKey<string[]>(exportedUser.custom_attributes, 'interests_podcasts_followed');
            const podcastListened = getCustomAttributeByKey<string[]>(exportedUser.custom_attributes, 'listenings_podcasts_titles');
            const premiumListenedPodcastsCount = getCustomAttributeByKey<number>(exportedUser.custom_attributes, 'listenings_podcasts_counter_premium');
            const subscriptions = getCustomAttributeByKey<SubscriptionBrazeModel[]>(exportedUser.custom_attributes, 'subscriptions');
            translateSubscriptionStatusesToBackendCompatible(subscriptions);

            setEmail(exportedUser.email);
            setCountry(exportedUser.country);
            setCategory(topCategory);
            setLoginType(loginType);
            setInactivatedSubscription12monthsAgo(inactivatedSubscription12MonthsAgo);
            setUserHasSchibstedSubscriptionOutsidePodme(userHasSchibstedSubscriptionOutsidePodme);
            setOptedOutFromPersonalization(optedOutFromPersonalization);
            setFollowedPodcasts(podcastFollowed ?? []);
            setListenedPodcasts(podcastListened ?? []);
            setPremiumListenedPodcastCount(premiumListenedPodcastsCount ?? 0);
            setSubscriptions(subscriptions ?? []);
            checkForNewCards();
        }
        else {
            setListenedPodcasts([]);
            setFollowedPodcasts([]);
            setSubscriptions([]);
            setCards([]);
        }

        setUserId(userId);
    }

    function getCustomAttributeByKey<T>(customAttributes: Record<string, object>, key: string): T {
        return customAttributes[key] as any;
    }

    function translateSubscriptionStatusesToBackendCompatible(subscriptions: SubscriptionBrazeModel[]){
        subscriptions.forEach(sub => {
            switch(sub.subs_status.toLowerCase()) {
                case 'pending cancellation':
                    sub.subs_status = 'Canceled';
                    break;
                case 'terminated':
                    sub.subs_status = 'Inactive';
                    break;
                case 'in trial':
                    sub.subs_status = 'InTrial';
                    break;
            }
        })
    }

    const handleHasUser = (ev: any) => {
        if (!hasUser) {
            if (userAccountId.length === 0 || loginType.length === 0 || country.length === 0 || email.length === 0) {
                alert("Please enter all user info");
                return;
            }

            const addUserEvent = {
                account_login_method: loginType,
                account_creation_date: new Date(),
            }

            brazeService.identify(userAccountId);
            brazeService.setUserInfo(email, country);
            brazeService.setUserAttribute('account_login_method', loginType);
            brazeService.setUserAttribute('account_creation_date', new Date().toDateString());
            brazeService.logCustomEvent('account_created', addUserEvent);
        }

        setHasUser(!hasUser);
    };

    const checkForNewCards = async () => {
        const newCards = await brazeService.getContentCards();
        console.log(newCards);
        setCards(newCards);
    }

    const handleStartTrialSubscription = async (event: any) => {
        event.preventDefault();
        const subscriptionId = event.target.elements.subscriptionId.value;
        const subscriptionPlanId = event.target.elements.subscriptionPlanId.value;
        const couponCode = event.target.elements.couponCode.value !== '' ?
            event.target.elements.couponCode.value as string
            : null;

        if (!subscriptionId || !subscriptionPlanId) {
            alert("Please enter subscription id and select subscription plan");
            return;
        }

        if (subscriptions?.find(s => s.subs_id === subscriptionId)) {
            alert("Inserted subscription id already exists. Please enter a unique id");
            return;
        }

        const subscriptionPlan = subscriptionPlans.find(p => p.id == subscriptionPlanId);
        const subscriptionStatus = subscriptions?.length > 0 ? 'Active' : 'InTrial';
        const eventNameStart = 'subscription_started';
        const dateNow = new Date();
        const isCoupon = couponCode ? true : false;
        const isTrial = subscriptionStatus === 'InTrial';
        const isReactivated = false;
        const subscriptionEndDate = subscriptionPlan?.monthLimit ?
            new Date(new Date().setMonth(new Date().getMonth() + subscriptionPlan.monthLimit))
            : (isTrial ? new Date(new Date().setDate(new Date().getDate() + 14)) : new Date(new Date().setMonth(new Date().getMonth() + 1)));
        const trialEndDate = isTrial ? subscriptionEndDate : null;

        const subscription = {
            subs_id: subscriptionId,
            subs_status: subscriptionStatus,
            subs_plan: subscriptionPlan?.name,
            subs_start_date: dateNow,
            subs_trial_end_date: trialEndDate,
            subs_next_billing_date: subscriptionEndDate,
            subs_payment_type: subscriptionPlan?.paymentType,
            subs_is_reactivated: isReactivated,
            subs_continous_months: 0,
            coupon_used_code: couponCode
        } as SubscriptionBrazeModel;

        if (couponCode) {
            brazeService.setUserAttribute("coupon_used_codes", { add: couponCode });
            brazeService.setUserAttribute("coupon_latest_used_code", couponCode);
        }

        brazeService.setUserAttribute("subscriptions", { $add: [subscription] });

        const subscriptionStartedEvent = {
            subs_id: subscription?.subs_id,
            subs_plan: subscription?.subs_plan,
            subs_start_date: subscription?.subs_start_date,
            subs_next_billing_date: subscriptionEndDate,
            subs_is_trial: isTrial,
            subs_trial_or_coupon_ends_at: subscriptionEndDate,
            subs_is_reactivated: subscription.subs_is_reactivated,
            coupon_is_coupon: isCoupon,
            coupon_used_code: couponCode,
        };

        brazeService.logCustomEvent(eventNameStart, subscriptionStartedEvent);

        subscriptions.push(subscription);
        setSubscriptions(subscriptions)

        clearFormValues(event);
        alert("Subscription started");
        await checkForNewCards();
    };

    const handleTrialToActive = (subscriptionId: number) => {
        const subscription = subscriptions.find(s => s.subs_id === subscriptionId)!;
        subscription.subs_continous_months += 1;
        subscription.subs_status = 'Active';
        subscription.subs_next_billing_date = new Date(new Date().setMonth(new Date().getMonth() + 1));

        const updateSubscription = {
            $update: [{
                $identifier_key: "subs_id",
                $identifier_value: subscription?.subs_id,
                $new_object: {
                    subs_id: subscription.subs_id,
                    subs_status: subscription.subs_status,
                    subs_plan: subscription.subs_plan,
                    subs_start_date: subscription.subs_start_date,
                    subs_next_billing_date: subscription.subs_next_billing_date,
                    subs_payment_type: subscription.subs_payment_type,
                    subs_is_reactivated: subscription.subs_is_reactivated,
                    subs_continuos_months: subscription.subs_continous_months,
                    coupon_used_code: subscription.coupon_used_code
                }
            }]
        };

        brazeService.setUserAttribute("subscriptions", updateSubscription);
        alert("Subscription converted from trial to active");
    };

    const handleRenewal = (subscriptionId: number) => {
        const subscription = subscriptions.find(s => s.subs_id === subscriptionId)!;
        subscription.subs_continous_months += 1;
        subscription.subs_next_billing_date = new Date(new Date().setMonth(new Date().getMonth() + 1));

        const updateSubscription = {
            $update: [{
                $identifier_key: "subs_id",
                $identifier_value: subscription?.subs_id,
                $new_object: {
                    subs_id: subscription.subs_id,
                    subs_status: subscription.subs_status,
                    subs_plan: subscription.subs_plan,
                    subs_start_date: subscription.subs_start_date,
                    subs_next_billing_date: subscription.subs_next_billing_date,
                    subs_payment_type: subscription.subs_payment_type,
                    subs_is_reactivated: subscription.subs_is_reactivated,
                    subs_continuos_months: subscription.subs_continous_months,
                    coupon_used_code: subscription.coupon_used_code
                }
            }]
        };

        brazeService.setUserAttribute("subscriptions", updateSubscription);
        alert("Subscription monthly renewal");
    };

    const handleCancel = (subscriptionId: number) => {
        const subscription = subscriptions.find(s => s.subs_id === subscriptionId)!;
        subscription.subs_continous_months = 0;
        subscription.subs_status = 'Canceled';
        subscription.subs_cancel_requested_date = new Date();
        subscription.subs_end_date = subscription.subs_next_billing_date;

        const updateSubscription = {
            $update: [{
                $identifier_key: "subs_id",
                $identifier_value: subscription?.subs_id,
                $new_object: {
                    subs_id: subscription.subs_id,
                    subs_status: subscription.subs_status,
                    subs_plan: subscription.subs_plan,
                    subs_start_date: subscription.subs_start_date,
                    subs_end_date: subscription.subs_end_date,
                    subs_payment_type: subscription.subs_payment_type,
                    subs_cancel_requested_date: subscription.subs_cancel_requested_date,
                    subs_is_reactivated: subscription.subs_is_reactivated,
                    subs_continuos_months: subscription.subs_continous_months,
                    coupon_used_code: subscription.coupon_used_code
                }
            }]
        };

        brazeService.setUserAttribute("subscriptions", updateSubscription);

        const cancelEvent = {
            subs_id: subscriptionId,
            subs_cancel_requested_date: new Date()
        };

        brazeService.logCustomEvent("subscription_cancel_requested", cancelEvent);
        alert("Subscription cancelled");
    };

    const handleReactivate = (subscriptionId: number) => {
        const subscription = subscriptions.find(s => s.subs_id === subscriptionId)!;
        subscription.subs_is_reactivated = true;
        subscription.subs_continous_months += 1;
        subscription.subs_status = 'Active';
        subscription.subs_next_billing_date = new Date(new Date().setMonth(new Date().getMonth() + 1));

        const updateSubscription = {
            $update: [{
                $identifier_key: "subs_id",
                $identifier_value: subscription?.subs_id,
                $new_object: {
                    subs_id: subscription.subs_id,
                    subs_status: subscription.subs_status,
                    subs_plan: subscription.subs_plan,
                    subs_start_date: subscription.subs_start_date,
                    subs_next_billing_date: subscription.subs_next_billing_date,
                    subs_payment_type: subscription.subs_payment_type,
                    subs_is_reactivated: subscription.subs_is_reactivated,
                    subs_continuos_months: subscription.subs_continous_months,
                    coupon_used_code: subscription.coupon_used_code
                }
            }]
        };

        brazeService.setUserAttribute("subscriptions", updateSubscription);
        alert("Subscription reactivated");
    };

    const handleInactivate = (subscriptionId: number) => {
        const subscription = subscriptions.find(s => s.subs_id === subscriptionId)!;
        subscription.subs_continous_months = 0;
        subscription.subs_status = 'Inactive';
        subscription.subs_end_date = new Date();

        const updateSubscription = {
            $update: [{
                $identifier_key: "subs_id",
                $identifier_value: subscription?.subs_id,
                $new_object: {
                    subs_id: subscription.subs_id,
                    subs_status: subscription.subs_status,
                    subs_plan: subscription.subs_plan,
                    subs_start_date: subscription.subs_start_date,
                    subs_payment_type: subscription.subs_payment_type,
                    subs_is_reactivated: subscription.subs_is_reactivated,
                    subs_continuos_months: subscription.subs_continous_months,
                    coupon_used_code: subscription.coupon_used_code
                }
            }]
        };

        brazeService.setUserAttribute("subscriptions", updateSubscription);
        alert("Subscription inactivated");
    };

    const handleListenToEpisode = (ev: any) => {
        const selectedEpisode = episodes.find(ep => ep.episodeId == episodeId);

        if (!selectedEpisode) {
            alert('Something went wrong. Try again.');
        }

        const addListenedEpisode = { add: [selectedEpisode!.podcastTitle] };
        brazeService.setUserAttribute("listenings_podcasts_titles", addListenedEpisode);
        brazeService.setUserAttribute('listenings_latest_date', new Date());
        brazeService.setUserAttribute('listenings_last_listened_podcast', selectedEpisode!.podcastTitle);

        listenedPodcasts.push(selectedEpisode?.podcastTitle!);
        setListenedPodcasts(listenedPodcasts);

        if (selectedEpisode?.isPremium) {
            const premiumListenedCount = premiumListenedPodcastsCount + 1;
            setPremiumListenedPodcastCount(premiumListenedCount)

            brazeService.setUserAttribute('listenings_podcasts_counter_premium', premiumListenedCount);
            brazeService.setUserAttribute('listenings_latest_date_premium', new Date());
        }

        alert(`Listening to ${selectedEpisode?.podcastTitle}`);
    };

    const handleFollowPodcast = (ev: any) => {
        const selectedEpisode = episodes.find(ep => ep.episodeId == episodeId);

        if (!selectedEpisode) {
            alert('Something went wrong. Try again.');
        }

        const addListenedEpisode = { add: [selectedEpisode!.podcastTitle] };
        const podcastFollowCounter = followedPodcasts.length ?? 0;
        brazeService.setUserAttribute("interests_podcasts_followed", addListenedEpisode);
        brazeService.setUserAttribute('interests_follow_counter', podcastFollowCounter);

        followedPodcasts.push(selectedEpisode!.podcastTitle);
        setFollowedPodcasts(followedPodcasts);

        alert(`Follow podcast ${selectedEpisode?.podcastTitle}`);
    };

    const handleRemoveCard = (card: any) => {
        brazeService.logCardClick(card);
        brazeService.logCardDismissal(card);
        const remainingCards = cards.filter((c: any) => c.id !== card.id);
        setCards(remainingCards);
    };

    const clearFormValues = (event: any) => {
        event.target.elements.subscriptionId.value = '';
        event.target.elements.subscriptionPlanId.value = '';
        event.target.elements.couponCode.value = '';
    }

    return (
        <Box width='100%' margin='0 auto'>
            <Heading as='h1' size='xl' mb='20px' color='white'>Braze Playground</Heading>
            <Container>
                <Box borderWidth='1px' borderRadius='lg' padding='20px' mb='20px'>
                    <Heading as='h2' size='m'>User</Heading>
                    {hasUser && <Text mb='8px' paddingBottom='10px'>{userAccountId}, {loginType}, {email}, {country}</Text>}
                    {!hasUser && (
                        <Box>
                            <InputContainer>
                                <Input value={userAccountId} onChange={handleUserId} placeholder='Enter user Id' />
                                <Select disabled={!userAccountId} mb='10px' value={loginType} onChange={handleLoginType}>
                                    <option key={0} value=''>Pick login method</option>
                                    <option key={1} value='PodMeAccount'>PodMeAccount</option>
                                    <option key={2} value='Apple'>Apple</option>
                                    <option key={3} value='Google'>Google</option>
                                    <option key={4} value='Facebook'>Facebook</option>
                                    <option key={5} value='SchibstedSE'>SchibstedSE</option>
                                    <option key={6} value='SchibstedFI'>SchibstedFI</option>
                                    <option key={7} value='SchibstedNO'>SchibstedNO</option>
                                </Select>
                                <Input disabled={!userAccountId} value={email} onChange={handleEmail} placeholder='Enter email' />
                                <Select disabled={!userAccountId} mb='10px' value={country} onChange={handleCountry}>
                                    <option key={0} value=''>Pick country</option>
                                    <option key={1} value='SE'>SE</option>
                                    <option key={2} value='NO'>NO</option>
                                    <option key={3} value='FI'>FI</option>
                                </Select>
                            </InputContainer>
                        </Box>
                    )}
                    <Button disabled={!userAccountId} onClick={handleHasUser} mt='10px'>
                        {hasUser ? 'Change User' : 'Set User'}
                    </Button>
                </Box>

                {hasUser && <>
                    <Box borderWidth='1px' borderRadius='lg' padding='20px' mb='20px'>
                        <Heading as='h2' size='m'>Custom Attributes</Heading>
                        <Text>{category}</Text>
                        <Select mb='10px' value={category} onChange={handleCategory}>
                            <option key={0} value=''>Top category</option>
                            <option key={1} value='Sport'>Sport</option>
                            <option key={2} value='Comedy'>Comedy</option>
                            <option key={3} value='True Crime'>True Crime</option>
                            <option key={4} value='Documentary'>Documentary</option>
                        </Select>
                        <Button onClick={handleCustomAttributes}>Set custom attributes</Button>
                    </Box>

                    <Box borderWidth='1px' borderRadius='lg' padding='20px' mb='20px'>
                        <Heading as='h2' size='m'>GDPR flags</Heading>
                        <Text>Opted out from personalization: {optedOutFromPersonalization}</Text>
                        <Text>Inactivated subscription 12 months ago: {inactivatedSubscription12monthsAgo}</Text>
                        <Text>User has Schibsted subscription outside Podme: {userHasSchibstedSubscriptionOutsidePodme}</Text>
                        <Select mb='10px' value={optedOutFromPersonalization} onChange={handleOptedOutFromPersonalization}>
                            <option key={0} value=''>Opted out from personalization</option>
                            <option key={1} value='True'>True</option>
                            <option key={2} value='False'>False</option>
                        </Select>
                        <Select mb='10px' value={inactivatedSubscription12monthsAgo} onChange={handleInactivatedSubscription12monthsAgo}>
                            <option key={0} value=''>Inactivated subscription 12 months ago</option>
                            <option key={1} value='True'>True</option>
                            <option key={2} value='False'>False</option>
                        </Select>
                        <Select mb='10px' value={userHasSchibstedSubscriptionOutsidePodme} onChange={handleUserHasSchibstedSubscriptionOutsidePodme}>
                            <option key={0} value=''>User has Schibsted subscription outside Podme</option>
                            <option key={1} value='True'>True</option>
                            <option key={2} value='False'>False</option>
                        </Select>
                        <Button onClick={handleSchibstedGdprFlags}>Set GDPR flags</Button>
                    </Box>

                    <Box borderWidth='1px' borderRadius='lg' padding='20px' mb='20px'>
                        <Heading as='h2' size='m' paddingBottom='10px'>Subscriptions ({subscriptions?.length ?? 0})</Heading>
                        {subscriptions?.map(subscription =>
                            <Box key={subscription.subs_id} borderWidth='1px' borderRadius='lg' padding='20px' mb='20px'>
                                <Heading as='h2' size='m'>Subscription</Heading>
                                <Text mb='8px' paddingBottom='10px'>
                                    {subscription.subs_id}, {subscription.subs_plan}, {subscription.subs_status}, {subscription.coupon_used_code}
                                </Text>
                                <Box>
                                    {subscription.subs_status === 'InTrial' &&
                                        <>
                                            <Button mr='20px' onClick={() => handleTrialToActive(subscription.subs_id)}>
                                                Convert trial to active
                                            </Button>
                                            <Button mr='20px' onClick={() => handleCancel(subscription.subs_id)}>
                                                Cancel Subscription
                                            </Button>
                                        </>
                                    }
                                    {(subscription.subs_status !== 'InTrial' && subscription.subs_status !== 'Canceled' && subscription.subs_status !== 'Inactive') &&
                                        <>
                                            <Button mr='20px' onClick={() => handleRenewal(subscription.subs_id)}>
                                                Renew subscription
                                            </Button>
                                            <Button mr='20px' onClick={() => handleCancel(subscription.subs_id)}>
                                                Cancel Subscription
                                            </Button>
                                        </>
                                    }
                                    {(subscription.subs_status === 'Canceled') &&
                                        <>
                                            <Button mr='20px' onClick={() => handleReactivate(subscription.subs_id)}>
                                                Reactivate Subscription
                                            </Button>
                                            <Button mr='20px' onClick={() => handleInactivate(subscription.subs_id)}>
                                                Inactivate Subscription
                                            </Button>
                                        </>
                                    }
                                    {subscription.subs_status === 'Inactive' &&
                                        <Button mt='10px' onClick={() => handleReactivate(subscription.subs_id)}>
                                            Reactivate Inactive Subscription
                                        </Button>
                                    }
                                </Box>
                            </Box>
                        )}
                        <Box borderWidth='1px' borderRadius='lg' padding='20px' mb='20px'>
                            <Heading as='h2' size='m' paddingBottom='10px'>Add new subscription</Heading>
                            <form onSubmit={handleStartTrialSubscription}>
                                <InputContainer>
                                    <Input name="subscriptionId" placeholder='Enter subscription Id' />
                                    <Select mb='10px' name="subscriptionPlanId">
                                        <option value=''>Pick subscription plan</option>
                                        {subscriptionPlans.map(s => <option key={s.id} value={s.id}>{s.name} ({s.currency})</option>)}
                                    </Select>
                                    <Input name="couponCode" placeholder='Coupon code' />
                                </InputContainer>
                                <Button mt='10px' type="submit">
                                    {subscriptions?.length > 0 ? 'Start Subscription' : 'Start Trial Subscription'}
                                </Button>
                            </form>
                        </Box>
                    </Box>

                    <Box borderWidth='1px' borderRadius='lg' padding='20px' mb='20px'>
                        <Heading as='h2' size='m'>Podcasts</Heading>
                        <Text>Listened podcasts: {listenedPodcasts.map(podcast => `${podcast}, `)}</Text>
                        <Text>Followed podcasts: {followedPodcasts.map(podcast => `${podcast}, `)}</Text>
                        <InputContainer>
                            <Select value={episodeId} onChange={handleSelectEpisode}>
                                {episodes.map(e => <option key={e.episodeId} value={e.episodeId}>{e.podcastTitle}: {e.episodeTitle} {e.isPremium ? '(premium)' : '(rss)'}</option>)}
                            </Select>
                            <Button ml='20px' onClick={handleListenToEpisode}>Listen</Button>
                            <Button ml='20px' onClick={handleFollowPodcast}>Follow</Button>
                        </InputContainer>
                    </Box>

                    {cards.length > 0 && <Heading size='md'>Content cards:</Heading>}
                    <Divider mt='5px' mb='20px' />
                    <Button onClick={checkForNewCards}>Check for new messages</Button>
                    {cards.map((card: any) => {
                        return (
                            <Box key={card.id} padding='10px'>
                                <Heading size='s'>
                                    {card.title}
                                </Heading>
                                <Text>{card.description}</Text>
                                <Button onClick={() => handleRemoveCard(card)}>Remove</Button>
                                <Divider />
                            </Box>
                        );
                    })}
                </>}
            </Container>

        </Box>

    )
}
