import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { Grid, SimpleGrid, Box, Heading, Switch, Text, Flex } from "@chakra-ui/react";
import { FaRegSadTear } from "react-icons/fa";

import PodcastTable from "../components/PodcastTable";
import SearchField from "../../components/SearchField/SearchField";
import { actions } from "../redux";
import { useMedia } from "../../hooks";
import { colors } from "../../constants";
import AddRssPodcast from "../components/AddRssPodcast";
import AddPremiumPodcast from "../components/AddPremiumPodcast";
import { timeout } from "../../utils";
import { UserRole } from "../../ManageUsers/redux/types";

const podcastListViewState = (state) => state.managePodcasts.podcastListView;
const manageUsersState = (state) => state.manageUsers;
const globalState = (state) => state.global;

function PodcastListView() {
    const dispatch = useDispatch();
    const { isLargeScreen } = useMedia();
    const { podcasts, categories, loadMorePodcasts, searchResult, currentRegion } = useSelector(podcastListViewState);
    const { userRole } = useSelector(manageUsersState);
    const { region } = useSelector(globalState);
    const [hasMore, setHasMore] = useState(loadMorePodcasts);
    const [input, setInput] = useState("");
    const [page, setCurrentPage] = useState(0);
    const [filter, setFilter] = useState({ onlyPremium: true });
    const [resetPage, setResetPage] = useState(false);

    useEffect(() => {
        dispatch(actions.resetPodcasts());
        if (!categories) {
            dispatch(actions.getAllCategories());
        }
    }, []);

    useEffect(() => {
        if (region !== currentRegion) {
            dispatch(actions.resetPodcasts());
        }

        if (filter.onlyPremium) {
            if (!resetPage) {
                setCurrentPage(0);
                dispatch(actions.resetPodcasts());
                setResetPage(true);
            }

            dispatch(actions.getAllPodcasts(page, 1, region));
        } else {
            setResetPage(false);
            dispatch(actions.getAllPodcasts(page, 2, region));
        }
    }, [filter.onlyPremium, region, page]);

    useEffect(() => {
        if (searchResult && searchResult.length > 0) {
            setHasMore(false);
        } else {
            setHasMore(loadMorePodcasts);
        }
    }, [loadMorePodcasts, searchResult]);

    useEffect(() => {
        setCurrentPage(0);
    }, [region]);

    const handleChange = async (query) => {
        setInput(query);

        await timeout(400);
        dispatch(actions.searchPodcast(query));

        setCurrentPage(0);
        setHasMore(false);
    };

    const fetchMorePodcasts = () => {
        setCurrentPage(page + 1);
    };

    const data = searchResult && searchResult.length > 0 ? searchResult : podcasts;

    return (
        <>
            <Box width="100%" margin="0 auto">
                <Heading as="h1" size={"xl"} mb={8} color="white">
                    Podcasts
                </Heading>

                {(userRole === UserRole.Admin || userRole === UserRole.Editor) && (
                    <>
                        <Box paddingBottom={1} display="flex" alignItems="center">
                            <Box as="label" htmlFor="enable-premium" color="white" mb={2} mr={3}>
                                Only Premium podcasts:
                            </Box>
                            <Switch
                                id="enable-premium"
                                colorScheme="blue"
                                isChecked={filter.onlyPremium}
                                onChange={(evt) => setFilter({ ...filter, onlyPremium: evt.target.checked })}
                            />
                        </Box>

                        <SimpleGrid columns={[1, 1, 1, 2]} spacing="10px" mb={4}>
                            <Box order={[3, null, null, 1]}>
                                <SearchField label="Search podcast" handleChange={handleChange} type={"podcasts"} />
                            </Box>
                            <Box
                                display="flex"
                                alignItems="flex-end"
                                justifyContent={["flex-start", null, "flex-end"]}
                                order={2}
                                gridGap="1rem"
                            >
                                <AddRssPodcast
                                    onSubmit={(values) => dispatch(actions.createRssPodcast(values))}
                                    resetForm={() => dispatch(actions.resetForm())}
                                />
                                <AddPremiumPodcast
                                    onSubmit={(values) => dispatch(actions.createPremiumPodcast(values))}
                                    resetForm={() => dispatch(actions.resetForm())}
                                />
                            </Box>
                        </SimpleGrid>
                    </>
                )}

                <Box bg={colors.darkblue} padding={2}>
                    <Grid templateColumns={["80% 20%", null, null, "50% 25% 25%"]}>
                        <Box display="flex" alignItems="center">
                            <Text color="white">Podcast</Text>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent={["flex-end", "flex-start"]}>
                            <Text color="white">Type</Text>
                        </Box>
                        {isLargeScreen && (
                            <Box display="flex" alignItems="center" justifyContent="flex-start">
                                <Text color="white">Status</Text>
                            </Box>
                        )}
                    </Grid>
                </Box>

                {searchResult && searchResult.length === 0 && input ? (
                    <Flex align="center" direction="column">
                        <Box paddingBottom={4} paddingTop={8}>
                            <FaRegSadTear style={{ color: "white", fontSize: "4em" }} />
                        </Box>

                        <Text fontSize="xl" color="white">
                            Sorry we cant find what you're looking for
                        </Text>
                    </Flex>
                ) : (
                    <Box>
                        <InfiniteScroll
                            dataLength={data.length}
                            hasMore={hasMore}
                            next={fetchMorePodcasts}
                            loader={<PodcastTable data={[]} />}
                        >
                            <PodcastTable data={data} />
                        </InfiniteScroll>
                    </Box>
                )}
            </Box>
        </>
    );
}

export default PodcastListView;
