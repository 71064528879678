import { Box, FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react";
import EmailAddressTooltip from "./EmailAddressTooltip";
import { FormikErrors, FormikTouched } from "formik";
import { ChangeEventHandler, FocusEventHandler } from "react";

interface Props {
    disabled: boolean;
    errors: FormikErrors<{
        email: string;
        userGroup: string;
        validUntil: Date;
    }>;
    touched: FormikTouched<{
        email: string;
        userGroup: string;
        validUntil: Date;
    }>;
    handleChange: ChangeEventHandler<HTMLInputElement>;
    handleBlur: FocusEventHandler<HTMLInputElement>;
    values: {
        email: string;
        userGroup: string;
        validUntil: Date;
    };
}

export default function EmailAddressInput({ disabled, errors, touched, handleChange, handleBlur, values }: Props) {
    return (
        <Box>
            <FormControl isRequired isInvalid={!!errors.email && touched.email}>
                <Box display={"flex"} margin={"0.5em 0"}>
                    <FormLabel margin={0} htmlFor="email">
                        Email address
                    </FormLabel>
                    <EmailAddressTooltip />
                </Box>
                <Input
                    disabled={disabled}
                    id="email"
                    type="email"
                    placeholder="Email address"
                    onChange={(evt) => {
                        handleChange(evt);
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>
        </Box>
    );
}
