import { Box } from "@chakra-ui/react";
import { LoginType, LOGIN_TYPE } from "../../redux/types";

interface Props {
    loginType: LoginType;
}

function LoginMethodLabel({ loginType }: Props) {
    switch (loginType) {
        case LOGIN_TYPE.Facebook:
            return (
                <Box as="span" fontWeight="700">
                    Facebook
                </Box>
            );
        case LOGIN_TYPE.Google:
            return (
                <Box as="span" fontWeight="700">
                    Google
                </Box>
            );
        case LOGIN_TYPE.Apple:
            return (
                <Box as="span" fontWeight="700">
                    Apple
                </Box>
            );
        case LOGIN_TYPE.SchibstedSE:
            return (
                <Box as="span" fontWeight="700">
                    Schibsted Sweden
                </Box>
            );
        case LOGIN_TYPE.SchibstedNO:
            return (
                <Box as="span" fontWeight="700">
                    Schibsted Norway
                </Box>
            );
        case LOGIN_TYPE.SchibstedFI:
            return (
                <Box as="span" fontWeight="700">
                    Schibsted Finland
                </Box>
            );
        default:
            return (
                <Box as="span" fontWeight="700">
                    Podme
                </Box>
            );
    }
}

export default LoginMethodLabel;
