import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Grid from "react-css-grid";

import SubscriptionsFilter from "../components/SubscriptionsFilter";
import SubscriptionsDisplay from "../components/SubscriptionsDisplay";
import Spinner from "../../components/Spinner";

import { actions } from "../redux";
import { useMedia } from "../../hooks";
import SchibstedSubscriptionsDisplay from "../components/SchibstedSubscriptionsDisplay";

const statisticsState = (state) => state.statistics;
const globalState = (state) => state.global;

function SubscriptionsPage({ podcasts }) {
    const dispatch = useDispatch();
    const { filter, subscriptionsData, loading } = useSelector(statisticsState);
    const { region } = useSelector(globalState);
    const { isLargeScreen } = useMedia();

    useEffect(() => {

        if (filter && filter.subscriptionType === "single") {
            dispatch(actions.getSingleSubscriptions(filter, region));
        } else {
            dispatch(actions.getPackageSubscriptions(filter, region));
        }
    }, [filter, region]);

    return (
        <PageContainer>
            <SubscriptionsFilter podcasts={podcasts} />
            {loading ? (
                <Spinner />
            ) : (
                <Grid width={"100%"} gap={isLargeScreen ? "2rem" : "1rem"}>
                    <SubscriptionsDisplay title='Number of subscribers' displayData={subscriptionsData ? { ...subscriptionsData } : {}} />
                    <SchibstedSubscriptionsDisplay title='Number of Schibsted subscribers' displayData={{ total: subscriptionsData.schibstedTotal }} />
                </Grid>
            )}


        </PageContainer>
    );
}
const PageContainer = styled.div`
    overflow: hidden;
    max-width: 100%;
    margin: 0 auto;

    h1 {
        color: white;
    }
`;

export default SubscriptionsPage;
