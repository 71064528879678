import { Flex, Menu, MenuButton, IconButton, MenuList, MenuItem } from "@chakra-ui/react";
import { MdFilterAlt } from "react-icons/md";

interface Props {
    queryTypes: string[] | undefined;
    changeSearchType: (searchType: string) => void;
}

function QueryTypesDropdown({ queryTypes, changeSearchType }: Props) {
    if (!queryTypes || !queryTypes.length || !changeSearchType) return null;

    return (
        <Flex alignItems="center">
            <Menu>
                <MenuButton rounded="50%" margin-left="1.5em" as={IconButton} icon={<MdFilterAlt />} />
                <MenuList zIndex="20">
                    {queryTypes.map((type) => (
                        <MenuItem
                            key={type}
                            onClick={() => {
                                changeSearchType(type);
                            }}
                        >
                            {type}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Flex>
    );
}

export default QueryTypesDropdown;
