import Category from './category';
import { Header, VerticalList } from './commonStyles';

interface CategoryListProps {
    data: {
        title: string;
        categories: [any];
    }

}
export default function CategoryList({ data }: CategoryListProps) {
    return (
        <div>
            <Header>
                {data.title}
            </Header>
            <VerticalList>
                {data.categories.map((c: any, i: number) => <Category key={i} data={c} />)}
            </VerticalList>
        </div>
    )
}