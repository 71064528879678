import { Box } from "@chakra-ui/react";

import TableContainer from "./TableContainer";
import HorizontalBarChart from "../../Statistics/charts/HorizontalBarChart";

interface Props {
    data: any;
    isLoading: boolean;
}

export default function ActiveVoucherCampaignsGraph({ data, isLoading }: Props) {
    if (!data) return null;

    const labels = data.map((item: any) => item.voucherCampaignName);
    const count = data.map((item: any) => item.redemptionCount);

    return (
        <TableContainer isLoading={isLoading} title='Active voucher campaigns'>
            <Box pl={6} pr={6}>
                <HorizontalBarChart labels={labels} data={count} title='Active voucher campaigns - activations' />
            </Box>
        </TableContainer>
    );
}
