import { PromoItem, PromoItemProps } from './promoItem';
import { Header, HorizontalList } from './commonStyles';

interface PodcastPromoCarouselProps {
    data: {
        title: string;
        promotedPodcasts: [PromoItemProps["data"]];
        isToplist: boolean;
    }
}

export default function PodcastPromoCarousel({ data }: PodcastPromoCarouselProps) {
    return (
        <div>
            <Header>
                {data.title}
            </Header>
            <HorizontalList>
                {data.promotedPodcasts.map((e, i) => <PromoItem key={i} data={{ ...e, isToplist: data.isToplist, index: i }} />)}
            </HorizontalList>
        </div>
    )
}