import * as brazeSDK from "@braze/web-sdk";

export class Braze {
    constructor(apiKey: string, options: any) {
        brazeSDK.initialize(apiKey, options);
    }

    identify = (userAccountId: string) => {
        brazeSDK.changeUser(userAccountId);
        brazeSDK.openSession();
    };

    logCustomEvent = (eventName: string, eventProperties: any) => {
        const result = brazeSDK.logCustomEvent(eventName, eventProperties);
        brazeSDK.requestImmediateDataFlush();
        return result;
    };

    showContentCards = (parentNode: any, filterFunction: any) => {
        brazeSDK.showContentCards(parentNode, filterFunction);
    };

    setUserInfo(email: string, country: string) {
        brazeSDK.getUser()?.setEmail(email);
        brazeSDK.getUser()?.setCountry(country);
    };

    setUserAttribute(key: string, value: object | object[] | number | string | string[] | boolean, merge: boolean = false) {
        brazeSDK.getUser()?.setCustomUserAttribute(key, value, merge);
    };

    getContentCards = () =>
        new Promise((resolve) => {
            brazeSDK.subscribeToContentCardsUpdates((event) => {
                const cards = event.cards;
                resolve(cards);
            });
            brazeSDK.requestContentCardsRefresh();
        });

    logCardClick = (card: any) => {
        brazeSDK.logCardClick(card, true);
    };

    logCardDismissal = (card: any) => {
        brazeSDK.logCardDismissal(card);
    };
}