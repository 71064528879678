import { Button, Modal, ModalBody, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import PodmeModalHeader from "./PodmeModalHeader";
import PodmeModalFooter from "./PodmeModalFooter";
import EmailAddressInput from "./EmailAddressInput";
import ValidUntilSelect from "./ValidUntilSelect";
import RoleSelect from "./RoleSelect";
import { FormValidation } from "./FormValidation";
import PodcastSelect from "./PodcastSelect";
import { Podcast } from "../../redux/types";
import { isUserAuthorizedForAction } from "../../utils";

interface Props {
    userGroups: string[] | null;
    premiumPodcasts: Podcast[];
    onSubmit: any;
    buttonLabel: string;
    emailFieldDisabled: boolean;
    email?: string;
    role?: string;
    validUntil?: Date;
}

export default function UpdateUserGroupModal({
    userGroups,
    premiumPodcasts,
    onSubmit,
    buttonLabel,
    emailFieldDisabled,
    email,
    role,
    validUntil,
}: Props) {
    const formValidation = new FormValidation();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [, setFormsubmitted] = useState(false);

    const manageAccessAndPermissionsState = (state: RootState) => state.manageAccessAndPermissions;
    const { userGroupUpdated } = useSelector(manageAccessAndPermissionsState);

    const manageUsersState = (state: RootState) => state.manageUsers;
    const { userRole } = useSelector(manageUsersState);
    const isUserAuthorized = isUserAuthorizedForAction(userRole, role);

    useEffect(() => {
        if (!isOpen) {
            setFormsubmitted(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (userGroupUpdated) {
            onClose();
        }
    }, [userGroupUpdated]);

    return (
        <>
            <Button disabled={!isUserAuthorized} colorScheme="teal" size="md" onClick={onOpen}>
                {buttonLabel}
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="xl">
                <ModalOverlay backgroundColor="rgba(0,0,0,0.7)" />
                <Formik
                    initialValues={formValidation.setInitialValues(email, role, validUntil)}
                    validate={(values) => formValidation.onValidation(values)}
                    onSubmit={(values, { setSubmitting }) => {
                        onSubmit(values);
                        setSubmitting(false);
                        setFormsubmitted(false);
                        onClose();
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <ModalContent>
                            <>
                                <PodmeModalHeader />
                                <ModalBody>
                                    <Form>
                                        <EmailAddressInput
                                            disabled={emailFieldDisabled}
                                            errors={errors}
                                            touched={touched}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            values={values}
                                        />
                                        <RoleSelect
                                            userGroups={userGroups}
                                            errors={errors}
                                            touched={touched}
                                            handleChange={handleChange}
                                            values={values}
                                        />
                                        <PodcastSelect
                                            premiumPodcasts={premiumPodcasts}
                                            userGroup={values.userGroup}
                                            values={values}
                                            errors={errors}
                                        />
                                        <ValidUntilSelect errors={errors} values={values} />
                                    </Form>
                                </ModalBody>
                                <PodmeModalFooter handleSubmit={handleSubmit} isSubmitting={isSubmitting} />
                            </>
                        </ModalContent>
                    )}
                </Formik>
            </Modal>
        </>
    );
}
