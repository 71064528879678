import styled from "styled-components";

export const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const LoginContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 50px;
`;
