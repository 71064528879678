import { Image } from "@chakra-ui/react";

import facebook from "../../assets/images/facebook.png";
import google from "../../assets/images/google.png";
import podmeLogoBlack from "../../assets/images/logo_black.png";
import apple from "../../assets/images/apple-logo.svg";
import schibstedSweden from "../../assets/images/schibsted_sweden.svg";
import schibstedNorway from "../../assets/images/schibsted_norway.svg";
import schibstedFinland from "../../assets/images/schibsted_finland.svg";
import { LoginType, LOGIN_TYPE } from "../../redux/types";

interface Props {
    loginType: LoginType;
}

function LoginMethodImg({ loginType }: Props) {
    switch (loginType) {
        case LOGIN_TYPE.Facebook:
            return <Image src={facebook} alt="facebook" maxWidth="3.5rem" />;
        case LOGIN_TYPE.Google:
            return <Image src={google} alt="google" maxWidth="2.25rem" />;
        case LOGIN_TYPE.Apple:
            return <Image src={apple} alt="apple" maxWidth="1.5rem" />;
        case LOGIN_TYPE.SchibstedSE:
            return <Image src={schibstedSweden} alt="Schibsted SE" title="Schibsted Sweden" maxWidth="5.5rem" />;
        case LOGIN_TYPE.SchibstedNO:
            return <Image src={schibstedNorway} alt="Schibsted NO" title="Schibsted Norway" maxWidth="5.5rem" />;
        case LOGIN_TYPE.SchibstedFI:
            return <Image src={schibstedFinland} alt="Schibsted FI" title="Schibsted Finland" maxWidth="5.5rem" />;
        default:
            return <Image src={podmeLogoBlack} alt="podme" maxWidth="3.75rem" />;
    }
}

export default LoginMethodImg;
