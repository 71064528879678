import styled from "styled-components";
import { Episode, EpisodeProps } from './episode';
import { Header, VerticalList } from './commonStyles';
import premiumImg from "../../assets/images/cmspreview/premium.svg";

const PremiumImg = styled.img`
    margin-right: 7px;
`;

const TextTop = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;
`;

const SubHeader = styled.h6`
    padding: 0;
    margin: 0;    
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #BABABA;  
`;

export interface EpisodeListProps {
    data: {
        title: string;
        episodes: [EpisodeProps["data"]];
    }
    isSmall?: boolean;
}

export function EpisodeList({ data, isSmall }: EpisodeListProps) {
    return (
        <div>
            {isSmall ? (
                <>
                    <TextTop>
                        <PremiumImg alt="premium" src={premiumImg} />
                        <SubHeader>{data.title}</SubHeader>
                    </TextTop>
                    <VerticalList>
                        {data.episodes.map((e, i) => <Episode isSmall key={i} data={e} />)}
                    </VerticalList>
                </>) :
                (
                    <>
                        <Header>
                            {data.title}
                        </Header>
                        <VerticalList>
                            {data.episodes.map((e, i) => <Episode key={i} data={e} />)}
                        </VerticalList>
                    </>
                )}
        </div>
    )
}