import { AxiosError } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { Region } from "../../redux/types";

import { createAxiosRequest } from "../../utils";

import {
    GET_SINGLE_SUBSCRIPTIONS,
    GET_SINGLE_SUBSCRIPTIONS_SUCCESS,
    GET_SINGLE_SUBSCRIPTIONS_FAILED,
    GET_PACKAGE_SUBSCRIPTIONS,
    GET_PACKAGE_SUBSCRIPTIONS_SUCCESS,
    GET_PACKAGE_SUBSCRIPTIONS_FAILED,
    GET_PREMIUM_PODCASTS,
    GET_PREMIUM_PODCASTS_SUCCESS,
    GET_PREMIUM_PODCASTS_FAILED,
    GetSingleSubscriptions,
    GetPackageSubscriptions,
    GetPremiumPodcasts,
} from "./types";

function* getSingleSubscriptions({ podcastId, platform }: GetSingleSubscriptions): any {
    try {
        let queries = "";

        if (podcastId && platform >= 0) {
            queries = `&podcastId=${podcastId}&platform=${platform}`;
        } else if (podcastId) {
            queries = `&podcastId=${podcastId}`;
        } else if (platform) {
            queries = `&platform=${platform}`;
        }

        const { data } = yield createAxiosRequest(`api/statistics/subscriptions/single?productType=2${queries}`);

        return yield put({ type: GET_SINGLE_SUBSCRIPTIONS_SUCCESS, singleSubscriptions: data });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error: AxiosError = e.response;
            return yield put({ type: GET_SINGLE_SUBSCRIPTIONS_FAILED, message: error.message, status: error.response?.status });
        }
    }
}

function* getPackageSubscriptions({ platform, region = Region.unspecified }: GetPackageSubscriptions): any {
    try {
        const { data } = yield createAxiosRequest(
            `api/statistics/subscriptions/package?productType=1${platform ? `&platform=${platform}` : ""}&region=${region}`
        );

        return yield put({ type: GET_PACKAGE_SUBSCRIPTIONS_SUCCESS, packageSubscriptions: data });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error: AxiosError = e.response;
            return yield put({ type: GET_PACKAGE_SUBSCRIPTIONS_FAILED, message: error.message, status: error.response?.status });
        }
    }
}
function* getPremiumPodcasts({ region }: GetPremiumPodcasts): any {
    try {
        const { data } = yield createAxiosRequest(`api/podcast?page=0&pageSize=200&type=1&region=${region}`);

        return yield put({ type: GET_PREMIUM_PODCASTS_SUCCESS, premiumPodcasts: data });
    } catch (e) {
        if (e && e.stack && e.message) {
            const error: AxiosError = e.response;
            return yield put({ type: GET_PREMIUM_PODCASTS_FAILED, message: error.message, status: error.response?.status });
        }
    }
}

export default [
    takeLatest(GET_SINGLE_SUBSCRIPTIONS, getSingleSubscriptions),
    takeLatest(GET_PACKAGE_SUBSCRIPTIONS, getPackageSubscriptions),
    takeLatest(GET_PREMIUM_PODCASTS, getPremiumPodcasts),
];
